
export interface Price {
  amount: string
  priceLevel: string
}

interface SellPrice extends Price {
  isLoyaltyPrice: boolean
  isB2BPrice: boolean
  isEmployeePrice: boolean
  isSalePrice: boolean
  isVipPrice: boolean
}

export interface SalePrice {
  amount: string
  percentage: string
}

interface PriceWithDiscount extends Price {
  discount: SalePrice
}
export interface LoyaltyCardRefund {
  value: string
  percentage: string
  isExtraPercentage: boolean
}

export interface PriceValidity {
  from: string
  to: string
  started: boolean
  ended: boolean
}

export interface SaleOldPrice {
  amount: string
  priceLevel: string
  discount: SalePrice
}

export enum SaleType {
  blackFriday = 'blackFriday',
  cyberMonday = 'cyberMonday'
}

export interface SaleArticle {
  name: string
  nameLink: string
  nameDetail: string
  alias: string
  saleType: SaleType
  articleId: number
}

export interface SaleInfo {
  value: string
  isoValue: string
  validity: PriceValidity
  oldPrice: SaleOldPrice | null
  article: SaleArticle
  loyaltyCardRefund: LoyaltyCardRefund
}

export interface ProductBoxPriceBundle {
  sellPrice: SellPrice
  oldPrice: PriceWithDiscount | null
  loyaltyPrice: PriceWithDiscount | null
  rrpPrice: Price | null
  hasFreeTransport: boolean
  installmentsBridgeAvailable: boolean
  sale: SalePrice | null
  discount: SalePrice | null
  saleInfo: SaleInfo | null
}

export interface ProductBoxExtendedPriceBundle extends ProductBoxPriceBundle {
  loyaltyCardRefund: LoyaltyCardRefund
}

export interface ProductPriceBundle extends ProductBoxPriceBundle {
  homeCreditAvailable: boolean
  loyaltyCardRefund: LoyaltyCardRefund
}
