import { Ref, useMeta, useRoute, computed } from '@nuxtjs/composition-api'
import VueI18n from 'vue-i18n'
import { MetaAdvancedOptions, MetaOptions } from '~/src/Model/Meta/MetaOptions'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export const useSeoMetaTags = (metaSource: Ref<MetaOptions | null >, advancedOptions: MetaAdvancedOptions | null = null) => {
  const route = useRoute()
  const { app } = useApp()
  const webConfig = useWebConfig()
  const i18n = app.i18n as VueI18n

  const pageQueryName = advancedOptions?.queryName ?? 'page'
  const pageNumberText = computed<string>(() => {
    if (!route.value.query[pageQueryName]) {
      return ''
    }
    return `- ${i18n.t('seo_meta.page', { pageNumber: route.value.query[pageQueryName] }) as string} `
  })

  useMeta(() => {
    if (!metaSource.value) {
      return { }
    }

    let title = `${metaSource.value?.nameTitle ?? ''} ${pageNumberText.value}`
    if (route.value.path !== '/') {
      title += `| ${webConfig.presentableDomain}`
    }

    const description = metaSource.value?.metaDescription ?? ''

    const meta = [
      {
        name: 'description',
        content: description
      },
      {
        name: 'og:description',
        content: description
      },
      {
        name: 'og:title',
        content: title
      },
      {
        name: 'og:url',
        content: `https://${webConfig.domain}${route.value.fullPath}`
      }
    ]

    if (!advancedOptions?.disableRobotsProcessing) {
      meta.push({
        name: 'robots',
        content: metaSource.value?.noIndexFollow ? 'noindex,follow' : 'index,follow'
      })
    }

    return {
      title,
      meta
    }
  })
}
