export enum AclPrivilege {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete'
}

export interface AclPermissionRequest {
  resource: string
  privilege: AclPrivilege
}

export interface AclPermissionResponse {
  isAllowed: boolean
  message: string | null
}

export interface AclPermissionEvaluation {
  request: AclPermissionRequest
  response: AclPermissionResponse
}

export type AclPrivileges = Record<AclPrivilege, AclPermissionResponse>
export type AclResources = Record<string, AclPrivileges>
