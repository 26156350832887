import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { containsTab } from '~/src/Infrastructure/String/ContainsTab/ContainsTab'

export class NoTabsRule extends ValidationRule {
  message = (field: string): string => this.i18n.t('validations.no_tabs', { field }).toString()

  validate = (value: string): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = !containsTab(value)

    return {
      valid
    }
  };
}
