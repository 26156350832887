import { defineStore } from 'pinia'
import { ProductBox, ProductBoxes } from '~/src/Model/ProductBox/ProductBox'
import { useProductBoxesRepositoryWithAxios } from '~/src/Infrastructure/ProductBoxes/ProductBoxesRepository'

const PRODUCT_COMPARISON_STORE_ID = 'productComparison'
const HISTORY_LENGTH = 10

export interface ProductComparisonState {
  productBoxes: ProductBox[]
  products: number[]
  history: number[]
}

export const useProductComparisonStore = defineStore(PRODUCT_COMPARISON_STORE_ID, {
  state: (): ProductComparisonState => ({
    productBoxes: [] as ProductBox[],
    products: [],
    history: []
  }),
  persist: {
    key: PRODUCT_COMPARISON_STORE_ID,
    paths: ['products', 'history']
  },
  getters: {
    lastComparedProduct (): number {
      return this.products[this.products.length - 1]
    },
    isInComparison () {
      return (productId: number) => {
        return this.products.includes(productId)
      }
    }
  },
  actions: {
    addProducts (productsIds: number[]): void {
      const products: number[] = [...new Set([...this.products, ...productsIds])]
      this.products = products

      const history = [...this.history]

      products.forEach((productId) => {
        const historyIndex = history.indexOf(productId)
        if (~historyIndex) {
          history.splice(historyIndex, 1)
        }
      })

      this.history = history
    },
    addProduct (productId: number): void {
      this.addProducts([productId])
    },
    removeProduct (productId: number): void {
      const products = [...this.products]
      const productIndex = products.indexOf(productId)

      if (~productIndex) {
        products.splice(productIndex, 1)
        this.products = products

        const history = [...this.history]
        const historyIndex = history.indexOf(productId)

        if (~historyIndex) {
          history.splice(historyIndex, 1)
        }

        this.history = [productId, ...history.slice(0, HISTORY_LENGTH - 1)]
      }
    },
    async loadProductBoxes (): Promise<ProductBoxes> {
      const { loadProductBoxes } = useProductBoxesRepositoryWithAxios(this.$nuxt.$axios)

      const productBoxesId = this.productBoxes.map((p: ProductBox) => p.product.id)
      const productsWithoutProductBox = this.products.filter((p: number) => !productBoxesId.includes(p))
      const productBoxesWithoutProduct = productBoxesId.filter((pb: number) => !this.products.includes(pb))

      if (productBoxesWithoutProduct.length > 0 || productsWithoutProductBox.length > 0) {
        const response = await loadProductBoxes([...this.products])
        this.productBoxes = response.productBoxes
      }

      return new Promise<ProductBoxes>(() => this.productBoxes)
    },
    toggleProduct (productId: number): void {
      if (this.isInComparison(productId)) {
        return this.removeProduct(productId)
      }

      return this.addProduct(productId)
    }
  }
})
