







































import { defineComponent, ref } from '@nuxtjs/composition-api'
import BaseValidationProvider from '~/components/Forms/Validation/BaseValidationProvider.vue'

export default defineComponent({
  components: { BaseValidationProvider },
  inheritAttrs: false,
  props: {
    dataTest: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    textareaClass: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'eager'
    },
    vid: {
      type: String,
      default: null
    },
    immediate: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    maxlength: {
      type: Number,
      required: false,
      default: null
    },
    rows: {
      type: Number,
      required: false,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    noResize: {
      type: Boolean,
      default: false
    }
  },

  emits: ['input'],

  setup (props, { emit }) {
    const required = ref<boolean>(props.rules.includes('required'))

    const updateValue = (val: string): void => {
      emit('input', val)
    }

    return {
      required,
      updateValue
    }
  }
})
