import { Context } from '@nuxt/types/app'
import { BlackFridayCookieStorage } from '~/src/Infrastructure/Cookies/BlackFridayCookie'

export default function ({ route, error, $cookies }: Context) {
  const blackFridayTestAliases = [
    'nay-blackfriday-test-produkt-number-1',
    'nay-blackfriday-test-produkt-number-2',
    'nay-blackfriday-test-produkt-number-3',
    'nay-blackfriday-test-produkt-number-4',
    'nay-blackfriday-test-produkt-number-5',
    'nay-blackfriday-test-produkt-number-6',
    'nay-blackfriday-test-produkt-number-7',
    'nay-blackfriday-2023-test',
    'ew-blackfriday-test-produkt-number-1',
    'ew-blackfriday-test-produkt-number-2',
    'ew-blackfriday-test-produkt-number-3',
    'ew-blackfriday-test-produkt-number-4',
    'ew-blackfriday-test-produkt-number-5',
    'ew-blackfriday-test-produkt-number-6',
    'ew-blackfriday-test-produkt-number-7',
    'ew-blackfriday-2023-test'
  ]
  const blackFridayCookieStorage = new BlackFridayCookieStorage($cookies)

  const throwError = () => {
    error({ statusCode: 401, message: 'Permission denied' })
  }

  if (!blackFridayCookieStorage.blackFridayCookieAllowed && blackFridayTestAliases.includes(route.name ?? '')) {
    throwError()
  }
}
