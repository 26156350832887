







import { computed, defineComponent } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'span'
    }
  },
  setup () {
    const $webConfig = useWebConfig()

    const isNay = computed<boolean>(() => $webConfig.web === Web.Nay)

    return { isNay }
  }
})
