import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { isPhoneNumber } from '~/src/Infrastructure/String/IsPhoneNumber/IsPhoneNumber'

export class PhoneRule extends ValidationRule {
  params = ['minLength', 'maxLength']
  message = (field: string, { minLength, maxLength }: Record<string, any>): string => {
    if (minLength === maxLength) {
      return this.i18n.t('validations.phone_strict_length', { field, maxLength }).toString()
    }
    return this.i18n.t('validations.phone_with_range', { field, minLength, maxLength }).toString()
  }

  validate = (value: string, { minLength, maxLength }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = isPhoneNumber(value, minLength, maxLength)

    return {
      valid
    }
  }
}
