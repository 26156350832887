var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"modal-class":[_vm.modalClass, {'modal--full-h' : _vm.fullHeight}],"dialog-class":_vm.dialogClass,"header-class":[_vm.headerClass, {'modal-header--empty' : _vm.noHeaderContent}, {'modal-header--buttons' : _vm.showBackwardsButton}],"body-class":_vm.bodyClass,"footer-class":_vm.footerClass,"size":_vm.size,"centered":_vm.centered,"static":_vm.static,"visible":_vm.visible,"hide-footer":_vm.hideFooter,"hide-header":_vm.hideHeader,"scrollable":_vm.scrollable,"cancel-variant":_vm.cancelVariant,"cancel-title-html":("<span class='btn__inner'>" + _vm.cancelText + "</span>"),"ok-variant":_vm.confirmVariant,"ok-title-html":("<span class='btn__inner'>" + _vm.confirmText + "</span>"),"no-close-on-backdrop":_vm.noClose,"no-close-on-esc":_vm.noClose,"no-stacking":_vm.noStacking},on:{"hidden":function($event){return _vm.$emit('close-modal')},"ok":function($event){return _vm.$emit('confirm')},"cancel":function($event){return _vm.$emit('cancel')},"shown":function($event){return _vm.$emit('shown')}},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [(_vm.showBackwardsButton)?_c('button',{staticClass:"modal-back complex-link--hover-underline d-flex align-items-center bs-gap-2 border-0 text-primary typo-complex-14",attrs:{"aria-label":"Go back"},on:{"click":function($event){return _vm.$emit('go-back')}}},[_c('i',{staticClass:"icon-arrow-left icon-fs-14",attrs:{"aria-hidden":"true"}}),_vm._v(" "),(!_vm.hideBackwardsButtonText)?_c('span',{staticClass:"complex-link__underline"},[_vm._v(_vm._s(_vm.$t('modal.back')))]):_vm._e()]):_vm._e(),_vm._v(" "),_c('h2',[_vm._t("header",null,{"close":close})],2),_vm._v(" "),(!_vm.noClose || _vm.showCloseButton)?_c('b-btn-close',{on:{"click":function($event){return close()}}}):_vm._e()]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_vm._t("default",null,{"close":close})]}},{key:"modal-footer",fn:function(ref){
var close = ref.close;
var ok = ref.ok;
var cancel = ref.cancel;
return [_vm._t("footer",null,{"close":close,"ok":ok,"cancel":cancel})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }