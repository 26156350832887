




















import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import ProductDetailLayoutModal from '~/components/ProductDetail/ProductDetailLayout/ProductDetailLayoutModal.vue'
import { Gift } from '~/src/Model/ProductGift/ProductGift'
import ProductGiftItemImage from '~/components/Gift/Components/ProductGiftItemImage.vue'
import ProductGiftItemContent from '~/components/Gift/Components/ProductGiftItemContent.vue'

export default defineComponent({
  components: { ProductDetailLayoutModal, ProductGiftItemImage, ProductGiftItemContent },
  props: {
    isRadio: {
      type: Boolean,
      default: false
    },
    gift: {
      required: true,
      type: Object as PropType<Gift>
    },
    allowModal: {
      type: Boolean,
      default: true
    },
    isLink: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const showModal = ref<boolean>(false)
    const mainTag = computed<boolean>(() => props.isRadio) ? 'span' : 'li'

    return {
      showModal,
      mainTag
    }
  }
})
