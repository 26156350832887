






import { defineComponent } from '@nuxtjs/composition-api'
import ProductImage from '~/components/Basket/Components/ProductImage.vue'

export default defineComponent({
  components: { ProductImage },
  props: {
    iconClass: {
      default: null,
      type: [String, Array]
    },
    bgColor: {
      default: 'bg-white',
      type: String
    }
  }
})
