import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { FileUploadResponse } from '~/src/Model/FileUpload/FileUpload'
import { StorePageThumbnail } from '~/src/Model/Store/StorePage'

export enum ReclamationStateEnum {
  issueInfo = 'issueInfo',
  reclamationData = 'reclamationData',
  customerInfo = 'customerInfo',
  summary = 'summary'
}

export enum ReclamationPreferredSolutionEnum {
  repair = 'repair',
  moneyBack = 'moneyBack',
  discount = 'discount',
  newUnit = 'newUnit'
}

export enum ReclamationProductState {
  likeNew = 'likeNew',
  used = 'used',
  veryUsed = 'veryUsed'
}

export enum ReclamationAttachmentOption {
  image,
  document
}

export interface ReclamationIssueInfoStepData {
  issueDescription: string
  state: ReclamationProductState | null
  photos: FileUploadResponse[]
  attachments: FileUploadResponse[]
  serialNumber: string | null
  imei: string | null
}

export interface ReclamationIssueRequestData extends Omit<ReclamationIssueInfoStepData, 'photos' | 'attachments'> {
  photos: string[]
  attachments: string[]
}

export interface ReclamationInfoRequestData {
  preferredSolution: ReclamationPreferredSolutionEnum
  originalPackage: boolean
  accessoriesDescription: string | null
  note: string
}

export interface ReclamationCustomerAddressData {
  street: string
  city: string
  zip: string
}

export interface ReclamationCustomerInfoData {
  firstName: string
  lastName: string
  email: string
  phone: string
  contactAddress: ReclamationCustomerAddressData
  homeServiceAddress: ReclamationCustomerAddressData
}

export interface ReclamationCustomerRequestData {
  customer: ReclamationCustomerInfoData
  storeId: number | null
}

export interface ReclamationCustomerContactInfo {
  customer: ReclamationCustomerInfoData
  storeInfo: StorePageThumbnail | null
}

export interface ReclamationRequestPurchaseInfo extends Omit<PurchaseInfo, 'itemId'> {
  itemId: string
}

export interface ReclamationRequestData {
  issue: ReclamationIssueRequestData | null
  info: ReclamationInfoRequestData | null
  customer: ReclamationCustomerRequestData | null
  homeService: boolean
  purchaseInfo: ReclamationRequestPurchaseInfo
}

export interface Reclamation {
  isHomeService: boolean
  currentState: ReclamationStateEnum
  purchaseInfo: PurchaseInfo
  customerContactInfo: ReclamationCustomerContactInfo | null
  issueInfoStepFormData:ReclamationIssueInfoStepData | null
  reclamationInfoData: ReclamationInfoRequestData | null

  isStateActive (state: ReclamationStateEnum): boolean
  nextStep (): Reclamation
  previousStep(): Reclamation
  getReclamationRequestData(): ReclamationRequestData
}

export const emptyReclamationCustomerInfoData = (): ReclamationCustomerInfoData => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    contactAddress: {
      street: '',
      city: '',
      zip: ''
    },
    homeServiceAddress: {
      street: '',
      city: '',
      zip: ''
    }
  }
}
