import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { NavigationGuardNext, Route } from 'vue-router/types/router'
import { LUX, LUXDefaultOptions, LUXOptions } from '~/src/Model/LUX/LUX'

const speedCurveLuxPlugin: Plugin = ({ app: { router }, $config, app }, inject: Inject) => {
  const options: LUXOptions = { ...LUXDefaultOptions(), ...(($config && $config.lux) || {}) }
  const lux: LUX = new LUX(options)

  // client side setup the auto starter if requested
  if (process.client && options.enabled) {
    router?.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
      lux.isFirstHit = (lux.isFirstHit === null)

      lux.routerBeforeEach(to, from)
      next()
    })
  }

  // server side, inject the native LUX code on the fly
  if (options.id && options.enabled) {
    const head = app.head

    // @ts-ignore
    head.__dangerouslyDisableSanitizersByTagID = head.__dangerouslyDisableSanitizersByTagID || {}
    // @ts-ignore
    head.__dangerouslyDisableSanitizersByTagID['lux-init'] = ['innerHTML']

    // @ts-ignore
    head.script.unshift({
      hid: 'lux-src',
      src: `https://cdn.speedcurve.com/js/lux.js?id=${options.id}`,
      async: true,
      defer: true,
      crossorigin: 'anonymous',
      ...(options.debugMode && { onload: 'LUX.forceSample()' })
    })

    // @ts-ignore
    head.script.unshift({
      hid: 'lux-options',
      innerHTML: `
        LUX.debug = ${options.debugMode}
        LUX.samplerate = ${options.sampleRate}
        LUX.auto = false
        LUX.sendBeaconOnPageHidden = true
      `
    })

    // @ts-ignore
    head.script.unshift({
      hid: 'lux-init',
      innerHTML: 'LUX=function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?(r=t.now(),Math.floor(r)):n()-a.navigationStart;var r}(LUX=window.LUX||{}).ac=[],LUX.addData=function(n,r){return LUX.cmd(["addData",n,r])},LUX.cmd=function(n){return LUX.ac.push(n)},LUX.getDebug=function(){return[[e,0,[]]]},LUX.init=function(){return LUX.cmd(["init"])},LUX.mark=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());LUX.cmd(["mark",e,a])},LUX.markLoadTime=function(){return LUX.cmd(["markLoadTime",o()])},LUX.measure=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());LUX.cmd(["measure",a,e])},LUX.send=function(){return LUX.cmd(["send"])},LUX.ns=e;var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();'
    })
  }

  inject('lux', lux)
}

export default speedCurveLuxPlugin

declare module 'vue/types/vue' {
  interface Vue {
    $lux: LUX
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $lux: LUX
  }

  interface Context {
    $lux: LUX
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $lux: LUX
  }
}
