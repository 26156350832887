















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'
import OnboardingLoginPanel from '~/components/Auth/Karpaty/OnboardingLoginPanel.vue'
import LostPasswordPanel from '~/components/Sidebar/LostPasswordPanel.vue'

export default defineComponent({
  components: {
    LostPasswordPanel,
    Modal,
    OnboardingLoginPanel
  },
  props: {
    email: {
      type: String,
      required: true
    },
    checkedCustomer: {
      type: Object as PropType<CheckCustomerResponse>,
      required: true
    }
  },
  emits: ['close-modal', 'go-back', 'logged', 'registered'],
  setup (props) {
    const showLostPasswordPanel = ref<boolean>(false)
    const showRegistrationPanel = ref<boolean>(!props.checkedCustomer.datartMigration && !props.checkedCustomer.isCustomer)

    return {
      showLostPasswordPanel,
      showRegistrationPanel
    }
  }
})
