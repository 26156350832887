import { defineStore } from 'pinia'
import {
  SetWatchDogRequest,
  WatchDog,
  WatchDogGetAllRequest,
  WatchDogs,
  WatchDogUpdate
} from '~/src/Model/WatchDogs/WatchDogs'
import { useWatchDogsRepositoryWithAxios } from '~/src/Infrastructure/WatchDogs/WatchDogsRepository'

const WATCH_DOGS_STORE_ID = 'watchDogs'

interface WatchDogsState {
  watchDogs: WatchDog[]
}

export const useWatchDogsStore = defineStore(WATCH_DOGS_STORE_ID, {
  state: (): WatchDogsState => ({
    watchDogs: []
  }),
  getters: {
    productWatchDog () {
      return (productId: number) => {
        return this.watchDogs.find((w: WatchDog) => w.productId === productId) ?? null
      }
    }
  },
  actions: {
    setWatchDogs (watchDogs: WatchDog[]): void {
      this.watchDogs = watchDogs
    },
    updateWatchDog (watchDogId: number, newWatchDog: WatchDog): void {
      const newWatchDogsCollection = [...this.watchDogs]

      const indexOfCurrentWatchDog = newWatchDogsCollection.findIndex(currentWatchDog => currentWatchDog.id === watchDogId)
      if (indexOfCurrentWatchDog === -1) { return }

      newWatchDogsCollection[indexOfCurrentWatchDog] = newWatchDog

      this.watchDogs = newWatchDogsCollection
    },
    removeStoredWatchDog (watchDogId: number): void {
      this.watchDogs = this.watchDogs.filter((watchDog: WatchDog) => watchDog.id !== watchDogId)
    },
    clearWatchDogs (): Promise<void> {
      this.$reset()

      return Promise.resolve()
    },
    async loadWatchdogs (options: WatchDogGetAllRequest): Promise<WatchDogs> {
      const { loadWatchDog } = useWatchDogsRepositoryWithAxios(this.$nuxt.$axios)

      const response = await loadWatchDog({ withDetails: options.withDetails }, options.token)
      this.setWatchDogs(response.watchDogs)

      return response
    },
    async loadForProduct (productId: number): Promise<WatchDogs> {
      const { loadWatchDog } = useWatchDogsRepositoryWithAxios(this.$nuxt.$axios)

      const response = await loadWatchDog({ productId })
      const newWatchDogs = [...this.watchDogs].concat(response.watchDogs)

      this.setWatchDogs(newWatchDogs)

      return response
    },
    async addWatchDog (watchDog: SetWatchDogRequest): Promise<WatchDog> {
      const { setWatchDog } = useWatchDogsRepositoryWithAxios(this.$nuxt.$axios)

      const addedWatchDog = await setWatchDog(watchDog)
      this.setWatchDogs([...this.watchDogs, addedWatchDog])

      return addedWatchDog
    },
    async configureWatchDogWatchers (watchDogUpdateConfig: WatchDogUpdate): Promise<WatchDog> {
      const { setWatchDog } = useWatchDogsRepositoryWithAxios(this.$nuxt.$axios)

      const currentWatchDog = this.watchDogs.find((watchDog: WatchDog) => watchDog.productId === watchDogUpdateConfig.watchDogConfig.productId)

      if (!currentWatchDog) {
        throw new Error(`Watchdog with productId=${watchDogUpdateConfig.watchDogConfig.productId} was not found`)
      }

      const newWatchDogRequest: SetWatchDogRequest = {
        productId: currentWatchDog.productId,
        email: currentWatchDog.email,
        watchAvailability: watchDogUpdateConfig.watchDogConfig.watchAvailability ?? currentWatchDog.watchAvailability,
        watchPrice: watchDogUpdateConfig.watchDogConfig.watchPrice ?? currentWatchDog.watchPrice
      }

      const updatedWatchDog = await setWatchDog(newWatchDogRequest, { withDetails: 1 })
      this.updateWatchDog(updatedWatchDog.id, updatedWatchDog)

      return updatedWatchDog
    },
    async removeWatchDog  (watchDogId: number): Promise<void> {
      const { removeWatchDog } = useWatchDogsRepositoryWithAxios(this.$nuxt.$axios)

      const removedWatchDog = await removeWatchDog(watchDogId)
      this.removeStoredWatchDog(watchDogId)

      return removedWatchDog
    }
  }
})
