export enum State {
  Normal = 'normal',
  SaleEnded = 'saleEnded',
  NotSaleSeparately = 'notSaleSeparately',
  Unavailable = 'unavailable',
  Preorder = 'preorder'
}

export interface PurchaseAvailabilityOrder {
  isAllowed: boolean
  message: string | null
}

export interface PurchaseAvailabilityReservation {
  isAllowed: boolean
  message: string | null
}

interface PurchaseModeAvailablitiy {
  isAllowed: boolean
  message: string | null
}

export enum CtaType {
  None = 'None',
  Order = 'Order',
  OrderDigital = 'OrderDigital',
  OrderGiftVoucher = 'OrderGiftVoucher',
  Reservation = 'Reservation',
  Disabled = 'Disabled',
  Locked = 'Locked',
  SoldOut = 'SoldOut',
}

export interface PurchaseAvailability {
  order: PurchaseAvailabilityOrder
  reservation: PurchaseAvailabilityReservation
  digitalContent: PurchaseModeAvailablitiy
  state: State
}
