















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Color, EshopAvailability, IconType } from '~/src/Model/ProductAvailability/ProductAvailability'

const colorMap: { [key in Color]: string } = {
  [Color.green]: 'success',
  [Color.orange]: 'warning',
  [Color.red]: 'danger',
  [Color.default]: 'info'
}

const iconMap: { [key in IconType]: string } = {
  [IconType.check]: IconType.check,
  [IconType.cross]: IconType.cross,
  [IconType.default]: IconType.default
}

export default defineComponent({
  props: {
    availability: {
      required: true,
      type: Object as PropType<EshopAvailability>
    },
    underlineHover: {
      type: Boolean,
      default: true
    },
    iconSize: {
      type: String,
      default: 'icon-fs-15'
    },
    displayClass: {
      type: String,
      default: 'd-flex'
    }
  },
  emits: ['click'],
  setup (props) {
    const availabilityColor = computed<string>(() => colorMap[props.availability.color])
    const iconType = computed<string>(() => iconMap[props.availability.iconType])

    return {
      availabilityColor,
      iconType
    }
  }
})
