



































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { WatchDog } from '~/src/Model/WatchDogs/WatchDogs'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'
import Popover from '~/components/DesignSystem/Popover/Popover.vue'
import SystemLink from '~/components/SystemPage/SystemLink.vue'

export default defineComponent({
  components: { Popover, SystemLink },
  props: {
    watchDog: {
      default: null,
      type: Object as PropType<WatchDog>
    },
    authenticatedUser: {
      default: null,
      type: Object as PropType<AuthenticatedUser>
    },
    target: {
      required: true,
      type: String
    },
    container: {
      required: true,
      type: String
    }
  },
  emits: ['close', 'hidden']
})
