import { NuxtCookies } from 'cookie-universal-nuxt'

const FACEBOOK_LOGIN_COOKIE_ID = 'facebookLogin'

export class FacebookLoginCookieStorage {
  constructor (
    private cookies: NuxtCookies
  ) {}

  public get facebookLoginCookieAllowed (): boolean {
    return this.cookies.get(FACEBOOK_LOGIN_COOKIE_ID)?.toString() === 'on' ?? false
  }

  public setFacebookLoginCookie (): void {
    if (this.cookies.get(FACEBOOK_LOGIN_COOKIE_ID)) {
      return
    }

    this.cookies.set(FACEBOOK_LOGIN_COOKIE_ID, 'off')
  }
}
