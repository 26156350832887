















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import {
  ValidationMode,
  ValidationModeCustomImplementation,
  ValidationModePredefined
} from '~/src/Model/Validation/Validation'
import { validateOnInput } from '~/src/Infrastructure/Form/Validation/ValidationMode'

export default defineComponent({
  name: 'BaseValidationProvider',
  components: { ValidationProvider },
  props: {
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<ValidationMode>,
      default: 'eager'
    },
    immediate: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  setup (props) {
    const { unificate } = useVueInstanceUid()

    const uniqueId = computed<string>(() => {
      if (props.vid) {
        return props.vid
      }
      if (props.name) {
        return unificate(props.name)
      }
      return unificate('validationProvider')
    })

    const selectedMode = computed<ValidationModePredefined | ValidationModeCustomImplementation>(() => {
      if (props.mode === 'input') {
        return validateOnInput
      }
      return props.mode
    })

    return {
      uniqueId,
      selectedMode
    }
  }
})
