export const doubleRequestAnimationFrame = (callback: FrameRequestCallback) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback)
  })
}

export const trippleRequestAnimationFrame = (callback: FrameRequestCallback) => {
  requestAnimationFrame(() => {
    doubleRequestAnimationFrame(callback)
  })
}
