import images from '~/static/config/images.js'
import srcsets from '~/static/config/srcsets.js'
import { ImageConfiguration, ObjectFit } from '~/src/Model/Image/ImageConfiguration'
import { SrcsetConfiguration } from '~/src/Model/Image/SrcsetConfiguration'

export function createConfig (size: string, retina: boolean = false): ImageConfiguration {
  let config: ImageConfiguration = { ...images.defaultConfig }
  config.retina = retina
  config.objectFit = ObjectFit[config.objectFit]

  const sizeConfig = (images.imageSizes as any)[size] ?? {}

  if (sizeConfig) {
    config = { ...config, ...sizeConfig }
  }

  return config
}

export function getExtensionForSize (size: string): string | null {
  return (images.imageSizes as any)[size]?.fileType ?? null
}

export function getSrcsetConfig (srcsetName: string): SrcsetConfiguration {
  const imageTypeIndex = Object.keys(srcsets.srcsetSizes).indexOf(srcsetName)
  return Object.values(srcsets.srcsetSizes)[imageTypeIndex]
}
