import { computed } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { Pinia } from 'pinia'
import {
  BasketDeliveryMethod,
  BasketDonation,
  BasketInfo,
  BasketPayMethod,
  BasketVoucher
} from '~/src/Model/Basket/BasketInfo'
import { BasketProduct } from '~/src/Model/Basket/Product'
import { BasketProductBundle } from '~/src/Model/Basket/BasketProductBundle'
import {
  BasketDialog,
  BasketValidationError,
  BasketValidationMessage,
  BasketValidationResult
} from '~/src/Model/Basket/BasketValidation'
import { ValueVouchers } from '~/src/Model/Basket/ValueVoucher'
import { InstallmentChoice } from '~/src/Model/InstallmentsCalculator/InstallmentChoice'
import { mergeArrays } from '~/src/Infrastructure/Array/MergeArrays/MergeArrays'
import { Product } from '~/src/Model/Product/Product'
import { useBasketStore as useStore } from '~/stores/basket'
import { EshopAvailabilityUid } from '~/src/Model/ProductAvailability/ProductAvailability'

export const useBasketStore = (pinia?: Pinia | null) => {
  const store = useStore(pinia)

  const basketInfo = computed<BasketInfo | null>(() => cloneDeep(store.basket))
  const payMethod = computed<BasketPayMethod | null>(() => store.payMethod)
  const payMethodPrice = computed<string>(() => store.payMethodPrice)
  const deliveryMethod = computed<BasketDeliveryMethod | null>(() => store.deliveryMethod)
  const basketHash = computed<string | null>(() => store.hash)
  const basketProducts = computed<BasketProduct[]>(() => store.basketProducts)
  const basketProductBundles = computed<BasketProductBundle[]>(() => store.basketProductBundles)
  const validationResult = computed<BasketValidationResult | null>(() => store.basketValidationResult)
  const validationResultHistory = computed<BasketValidationResult | null>(() => store.basketValidationResultHistory)
  const validationResultBasketChanged = computed<boolean>(() => store.validationResultBasketChanged)
  const validationResultNotices = computed<BasketValidationMessage[]>(() => mergeArrays(validationResult.value?.notices ?? [], validationResultHistory.value?.notices ?? [], 'message'))
  const validationResultErrors = computed<BasketValidationError[]>(() => mergeArrays(validationResult.value?.errors ?? [], validationResultHistory.value?.errors ?? [], 'message'))
  const validationResultDialogs = computed<BasketDialog[]>(() => mergeArrays(validationResult.value?.dialogs ?? [], validationResultHistory.value?.dialogs ?? [], 'message'))
  const isEmptyBasket = computed<boolean>(() => store.isEmptyBasket)
  const isServiceInBasket = computed<boolean>(() => store.isServiceInBasket)
  const isBasketPaid = computed<boolean>(() => store.isBasketPaid)
  const selectedDonation = computed<BasketDonation | null>(() => store.selectedDonation)
  const basketProductsCount = computed<number>(() => store.basketProductsCount)
  const basketVouchers = computed<BasketVoucher[] | null>(() => store.basketVouchers)
  const totalPrice = computed<string>(() => store.totalPrice)
  const productsWithGifts = computed<Product[]>(() => store.productsWithGifts)
  const valueVouchers = computed<ValueVouchers | null>(() => store.valueVouchers)
  const deliveryPriceWithSlot = computed<string>(() => store.deliveryPriceWithSlot)
  const deliveryServicesTotalPrice = computed<string>(() => store.deliveryServicesTotalPrice)
  const installmentChoice = computed<InstallmentChoice | null>(() => store.installmentChoice)
  const basketExhibitedProducts = computed<BasketProduct[]>(() => basketProducts.value.filter(p => p.availability.uid === EshopAvailabilityUid.EXHIBIT_ON_ESHOP))
  const productsTotalPrice = computed<string>(() => basketInfo.value?.productsTotalPrice ?? '0')

  const isProductInBasket = (productId: number): boolean => {
    return basketProducts.value.some(p => p.product.id === productId)
  }

  return {
    createBasket: store.createBasket,
    updateBasketHash: store.updateBasketHash,
    deleteLocalBasket: store.deleteLocalBasket,
    setDelivery: store.setDelivery,
    reserveComfortDeliveryDeprecated: store.reserveComfortDeliveryDeprecated,
    reserveComfortDelivery: store.reserveComfortDelivery,
    clearComfortDeliverySlot: store.clearComfortDeliverySlot,
    setPayment: store.setPayment,
    clearBasketValidationResultHistory: store.clearBasketValidationResultHistory,
    addValueVoucher: store.addValueVoucher,
    deleteValueVoucher: store.deleteValueVoucher,
    setInstallmentChoice: store.setInstallmentChoice,
    clearInstallmentChoice: store.clearInstallmentChoice,
    updateServices: store.updateServices,
    deleteService: store.deleteService,
    validateBasket: store.validateBasket,
    setBasketValidationResultHistory: store.setBasketValidationResultHistory,
    deleteDeliveryService: store.deleteDeliveryService,
    addProductDeliveryService: store.addProductDeliveryService,
    applyCredits: store.applyCredits,
    deleteCredits: store.deleteCredits,
    updateProductGifts: store.updateGifts,
    deleteProductGift: store.deleteGift,
    addDeliveryService: store.addDeliveryService,
    deleteDonation: store.deleteDonation,
    applyDonation: store.applyDonation,
    deletePayment: store.deletePayment,
    deleteDelivery: store.deleteDelivery,
    deleteVoucher: store.deleteVoucher,
    loadProductBundles: store.loadProductBundles,
    addVoucher: store.addVoucher,
    loadProductsIfNotEmpty: store.loadProductsIfNotEmpty,
    isProductInBasket,
    addProduct: store.addProduct,
    addProductBundle: store.addProductBundle,
    createBasketIfNotExists: store.createBasketIfNotExists,
    deleteProductBundle: store.deleteProductBundle,
    updateProductBundle: store.updateProductBundle,
    updateBasketProductsByDialog: store.updateProductsByDialog,
    deleteBasketProduct: store.deleteProduct,
    updateBasketProduct: store.updateProduct,
    reloadAll: store.reloadAll,
    loadBasketInfo: store.loadBasketInfo,
    loadProducts: store.loadProducts,
    productsTotalPrice,
    basketInfo,
    payMethod,
    payMethodPrice,
    deliveryMethod,
    basketHash,
    basketProducts,
    basketProductBundles,
    validationResult,
    validationResultHistory,
    validationResultBasketChanged,
    validationResultNotices,
    validationResultErrors,
    validationResultDialogs,
    isEmptyBasket,
    isServiceInBasket,
    isBasketPaid,
    selectedDonation,
    basketProductsCount,
    basketVouchers,
    totalPrice,
    productsWithGifts,
    valueVouchers,
    deliveryPriceWithSlot,
    deliveryServicesTotalPrice,
    installmentChoice,
    basketExhibitedProducts
  }
}

export type BasketStore = ReturnType<typeof useBasketStore>
