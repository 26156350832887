
























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

import { MigrationAccountType } from '~/src/Model/Karpaty/Onboarding'

export default defineComponent({
  components: {
    Alert
  },
  props: {
    email: {
      type: String,
      required: true
    },
    migrationType: {
      type: String as PropType<MigrationAccountType>,
      required: true
    },
    isInModal: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const translationPrefix = computed<string>(() => `karpaty.onboarding_login${props.isInModal ? '.modal' : ''}`)

    return {
      MigrationAccountType,
      translationPrefix
    }
  }
})
