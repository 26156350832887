

















































































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { DatartMigration } from '~/src/Model/CheckCustomer/CheckCustomer'
import LoadingContent from '~/components/Loading/LoadingContent.vue'
import { useCustomerRepository } from '~/src/Infrastructure/Customers/CustomerRepository'

export default defineComponent({
  components: {
    LoadingContent,
    Alert,
    Button,
    Modal
  },
  props: {
    isFinalStep: {
      type: Boolean,
      default: false
    },
    datartMigration: {
      type: Object as PropType<DatartMigration | null>,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isFromLogin: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-modal', 'sign-up-to-loyalty-club'],
  setup (_, { emit }) {
    const { datartUser, authenticatedUser, isLoyaltyMember, authenticatedUserId, isZAM, isB2B, isLoyaltyBlueCardMember } = useAuthenticatedUser()
    const { disableOnboardingWindow } = useCustomerRepository()

    const disableOnboarding = () => {
      if (authenticatedUserId.value) {
        disableOnboardingWindow(authenticatedUserId.value)
      }
      emit('close-modal')
    }

    return {
      datartUser,
      authenticatedUser,
      isLoyaltyMember,
      disableOnboarding,
      isLoyaltyBlueCardMember,
      isZAM,
      isB2B
    }
  }
})
