













































import { defineComponent, ref, PropType, watch } from '@nuxtjs/composition-api'
import BaseValidationProvider from '~/components/Forms/Validation/BaseValidationProvider.vue'

export default defineComponent({
  components: { BaseValidationProvider },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<String[]>,
      default: () => []
    },
    selectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    textField: {
      type: String,
      default: 'text'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    value: {
      type: Array as PropType<String[]>,
      default: () => []
    },
    rules: {
      type: String,
      default: ''
    },
    buttons: {
      type: Boolean,
      default: false
    },
    validationProviderTag: {
      type: String,
      default: undefined
    },
    checkboxGroupClass: {
      type: String,
      default: null
    }
  },
  emits: ['input', 'value-added', 'value-removed'],

  setup (props, { emit }) {
    const allSelected = ref<boolean>(false)
    const selected = ref<String[]>(props.value)
    const indeterminate = ref<boolean>(false)

    const required = ref<boolean>(props.rules.includes('required'))

    const toggleAll = (checked: boolean) => {
      selected.value = checked ? [...props.options] : []
    }

    watch(() => props.value, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        selected.value = props.value
      }
    })

    watch(selected, (newValue, oldValue) => {
      if (newValue.length === 0) {
        indeterminate.value = false
        allSelected.value = false
      } else if (newValue.length === props.options.length) {
        indeterminate.value = false
        allSelected.value = true
      } else {
        indeterminate.value = true
        allSelected.value = false
      }

      emit('input', selected.value)

      if (newValue.length > oldValue.length) {
        emit('value-added', newValue.filter(v => !oldValue.includes(v))[0])
      } else {
        emit('value-removed', oldValue.filter(v => !newValue.includes(v))[0])
      }
    })

    return {
      allSelected,
      selected,
      indeterminate,
      toggleAll,
      required
    }
  }
})
