import { BreadCrumbsResponse } from '~/src/Model/BreadCrumb/BreadCrumb'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useBreadCrumbsRepository = () => {
  const { $axios } = useAxios()

  return {
    getAll: (id: number): Promise<BreadCrumbsResponse> => $axios.$get<BreadCrumbsResponse>(`/breadcrumbs/${id}`)
  }
}
