















































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import { TwoFA, TwoFAType } from '~/src/Model/Auth/TwoFA'
import { FlashMessage } from '~/src/Model/FlashMessage/FlashMessage'
import { FlashMessageType } from '~/src/Model/FlashMessage/FlashMessageType'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'

export default defineComponent({
  components: {
    LoadingOverlay,
    FlashMessageCenter,
    BaseValidationObserver,
    FormInput,
    Button,
    Form
  },
  props: {
    twoFa: {
      required: true,
      type: Object as PropType<TwoFA>
    },
    challengePending: {
      default: false,
      type: Boolean
    },
    flashMessages: {
      default: () => [],
      type: Array as PropType<FlashMessage[]>
    }
  },
  emits: ['back-to-login', 'request-code-again', 'submit'],
  setup (props, { emit }) {
    const { i18n } = useTranslator()
    const verificationCode = ref<string>('')

    const messages = ref<FlashMessage[]>(cloneDeep(props.flashMessages))

    watch(() => props.flashMessages, () => {
      messages.value = cloneDeep(props.flashMessages)
    })

    const submit = () => {
      emit('submit', verificationCode.value)
    }

    const requestCodeAgain = () => {
      messages.value = [{ message: i18n.t('auth.two_factor_login.code_sent_again') as string, type: FlashMessageType.success }]
      emit('request-code-again')
    }

    return {
      TwoFAType,
      verificationCode,
      submit,
      requestCodeAgain,
      messages
    }
  }
})
