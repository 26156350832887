export const parseQueryParametersFromLink = (link: string): { [key: string]: string } => {
  const queryParameters: { [key: string]: string } = {}

  if (!link.includes('?')) {
    return {}
  }

  if (link.startsWith('/')) {
    link = `https://www.dummy.com${link}`
  }

  const url = new URL(link)

  url.searchParams.forEach((value: string, key: string) => {
    queryParameters[key] = value
  })

  return queryParameters
}
