

















































































































import { defineComponent, reactive, ref, toRefs } from '@nuxtjs/composition-api'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { CustomerRegistrationData, CustomerRegistrationResponse } from '~/src/Model/Customer/CustomerRegistrationData'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import { usePasswordStrengthRepository } from '~/src/Infrastructure/PasswordStrength/PasswordStrengthRepository'
import { PasswordStrength } from '~/src/Model/PasswordStrength/PasswordStrength'
import PasswordStrengthCheck from '~/components/Auth/PasswordStrengthCheck.vue'
import RegistrationColumns from '~/components/Auth/RegistrationColumns.vue'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import InvisibleRecaptcha from '~/components/Forms/Recaptcha/InvisibleRecaptcha.vue'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import CheckboxGroup from '~/components/DesignSystem/Input/CheckboxGroup.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

const checkPasswordStrengthDelayInMiliseconds = 170

export default defineComponent({
  components: {
    BaseValidationObserver,
    FlashMessageCenter,
    PasswordStrengthCheck,
    RegistrationColumns,
    InvisibleRecaptcha,
    FormInput,
    Button,
    CheckboxGroup,
    Form
  },
  props: {
    onlyForm: {
      type: Boolean,
      default: false
    },
    emailLabel: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    disableEmailChange: {
      type: Boolean,
      default: false
    }
  },
  emits: ['registered'],

  setup (props, { emit }) {
    const { unificate } = useVueInstanceUid()
    const { i18n } = useTranslator()
    const { loyaltyClubAllowed } = useWebConfig()

    const isSubmitted = ref<boolean>(false)

    const { registerCustomer } = useCustomerAuthRepository()
    const authService = useAuthService()

    const { checkPassword } = usePasswordStrengthRepository()
    const passwordStrength = ref<PasswordStrength | null>(null)
    const checkPasswordStrength = async () => {
      passwordStrength.value = await checkPassword({
        password: customerData.password,
        userInputs: [customerData.username]
      })
    }

    const repeatedPassword = ref<String | null>(null)
    const customerData = reactive<CustomerRegistrationData>({
      username: props.email,
      password: '',
      agreedNewsletter: false,
      agreedPersonalizedNewsletter: false,
      recaptchaToken: ''
    })

    const selectionChanged = (selected: String[]) => {
      customerData.agreedNewsletter = selected.includes(i18n.t('auth.agreed_newsletter') as string)
      customerData.agreedPersonalizedNewsletter = selected.includes(i18n.t('auth.agreed_personalised_newsletter') as string)
    }

    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const registerCustomerCallback = async () => {
      const response: CustomerRegistrationResponse = await registerCustomer(customerData)
      await authService.setUserToken(response.accessToken, response.refreshToken)
    }
    const { exec: submitRegistration, pending, onSuccess: onRegistered } = useApiCall(registerCustomerCallback, flashMessageCenter)

    onRegistered.subscribe(() => emit('registered'))

    const submit = () => {
      isSubmitted.value = true
    }

    const recaptchaSuccess = (recaptchaToken: string) => {
      flashMessageCenter.clear()
      customerData.recaptchaToken = recaptchaToken
      submitRegistration()
      isSubmitted.value = false
    }

    const recaptchaError = () => {
      flashMessageCenter.error(i18n.t('auth.registration.recaptcha_failed') as string)
      isSubmitted.value = false
    }

    const recaptchaExpired = () => {
      flashMessageCenter.error(i18n.t('auth.registration.recaptcha_expired') as string)
      isSubmitted.value = false
    }

    return {
      flashMessages,
      ...toRefs(customerData),
      repeatedPassword,
      selectionChanged,
      pending,
      unificate,
      checkPasswordStrength,
      passwordStrength,
      checkPasswordStrengthDelayInMiliseconds,
      recaptchaError,
      recaptchaExpired,
      recaptchaSuccess,
      submit,
      isSubmitted,
      loyaltyClubAllowed
    }
  }
})
