import Vue from 'vue';

import {
  AlertPlugin,
  BadgePlugin,
  ButtonPlugin,
  CarouselPlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormRatingPlugin,
  FormSelectPlugin,
  FormSpinbuttonPlugin,
  FormTextareaPlugin,
  FormTimepickerPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  PaginationPlugin,
  PaginationNavPlugin,
  PopoverPlugin,
  SidebarPlugin,
  TabsPlugin,
  TooltipPlugin,
  FormTagsPlugin,
  EmbedPlugin,
  VBPopoverPlugin,
  VBTogglePlugin,
  VBTooltipPlugin,
  BSkeleton
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CarouselPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormRatingPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationPlugin);
Vue.use(PaginationNavPlugin);
Vue.use(PopoverPlugin);
Vue.use(SidebarPlugin);
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(FormTagsPlugin);
Vue.use(EmbedPlugin);

Vue.use(VBPopoverPlugin);
Vue.use(VBTogglePlugin);
Vue.use(VBTooltipPlugin);

Vue.component('BSkeleton', BSkeleton);
