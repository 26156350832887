






















import { defineComponent, computed } from '@nuxtjs/composition-api'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export default defineComponent({
  components: { Badge },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['sidebar-opened'],
  setup (props, { emit }) {
    const { authenticatedUser } = useAuthenticatedUser()
    const openSidebar = () => {
      emit('sidebar-opened', ActiveHeaderPanel.userBox)
    }

    const sidebarOpened = computed<boolean>(() => props.activePanel === ActiveHeaderPanel.userBox)

    return {
      sidebarOpened,
      openSidebar,
      authenticatedUser
    }
  }
})
