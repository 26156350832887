
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import PdpBarAppVersion from '~/components/PdpBar/PdpBarAppVersion.vue'
import PdpBarParameters from '~/components/PdpBar/PdpBarParameters.vue'
import PdpBarLinks from '~/components/PdpBar/PdpBarLinks.vue'
import { PdpBar } from '~/src/Model/PdpBar/PdpBar'

export default defineComponent({
  components: { PdpBarAppVersion, PdpBarLinks, PdpBarParameters },
  props: {
    pdpBar: {
      type: Object as PropType<PdpBar>,
      required: true
    }
  }
})
