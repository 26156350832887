import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export class VirtualPageViewEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (): void {
    this.dataLayer.push({ event: 'virtual_pageview' })
  }
}

export const useVirtualPageViewEvent = () => {
  const dataLayer = useDataLayer()

  return new VirtualPageViewEvent(dataLayer)
}
