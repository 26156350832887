




























































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { ServiceVariant } from '~/src/Model/Services/Service'
import { FinancialService, ServiceVariantRequirement } from '~/src/Model/ProductServices/ProductServices'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import ChooseBox from '~/components/DesignSystem/Input/ChooseBox.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    Alert,
    Row,
    ChooseBox
  },
  props: {
    service: {
      required: true,
      type: Object as PropType<FinancialService>
    },
    value: {
      required: false,
      type: Object as PropType<ServiceVariant | null>,
      default: null
    }
  },
  emits: ['input', 'open-detail'],
  setup (props, { emit }) {
    const showModal = ref<boolean>(false)

    const selectedItem = computed<ServiceVariant | null>({
      get: () => props.value,
      set: (variant: ServiceVariant | null): void => emit('input', variant)
    })

    return {
      showModal,
      selectedItem,
      ServiceVariantRequirement
    }
  }
})
