import { ref, computed, provide, inject, Ref } from '@nuxtjs/composition-api'
import { FlashMessageCenter, UseFlashMessageCenter } from '~/src/Model/FlashMessage/FlashMessageCenter'
import { FlashMessage } from '~/src/Model/FlashMessage/FlashMessage'
import { FlashMessageType } from '~/src/Model/FlashMessage/FlashMessageType'

export class LocalFlashMessageCenter implements FlashMessageCenter {
  constructor (private _messages: Ref<FlashMessage[]>) {}

  success (message: string, code?: number): void {
    this._messages.value.push({
      message,
      code,
      type: FlashMessageType.success
    })
  }

  info (message: string, code?: number): void {
    this._messages.value.push({
      message,
      code,
      type: FlashMessageType.info
    })
  }

  warning (message: string, code?: number): void {
    this._messages.value.push({
      message,
      code,
      type: FlashMessageType.warning
    })
  }

  error (message: string, code?: number): void {
    this._messages.value.push({
      message,
      code,
      type: FlashMessageType.error
    })
  }

  message (message: string, type: FlashMessageType, code?: number): void {
    this._messages.value.push({ message, code, type })
  }

  clear (): void {
    this._messages.value = []
  }

  get messages () {
    return computed(() => this._messages.value)
  }
}

const createFlashMessageCenterKey = (key?: string) => {
  return `flashMessageCenter${key ? '-' + key : ''}`
}

export const useLocalFlashMessageCenter = (key?: string): UseFlashMessageCenter => {
  const flashMessages = ref<FlashMessage[]>([])
  const flashMessageCenter = new LocalFlashMessageCenter(flashMessages)

  const flashMessageKey = createFlashMessageCenterKey(key)
  provide<UseFlashMessageCenter>(flashMessageKey, { flashMessageCenter, flashMessages })

  return {
    flashMessageCenter,
    flashMessages
  }
}

export const useInjectFlashMessageCenter = (key?: string): UseFlashMessageCenter | undefined => {
  const flashMessageKey = createFlashMessageCenterKey(key)
  const flashMessageCenterProvider = inject<UseFlashMessageCenter | undefined>(flashMessageKey)

  return flashMessageCenterProvider
}
