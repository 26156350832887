import Big from 'big.js'
import { createConfig, getExtensionForSize, getSrcsetConfig } from '~/src/Infrastructure/Image/ConfigCreator'
import ImageDimensionsCalculator from '~/src/Infrastructure/Image/ImageDimensionCalculator'
import { ImageConfiguration } from '~/src/Model/Image/ImageConfiguration'
import { ImageDimensions } from '~/src/Model/Image/ImageDimensions'
import { SrcsetConfiguration } from '~/src/Model/Image/SrcsetConfiguration'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

enum ImageDescriptor {
  ratio = 'x',
  width = 'w'
}

export class Srcset {
  private readonly URL: string

  constructor (imagesUrl: string) {
    this.URL = imagesUrl
  }

  generate (origWidth: number, origHeight: number, imageSize: string, id: number, extension: string, srcsetName: (string | null)): string {
    const srcset: string[] = []
    const imageType: SrcsetConfiguration | null = srcsetName ? getSrcsetConfig(srcsetName) : null

    if (imageType) {
      const descriptor = imageType.descriptor

      const masterConfig: ImageConfiguration = createConfig(imageType.sizes[0])
      const masterDimensions: ImageDimensions = ImageDimensionsCalculator.calculateDimensions(origWidth, origHeight, masterConfig)
      let prevSize: number | null = null

      imageType.sizes.forEach((size, index) => {
        const config: ImageConfiguration = createConfig(size)
        const dimensions: ImageDimensions = ImageDimensionsCalculator.calculateDimensions(origWidth, origHeight, config)

        if (prevSize && prevSize >= dimensions.width) {
          prevSize = dimensions.width
          return
        }
        prevSize = dimensions.width

        if (descriptor === ImageDescriptor.width) {
          srcset.push(`${this.createURL(id, extension, size)} ${dimensions.width}${descriptor}`)
        } else if (descriptor === ImageDescriptor.ratio) {
          let ratio = '1.00'

          if (index > 0) {
            ratio = new Big(dimensions.width).div(masterDimensions.width).toFixed(2)
          }
          srcset.push(`${this.createURL(id, extension, size)} ${ratio}${descriptor}`)
        }
      })
    } else {
      srcset.push(`${this.createURL(id, extension, imageSize)} 1x`)

      const config: ImageConfiguration = createConfig(imageSize)
      const finalDimensions: ImageDimensions = ImageDimensionsCalculator.calculateDimensions(origWidth, origHeight, config)

      const retinaConfig: ImageConfiguration = createConfig(imageSize, true)
      const retinaDimensions: ImageDimensions = ImageDimensionsCalculator.calculateDimensions(origWidth, origHeight, retinaConfig)

      let retinaMultiplier: number
      if (Math.abs(finalDimensions.width * 2 - retinaDimensions.width) === 1) {
        retinaMultiplier = 2
      } else {
        retinaMultiplier = Number((retinaDimensions.width / finalDimensions.width).toFixed(4))
      }

      if (retinaMultiplier > 1) {
        srcset.push(`${this.createURL(id, extension, imageSize, true)} ${retinaMultiplier}x`)
      }
    }

    return srcset.join(',')
  }

  createURL (id: number, extension: string, imageSize: string, isRetina: boolean = false): string {
    const uniformDistribution: number = id % 10
    const configExtension: string | null = getExtensionForSize(imageSize)

    return `${this.URL}/${imageSize}${isRetina ? '-2x' : ''}/${uniformDistribution}/${id}.${configExtension ?? extension}`
  }
}

export const useSrcset = () => {
  const webConfig = useWebConfig()

  return new Srcset(webConfig.cdnUrl)
}
