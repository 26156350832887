import { ref } from '@nuxtjs/composition-api'
import { AxiosError } from 'axios'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { AppleCredentialsResponse, AppleLoginRequest } from '~/src/Model/Auth/Apple/Apple'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'

export const useAppleLoginPostProcess = () => {
  const authService = useAuthService()
  const { appleLogin } = useCustomerAuthRepository()

  const mergeAccount = ref<boolean>(false)
  const appleLoginRequest = ref<AppleLoginRequest | null>(null)
  const pending = ref<boolean>(false)

  const onLoggedIn = async (appleCredentialResponse: AppleCredentialsResponse) => {
    pending.value = true

    appleLoginRequest.value = {
      authorization: {
        code: appleCredentialResponse.authorization.code,
        idToken: appleCredentialResponse.authorization.id_token
      },
      user: appleCredentialResponse.user
        ? {
            firstName: appleCredentialResponse.user.name.firstName,
            lastName: appleCredentialResponse.user.name.lastName
          }
        : null
    }

    let loginResponse
    try {
      loginResponse = await appleLogin(appleLoginRequest.value)
    } catch (e) {
      const error = e as AxiosError
      if (error.response?.status === 412 || error.response?.status === 428) {
        mergeAccount.value = true
      }
      return
    } finally {
      pending.value = false
    }

    await authService.setUserToken(loginResponse.accessToken, loginResponse.refreshToken)
  }

  const mergeAccountCompleted = () => {
    mergeAccount.value = false
  }

  return {
    onLoggedIn,
    mergeAccountCompleted,
    mergeAccount,
    appleLoginRequest,
    pending
  }
}
