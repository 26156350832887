
























import { defineComponent, ref } from '@nuxtjs/composition-api'
import ProductBoxActionLink from '~/components/ProductBox/Components/ProductBoxActionLink/ProductBoxActionLink.vue'
import ComparePopover from '~/components/Popover/ComparePopover.vue'

export default defineComponent({
  components: { ProductBoxActionLink, ComparePopover },
  props: {
    productId: {
      required: true,
      type: Number
    },
    wrapperClass: {
      type: String,
      default: null
    },
    linkClass: {
      type: String,
      default: null
    },
    linkTextOnPhoneOnly: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    iconFs: {
      type: String,
      default: null
    }
  },
  setup () {
    const actionLinkElement = ref<HTMLElement>()

    return {
      actionLinkElement
    }
  }
})
