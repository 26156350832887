





















import { defineComponent } from '@nuxtjs/composition-api'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import InfoBoxLinks from '~/components/Header/InfoBox/InfoBoxLinks.vue'

export default defineComponent({
  components: { Sidebar, InfoBoxLinks },
  emits: ['hidden']
})
