















































































import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    optionValue: {
      type: [String, Number, Object, Boolean],
      default: null,
      required: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    valueField: {
      type: String,
      default: null
    },
    wrapClass: {
      type: [String, Array],
      default: null
    },
    labelClass: {
      type: [String, Array],
      default: null
    },
    full: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    isComplex: {
      type: Boolean,
      default: false
    },
    hasHover: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    isRadioProgress: {
      type: Boolean,
      default: false
    },
    isRadioProgressLast: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    isSwitch: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'click'],

  setup (props) {
    const inputValue = computed<string | number>(() => {
      return props.valueField ? (props.optionValue as any)[props.valueField] : props.optionValue
    })

    const inputName = computed<string>(() => {
      return `${props.name}-${inputValue.value}`
    })

    return {
      inputValue,
      inputName
    }
  }
})
