import { PasswordStrengthCheckRequest, PasswordStrength } from '~/src/Model/PasswordStrength/PasswordStrength'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const usePasswordStrengthRepository = () => {
  const { $axios } = useAxios()

  return {
    checkPassword: (request: PasswordStrengthCheckRequest): Promise<PasswordStrength> => $axios.$post<PasswordStrength>('/actions/password-strength', request)
  }
}
