import { MenuCategoryBrand } from '~/src/Model/MenuCategoryBrands/MenuCategoryBrand'

export const menuCategoryBrands: MenuCategoryBrand[] = [
  {
    category: 66265,
    nay: [
      {
        link: 'apple',
        imageLink: '3/2029913.png',
        alt: 'Apple'
      },
      {
        link: 'bang-olufsen',
        imageLink: '1/1546971.png',
        alt: 'Bang &amp; olufsen'
      },
      {
        link: 'bose',
        imageLink: '1/194931.jpg',
        alt: 'Bose'
      },
      {
        link: 'canon',
        imageLink: '9/1564899.png',
        alt: 'Canon'
      },
      {
        link: 'xiaomi',
        imageLink: '1/1565141.png',
        alt: 'Xiaomi'
      }
    ],
    ew: [
      {
        link: 'apple',
        imageLink: '1/2029911.png',
        alt: 'Apple'
      },
      {
        link: 'canon',
        imageLink: '9/1564899.png',
        alt: 'Canon'
      },
      {
        link: 'niceboy',
        imageLink: '1/1730321.png',
        alt: 'Niceboy'
      }
    ]
  },
  {
    category: 66266,
    nay: [
      {
        link: 'apple',
        imageLink: '3/2029913.png',
        alt: 'Apple'
      },
      {
        link: 'samsung',
        imageLink: '7/193107.jpg',
        alt: 'Samsung'
      },
      {
        link: 'canon',
        imageLink: '9/1564899.png',
        alt: 'Canon'
      },
      {
        link: 'canyon',
        imageLink: '1/1543021.png',
        alt: 'Canyon'
      },
      {
        link: 'logitech',
        imageLink: '7/1539367.png',
        alt: 'Logitech'
      },
      {
        link: 'panzerglass',
        imageLink: '5/1730325.png',
        alt: 'Panzerglass'
      },
      {
        link: 'sonyps',
        imageLink: '1/1565111.png',
        alt: 'Playstation'
      },
      {
        link: 'razer',
        imageLink: '9/1730419.png',
        alt: 'Razer'
      },
      {
        link: 'sbs',
        imageLink: '3/1730323.png',
        alt: 'SBS'
      },
      {
        link: 'tplink',
        imageLink: '9/1565129.png',
        alt: 'TP-link'
      },
      {
        link: 'xiaomi',
        imageLink: '3/1546813.png',
        alt: 'Xiaomi'
      }
    ],
    ew: [
      {
        link: 'apple',
        imageLink: '1/2029911.png',
        alt: 'Apple'
      },
      {
        link: 'samsung',
        imageLink: '7/193107.jpg',
        alt: 'Samsung'
      },
      {
        link: 'asus',
        imageLink: '9/1564869.png',
        alt: 'Asus'
      },
      {
        link: 'canon',
        imageLink: '9/1564899.png',
        alt: 'Canon'
      },
      {
        link: 'canyon',
        imageLink: '1/1564901.png',
        alt: 'Canyon'
      },
      {
        link: 'corsair',
        imageLink: '3/1564943.png',
        alt: 'Corsair'
      },
      {
        link: 'hp',
        imageLink: '7/195217.jpg',
        alt: 'Hp'
      },
      {
        link: 'lenovo',
        imageLink: '3/1565023.png',
        alt: 'Lenovo'
      },
      {
        link: 'nintendo',
        imageLink: '6/195736.jpg',
        alt: 'Nintendo'
      },
      {
        link: 'playstation-konzole',
        imageLink: '1/1565111.png',
        alt: 'Playstation'
      },
      {
        link: 'tplink',
        imageLink: '9/1565129.png',
        alt: 'TP LINK'
      },
      {
        link: 'xiaomi',
        imageLink: '1/1565141.png',
        alt: 'Xiaomi'
      }
    ]
  },
  {
    category: 66268,
    nay: [
      {
        link: 'aeg',
        imageLink: '3/1545073.png',
        alt: 'AEG'
      },
      {
        link: 'beko',
        imageLink: '5/1539225.png',
        alt: 'BEKO'
      },
      {
        link: 'bosch',
        imageLink: '9/1843059.png',
        alt: 'Bosch'
      },
      {
        link: 'electrolux',
        imageLink: '1/1545061.png',
        alt: 'ELECTROLUX'
      },
      {
        link: 'gorenje',
        imageLink: '5/1564995.png',
        alt: 'Gorenje'
      },
      {
        link: 'haier',
        imageLink: '9/1546249.png',
        alt: 'HAIER'
      },
      {
        link: 'siemens',
        imageLink: '7/1539317.png',
        alt: 'SIEMENS'
      },
      {
        link: 'teka',
        imageLink: '5/1565125.png',
        alt: 'Teka'
      },
      {
        link: 'whirlpool',
        imageLink: '1/1565131.png',
        alt: 'Whirlpool'
      }
    ],
    ew: [
      {
        link: 'aeg',
        imageLink: '3/1564813.png',
        alt: 'Aeg'
      },
      {
        link: 'beko',
        imageLink: '9/1564889.png',
        alt: 'BEKO'
      },
      {
        link: 'gorenje',
        imageLink: '5/1564995.png',
        alt: 'Gorenje'
      },
      {
        link: 'teka',
        imageLink: '5/1565125.png',
        alt: 'Teka'
      },
      {
        link: 'whirlpool',
        imageLink: '1/1565131.png',
        alt: 'Whirlpool'
      }
    ]
  },
  {
    category: 66269,
    nay: [
      {
        link: 'bosch',
        imageLink: '9/1843059.png',
        alt: 'Bosch'
      },
      {
        link: 'dyson',
        imageLink: '9/195189.jpg',
        alt: 'Dyson'
      },
      {
        link: 'electrolux',
        imageLink: '1/1545061.png',
        alt: 'ELECTROLUX'
      },
      {
        link: 'jura',
        imageLink: '9/1535469.png',
        alt: 'JURA'
      },
      {
        link: 'kenwood',
        imageLink: '5/1730345.png',
        alt: 'Kenwood'
      },
      {
        link: 'krups',
        imageLink: '9/1544289.png',
        alt: 'KRUPS'
      },
      {
        link: 'nespresso',
        imageLink: '5/1541245.png',
        alt: 'Nespresso'
      },
      {
        link: 'oral-b',
        imageLink: '5/1535165.png',
        alt: 'ORAL-B'
      },
      {
        link: 'philips',
        imageLink: '3/1534643.png',
        alt: 'PHILIPS'
      },
      {
        link: 'rowenta',
        imageLink: '1/1544311.png',
        alt: 'Rowenta'
      },
      {
        link: 'tefal',
        imageLink: '7/1544297.png',
        alt: 'Tefal'
      }
    ],
    ew: [
      {
        link: 'bissell',
        imageLink: '3/1564893.png',
        alt: 'Bissell'
      },
      {
        link: 'gorenje',
        imageLink: '5/1564995.png',
        alt: 'Gorenje'
      },
      {
        link: 'kenwood',
        imageLink: '5/1730345.png',
        alt: 'Kenwood'
      },
      {
        link: 'krups',
        imageLink: '5/1565005.png',
        alt: 'Krups'
      },
      {
        link: 'nedis',
        imageLink: '7/1565027.png',
        alt: 'Nedis'
      },
      {
        link: 'nespresso',
        imageLink: '9/1565029.png',
        alt: 'Nespresso'
      },
      {
        link: 'rowenta',
        imageLink: '5/1565105.png',
        alt: 'Rowenta'
      },
      {
        link: 'tefal',
        imageLink: '3/1565123.png',
        alt: 'Tefal'
      },
      {
        link: 'wmf',
        imageLink: '3/1565133.png',
        alt: 'WMF'
      }
    ]
  },
  {
    category: 66270,
    nay: [
      {
        link: 'bosch-professional',
        imageLink: '9/1662849.jpg',
        alt: 'Bosch Professional'
      },
      {
        link: 'karcher',
        imageLink: '1/1540121.png',
        alt: 'Kärcher'
      }
    ],
    ew: []
  },
  {
    category: 100630,
    nay: [
      {
        link: 'fitstream',
        imageLink: '5/1298995.jpg',
        alt: 'Fitstream'
      }
    ],
    ew: []
  }
]
