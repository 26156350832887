















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  emits: ['toggle']
})
