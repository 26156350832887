import { ref, watch, useFetch } from '@nuxtjs/composition-api'
import isEqual from 'lodash/isEqual'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { ExpressPickupStores } from '~/src/Model/ExpressPickup/ExpressPickup'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { useBasketRepository } from '~/src/Infrastructure/Basket/BasketRepository'

export const useExpressPickupStores = (disableFetch: boolean = false) => {
  const { loadExpressPickupStores } = useBasketRepository()
  const basketStore = useBasketStore()
  const expressPickupStores = ref<ExpressPickupStores | null>(null)
  const disablePickupStoresLoad = ref<boolean>(disableFetch ?? false)

  const { exec: loadStores, onSuccess: onExpressPickupStoresLoaded, loading: expressPickupStoresLoading } = usePromise(async () => {
    if (!basketStore.basketHash.value) {
      return
    }

    expressPickupStores.value = await loadExpressPickupStores(basketStore.basketHash.value)
    disablePickupStoresLoad.value = false
  })

  watch(basketStore.basketInfo, (value, oldValue) => {
    if (isEqual(value, oldValue) || disablePickupStoresLoad.value) {
      return
    }

    return loadStores()
  })

  useFetch(() => {
    if (!disablePickupStoresLoad.value) {
      return loadStores()
    }
  })

  const invalidateFetch = () => {
    disablePickupStoresLoad.value = true
  }

  return {
    expressPickupStores,
    onExpressPickupStoresLoaded,
    loadStores,
    expressPickupStoresLoading,
    invalidateFetch
  }
}
