




































import { ref, defineComponent, PropType } from '@nuxtjs/composition-api'
import { ChristmasAnnouncementContent, TypedAnnouncement } from '~/src/Model/Announcements/Announcements'
import AnnouncementLayout from '~/components/Announcements/AnnouncementLayout.vue'
import ChristmasAnnouncementDeliveryBadge
  from '~/components/Announcements/ChristmasAnnouncementContent/ChristmasAnnouncementDeliveryBadge.vue'
import ChristmasDeliveryCalendarModal from '~/components/ChristmasDelivery/ChristmasDeliveryCalendarModal.vue'
import ContentEditor from '~/components/ContentEditor/ContentEditor.vue'
import { ContentEditorBlockType } from '~/src/Model/ContentEditor/ContentEditor'

const CHRISTMAS_INFO_PAGE_UID = 'CHRISTMAS_INFO_PAGE'

export default defineComponent({
  components: {
    ContentEditor,
    ChristmasDeliveryCalendarModal,
    ChristmasAnnouncementDeliveryBadge,
    AnnouncementLayout
  },
  props: {
    announcement: {
      required: true,
      type: Object as PropType<TypedAnnouncement<ChristmasAnnouncementContent>>
    }
  },
  emits: ['dismissed'],
  setup () {
    const showChristmasDeliveryModal = ref<boolean>(false)

    return {
      showChristmasDeliveryModal,
      CHRISTMAS_INFO_PAGE_UID,
      contentEditorBlockTypes: ContentEditorBlockType
    }
  }
})
