










import { ref, computed, defineComponent } from '@nuxtjs/composition-api'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { useCoreDataStore } from '~/src/Infrastructure/CoreData/CoreDataStore'
import { Announcement, AnnouncementType } from '~/src/Model/Announcements/Announcements'
import StandardAnnouncement from '~/components/Announcements/StandardAnnouncement.vue'
import ChristmasAnnouncement from '~/components/Announcements/ChristmasAnnouncement.vue'
import DatartAnnouncement from '~/components/Announcements/DatartAnnouncement.vue'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

const DATART_ANNOUNCEMENT_COOKIE_KEY = 'DATART_ANNOUNCEMENT_COOKIE_KEY'

export default defineComponent({
  components: { ChristmasAnnouncement, StandardAnnouncement, DatartAnnouncement },
  setup () {
    const { app } = useApp()
    const webConfig = useWebConfig()
    const isNay = computed<boolean>(() => webConfig.web === Web.Nay)
    const cookies = app.$cookies
    const coreDataStore = useCoreDataStore()
    const announcements = coreDataStore.announcements
    const showDatartAnnouncement = ref<boolean>(!cookies.get(DATART_ANNOUNCEMENT_COOKIE_KEY) && isNay.value)

    const validAnnouncements = computed<Announcement[]>(() => {
      return announcements.value.filter(announcement => !cookies.get(announcement.cookieKey))
    })

    return {
      announcements,
      validAnnouncements,
      announcementType: AnnouncementType,
      showDatartAnnouncement,
      DATART_ANNOUNCEMENT_COOKIE_KEY
    }
  }
})
