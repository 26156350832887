import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { FilterSelectedAvailability } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterQueryAvailability } from '~/src/Model/ProductCategory/ParameterFilter/Request'

export type AvailabilityConvertorType = FilterElementConvertor<FilterSelectedAvailability, FilterQueryAvailability, never>

export class AvailabilityConvertor implements AvailabilityConvertorType {
  constructor (
    private readonly identifier: string = 'availability'
  ) {
  }

  shouldConvert (availability: FilterSelectedAvailability): boolean {
    return availability.length > 0
  }

  toUrl (filterAvailabilityValues: FilterSelectedAvailability): UrlElement {
    return new UrlElement(
      this.identifier,
      filterAvailabilityValues.map((availability) => {
        return availability.value.toString().replace(/_/g, '-')
      })
    )
  }

  toUrlFromTranslated (): UrlElement {
    throw new Error('This type of parameter is not supported in old parameter fitler')
  }

  fromUrl (element: UrlElement): FilterQueryAvailability {
    if (element.values.length === 0) {
      throw new Error('missing parameter values')
    }

    return [element.values.join('_').replace(/-/g, '_')]
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }
}
