import { FilterSort, FilterSortOrder, FilterSortSettings } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import {
  DefaultFilterSort,
  FilterSortMap,
  FilterSortType, sortTypeByFilterSort
} from '~/src/Model/ProductCategory/ParameterFilter/SortType'
import { TranslatedSort } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'

export type SortConvertorType = FilterElementConvertor<FilterSortSettings, FilterSort, TranslatedSort>

export class SortConvertor implements SortConvertorType {
  constructor (
    private readonly identifier: string = 'sort-by'
  ) {
  }

  toUrl (filterSortSettings: FilterSortSettings): UrlElement {
    const filterSortType: FilterSortType = sortTypeByFilterSort(filterSortSettings.filterSort)

    return new UrlElement(
      this.identifier,
      [filterSortType]
    )
  }

  toUrlFromTranslated (translatedSort: TranslatedSort): UrlElement {
    const translateMap: { [key: string]: string} = {
      price: 'prices'
    }

    if (translatedSort) {
      return this.toUrl({
        defaultFilterSort: {
          sortBy: translateMap[translatedSort.sortBy] ?? translatedSort.sortBy,
          order: translatedSort.order as FilterSortOrder
        },
        filterSort: {
          sortBy: translateMap[translatedSort.sortBy] ?? translatedSort.sortBy,
          order: translatedSort.order as FilterSortOrder
        }
      })
    }

    throw new Error('could not create new URL from empty sort')
  }

  fromUrl (element: UrlElement): FilterSort {
    if (element.values.length === 0) {
      throw new Error('missing sort by value')
    }

    return FilterSortMap[element.values[0] as FilterSortType] ?? DefaultFilterSort
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }

  shouldConvert (filterSortSettings: FilterSortSettings): boolean {
    if (filterSortSettings.filterSort.sortBy !== filterSortSettings.defaultFilterSort.sortBy) {
      return true
    }

    if (filterSortSettings.filterSort.order !== filterSortSettings.defaultFilterSort.order) {
      return true
    }

    return false
  }
}
