














import { defineComponent } from '@nuxtjs/composition-api'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: { Button },
  props: {
    size: {
      type: String,
      default: null
    },
    innerClass: {
      type: String,
      default: 'justify-content-start'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
})
