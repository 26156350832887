export enum ContextName {
  Warehouse = 'warehouse',
  Order = 'order',
}

export interface PdpBarContext {
  orderNumber?: number
  contextName: ContextName
}

export interface PdpBarRequest {
  alias: string
  context: PdpBarContext | null
}

export interface PanelLink {
  name: string
  url: string
}

export interface PanelParameter {
  label: string
  value: string
}

export interface PdpBar {
  title: string
  parameters: PanelParameter[]
  navigation: PanelLink[]
}
