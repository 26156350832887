import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { DeliveryDays } from '~/src/Model/ComfortDelivery/Deprecated/DeliveryDays'
import { ComfortDeliveryReservationRequest } from '~/src/Model/ComfortDelivery/Deprecated/Reservation'
import { BasketInfo } from '~/src/Model/Basket/BasketInfo'
import { OrderSlotSelectRequest } from '~/src/Model/ComfortDelivery/Deprecated/OrderSlotSelect'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useComfortDeliveryRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    getDeliveryDays: (basketHash: string, zip: string): Promise<DeliveryDays> => {
      return $axios.$get<DeliveryDays>(`/comfort-delivery-days/${basketHash}/${zip}`)
    },
    reserve: (request: ComfortDeliveryReservationRequest): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>('/comfort-delivery-days/reserve', request)
    },
    getDeliveryDaysForOrder: (token: string): Promise<DeliveryDays> => {
      return $axios.$get<DeliveryDays>(`/comfort-delivery-days/order/${token}`)
    },
    select: (request: OrderSlotSelectRequest): Promise<void> => {
      return $axios.$post<void>('/comfort-delivery-days/select', request)
    }
  }
}

export const useComfortDeliveryRepository = () => {
  const { $axios } = useAxios()

  return useComfortDeliveryRepositoryWithAxios($axios)
}
