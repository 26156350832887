





















import { defineComponent, computed } from '@nuxtjs/composition-api'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'

export default defineComponent({
  components: { Badge },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['sidebar-toggled'],
  setup (props, { emit }) {
    const basketStore = useBasketStore()
    const basketInfo = basketStore.basketInfo

    const toggleSidebar = () => {
      if (basketInfo.value === null) {
        return
      }
      emit('sidebar-toggled', ActiveHeaderPanel.basketBox)
    }

    const sidebarOpened = computed<boolean>({
      get: () => props.activePanel === ActiveHeaderPanel.basketBox,
      set: toggleSidebar
    })

    const basketTotalPrice = computed<string>(() => basketInfo.value?.totalPrice ?? '0.0')

    return {
      sidebarOpened,
      toggleSidebar,
      basketProductsCount: basketStore.basketProductsCount,
      basketInfo,
      basketTotalPrice
    }
  }
})
