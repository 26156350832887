import { Image } from '~/src/Model/Image/Image'
import { Video } from '~/src/Model/Video/Video'

export enum GalleryItemType {
  image = 'image',
  video = 'video'
}

export interface GalleryItem {
  type: GalleryItemType
}

export interface ImageGalleryItem extends GalleryItem{
  imageData: Image
}

export interface VideoGalleryItem extends GalleryItem {
  videoData: Video
}

export type GalleryItemValue = ImageGalleryItem | VideoGalleryItem
export interface ProductDetailGallery {
  items: GalleryItemValue[]
}
