import { SensitiveAttributeNames } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/SensitiveAttributeNames'
import {
  anonymizePrimitiveValue
} from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeDeep/AnonymizePrimitiveValue/AnonymizePrimitiveValue'

export const anonymizeDeep = (data: any, sensitiveAttributeNames: SensitiveAttributeNames, forceAnonymize?: boolean): any => {
  if (!data) {
    return data
  }

  for (const attributeName in data) {
    const isNonNullObject: boolean = data[attributeName] !== null && typeof data[attributeName] === 'object'

    if (sensitiveAttributeNames.includes(attributeName) || forceAnonymize) {
      data[attributeName] = isNonNullObject
        ? anonymizeDeep(data[attributeName], sensitiveAttributeNames, true)
        : anonymizePrimitiveValue(data[attributeName])
    } else if (isNonNullObject) {
      data[attributeName] = anonymizeDeep(data[attributeName], sensitiveAttributeNames, false)
    }
  }

  return data
}
