











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {},
  props: {
    name: {
      required: true,
      type: String
    },
    worthPrice: {
      required: true,
      type: String
    }
  }
})
