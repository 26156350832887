import { computed } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import { useVisitedProductsStore as useStore } from '~/stores/visited-products'

export const useVisitedProductsStore = (pinia?: Pinia | null | undefined) => {
  const store = useStore(pinia)

  const productBoxes = computed<ProductBox[]>(() => store.productBoxes)
  const productsIds = computed<number[]>(() => store.productsIds)
  const productsListId = computed<string | null>(() => store.productsListId)

  return {
    addProduct: store.addProduct,
    clear: store.clear,
    updateProductsListId: store.updateProductListId,
    loadProductBoxes: store.loadProductBoxes,
    productsIds,
    productBoxes,
    productsListId
  }
}
