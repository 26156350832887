import { Event } from '@sentry/types'

export const shouldBeAnonymized = (event: Event): boolean => {
  if (!event.user?.email) {
    return true
  }

  const isPeckaDesignUser = /@peckadesign\.cz/.test(event.user.email)

  return !isPeckaDesignUser
}
