import { ref, useFetch } from '@nuxtjs/composition-api'
import { Page } from '~/src/Model/Page/Page'
import { usePagesRepository } from '~/src/Infrastructure/Page/PagesRepository'

export const usePageByUidFetch = (uid: string) => {
  const page = ref<Page | null>(null)
  const { loadPagesByUid } = usePagesRepository()

  useFetch(async () => {
    const pages = await loadPagesByUid(uid)

    if (pages.pages.length === 0) {
      return
    }

    page.value = pages.pages[0]
  })

  return {
    page
  }
}
