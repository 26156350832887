import { Context } from '@nuxt/types/app'
import { OldFilterDetector } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/BackCompatibility/OldFilterDetector'
import { filterUrlConvertorFactory } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/FilterUrlConvertor'

export default async function ({ route, redirect, app }: Context) {
  const oldFilterDetector = new OldFilterDetector()

  if (Object.keys(route.query).length === 0) {
    return
  }

  if (!oldFilterDetector.detect(route.query)) {
    return
  }

  try {
    const filterUrlConvertor = filterUrlConvertorFactory()

    const originalQueryObject = { ...route.query }
    delete originalQueryObject.page

    const urlSearchParams = new URLSearchParams(originalQueryObject as Record<string, string>)
    const queryString = urlSearchParams.toString()

    const translatedQuery = await app.$axios.$get(`/translate-filter-query/${queryString}`)
    let newQueryParameters = { }

    if (route.query.page !== undefined) {
      newQueryParameters = { page: route.query.page }
    }

    redirect(301, `${route.path}/${filterUrlConvertor.persistFromTranslatedQuery(translatedQuery)}`, newQueryParameters)
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
  }
}
