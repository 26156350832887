















































































import { defineAsyncComponent, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import PhoneInput from '~/components/DesignSystem/Input/PhoneInput.vue'
import DatePicker from '~/components/DesignSystem/Input/DatePicker.vue'
import { LoyaltyMembersSignUpRequest } from '~/src/Model/LoyaltyMembers/LoyaltyMembersSignUpRequest'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import CheckboxInput from '~/components/DesignSystem/Input/CheckboxInput.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

const LOYALTY_CLUB_POLICY_UID = 'LOYALTY_CLUB_POLICY'

export default defineComponent({
  components: {
    CheckboxInput,
    DatePicker,
    PhoneInput,
    FormInput,
    PageModal: defineAsyncComponent(() => import('~/components/Page/PageModal.vue'))
  },
  props: {
    customerInfo: {
      type: Object as PropType<LoyaltyMembersSignUpRequest>,
      required: true
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    },
    isInModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:customer-info'],
  setup (props, { emit }) {
    const { unificate } = useVueInstanceUid()
    const customer = ref<LoyaltyMembersSignUpRequest>(cloneDeep(props.customerInfo))
    const showLoyaltyClubPolicyModal = ref<boolean>(false)
    const systemPagesStore = useSystemPagesStore()
    const loyaltyClubPolicyPath = systemPagesStore.pathByUid(LOYALTY_CLUB_POLICY_UID)

    watch(() => props.customerInfo, () => {
      customer.value = cloneDeep(props.customerInfo)
    })

    const updateCustomerInfo = () => {
      emit('update:customer-info', cloneDeep(customer.value))
    }

    const today = new Date()
    const maxDate = new Date()
    maxDate.setFullYear(today.getFullYear() - 18)

    return {
      unificate,
      customer,
      maxDate: maxDate.toISOString(),
      updateCustomerInfo,
      LOYALTY_CLUB_POLICY_UID,
      loyaltyClubPolicyPath,
      showLoyaltyClubPolicyModal
    }
  }
})
