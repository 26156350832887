








import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'PdpBarCopyIcon',
  props: {
    value: {
      required: true,
      type: [String, Number]
    },
    iconSize: {
      type: String,
      default: 'icon-fs-18'
    }
  },
  setup () {
    const showTooltip = ref<boolean>(false)
    const inputRef = ref<HTMLInputElement>()

    const copyToClipboard = () => {
      inputRef.value?.select()
      document.execCommand('copy')
      showTooltip.value = true
      setTimeout(function () {
        showTooltip.value = false
      }, 2000)
    }

    const iconClass = computed<string>(() => {
      if (showTooltip.value) {
        return 'icon-check text-success'
      }
      return 'icon-new-window'
    })

    return {
      inputRef,
      showTooltip,
      iconClass,
      copyToClipboard
    }
  }
})
