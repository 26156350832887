
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { BasketGift } from '~/src/Model/ProductGift/ProductGift'

export default defineComponent({
  props: {
    basketGift: {
      required: true,
      type: Object as PropType<BasketGift>
    },
    productQuantity: {
      required: true,
      type: Number
    }
  }
})
