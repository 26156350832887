import { ref, useFetch } from '@nuxtjs/composition-api'
import { WishListDetail } from '~/src/Model/WishLists/WishLists'
import { useWishListsRepository } from '~/src/Infrastructure/WishLists/WishListsRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const useWishListItemsFetch = (hash?: string) => {
  const wishList = ref<WishListDetail | null>(null)
  const wishListHash = ref<string | null>(hash ?? null)
  const { loadWishList } = useWishListsRepository()

  const { exec: execLoadWishlist, loading: fetchPending } = usePromise(async () => {
    if (!wishListHash.value) {
      return
    }
    wishList.value = await loadWishList(wishListHash.value)
  })

  useFetch(execLoadWishlist)

  const reloadItems = (hash: string) => {
    wishListHash.value = hash
    execLoadWishlist()
  }

  return {
    wishList,
    reloadItems,
    fetchPending
  }
}
