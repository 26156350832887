export enum RedirectConfigType {
  systemLink = 'systemLink',
  aliasLink = 'aliasLink'
}

export interface RedirectConfigQuery {
  name: string
  value: string
}

interface BaseLink {
  type: RedirectConfigType
  query: RedirectConfigQuery[]
}

export interface SystemLink extends BaseLink {
  type: RedirectConfigType.systemLink
  uid: string
}

export interface AliasLink extends BaseLink {
  type: RedirectConfigType.aliasLink
  alias: string
}

export type RedirectConfig = SystemLink | AliasLink

export interface RedirectConfigResponse {
  redirect: RedirectConfig
}

export interface OrderRedirectConfig {
  backLink: RedirectConfig | null
  continue: RedirectConfig
}
