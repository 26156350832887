






import { defineComponent, watch, ref } from '@nuxtjs/composition-api'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'

const MINIMAL_TIME_MS = 250

export default defineComponent({
  components: { LoadingAnim },
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: true
    },
    displayClass: {
      type: String,
      default: 'd-contents'
    },
    overlayClass: {
      type: String,
      default: null
    },
    wrapClass: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    hideAnim: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const isActive = ref<boolean>(props.showOverlay)
    let started = 0

    watch(() => props.showOverlay, (value: boolean) => {
      if (value === true) {
        isActive.value = true
        started = Date.now()

        return
      }

      const elapsedMilliseconds = Date.now() - started

      if (elapsedMilliseconds < MINIMAL_TIME_MS) {
        setTimeout(() => { isActive.value = false }, MINIMAL_TIME_MS - elapsedMilliseconds)
      } else {
        isActive.value = false
      }
    })

    return {
      isActive
    }
  }
})
