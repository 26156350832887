














import { defineComponent } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  props: {
    link: {
      type: String,
      required: true
    },
    imageLink: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  },
  setup () {
    const webConfig = useWebConfig()

    return {
      url: webConfig.cdnUrl
    }
  }
})
