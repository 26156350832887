












import { defineComponent, PropType } from '@nuxtjs/composition-api'
import TechnicalServiceItem from '~/components/Basket/PreBasket/ProductServices/TechnicalServices/TechnicalServiceItem.vue'
import {
  SelectedPreBasketTechnicalService,
  SelectedTechnicalService,
  TechnicalService
} from '~/src/Model/ProductServices/ProductServices'
import { ServiceVariant } from '~/src/Model/Services/Service'

export default defineComponent({
  components: { TechnicalServiceItem },
  props: {
    technicalServices: {
      required: true,
      type: Array as PropType<TechnicalService[]>
    },
    selectedTechnicalServices: {
      required: true,
      type: Array as PropType<SelectedTechnicalService[]>
    }
  },
  emits: ['selected-services', 'open-detail', 'input'],
  setup (props, { emit }) {
    const selectedServiceVariant = (service: TechnicalService) => {
      return props.selectedTechnicalServices.find(s => service.id === s.service.id)?.variant ?? null
    }

    const modifyServices = (service: TechnicalService, variant: ServiceVariant | null): void => {
      const newMap = new Map<number, SelectedPreBasketTechnicalService>()

      props.selectedTechnicalServices.forEach((item: SelectedPreBasketTechnicalService) => {
        newMap.set(item.service.id, item)
      })

      const existingService = newMap.get(service.id)

      if (existingService && !variant) {
        newMap.delete(service.id)
      } else {
        newMap.set(service.id, { service, variant })
      }
      emit('input', [...newMap.values()])
    }

    return {
      modifyServices,
      selectedServiceVariant
    }
  }
})
