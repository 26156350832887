




































import { defineComponent, onBeforeUnmount, PropType, ref } from '@nuxtjs/composition-api'

const MultiSelect = () => ({
  component: import('vue-multiselect')
})

export default defineComponent({
  name: 'BaseMultiselect',
  components: {
    MultiSelect
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, String, Number],
      default: Object
    },
    options: {
      type: Array as PropType<Array<Object | String | Number>>,
      required: true
    },
    searchable: {
      type: Boolean,
      default: true
    },
    showLabels: {
      type: Boolean,
      required: true
    },
    optionHeight: {
      type: Number,
      default: 4.0
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    trackBy: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    openDirection: {
      type: String,
      required: true
    },
    tabindex: {
      type: Number,
      default: null
    }
  },
  emits: ['input'],

  setup () {
    const multiselectRef = ref<any>()

    onBeforeUnmount(() => {
      multiselectRef.value?.deactivate()
    })

    return {
      multiselectRef
    }
  }
})

