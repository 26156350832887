import { ShareByEmail } from '~/src/Model/Share/ShareByEmail'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useShareRepository = () => {
  const { $axios } = useAxios()

  return {
    shareByEmail: (request: ShareByEmail): Promise<void> => $axios.$post<void>('/actions/share-by-email', request)
  }
}
