







import { defineComponent } from '@nuxtjs/composition-api'
import SendRecoveryEmail from '~/components/Auth/sendRecoveryEmail.vue'

export default defineComponent({
  components: {
    SendRecoveryEmail
  },
  emits: ['closeSubSidebar'],
  setup () {}
})
