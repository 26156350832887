import { useContext } from '@nuxtjs/composition-api'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  ChristmasDeliveryConfiguration
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'

export const useChristmasDeliveryConfigurationRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadConfiguration: (): Promise<ChristmasDeliveryConfiguration> => $axios.$get<ChristmasDeliveryConfiguration>('/delivery/christmas-configurations')
  }
}

export const useChristmasDeliveryConfigurationRepository = () => {
  const { $axios } = useContext()

  return useChristmasDeliveryConfigurationRepositoryWithAxios($axios)
}
