import {
  BreadCrumb,
  BreadCrumbRichSnippet,
  BreadCrumbRichSnippetItemListElement
} from '~/src/Model/BreadCrumb/BreadCrumb'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export const useBreadCrumbRichSnippetFactory = () => {
  const webConfig = useWebConfig()

  const breadCrumbRichSnippetFactory = (crumbs: BreadCrumb[]): BreadCrumbRichSnippet => {
    let iterator = 0

    const breadCrumbItemList: BreadCrumbRichSnippetItemListElement[] = crumbs.map((c) => {
      iterator++

      let path = `https://${webConfig.domain}`
      if (iterator > 1) {
        path = `https://${webConfig.domain}/${c.alias}`
      }

      return {
        '@type': 'ListItem',
        position: iterator,
        name: c.title,
        item: path
      }
    })

    return {
      richSnippet: {
        '@context': 'https://schema.org',
        '@type': 'BreadCrumbList',
        itemListElement: breadCrumbItemList
      }
    }
  }

  return {
    breadCrumbRichSnippetFactory
  }
}
