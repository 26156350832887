export enum ActiveHeaderPanel {
  none = 'NONE',
  mainMenu = 'MAIN_MENU',
  infoBox = 'INFO_BOX',
  wishlistBox = 'WISHLIST_BOX',
  compareBox = 'COMPARE_BOX',
  basketBox = 'BASKET_BOX',
  userBox = 'USER_BOX',
  searchBox = 'SEARCH_BOX',
  suggestBox = 'SUGGEST_BOX',
}
