










import { defineComponent } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'

export default defineComponent({
  components: { CurrentWeb },
  setup () {
    const $webConfig = useWebConfig()

    return {
      isNay: $webConfig.isNay
    }
  }
})
