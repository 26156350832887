









































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Image } from '~/src/Model/Image/Image'
import { GalleryItemValue } from '~/src/Model/ProductDetail/ProductDetailGallery'
import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import Gallery from '~/components/Gallery/Gallery.vue'
import EnergyLabel from '~/components/ProductBox/Components/EnergyLabel/EnergyLabel.vue'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'

export default defineComponent({
  components: { ImageWithFallback, EnergyLabel, Gallery },
  props: {
    productDetail: {
      type: Object as PropType<ProductDetail>,
      default: null
    },
    galleryItems: {
      type: Array as PropType<GalleryItemValue[]>,
      required: true
    },
    hideMosaic: {
      type: Boolean,
      default: false
    },
    disableModal: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const brandLogo = computed<Image | null>(() => {
      return props.productDetail?.brand?.image ?? null
    })

    return {
      brandLogo
    }
  }
})
