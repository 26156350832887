








































import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import BasketBoxProducts from '~/components/Header/BasketBox/BasketBoxProducts.vue'
import DeliveryProgress from '~/components/Basket/Components/DeliveryProgress.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PreBasketSidebarFooter from '~/components/Basket/PreBasket/PreBasketSidebarFooter.vue'

export default defineComponent({
  components: {
    PreBasketSidebarFooter,
    DeliveryProgress,
    BasketBoxProducts,
    Sidebar,
    Alert
  },
  emits: ['hidden'],
  setup (_props, { emit }) {
    const basketStore = useBasketStore()
    const basketInfo = basketStore.basketInfo
    const basketInitialized = ref<boolean>(false)

    onMounted(async () => {
      await basketStore.reloadAll()
      basketInitialized.value = true
    })

    const closeSidebar = () => {
      emit('hidden')
    }

    return {
      closeSidebar,
      basketInfo,
      basketInitialized
    }
  }
})
