








































































import { defineComponent, ref, watch, defineAsyncComponent } from '@nuxtjs/composition-api'
import CheckboxInput from '~/components/DesignSystem/Input/CheckboxInput.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { LoyaltyClubRegistrationAgreements } from '~/src/Model/LoyaltyClub/LoyaltyClubRegistrationAgreements'

const LOYALTY_CLUB_POLICY_UID = 'LOYALTY_CLUB_POLICY'
const GDPR_NEWSLETTER_AGREEMENT_UID = 'GDPR_AGREEMENT_NEWSLETTER'
const GDPR_PERSONALIZED_NEWSLETTER_AGREEMENT_UID = 'GDPR_AGREEMENT_NEWSLETTER_PERSONALIZED'

export default defineComponent({
  components: {
    CheckboxInput,
    PageModal: defineAsyncComponent(() => import('~/components/Page/PageModal.vue'))
  },
  emits: ['input'],
  setup (_, { emit }) {
    const allSelected = ref<boolean>(false)
    const agreements = ref<LoyaltyClubRegistrationAgreements>({
      agreedNewsletter: false,
      agreedClubPolicy: false,
      agreedPersonalizedNewsletter: false
    })

    const showLoyaltyClubPolicyModal = ref<boolean>(false)
    const showNewsletterAgreementModal = ref<boolean>(false)
    const showPersonalizedNewsletterAgreementModal = ref<boolean>(false)

    const systemPagesStore = useSystemPagesStore()
    const loyaltyClubPolicyPath = systemPagesStore.pathByUid(LOYALTY_CLUB_POLICY_UID)
    const gdprNewsletterAgreementPath = systemPagesStore.pathByUid(GDPR_NEWSLETTER_AGREEMENT_UID)
    const gdprPersonalizedNewsletterAgreementPath = systemPagesStore.pathByUid(GDPR_PERSONALIZED_NEWSLETTER_AGREEMENT_UID)

    const toggleAll = (checked: boolean) => {
      agreements.value.agreedClubPolicy = checked
      agreements.value.agreedNewsletter = checked
      agreements.value.agreedPersonalizedNewsletter = checked
    }

    watch(agreements.value, (newValue) => {
      allSelected.value = Object.values(newValue).every(Boolean)
      emit('input', agreements.value)
    })

    return {
      allSelected,
      toggleAll,
      loyaltyClubPolicyPath,
      agreements,
      gdprNewsletterAgreementPath,
      gdprPersonalizedNewsletterAgreementPath,
      showPersonalizedNewsletterAgreementModal,
      showNewsletterAgreementModal,
      showLoyaltyClubPolicyModal,
      LOYALTY_CLUB_POLICY_UID,
      GDPR_NEWSLETTER_AGREEMENT_UID,
      GDPR_PERSONALIZED_NEWSLETTER_AGREEMENT_UID
    }
  }
})
