
























































import { computed, defineComponent, PropType, ref, useRouter } from '@nuxtjs/composition-api'
import { addDays, set } from 'date-fns'
import { useResizeObserver } from '~/src/Infrastructure/WebAPI/ResizeObserver'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import { Announcement, LinkTarget } from '~/src/Model/Announcements/Announcements'
import { useInteralLinkFactory } from '~/src/Infrastructure/InternalLinkFactory/InternalLinkFactory'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { CookieExpiration } from '~/src/Model/Cookies/CookieExpiration'
import { useUrl } from '~/src/Infrastructure/Url/Url'

export default defineComponent({
  components: { Container, Button },
  props: {
    announcement: {
      required: true,
      type: Object as PropType<Announcement>
    },
    alertClass: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'bs-mr-3'
    },
    contentDisplayClass: {
      type: String,
      default: 'd-md-contents'
    }
  },
  emits: ['dismissed'],
  setup (props) {
    const { getRelativePath, isInternalLink } = useInteralLinkFactory()
    const { ensureProtocol } = useUrl()
    const router = useRouter()
    const { app } = useApp()
    const cookies = app.$cookies

    const localCssVariables = computed<string>(() => {
      const cssVariables = [
        `--background-color: ${props.announcement.content.style.backgroundColor};`,
        `--text-color: ${props.announcement.content.style.textColor};`,
        `--close-text-color: ${props.announcement.closeButtonStyle.textColor};`,
        `--close-text-color-hover: ${props.announcement.closeButtonStyle.backgroundColor};`,
        `--icon-color: ${props.announcement.iconColor};`
      ]

      if (props.announcement.link) {
        cssVariables.push(
          `--button-background-color: ${props.announcement.link.style.backgroundColor};`,
          `--button-text-color: ${props.announcement.link.style.textColor};`
        )
      }

      return cssVariables.join(' ')
    })

    const isInternalLinkUrl = computed<boolean>(() => isInternalLink(props.announcement.link.url))

    const announcementRef = ref<HTMLElement | undefined>()

    useResizeObserver(announcementRef, (entries) => {
      for (const entry of entries) {
        if (entry.borderBoxSize?.[0]) {
          document.documentElement.style.setProperty('--announcementHeight', `${entry.borderBoxSize[0].blockSize}px`)
        }
      }
    })

    const redirect = () => {
      if (props.announcement.link.target === LinkTarget.BLANK) {
        window.open(props.announcement.link.url, '_blank')
        return
      }
      if (props.announcement.link.target === LinkTarget.SELF) {
        router.push(getRelativePath(props.announcement.link.url))
      }
    }

    const getLinkPath = () => {
      const link = props.announcement.link.url
      if (isInternalLinkUrl.value) {
        return getRelativePath(link)
      }

      return link
    }

    const dismissed = () => {
      let expiration = null
      if (props.announcement.closeButton.cookieExpiration === CookieExpiration.NEVER) {
        expiration = addDays(new Date(), 365)
      } else if (props.announcement.closeButton.cookieExpiration === CookieExpiration.END_OF_DAY) {
        expiration = set(new Date(), {
          hours: 23,
          minutes: 59,
          seconds: 59
        })
      } else if (props.announcement.closeButton.cookieExpiration === CookieExpiration.DAY) {
        expiration = addDays(new Date(), 1)
      }
      cookies.set(props.announcement.cookieKey, 1, { expires: expiration })
    }

    return {
      localCssVariables,
      announcementRef,
      getRelativePath,
      redirect,
      getLinkPath,
      dismissed,
      isInternalLinkUrl,
      ensureProtocol
    }
  }
})
