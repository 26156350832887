


























import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import WatchdogSidebar from '~/components/Watchdog/WatchdogSidebar.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { SetWatchDogRequest } from '~/src/Model/WatchDogs/WatchDogs'
import { useWatchDogsStore } from '~/src/Infrastructure/WatchDogs/WatchDogsStore'
import UserBoxSidebar from '~/components/Header/UserBox/UserBoxSidebar.vue'
import WatchdogPopover from '~/components/Watchdog/WatchdogPopover.vue'

export default defineComponent({
  components: { WatchdogPopover, UserBoxSidebar, WatchdogSidebar },
  props: {
    productId: {
      required: true,
      type: Number
    },
    processWatchdog: {
      required: true,
      type: Boolean
    },
    popoverTarget: {
      required: true,
      type: String
    },
    popoverContainer: {
      required: true,
      type: String
    }
  },
  emits: ['update:process-watchdog', 'sidebar-hidden'],
  setup (props, { emit }) {
    const { authenticatedUser } = useAuthenticatedUser()
    const watchDogsStore = useWatchDogsStore()
    const watchDog = watchDogsStore.productWatchDog(props.productId)

    const showWatchDogSidebar = ref<boolean>(false)
    const showUserBoxSidebar = ref<boolean>(false)
    const showPopover = ref<boolean>(false)

    watch(() => props.processWatchdog, (value) => {
      if (value === false) {
        return
      }

      confirmIfAuthenticated()
      emit('update:process-watchdog', false)
    })

    const confirmWatchDog = (email?: string) => {
      const request: SetWatchDogRequest = {
        productId: props.productId,
        watchPrice: true,
        watchAvailability: true,
        email: email ?? null
      }

      watchDogsStore.addWatchDog(request).catch(() => {

      }).then(() => {
        showPopover.value = true
        showWatchDogSidebar.value = false
        showUserBoxSidebar.value = false
      })
    }

    const confirmIfAuthenticated = () => {
      if (watchDog.value) {
        showPopover.value = !showPopover.value
        return
      }

      if (authenticatedUser.value) {
        confirmWatchDog()
      } else {
        showUserBoxSidebar.value = false
        showWatchDogSidebar.value = true
      }
    }

    const userLogged = () => {
      confirmWatchDog()
    }

    const userBoxHidden = () => {
      showUserBoxSidebar.value = false
      showWatchDogSidebar.value = false
    }

    const showAuth = () => {
      showUserBoxSidebar.value = true
    }

    const watchDogSidebarHidden = () => {
      showWatchDogSidebar.value = false
    }

    return {
      authenticatedUser,
      watchDog,
      showPopover,
      showWatchDogSidebar,
      showUserBoxSidebar,
      confirmWatchDog,
      userLogged,
      userBoxHidden,
      showAuth,
      watchDogSidebarHidden
    }
  }
})
