import { ImageConfiguration, ObjectFit } from '~/src/Model/Image/ImageConfiguration'
import { ImageDimensions } from '~/src/Model/Image/ImageDimensions'

class ImageDimensionsCalculator {
  public calculateDimensions (width: number, height: number, configuration: ImageConfiguration): ImageDimensions {
    const dpr = configuration.retina ? 2 : 1
    const maxWidth = configuration.width * dpr
    const maxHeight = configuration.height * dpr

    if (!width || !height) {
      return { width: maxWidth, height: maxHeight }
    }

    if (!maxWidth || !maxHeight) {
      return { width, height }
    }

    // implicitní fixedRatio, řeší se zvlášť
    if (configuration.objectFit === ObjectFit.cover) {
      if (width >= maxWidth && height >= maxHeight) {
        return { width: maxWidth, height: maxHeight }
      } else {
        const ratio = width / height
        const maxRatio = maxWidth / maxHeight

        if (ratio > maxRatio) {
          return { width: this.round(height * maxRatio), height }
        } else {
          return { width, height: this.round(width / maxRatio) }
        }
      }
    }

    // obrázek je dostatečně velký nebo objectFit je contain, tj. můžeme zvětšovat
    if (configuration.objectFit === ObjectFit.contain || (width >= maxWidth && height >= maxHeight)) {
      if (configuration.objectFit === ObjectFit.none || configuration.fixedRatio) {
        return { width: maxWidth, height: maxHeight }
      } else {
        const ratio = Math.min(maxWidth / width, maxHeight / height)
        return { width: this.round(width * ratio), height: this.round(height * ratio) }
      }
    }

    // scale-down v případě, že obrázek není dostatečně velký
    if (configuration.objectFit === ObjectFit.scaleDown) {
      const wRatio = maxWidth / width
      const hRatio = maxHeight / height

      // ani jeden z rozměrů není dostatečný, odpovídá podmínce ($wRatio >= 1 && $hRatio >= 1),
      // protože $maxWidth >= $width && $maxHeight > $height, (opačný případ je ošetřen výše)
      let newWidth = width
      let newHeight = height

      if (wRatio >= 1 && hRatio <= 1) {
        // nedostatečná šířka
        newWidth = width * hRatio
        newHeight = height * hRatio
      } else if (wRatio <= 1 && hRatio >= 1) {
        // nedostatečná výška
        newWidth = width * wRatio
        newHeight = height * wRatio
      }

      if (configuration.fixedRatio) {
        const maxRatio = maxWidth / maxHeight
        const ratio = width / height

        if (maxRatio > ratio) {
          newWidth = newHeight * maxRatio
        } else {
          newHeight = newWidth / maxRatio
        }
      }

      return { width: this.round(newWidth), height: this.round(newHeight) }
    }

    if (configuration.objectFit === ObjectFit.none) {
      if (configuration.fixedRatio) {
        const newWidth = Math.min(width, maxWidth)
        const newHeight = Math.min(height, maxHeight)
        const maxRatio = maxWidth / maxHeight
        const newRatio = newWidth / newHeight

        if (maxRatio > newRatio) {
          return { width: this.round(newHeight * maxRatio), height: newHeight }
        } else {
          return { width: newWidth, height: this.round(newWidth / maxRatio) }
        }
      } else {
        return { width: Math.min(width, maxWidth), height: Math.min(height, maxHeight) }
      }
    }

    return { width, height }
  }

  public round (number: number): number {
    return Math.round(number)
  }
}

const imageDimensionsCalculator = new ImageDimensionsCalculator()

export default imageDimensionsCalculator
