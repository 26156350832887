





























































import { defineComponent } from '@nuxtjs/composition-api'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'

export default defineComponent({
  components: { CurrentWeb },
  props: {
    iconClass: {
      type: [String, Array],
      default: null
    },
    message: {
      type: String,
      default: null
    }
  },
  setup () {}
})
