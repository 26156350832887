
export enum IconType {
  cross = 'cross',
  check = 'check',
  default = 'default'
}

export enum Color {
  green = 'green',
  orange = 'orange',
  red = 'red',
  default = 'default'
}

export enum EshopAvailabilityUid {
  IN_STOCK = 'IN_STOCK',
  NOT_IN_STOCK = 'NOT_IN_STOCK',
  EXHIBIT_ON_ESHOP = 'EXHIBIT_ON_ESHOP'
}

export interface EshopAvailability {
  title: string
  iconType: IconType
  color: Color
  description: string | null
  availabilityUid: string | null
}

export interface Placeholder {
  name: string
  content: string
}

export interface AvailabilityTextInfo {
  text: string
  placeholder: Placeholder|null
}

export interface ProductAvailability {
  eshop: EshopAvailability
  delivery: AvailabilityTextInfo
  stores: AvailabilityTextInfo
}

export enum AvailabilityModalTab {
  courier = 0,
  personalPickup = 1
}
