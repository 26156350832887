const ProductDetail = () => import(/* webpackChunkName: "pages/product-detail/default" */ '~/pages/product-detail/default.vue')
const SaleProductDetail = () => import(/* webpackChunkName: "pages/product-detail/sale" */ '~/pages/product-detail/sale.vue')
const DefaultCategory = () => import(/* webpackChunkName: "pages/product-category/default" */ '~/pages/product-category/default.vue')
const TopLevelCategory = () => import(/* webpackChunkName: "pages/product-category/topLevel" */ '~/pages/product-category/topLevel.vue')
const DefaultPage = () => import(/* webpackChunkName: "pages/page/default" */ '~/pages/page/default.vue')
const DefaultArticle = () => import(/* webpackChunkName: "pages/article/default" */ '~/pages/article/default.vue')
const CheckoutBasket = () => import(/* webpackChunkName: "pages/order/checkout-basket" */ '~/pages/order/checkout-basket.vue')
const CheckoutOrder = () => import(/* webpackChunkName: "pages/order/checkout-order" */ '~/pages/order/checkout-order.vue')
const CheckoutExpressPickup = () => import(/* webpackChunkName: "pages/order/checkout-express-pickup" */ '~/pages/order/checkout-express-pickup.vue')
const Resume = () => import(/* webpackChunkName: "pages/order/resume" */ '~/pages/order/resume.vue')
const PrintOrder = () => import(/* webpackChunkName: "pages/order/print" */ '~/pages/order/print.vue')
const BrandDetail = () => import(/* webpackChunkName: "pages/page/brandDetail" */ '~/pages/page/brandDetail.vue')
const Login = () => import(/* webpackChunkName: "pages/auth/login.vue" */ '~/pages/auth/login.vue')
const ProducersList = () => import(/* webpackChunkName: "pages/page/producersList" */'~/pages/page/producersList.vue')
const LostPassword = () => import(/* webpackChunkName: "pages/page/LostPassword" */ '~/pages/page/lostPassword.vue')
const PersonalData = () => import(/* webpackChunkName: "pages/personal-data/default.vue" */ '~/pages/personal-data/default.vue')
const ResetPassword = () => import(/* webpackChunkName: "pages/page/ResetPassword" */ '~/pages/page/ResetPassword.vue')
const Registration = () => import(/* webpackChunkName: "pages/auth/registration.vue" */ '~/pages/auth/registration.vue')
const Profile = () => import(/* webpackChunkName: "pages/profile/default" */ '~/pages/profile/default.vue')
const SubscribeNewsletter = () => import(/* webpackChunkName: "pages/profile/default" */ '~/pages/page/subscribeNewsletter.vue')
const NewsletterDoubleOptInApproval = () => import(/* webpackChunkName: "pages/newsletter/doubleOptInApproval" */ '~/pages/newsletter/doubleOptInApproval.vue')
const StoresMap = () => import(/* webpackChunkName: "pages/warehouse/list" */ '~/pages/store/map.vue')
const NewsletterLogout = () => import(/* webpackChunkName: "pages/newsletter/logout" */ '~/pages/newsletter/logout.vue')
const StoreDetail = () => import(/* webpackChunkName: "pages/warehouse/detail" */ '~/pages/store/detail.vue')
const Purchases = () => import(/* webpackChunkName: "pages/profile/purchases" */ '~/pages/profile/purchases.vue')
const ProductComparison = () => import(/* webpackChunkName: "pages/product-comparison/default" */ '~/pages/product-comparison/default.vue')
const PurchaseDetail = () => import(/* webpackChunkName: "pages/profile/purchase-detail" */ '~/pages/profile/purchase-detail.vue')
const ReceiptDetail = () => import(/* webpackChunkName: "pages/receipt/receiptDetail" */ '~/pages/receipt/receiptDetail.vue')
const PrintReceipt = () => import(/* webpackChunkName: "pages/receipt/print" */ '~/pages/receipt/print.vue')
const ContactInfoOrders = () => import(/* webpackChunkName: "pages/contact-info-orders/default" */ '~/pages/contact-info-orders/default.vue')
const AfterSaleServices = () => import(/* webpackChunkName: "pages/afterSaleServices/default" */ '~/pages/afterSaleServices/default.vue')
const ContactPage = () => import(/* webpackChunkName: "pages/contact/default" */ '~/pages/contact/default.vue')
const LoyaltyClubProfile = () => import(/* webpackChunkName: "pages/profile/loyaltyClub" */ '~/pages/profile/loyaltyClub.vue')
const LoyaltyMemberTransactions = () => import(/* webpackChunkName: "pages/profile/loyaltyClubTransactions" */ '~/pages/profile/loyaltyClubTransactions.vue')
const AfterSaleServiceProductOffer = () => import(/* webpackChunkName: "pages/afterSaleServices/productOffer" */ '~/pages/afterSaleServices/productOffer.vue')
const AfterSaleServicesRegistered = () => import(/* webpackChunkName: "pages/profile/afterSaleServices" */ '~/pages/profile/afterSaleServices.vue')
const AfterSaleServicesForPurchasedProducts = () => import(/* webpackChunkName: "pages/profile/afterSaleServicesForPurchasedProducts" */ '~/pages/profile/afterSaleServicesForPurchasedProducts.vue')
const LuigisboxSearch = () => import(/* webpackChunkName: "pages/luigisbox/default" */ '~/pages/luigisbox/default.vue')
const B2BContact = () => import(/* webpackChunkName: "pages/b2b/default" */ '~/pages/b2b/default.vue')
const SpecialOffers = () => import(/* webpackChunkName: "pages/article/specialOffers" */ '~/pages/article/specialOffers.vue')
const ProductsToReview = () => import(/* webpackChunkName: "pages/profile/productsToReview" */ '~/pages/profile/productsToReview.vue')
const PurchasedProducts = () => import(/* webpackChunkName: "pages/profile/purchasedProducts" */ '~/pages/profile/purchasedProducts.vue')
const purchasedGiftVouchers = () => import(/* webpackChunkName: "pages/profile/purchasedGiftVouchers" */ '~/pages/profile/purchasedGiftVouchers.vue')
const ReviewedProducts = () => import(/* webpackChunkName: "pages/reviewed-products/default.vue" */ '~/pages/reviewed-products/default.vue')
const PageCrossroad = () => import(/* webpackChunkName: "pages/page/pageCrossroad" */ '~/pages/page/pageCrossroad.vue')
const ArticleMagazinCategory = () => import(/* webpackChunkName: "pages/article/articleMagazineCategory.vue" */ '~/pages/article/articleMagazineCategory.vue')
const InstallmentBrigdeCalculator = () => import(/* webpackChunkName: "pages/order/installmentBridgeCalculator.vue" */ '~/pages/order/installmentBridgeCalculator.vue')
const OnlinePayment = () => import(/* webpackChunkName: "pages/order/online-payment" */ '~/pages/order/online-payment.vue')
const Review = () => import(/* webpackChunkName: "pages/review/default" */ '~/pages/review/default.vue')
const ExponeaReview = () => import(/* webpackChunkName: "pages/review/default" */ '~/pages/review/ExponeaReview.vue')
const PrintReservation = () => import(/* webpackChunkName: "pages/print-reservation/default" */ '~/pages/print-reservation/default.vue')
const ArticleAction = () => import(/* webpackChunkName: "pages/article/action" */ '~/pages/article/action.vue')
const ComfortDeliverySlotSelect = () => import(/* webpackChunkName: "pages/comfort-delivery-deprecated/slot-select" */ '~/pages/comfort-delivery-deprecated/slot-select.vue')
const ComfortDeliverySlotChange = () => import(/* webpackChunkName: "pages/comfort-delivery-deprecated/slot-change" */ '~/pages/comfort-delivery-deprecated/slot-change.vue')
const DysonShopInShop = () => import(/* webpackChunkName: "pages/brandShop/dyson" */ '~/pages/brandShop/dyson.vue')
const ShopInShop = () => import(/* webpackChunkName: "pages/brandShop/default" */ '~/pages/brandShop/default.vue')
const Wishlist = () => import(/* webpackChunkName: "pages/wishlist/wishlist" */ '~/pages/wishlist/wishlist.vue')
const ElectronicContentLibrary = () => import(/* webpackChunkName: "pages/profile/electronicContentLibrary" */ '~/pages/profile/electronicContentLibrary.vue')
const RequestGetUserData = () => import(/* webpackChunkName: "pages/gdpr/requestGetUserData" */ '~/pages/gdpr/requestGetUserData.vue')
const UserRequestsList = () => import(/* webpackChunkName: "pages/gdpr/userRequestsList" */ '~/pages/gdpr/userRequestsList.vue')
const GdprConsents = () => import(/* webpackChunkName: "pages/gdpr/gdprConsents" */ '~/pages/gdpr/gdprConsents.vue')
const RequestChangeUserData = () => import(/* webpackChunkName: "pages/gdpr/requestChangeUserData" */ '~/pages/gdpr/requestChangeUserData.vue')
const ProtestRequest = () => import(/* webpackChunkName: "pages/gdpr/protestRequest" */ '~/pages/gdpr/protestRequest.vue')
const RequestDeleteLoyaltyClubUserData = () => import(/* webpackChunkName: "pages/gdpr/requestDeleteLoyaltyClubUserData" */ '~/pages/gdpr/requestDeleteLoyaltyClubUserData.vue')
const RequestDeleteUserData = () => import(/* webpackChunkName: "pages/gdpr/requestDeleteUserData" */ '~/pages/gdpr/requestDeleteUserData.vue')
const NewReclamation = () => import(/* webpackChunkName: "pages/reclamations/newReclamation" */ '~/pages/reclamations/newReclamation.vue')
const ReclamationProductSelect = () => import(/* webpackChunkName: "pages/reclamations/reclamationProductSelect" */ '~/pages/reclamations/reclamationProductSelect.vue')
const ElectronicContentOrderFinished = () => import(/* webpackChunkName: "pages/electronic-content/orderFinished" */ '~/pages/electronic-content/orderFinished.vue')
const GiftVoucherOrderFinished = () => import(/* webpackChunkName: "pages/electronic-content/giftVoucherOrderFinished" */ '~/pages/electronic-content/giftVoucherOrderFinished.vue')
const DualEducation = () => import(/* webpackChunkName: "pages/dual-education/default" */ '~/pages/dual-education/default.vue')
const GdprNewsletter = () => import(/* webpackChunkName: "pages/gdpr/gdprNewsletter" */ '~/pages/gdpr/gdprNewsletter.vue')
const GdprPersonalizedNewsletter = () => import(/* webpackChunkName: "pages/gdpr/gdprPersonalizedNewsletter" */ '~/pages/gdpr/gdprPersonalizedNewsletter.vue')
const GdprProductReview = () => import(/* webpackChunkName: "pages/gdpr/gdprProductReview" */ '~/pages/gdpr/gdprProductReview.vue')
const GdprLoyaltyClub = () => import(/* webpackChunkName: "pages/gdpr/gdprLoyaltyClub" */ '~/pages/gdpr/gdprLoyaltyClub.vue')
const GdprProfiling = () => import(/* webpackChunkName: "pages/gdpr/gdprProfiling" */ '~/pages/gdpr/gdprProfiling.vue')
const GdprRetargeting = () => import(/* webpackChunkName: "pages/gdpr/gdprRetargeting" */ '~/pages/gdpr/gdprRetargeting.vue')
const LeafletDetail = () => import(/* webpackChunkName: "pages/article/leaflet" */ '~/pages/article/leaflet.vue')
const LeafletList = () => import(/* webpackChunkName: "pages/page/leafletList" */ '~/pages/page/leafletList.vue')
const VoucherCodes = () => import(/* webpackChunkName: "pages/profile/voucherCodes" */ '~/pages/profile/voucherCodes.vue')
const ProductClearance = () => import(/* webpackChunkName: "pages/product-category/clearance" */ '~/pages/product-category/clearance.vue')
const ProductsWithLoyaltyPrice = () => import(/* webpackChunkName: "pages/product-category/loyaltyProducts" */ '~/pages/product-category/loyaltyProducts.vue')
const ReclamationProductSearch = () => import(/* webpackChunkName: "pages/reclamations/reclamationProductSearch" */ '~/pages/reclamations/reclamationProductSearch.vue')
const ArticleMagazineDetail = () => import(/* webpackChunkName: "pages/article/articleMagazineDetail" */ '~/pages/article/articleMagazineDetail.vue')
const AnnualReportsSk = () => import(/* webpackChunkName: "pages/annualReports/default" */ '~/pages/annualReports/annualReportsSk.vue')
const AnnualReportsEn = () => import(/* webpackChunkName: "pages/annualReports/default" */ '~/pages/annualReports/annualReportsEn.vue')
const HomeCreditCalculator = () => import(/* webpackChunkName: "pages/order/homeCreditCalculator" */ '~/pages/order/homeCreditCalculator.vue')
const PageCrossroadMenu = () => import(/* webpackChunkName: "pages/page/pageCrossroadMenu" */ '~/pages/page/pageCrossroadMenu.vue')
const ReclamationsInProgress = () => import(/* webpackChunkName: "pages/reclamations/reclamationsInProgress" */ '~/pages/reclamations/reclamationsInProgress.vue')
const ReclamationsResolved = () => import(/* webpackChunkName: "pages/reclamations/reclamationsResolved" */ '~/pages/reclamations/reclamationsResolved.vue')
const ResumeServiceOrder = () => import(/* webpackChunkName: "pages/serviceOrder/resumeServiceOrder" */ '~/pages/afterSaleServices/resumeServiceOrder.vue')
const GdprUnloggedUserRequest = () => import(/* webpackChunkName: "pages/gdpr/gdprUnloggedUserRequest" */ '~/pages/gdpr/gdprUnloggedUserRequest.vue')
const GdprRouter = () => import(/* webpackChunkName: "pages/gdpr/gdprRouter" */ '~/pages/gdpr/gdprRouter.vue')
const AfterSaleServiceMicrosite = () => import(/* webpackChunkName: "pages/afterSaleServices/afterSaleServiceMicrosite" */ '~/pages/afterSaleServices/afterSaleServiceMicrosite.vue')
const ChristmasMicrosite = () => import(/* webpackChunkName: "pages/christmas/default" */ '~/pages/christmas/default.vue')
const BlackFridayMicrosite = () => import(/* webpackChunkName: "pages/article/blackFriday" */ '~/pages/article/blackFriday.vue')
const Watchdog = () => import(/* webpackChunkName: "pages/profile/watchdog" */ '~/pages/profile/watchdog.vue')
const WatchdogUnlogged = () => import(/* webpackChunkName: "pages/profile/watchdogUnlogged" */ '~/pages/profile/watchdogUnlogged.vue')
const RequestDataDownload = () => import(/* webpackChunkName: "pages/gdpr/requestDataDownload" */ '~/pages/gdpr/requestDataDownload.vue')
const ExponeaUnsubscribeReview = () => import(/* webpackChunkName: "pages/review/exponeaUnsubscribeReview" */ '~/pages/review/exponeaUnsubscribeReview.vue')
const PersonalizedNewsletterLogout = () => import(/* webpackChunkName: "pages/newsletter/logoutPersonalized" */ '~/pages/newsletter/logoutPersonalized.vue')
const MieleCategory = () => import(/* webpackChunkName: "pages/brandShop/mieleCategory" */ '~/pages/brandShop/miele/mieleCategory.vue')
const MieleBrand = () => import(/* webpackChunkName: "pages/brandShop/mieleBrand" */ '~/pages/brandShop/miele/mieleBrand.vue')
const UrbantzSlotSelect = () => import(/* webpackChunkName: "pages/comfort-delivery/timeSlotSelect" */ '~/pages/comfort-delivery/timeSlotSelect.vue')
const UrbantzResume = () => import(/* webpackChunkName: "pages/comfort-delivery/resume" */ '~/pages/comfort-delivery/resume.vue')
const VipRegistration = () => import(/* webpackChunkName: "pages/vip/default" */ '~/pages/vip/default.vue')
const EmployeeRegistration = () => import(/* webpackChunkName: "pages/employee/default" */ '~/pages/employee/default.vue')
const PageCrossroadUserSection = () => import(/* webpackChunkName: "pages/page/pageCrossroadUserSection" */ '~/pages/page/pageCrossroadUserSection.vue')
const ReverseCharge = () => import(/* webpackChunkName: "pages/order/reverseChargeConfirm" */ '~/pages/order/reverseChargeConfirm.vue')
const AppleIphone13 = () => import(/* webpackChunkName: "pages/page/microsite/appleIphone13" */ '~/pages/page/microsite/appleIphone13.vue')
const Apple202209Unknown1 = () => import(/* webpackChunkName: "pages/page/microsite/apple202209unknown1" */ '~/pages/page/microsite/apple202209unknown1.vue')
const AppleSwitchToMac = () => import(/* webpackChunkName: "pages/page/microsite/appleSwitchToMac" */ '~/pages/page/microsite/appleSwitchToMac.vue')
const Apple202209Unknown3 = () => import(/* webpackChunkName: "pages/page/microsite/apple202209unknown3" */ '~/pages/page/microsite/apple202209unknown3.vue')
const Apple202209Unknown4 = () => import(/* webpackChunkName: "pages/page/microsite/apple202209unknown4" */ '~/pages/page/microsite/apple202209unknown4.vue')
const AppleIphonePrivacyCampaign = () => import(/* webpackChunkName: "pages/page/microsite/appleIphonePrivacyCampaign" */ '~/pages/page/microsite/appleIphonePrivacyCampaign.vue')
const AppleIPadAir5G = () => import(/* webpackChunkName: "pages/page/microsite/appleIPadAir5G" */ '~/pages/page/microsite/appleIPadAir5G.vue')
const SamsungGalaxyGepard = () => import(/* webpackChunkName: "pages/page/microsite/samsungGalaxyGepard" */ '~/pages/page/microsite/samsungGalaxyGepard.vue')
const ChristmasInfoPage = () => import(/* webpackChunkName: "pages/brandShop/christmasInfoPage" */ '~/pages/brandShop/christmasInfoPage.vue')
const ChristmasDeliveryCalendar = () => import(/* webpackChunkName: "pages/christmas/deliveryCalendar" */ '~/pages/christmas/deliveryCalendar.vue')
const AppleEsisHomepage2023 = () => import(/* webpackChunkName: "pages/page/microsite/appleEsisHomepage2023" */ '~/pages/page/microsite/appleEsisHomepage2023.vue')
const AppleEsisSubpage2023 = () => import(/* webpackChunkName: "pages/page/microsite/appleEsisSubpage2023" */ '~/pages/page/microsite/appleEsisSubpage2023.vue')
const StudentClub = () => import(/* webpackChunkName: "~/pages/profile/studentClub.vue" */ '~/pages/profile/studentClub.vue')

type ComponentMap = { [module: string]: { [template: string]: Function } }

export const componentMap: ComponentMap = {
  product: {
    default: ProductDetail,
    sale: SaleProductDetail,
    'product:category': DefaultCategory,
    'product:categoryTopLevel': TopLevelCategory,
    'product:detailBrand': BrandDetail,
    'product:producerList': ProducersList,
    'warehouse:warehouseMap': StoresMap,
    'warehouse:warehouseDetail': StoreDetail,
    'product:compare': ProductComparison,
    'product:action': ArticleAction,
    'brandShop:default': ShopInShop,
    'brandShop:dyson': DysonShopInShop,
    'product:clearance': ProductClearance,
    'ProductsWithLoyaltyPrice:default': ProductsWithLoyaltyPrice,
    'christmas:default': ChristmasMicrosite,
    'product:blackfriday': BlackFridayMicrosite,
    'product:serviceProducerDetail': DefaultPage, // Fallback
    'mieleCategory:default': MieleCategory,
    'brandShop:miele': MieleBrand,
    'product:serviceProducerList': DefaultPage, // Fallback
    'brandShop:christmasInfoPage': ChristmasInfoPage,
    'christmas:deliveryCalendar': ChristmasDeliveryCalendar
  },
  page: {
    'page:default': DefaultPage,
    default: DefaultPage,
    'page:contactInfoOrders': ContactInfoOrders,
    'page:contact': ContactPage,
    'page:pageCrossroad': PageCrossroad,
    'page:pageCrossroadMenu': PageCrossroadMenu,
    'page:pageCrossroadUserSection': PageCrossroadUserSection,
    'page:pageReviews': Review,
    'page:leaflet': LeafletList,
    'page:annualReportsSk': AnnualReportsSk,
    'page:annualReportsEn': AnnualReportsEn,
    'page:offerOfServicesForPurchasedProducts': AfterSaleServicesForPurchasedProducts,
    'microsite:appleIphone13': AppleIphone13,
    'microsite:apple202209unknown1': Apple202209Unknown1,
    'microsite:appleSwitchToMac': AppleSwitchToMac,
    'microsite:apple202209unknown3': Apple202209Unknown3,
    'microsite:apple202209unknown4': Apple202209Unknown4,
    'microsite:appleiPhonePrivacyCampaign': AppleIphonePrivacyCampaign,
    'microsite:appleIPadAir5G': AppleIPadAir5G,
    'microsite:samsungGalaxyGepard': SamsungGalaxyGepard,
    'microsite:appleEsisHomepage2023': AppleEsisHomepage2023,
    'microsite:appleEsisSubpage2023': AppleEsisSubpage2023,
    'page:full': DefaultPage, // Fallback,
    'page:mieleConfiguratorCookingCombiSet': DefaultPage, // Fallback
    'page:mieleConfiguratorOven': DefaultPage, // Fallback
    'page:mieleConfiguratorDryer': DefaultPage, // Fallback
    'page:mieleConfiguratorCoffemachine': DefaultPage, // Fallback
    'page:helpOnlineShopping': DefaultPage, // Fallback
    'page:mieleConfiguratorVacuumCleaner': DefaultPage, // Fallback
    'page:mieleConfiguratorMicrowaveOven': DefaultPage, // Fallback
    'page:mieleConfiguratorDishWasher': DefaultPage, // Fallback
    'product:serviceProducerList': DefaultPage, // Fallback
    'page:unsubscribeReviews': DefaultPage, // Fallback
    'page:mieleConfiguratorSteamoven': DefaultPage, // Fallback
    'page:mieleConfiguratorFridge': DefaultPage, // Fallback
    'page:mieleConfiguratorCookerHoods': DefaultPage, // Fallback
    'page:mieleConfigurator': DefaultPage // Fallback
  },
  article: {
    default: DefaultArticle,
    'article:articlePromoCategoryPictures': SpecialOffers,
    'article:articleMagazineCategory': ArticleMagazinCategory,
    'article:articleMagazineDetail': ArticleMagazineDetail,
    'article:articleCategory': ArticleMagazinCategory,
    'article:leaflet': LeafletDetail,
    'article:articleCategoryPictures': DefaultPage // Fallback
  },
  order: {
    'order:resume': Resume,
    'order:resumeServiceOrder': ResumeServiceOrder,
    'order:print': PrintOrder,
    'order:resumeUrbantz': UrbantzResume,
    'order:reverseCharge': ReverseCharge,
    'checkout:basket': CheckoutBasket,
    'checkout:order': CheckoutOrder,
    'checkout:expressPickup': CheckoutExpressPickup
  },
  Order: {
    'PrintReservation:default': PrintReservation
  },
  webpay: {
    'onlinePayment:default': OnlinePayment,
    'installmentsBridgeCalculator:default': InstallmentBrigdeCalculator,
    'homeCredit:default': HomeCreditCalculator,
    'homeCreditApprovedPayment:default': DefaultPage, // Fallback
    'homeCreditRejectedPayment:default': DefaultPage, // Fallback
    'installmentsBridgeBackLink:approved': DefaultPage // Fallback
  },
  user: {
    'user:login': Login,
    'user:lostPassword': LostPassword,
    'profil:personalData': PersonalData,
    'user:resetPassword': ResetPassword,
    'user:registration': Registration,
    'profil:profil': Profile,
    'profil:orderHistory': Purchases,
    'orderDetail:default': PurchaseDetail,
    'loyaltyClub:default': LoyaltyClubProfile,
    'profil:productsToReview': ProductsToReview,
    'profil:reviewedProducts': ReviewedProducts,
    'profil:purchasedProducts': PurchasedProducts,
    'gdpr:newsletter': GdprNewsletter,
    'profil:voucherCodes': VoucherCodes,
    'profil:gdprRouter': GdprRouter,
    'profil:purchasedGiftVouchers': purchasedGiftVouchers,
    'vipRegistration:default': VipRegistration,
    'zamRegistration:default': EmployeeRegistration,
    'studentClub:default': StudentClub
  },
  loyalty: {
    'loyaltyMemberTransactions:default': LoyaltyMemberTransactions
  },
  gdprNewsletter: {
    'GdprNewsletter:subscribe': NewsletterDoubleOptInApproval
  },
  Newsletter: {
    'SubscribeNewsletter:default': SubscribeNewsletter
  },
  exponea: {
    'ExponeaNewsletter:unsubscribe': NewsletterLogout,
    'exponeaNewsletter:unsubscribePersonalized': PersonalizedNewsletterLogout
  },
  receipt: {
    'receiptDetail:default': ReceiptDetail,
    'receiptPrint:default': PrintReceipt
  },
  afterSaleServices: {
    'publicPage:default': AfterSaleServices,
    'productOffer:default': AfterSaleServiceProductOffer,
    'customerPage:default': AfterSaleServicesRegistered,
    'afterSaleServices:default': AfterSaleServiceMicrosite
  },
  LuigisBox: {
    default: LuigisboxSearch
  },
  B2B: {
    'Contact:default': B2BContact
  },
  plantour: {
    'timeSlotSelect:default': ComfortDeliverySlotSelect,
    'timeSlotChange:default': ComfortDeliverySlotChange
  },
  wishlist: {
    'wishlist:wishlist': Wishlist,
    'wishlist:sendToMail': DefaultPage // Fallback
  },
  electronicContent: {
    'electronicContentLibrary:default': ElectronicContentLibrary,
    'orderFinished:default': ElectronicContentOrderFinished,
    'giftVoucherOrderFinished:default': GiftVoucherOrderFinished,
    'checkout:default': DefaultPage // Fallback
  },
  gdpr: {
    'requestGetUserData:default': RequestGetUserData,
    'userRequestsList:default': UserRequestsList,
    'personalDataProcessingRestriction:default': GdprConsents,
    'requestChangeUserData:default': RequestChangeUserData,
    'protestRequest:default': ProtestRequest,
    'requestDeleteLoyaltyClubUserData:default': RequestDeleteLoyaltyClubUserData,
    'requestDeleteUserData:default': RequestDeleteUserData,
    'loyaltyClubMembershipRequestsList:default': GdprLoyaltyClub,
    'profilingAgreementsList:default': GdprProfiling,
    'retargetingAgreementsList:default': GdprRetargeting,
    'unloggedUserRequest:default': GdprUnloggedUserRequest,
    'requestDataDownload:default': RequestDataDownload
  },
  reclamations: {
    'reclamation:newReclamation': NewReclamation,
    'personalDataProcessingRestriction:default': GdprConsents,
    'reclamation:productSearch': ReclamationProductSearch,
    'reclamation:reclamationProductSelect': ReclamationProductSelect,
    'reclamation:reclamationsInProgress': ReclamationsInProgress,
    'reclamation:reclamationsResolved': ReclamationsResolved
  },
  dualEducation: {
    'dualEducation:default': DualEducation,
    'dualEducation:agreement': DefaultPage // Fallback
  },
  personalizedNewsletter: {
    'personalizedNewsletterList:default': GdprPersonalizedNewsletter
  },
  gdprEmailReview: {
    'emailReviewRequestsList:default': GdprProductReview
  },
  watchDog: {
    'watchDogs:watchDogProductsList': Watchdog,
    'watchDogs:unloggedCustomer': WatchdogUnlogged
  },
  review: {
    'exponeaUnsubscribeReview:unsubscribe': ExponeaUnsubscribeReview,
    'exponeaReview:default': ExponeaReview
  },
  card: {
    'UserCardReunite:default': DefaultPage // Fallback
  },
  urbantz: {
    'timeSlotSelect:urbantz': UrbantzSlotSelect
  }
}
