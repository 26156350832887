











import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import { useWishListsStore } from '~/src/Infrastructure/WishLists/WishListsStore'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'

export default defineComponent({
  components: { Badge },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['sidebar-opened'],
  setup (props, { emit }) {
    const { authenticatedUser } = useAuthenticatedUser()
    const wishListsStore = useWishListsStore()
    const wishListsItemsCount = wishListsStore.wishListsItemsCount

    onMounted(() => {
      if (authenticatedUser.value) {
        wishListsStore.loadWishLists()
      }
    })

    const openSidebar = () => {
      emit('sidebar-opened', ActiveHeaderPanel.wishlistBox)
    }

    const sidebarOpened = computed<boolean>(() => props.activePanel === ActiveHeaderPanel.wishlistBox)

    return {
      sidebarOpened,
      openSidebar,
      wishListsItemsCount
    }
  }
})
