export class UrlElement {
  private readonly _values: string[]

  constructor (
    private readonly _identifier: string,
    values: string[],
    private readonly compareFunction?: (a: string, b: string) => number
  ) {
    this._values = [...values]
  }

  toString (): string {
    let values: string[] = []

    if (this.compareFunction) {
      values = this.values.sort(this.compareFunction)
    } else {
      values = this.values.sort((a, b) => {
        return isNaN(Number(a)) || isNaN(Number(b)) ? a.localeCompare(b) : Number(a) - Number(b)
      })
    }

    return `${this.identifier}_${values.join('_')}`
  }

  static fromString (input: string): UrlElement {
    const items = input.split('_')

    return new UrlElement(items[0], items.slice(1))
  }

  get identifier (): string {
    return this._identifier
  }

  get values (): string[] {
    return this._values
  }
}
