









import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

export default defineComponent({
  name: 'SystemLink',
  props: {
    uid: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const route = useRoute()
    const systemPagesStore = useSystemPagesStore()

    const alias = computed<string>(() => systemPagesStore.pathByUid(props.uid).value)
    const isActive = computed<boolean>(() => route.value?.path === alias.value)

    return {
      alias,
      isActive
    }
  }
})
