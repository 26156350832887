export enum DatartMigrationUserType {
  new = 'new',
  migrated = 'migrated'
}

export enum DatartMigrationLoginType {
  datart = 'D',
  nay = 'N'
}

export interface DatartMigration {
  type: DatartMigrationUserType
  login: DatartMigrationLoginType
}

export interface CheckCustomerResponse {
  isCompanyCustomer: boolean
  isCustomer: boolean
  isB2B: boolean
  datartMigration: DatartMigration | null
}
