export interface CheckCompanyCustomerResponse {
  isCompanyCustomer: boolean
}

export interface ForceLoginState {
  force: boolean
  expand: boolean
  email: string
}

export type ForceExpandedState = Omit<ForceLoginState, 'email'>
export type ForceLoginEmail = Omit<ForceLoginState, 'expand'>

export const defaultForceLoginState: ForceLoginState = {
  force: false,
  expand: false,
  email: ''
}

export const defaultForceLoginEmail: ForceLoginEmail = {
  force: false,
  email: ''
}

export const defaultForceExpandedState: ForceExpandedState = {
  force: false,
  expand: false
}
