
































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { NavigationNode } from '~/src/Model/NavigationNode/NavigationNode'
import MainMenuVouchersBrands from '~/components/Header/MainMenu/VouchersBrands/MainMenuVouchersBrands.vue'

export default defineComponent({
  components: { MainMenuVouchersBrands },
  props: {
    node: {
      type: Object as PropType<NavigationNode> | null,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    disabledBrands: {
      type: Array,
      default: () => []
    },
    showGiftVouchersLink: {
      type: Boolean,
      default: false
    }
  },
  emits: ['step-back'],
  setup (_props, { emit }) {
    const stepBack = () => {
      emit('step-back')
    }

    return {
      stepBack
    }
  }
})
