

















import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import Header from '~/components/Header/Header.vue'
import Announcements from '~/components/Announcements/Announcements.vue'
import Footer from '~/components/Footer/Footer.vue'
import PdpBar from '~/components/PdpBar/PdpBar.vue'
import { useVirtualPageViewTriggerWatcher } from '~/src/Infrastructure/DataLayer/VirtualPageViewTriggerWatcher'
import { useWindowInnerHeightOnMounted } from '~/src/Infrastructure/Layout/WindowInnerHeightOnMounted'
import { useScrollbarWidthOnMounted } from '~/src/Infrastructure/Layout/ScrollbarWidthOnMounted'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'
import OnboardingModalControl from '~/components/Karpaty/OnboardingModalControl.vue'
import { useDatartOnboardingModal } from '~/src/Infrastructure/Karpaty/DatartOnboardingModal'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'

const LAYOUT_NAME = 'default'

export default defineComponent({
  components: { Footer, Announcements, Header, PdpBar, OnboardingModalControl },
  setup () {
    useVirtualPageViewTriggerWatcher(LAYOUT_NAME)
    useWindowInnerHeightOnMounted()
    useScrollbarWidthOnMounted()

    const checkedCustomer = ref<CheckCustomerResponse | null>(null)
    const { showDatartOnboardingModal, setDatartOnboardingCookie, openModal } = useDatartOnboardingModal()
    const openOnboardingModal = (checkedCustomerEvent: CheckCustomerResponse) => {
      checkedCustomer.value = checkedCustomerEvent
      openModal()
    }

    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    onMounted(async () => {
      await christmasDeliveryConfigurationStore.loadChristmasDeliveryConfigurations()
    })

    return {
      LAYOUT_NAME,
      showDatartOnboardingModal,
      setDatartOnboardingCookie,
      openOnboardingModal,
      checkedCustomer
    }
  }
})
