













import { defineComponent, computed } from '@nuxtjs/composition-api'

interface PropTypes {
  disabled: boolean
  name: string | null
  checked: boolean
  value: boolean | string | number | object
  checkedValue: boolean | string | number | object
}

export default defineComponent<PropTypes>({
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [Boolean, String, Number, Object],
      required: false,
      default: null
    },
    checkedValue: {
      type: [Boolean, String, Number, Object],
      required: false,
      default: true
    }
  },
  emits: ['input', 'change'],
  setup (props, { emit }) {
    const localValue = computed({
      get: () => {
        return props.value
      },
      set: (value) => {
        emit('input', value)
      }
    })

    return {
      localValue
    }
  }
})
