

























import { defineComponent, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { ref } from '@vue/composition-api'

interface PropTypes {
  srOnlyText: string
  icon: string
  boundary: string
  noninteractive: boolean
  tooltipPlacementBottom: boolean
  tooltipPlacementBottomMdLeft: boolean
}

export default defineComponent<PropTypes>({
  inheritAttrs: false,
  props: {
    srOnlyText: {
      type: String,
      default: 'show_tooltip'
    },
    icon: {
      type: String,
      default: 'question'
    },
    margin: {
      type: String,
      default: 'bs-ml-rem-05'
    },
    boundary: {
      type: String,
      default: 'window'
    },
    noninteractive: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: null
    },
    tabSkip: {
      type: Boolean,
      default: false
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    },
    tooltipPlacementBottomMdLeft: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const windowWidth = ref<number>(0)

    const placement = computed<string>(() => {
      if (props.tooltipPlacementBottomMdLeft && windowWidth.value < 576) {
        return 'bottom'
      } else if (props.tooltipPlacementBottomMdLeft && windowWidth.value >= 576) {
        return 'left'
      } else if (props.tooltipPlacementBottom || windowWidth.value < 768) {
        return 'bottom'
      }
      return 'right'
    })

    const adjustByWindowInnerWidth = () => {
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      adjustByWindowInnerWidth()
      window.addEventListener('resize', adjustByWindowInnerWidth)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', adjustByWindowInnerWidth)
    })

    return {
      placement
    }
  }
})
