import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { isAfter, isSameDay, parse } from 'date-fns'
import { IVueI18n } from 'vue-i18n'
import Formatter from '~/src/Infrastructure/Date/DateFormatter'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class MinDateRule extends ValidationRule {
  constructor (private $formatter: Formatter, protected i18n: IVueI18n) {
    super(i18n)
  }

  params = ['min']

  message = (_: string, { min }: Record<string, any>): string => {
    return this.i18n.t('validations.minDate', { date: this.$formatter.validationDate(min, 'yyyy-MM-dd') }).toString()
  }

  validate = (value: string, { min }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const insertedDate = parse(value, 'yyyy-MM-dd', new Date())
    const minDate = parse(min, 'yyyy-MM-dd', new Date())

    return {
      valid: isSameDay(minDate, insertedDate) || isAfter(insertedDate, minDate)
    }
  }
}
