






















































































import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'

export default defineComponent({
  components: { Container, CurrentWeb, Row, Column },
  setup () {
    const systemPagesStore = useSystemPagesStore()
    const awardsPath = systemPagesStore.pathByUid('AWARDS')
    const goodAngelPath = systemPagesStore.pathByUid('GOOD_ANGEL_PAGE')
    return {
      awardsPath,
      goodAngelPath
    }
  }
})
