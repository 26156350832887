


















































































import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { useProductDetailFetch } from '~/src/Infrastructure/ProductDetail/ProductDetailFetch'
import { useProductDetailGalleryFetch } from '~/src/Infrastructure/ProductDetail/ProductDetailGalleryFetch'
import ProductReviewsAwardsSummary from '~/components/ProductDetail/ProductReviewsAwardsSummary/ProductReviewsAwardsSummary.vue'
import ProductGallery from '~/components/ProductDetail/ProductGallery/ProductGallery.vue'
import TopParameters from '~/components/ProductBox/Components/Parameters/TopParameters.vue'
import ProductDetailLayout from '~/components/ProductDetail/ProductDetailLayout/ProductDetailLayout.vue'
import ProductSpecialStates from '~/components/ProductDetail/ProductSpecialStates/ProductSpecialStates.vue'
import { State } from '~/src/Model/PurchaseAvailability/PurchaseAvailability'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    LoadingAnim,
    ProductGallery,
    ProductSpecialStates,
    ProductDetailLayout,
    TopParameters,
    ProductReviewsAwardsSummary,
    Row,
    Column,
    Button,
    Modal,
    PageSectionContent
  },
  props: {
    productId: {
      required: true,
      type: Number
    },
    alias: {
      required: true,
      type: String
    },
    isGift: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup (props) {
    const route = useRoute()
    const { productDetail } = useProductDetailFetch(props.productId)
    const { items: galleryItems } = useProductDetailGalleryFetch(props.productId)

    const isCurrentProduct = computed<boolean>(() => {
      return route.value.name === props.alias
    })

    return {
      productDetail,
      purchaseAvailabilityState: State,
      galleryItems,
      isCurrentProduct
    }
  }
})
