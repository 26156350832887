












































































import { defineComponent, PropType, computed, ref } from '@nuxtjs/composition-api'
import ProductBoxHeading from '../ProductBox/Components/ProductBoxHeading/ProductBoxHeading.vue'
import ProductCompareControl from '~/components/ProductBox/Components/Comparison/ProductCompareControl.vue'
import { WishListItem } from '~/src/Model/WishLists/WishLists'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import ProductAvailabilityEshop from '~/components/ProductAvailability/ProductAvailabilityEshop.vue'
import ConfirmDialog from '~/components/DesignSystem/Modals/ConfirmDialog.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    ConfirmDialog,
    ProductCompareControl,
    ProductBoxHeading,
    ImageWithFallback,
    ProductAvailabilityEshop,
    Row,
    Column,
    Alert
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<WishListItem>
    }
  },
  emits: ['remove-item', 'close'],
  setup (props, { emit }) {
    const showDialog = ref<boolean>(false)

    const storeAvailabilityText = computed<string>(() => {
      const availability = props.item.favouriteStoreAvailability

      if (!availability) {
        return ''
      }

      if (availability.placeholder === null) {
        return availability.text
      }

      const storesInfoText = availability.text.split(availability.placeholder.name)
      storesInfoText[1] = availability.placeholder.content + ((storesInfoText[1] !== undefined) ? ` ${storesInfoText[1]}` : '')

      return storesInfoText.join(' ').trim()
    })

    const confirmClose = () => {
      showDialog.value = false
      emit('close')
    }

    return {
      storeAvailabilityText,
      showDialog,
      confirmClose
    }
  }
})
