




















































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { NavigationNode } from '~/src/Model/NavigationNode/NavigationNode'
import MainMenuVouchersBrands from '~/components/Header/MainMenu/VouchersBrands/MainMenuVouchersBrands.vue'

export default defineComponent({
  components: { MainMenuVouchersBrands, ImageWithFallback },
  props: {
    node: {
      type: Object as PropType<NavigationNode>,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    disabledBrands: {
      type: Array,
      default: () => []
    },
    showGiftVouchersLink: {
      type: Boolean,
      default: false
    }
  },
  emits: ['menu-closed', 'step-back', 'step-forward'],
  setup (props, { emit }) {
    const activeLink = ref<NavigationNode|null>(null)

    const menuClosed = () => {
      emit('menu-closed')
    }

    const stepBack = () => {
      activeLink.value = null
      emit('step-back')
    }

    watch(() => props.isVisible, () => {
      if (!props.isVisible) {
        activeLink.value = null
      }
    })

    const stepForward = (node: NavigationNode) => {
      activeLink.value = node
      emit('step-forward', node)
    }

    return {
      menuClosed,
      stepBack,
      stepForward,
      activeLink
    }
  }
})
