





import { defineComponent } from '@nuxtjs/composition-api'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    LoadingAnim,
    PageSectionContent
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: true
    },
    displayClass: {
      type: String,
      default: 'd-contents'
    },
    wrapClass: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  },
  setup () {}
})
