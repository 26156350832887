




















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  props: {
    gapLg: {
      type: Boolean,
      default: false
    },
    buttonsLeft: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const webConfig = useWebConfig()
    const isNay = computed<boolean>(() => webConfig.web === Web.Nay)

    const pathPrefix = computed<string>(() => isNay.value ? 'nay' : 'ew')
    const appstore = computed<string>(() => isNay.value ? 'https://itunes.apple.com/cz/app/nay-smart-app/id1445419364?l=sk' : 'https://apps.apple.com/cz/app/electro-world-smart-app/id1458615292?l=cs')
    const googleplay = computed<string>(() => isNay.value ? 'https://play.google.com/store/apps/details?id=sk.nay.naysmartapp&hl=sk_SK' : 'https://play.google.com/store/apps/details?id=cz.ew.ewsmartapp')
    const appgallery = computed<string>(() => isNay.value ? 'https://appgallery.cloud.huawei.com/ag/n/app/C102570717?channelId=NAY&id=a1e691b980a348959[%E2%80%A6]EBF6876C469E50B8AE5EEE3975BD802968682C5&detailType=0&v=' : 'https://appgallery.cloud.huawei.com/ag/n/app/C102570651?channelId=Electro+World&id=9b2ccdf[…]578F4C07184CB86786017A1EC2CC30E518203DD&detailType=0&v=')

    return {
      isNay,
      pathPrefix,
      appstore,
      googleplay,
      appgallery
    }
  }
})
