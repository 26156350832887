










import { defineComponent } from '@nuxtjs/composition-api'
import SystemLink from '~/components/SystemPage/SystemLink.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    SystemLink,
    Alert
  },
  props: {
    uid: {
      type: String,
      required: true
    },
    uidMesssage: {
      type: String,
      required: true
    },
    messageStart: {
      type: String,
      required: true
    },
    messageEnd: {
      type: String,
      default: ''
    }
  }
})
