import { Ref } from '@nuxtjs/composition-api'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'
import { ValidationObserverType } from '~/src/Model/Validation/Validation'

export const useValidationObserver = (validationObserverRef: Ref<ValidationObserverType>) => {
  const { scrollToHtmlElement } = useScrollTo()

  const scrollToFirstError = (): void => {
    const element: HTMLInputElement | HTMLTextAreaElement | null = validationObserverRef.value?.$el.querySelector('textarea.is-invalid, input.is-invalid')

    if (element) {
      element.focus()
      scrollToHtmlElement(element)
    }
  }

  const handleSubmitWithScroll = async (onSuccessCallback: Function) => {
    const { isValid } = await validationObserverRef.value?.validateWithInfo()
    if (isValid) {
      onSuccessCallback()
    } else {
      scrollToFirstError()
    }
  }

  const reset = () => {
    validationObserverRef.value?.reset()
  }

  const validate = () => {
    return validationObserverRef.value?.validate()
  }

  return {
    handleSubmitWithScroll,
    reset,
    validate
  }
}
