import { Ref, useMeta } from '@nuxtjs/composition-api'
import { Article } from '~/src/Model/Article/Article'
import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import { StorePage } from '~/src/Model/Store/StorePage'
import { BreadCrumbRichSnippet } from '~/src/Model/BreadCrumb/BreadCrumb'

export const useRichSnippet = (richSnippetWrapper: Ref<Article | ProductDetail | StorePage | BreadCrumbRichSnippet | null>) => {
  useMeta(() => {
    if (!richSnippetWrapper.value) {
      return {}
    }

    return ({ script: [{ type: 'application/ld+json', json: richSnippetWrapper.value?.richSnippet as any }] })
  })
}
