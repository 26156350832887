import { computed, ComputedRef, WritableComputedRef } from '@nuxtjs/composition-api'
import { ISimpleEvent, SimpleEventDispatcher } from 'ste-simple-events'
import { AuthenticatedUser, AuthenticatedUserEvent } from '~/src/Model/Auth/AuthenticatedUser'
import { CustomerLoginData } from '~/src/Model/Customer/CustomerLoginData'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { TwoFARequired } from '~/src/Model/Auth/TwoFA'
import { Auth } from '~/plugins/auth'
import { LogoutOptions } from '~/src/Model/Auth/AuthService'

export class AuthService {
  private _onLoggedIn = new SimpleEventDispatcher<AuthenticatedUserEvent>()
  private _onLoggedOut = new SimpleEventDispatcher<LogoutOptions | undefined>()
  private _onTwoFALoggedIn = new SimpleEventDispatcher<TwoFARequired>()

  constructor (
    private readonly $auth: Auth
  ) {
  }

  public async reevaluateUser (): Promise<void> {
    const shouldReevaluate = await this.$auth.reevaluateLoginState()
    if (!shouldReevaluate) {
      return
    }

    if (this.authenticatedUser.value) {
      this._onLoggedIn.dispatch({ user: this.authenticatedUser.value })
      return
    }

    this._onLoggedOut.dispatch({})
  }

  public async login (
    customerLoginData: CustomerLoginData,
    disableRefresh?: boolean
  ): Promise<AuthTokens | TwoFARequired> {
    const response = await this.$auth.login(customerLoginData)

    if ('twoFA' in response) {
      this._onTwoFALoggedIn.dispatch(response)
    } else {
      const user = this.authenticatedUser.value

      if (user) {
        this._onLoggedIn.dispatch({ user, disableRefresh })
      } else {
        window?.location.reload()
      }
    }

    return response
  }

  public async logout (logoutOptions?: LogoutOptions): Promise<void> {
    await this.$auth.logout()
    this._onLoggedOut.dispatch(logoutOptions)
  }

  public async setUserToken (
    token: string,
    refreshToken: string,
    disableRefresh?: boolean
  ): Promise<AuthenticatedUser | null> {
    const response = await this.$auth.setUserToken(token, refreshToken)
    const user = this.authenticatedUser.value

    if (user) {
      this._onLoggedIn.dispatch({ user, disableRefresh })
    }

    return response
  }

  public async fetchUser (): Promise<AuthenticatedUser | void> {
    return await this.$auth.fetchUser()
  }

  public reloadWithTokensRefresh (): Promise<unknown> {
    return Promise.all([
      this.$auth.refreshTokens(),
      this.fetchUser()
    ])
  }

  public get onLoggedIn (): ISimpleEvent<AuthenticatedUserEvent> {
    return this._onLoggedIn.asEvent()
  }

  public get onLoggedOut (): ISimpleEvent<LogoutOptions | undefined> {
    return this._onLoggedOut.asEvent()
  }

  public get onTwoFALoggedIn (): ISimpleEvent<TwoFARequired> {
    return this._onTwoFALoggedIn.asEvent()
  }

  public get authenticatedUser (): WritableComputedRef<AuthenticatedUser | null> {
    return computed<AuthenticatedUser | null>({
      get: () => this.$auth.user.value,
      set: (user) => {
        this.$auth.user.value = user
      }
    })
  }

  public get isLoggedIn (): ComputedRef<boolean> {
    return computed(() => this.$auth.loggedIn.value)
  }
}
