






















import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { defaultForceExpandedState, ForceExpandedState } from '~/src/Model/CheckCompanyCustomer/CheckCompanyCustomer'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    isCollapsable: {
      type: Boolean,
      default: true
    },
    iconClass: {
      type: String,
      default: null
    },
    iconClassOpen: {
      type: String,
      required: false,
      default: 'icon-arrow-down'
    },
    iconClassClose: {
      type: String,
      required: false,
      default: 'icon-arrow-up'
    },
    controlClass: {
      type: [String, Array],
      required: false,
      default: null
    },
    collapseClass: {
      type: String,
      required: false,
      default: null
    },
    isAccordeon: {
      type: Boolean,
      default: false
    },
    accordionName: {
      type: String,
      default: null
    },
    forceExpandedState: {
      type: Object as PropType<ForceExpandedState>,
      default: () => defaultForceExpandedState
    }
  },
  emits: ['clicked'],
  setup (props, { emit }) {
    const showContent = ref<boolean>(props.defaultOpen)
    const { scrollToHtmlElement } = useScrollTo()
    const collapsableComponentRef = useRequiredTemplateRef<Element>()

    watch(() => props.forceExpandedState, (newExpandedState) => {
      if (!newExpandedState.force) {
        return
      }
      showContent.value = newExpandedState.expand
      setTimeout(() => scrollToHtmlElement(collapsableComponentRef.value), 0)
    }, { deep: true })

    const toggleContent = () => {
      showContent.value = !showContent.value
      emit('clicked')
    }

    watch(() => props.defaultOpen, () => {
      showContent.value = props.defaultOpen
    })

    return {
      collapsableComponentRef,
      showContent,
      toggleContent
    }
  }
})
