



















import { defineComponent, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import WatchDogForm from '~/components/Watchdog/WatchDogForm.vue'

export default defineComponent({
  components: { WatchDogForm, Sidebar },
  emits: ['show-auth', 'hidden', 'confirm-watchdog'],
  setup (_props, { emit }) {
    const showAuth = (callback: Function) => {
      emit('show-auth')
      callback()
    }

    const confirmWatchdog = (callback: Function) => {
      emit('confirm-watchdog')
      callback()
    }

    onMounted(() => {
      document.body?.classList.add('sidebar-open')
    })

    onUnmounted(() => {
      document.body?.classList.remove('sidebar-open')
    })

    return {
      showAuth,
      confirmWatchdog
    }
  }
})
