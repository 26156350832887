


































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { ExpressPickupStore, ExpressPickupStores } from '~/src/Model/ExpressPickup/ExpressPickup'
import ExpressPickupStorePickerDropdown from '~/components/ExpressPickup/ExpressPickupStorePickerDropdown.vue'

export default defineComponent({
  components: { ExpressPickupStorePickerDropdown },
  props: {
    expressPickupStores: {
      default: null,
      type: Object as PropType<ExpressPickupStores | null>
    },
    showDescriptionSection: {
      default: false,
      type: Boolean
    },
    dropup: {
      default: true,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  emits: ['store-selected'],
  setup (props) {
    const selectedStore = ref<ExpressPickupStore | null>(props.expressPickupStores?.favouriteCustomerStore ?? null)

    return {
      selectedStore
    }
  }
})
