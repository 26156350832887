






































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ProductBoxPriceBundle } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'
import Tag from '~/components/ProductDetail/Tag/Tag.vue'
import { ProductTagStaticType } from '~/src/Model/ProductTag/ProductTag'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export default defineComponent({
  components: { Tag, TextSkeleton },
  props: {
    priceBundle: {
      required: true,
      type: Object as PropType<ProductBoxPriceBundle>
    },
    isLoyaltyMember: {
      required: false,
      type: Boolean,
      default: false
    },
    forceClass: {
      type: [String, Array],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const { canSeeLoyaltyPrices } = useAuthenticatedUser()

    return {
      ProductTagStaticType,
      canSeeLoyaltyPrices
    }
  }
})
