























import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: { Button },
  props: {
    textContent: {
      type: String,
      default: ''
    },
    useDatartRedirector: {
      type: Boolean,
      default: false
    },
    useImageLink: {
      type: Boolean,
      default: false
    },
    isProductDetail: {
      type: Boolean,
      default: false
    },
    isPreBasket: {
      type: Boolean,
      default: false
    },
    isBasket: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const route = useRoute()

    const url = computed<string>(() => props.useDatartRedirector ? `https://redir.datart.cz${route.value.path}` : 'https://www.datart.cz')

    const img = computed<string|null>(() => {
      if (props.isProductDetail) {
        return '/ew/1180_detail_produktu_var1.jpg'
      }
      if (props.isPreBasket) {
        return '/ew/1300_banner_kosik.jpg'
      }
      if (props.isBasket) {
        return '/ew/1800_banner_kosik.jpg'
      }

      return null
    })

    const onButtonClick = () => {
      window.location.href = url.value
    }

    return {
      url,
      img,
      onButtonClick
    }
  }
})
