




















import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  useRouter
} from '@nuxtjs/composition-api'
import Pagination from '~/components/DesignSystem/Pagination/Pagination.vue'
import { usePaginationMetaTags } from '~/src/Infrastructure/Meta/usePaginationMetaTags'
import { useCurrentPageNumber } from '~/src/Infrastructure/Router/CurrentPageNumber'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'
import { useDataLayerStore } from '~/src/Infrastructure/DataLayer/DataLayerStore'

export default defineComponent({
  components: {
    Pagination,
    Button,
    PageSectionContent
  },
  props: {
    totalCount: {
      required: true,
      type: Number
    },
    perPage: {
      required: true,
      type: Number
    },
    queryName: {
      type: String,
      default: 'page'
    },
    showLoadMoreButton: {
      required: false,
      type: Boolean,
      default: true
    },
    paginationClass: {
      required: false,
      default: '',
      type: String
    },
    invertedOrder: {
      type: Boolean,
      default: false
    }
  },
  emits: ['load-more', 'page-changed'],
  setup (props, { emit }) {
    const router = useRouter()
    const currentPageNumber = useCurrentPageNumber()
    const dataLayerStore = useDataLayerStore()

    usePaginationMetaTags(currentPageNumber, Math.ceil(props.totalCount / props.perPage), props.queryName)

    const canLoadMore = computed<boolean>(() => {
      if (!props.showLoadMoreButton) {
        return false
      }

      return currentPageNumber.value * props.perPage < props.totalCount
    })

    const historyChangeHandler = () => {
      pageChanged(currentPageNumber.value)
    }

    onMounted(() => {
      window.addEventListener('popstate', historyChangeHandler)
    })

    onUnmounted(() => {
      window.removeEventListener('popstate', historyChangeHandler)
    })

    const pageChanged = (page: number) => {
      dataLayerStore.fireVirtualPageViewEvent()
      emit('page-changed', page)
    }

    const loadMore = () => {
      const updatedQuery = { ...router.currentRoute.query }
      updatedQuery[props.queryName] = (currentPageNumber.value + 1).toString()
      router.push({ path: router.currentRoute.path, query: updatedQuery })
      dataLayerStore.enableVirtualPageViewEvent()
      dataLayerStore.fireVirtualPageViewEvent()
      emit('load-more')
    }

    const showPagination = computed<boolean>(() => {
      return props.perPage < props.totalCount
    })

    return {
      canLoadMore,
      loadMore,
      pageChanged,
      showPagination,
      currentPageNumber
    }
  },
  head: {}
})
