import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { stripTags as stripTagsFunction } from '~/src/Infrastructure/String/StripTags'

type stripTagsFilterType = (value: string) => string

// eslint-disable-next-line no-empty-pattern
const stripTags: Plugin = ({ }, inject: Inject) => {
  const stripTagsFilter: stripTagsFilterType = (value: string) => stripTagsFunction(value)

  inject('stripTags', stripTagsFilter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $stripTags: stripTagsFilterType
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $stripTags: stripTagsFilterType
  }

  interface Context {
    $stripTags: stripTagsFilterType
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $stripTags: stripTagsFilterType
  }
}

export default stripTags
