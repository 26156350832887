








































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import { State } from '~/src/Model/PurchaseAvailability/PurchaseAvailability'
import PreorderInfo from '~/components/ProductDetail/PreorderInfo/PreorderInfo.vue'
import SaleEndedInfo from '~/components/ProductDetail/SaleEndedInfo/SaleEndedInfo.vue'
import ProductSpecialState from '~/components/ProductDetail/ProductSpecialStates/ProductSpecialState.vue'
import UnavailableState from '~/components/ProductDetail/ProductSpecialStates/UnavailableState.vue'
import ProductBlackFridayState from '~/components/ProductDetail/ProductSpecialStates/ProductBlackFridayState.vue'
import { ProductPriceBundle, SaleType } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'

export default defineComponent({
  components: { ProductBlackFridayState, SaleEndedInfo, PreorderInfo, UnavailableState, ProductSpecialState },
  props: {
    productDetail: {
      type: Object as PropType<ProductDetail>,
      required: true
    },
    priceBundle: {
      type: Object as PropType<ProductPriceBundle | null>,
      default: null
    },
    variant: {
      type: String,
      default: 'info'
    },
    hasAlternativeProducts: {
      type: Boolean,
      default: false
    },
    isInBundle: {
      type: Boolean,
      default: false
    },
    purchaseAvailabilityNotAllowed: {
      type: Boolean,
      default: false
    },
    showCta: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const showBlackFridayState = computed<boolean>(() => {
      const saleType = props.priceBundle?.saleInfo?.article.saleType
      if (!saleType) {
        return false
      }

      return [SaleType.blackFriday, SaleType.cyberMonday].includes(saleType) ?? false
    })

    return {
      purchaseAvailabilityState: State,
      showBlackFridayState
    }
  }
})
