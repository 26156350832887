import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export class PageUrlChangedEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (): void {
    this.dataLayer.push({
      bread_crumbs: null,
      ecommerce: null
    })
  }
}
