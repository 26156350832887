import { NuxtCookies } from 'cookie-universal-nuxt'

const BLACK_FRIDAY_COOKIE_ID = 'blackFriday'

export class BlackFridayCookieStorage {
  constructor (
    private cookies: NuxtCookies
  ) {}

  public get blackFridayCookieAllowed (): boolean {
    return this.cookies.get(BLACK_FRIDAY_COOKIE_ID)?.toString() === 'on' ?? false
  }

  public setBlackFridayCookie (): void {
    if (this.cookies.get(BLACK_FRIDAY_COOKIE_ID)) {
      return
    }
    this.cookies.set(BLACK_FRIDAY_COOKIE_ID, 'off')
  }
}
