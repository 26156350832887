






























import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import ServicesCrossroad from '~/components/ServicesCrossroad/ServicesCrossroad'
import StoresMap from '~/components/StoresMap/StoresMap'
import FooterSocials from '~/components/Footer/FooterSocials.vue'
import FooterApps from '~/components/Footer/FooterApps.vue'
import FooterNewsletter from '~/components/Footer/FooterNewsletter.vue'
import FooterAwards from '~/components/Footer/FooterAwards.vue'
import FooterLinks from '~/components/Footer/FooterLinks.vue'
import FooterContacts from '~/components/Footer/FooterContacts.vue'

export default defineComponent({
  components: {
    Container,
    ServicesCrossroad,
    StoresMap,
    FooterSocials,
    FooterApps,
    FooterNewsletter,
    FooterAwards,
    FooterLinks,
    FooterContacts
  },
  setup () {
    const route = useRoute()
    const isHomepage = computed(() => route.value.path === '/')

    return {
      isHomepage
    }
  }
})
