




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { Product } from '~/src/Model/Product/Product'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>
    },
    tag: {
      type: String,
      default: 'li'
    }
  }
})
