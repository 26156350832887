










import { computed, defineComponent } from '@nuxtjs/composition-api'
import PdpBarCopyIcon from '~/components/PdpBar/PdpBarCopyIcon.vue'
import { useConfig } from '~/src/Infrastructure/Nuxt/Config/UseConfig'

export default defineComponent({
  components: { PdpBarCopyIcon },
  setup () {
    const { $config } = useConfig()

    const appVersion = computed<string | undefined>(() => $config.appVersion)

    return {
      appVersion
    }
  }
})
