import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { BasketDataLayerData } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/BasketDataLayerData'
import { DataLayerBasket } from '~/src/Model/Datalayer/DataLayerBasket'
import { ProductDataLayerData } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Product/ProductDataLayerData'
import { createDataLayerBasketProduct } from '~/src/Infrastructure/DataLayer/DataLayerBasketProductFactory'

export class BasketDataLayerDataRepository {
  constructor (private readonly axios: NuxtAxiosInstance) {
  }

  async get (basketHash: string): Promise<BasketDataLayerData> {
    const response = await this.axios.$get<DataLayerBasket>(`/datalayer/baskets/${basketHash}`)
    const items: ProductDataLayerData[] = response.products.map(p => createDataLayerBasketProduct(p))

    return {
      items,
      deliveryName: response.deliveryMethod?.name ?? null,
      paymentName: response.payMethod?.name ?? null
    }
  }
}
