import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { AuthService } from '~/src/Infrastructure/Auth/AuthService'

export class UserLoggedStateChangedEvent {
  constructor (
    private readonly dataLayer: DataLayer,
    private readonly authService: AuthService
  ) {
  }

  public fire (id: number | null = null): void {
    this.dataLayer.push(
      {
        visitorLoginState: this.authService.authenticatedUser.value ? 'authenticated' : 'guest',
        visitorID: id,
        visitorDRS: this.authService.authenticatedUser.value?.drsId ?? null
      }
    )
  }
}
