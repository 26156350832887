



























import { computed, defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import PreBasketProductsDropdown from '~/components/Basket/PreBasket/PreBasketProductsDropdown.vue'

export default defineComponent({
  components: { PreBasketProductsDropdown, ImageWithFallback },
  setup () {
    const basketStore = useBasketStore()

    const maxThumbnails = computed<number>(() => basketStore.basketProductsCount.value > 3 ? 2 : 3)

    return {
      maxThumbnails,
      totalPrice: basketStore.totalPrice,
      basketProducts: basketStore.productsWithGifts
    }
  }
})
