export enum TwoFAType {
  sms = 'sms',
  email = 'email'
}

export interface TwoFA {
  type: TwoFAType
  anonymizedContact: string
  token: string
}

export interface TwoFARequired {
  twoFA: TwoFA
}

export interface TwoFAChallenge {
  code: string
  token: string
}

export interface TwoFAChallengeRequest {
  twoFA: TwoFAChallenge
}
