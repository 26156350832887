import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

// sentry has Extra as unknown type
interface Extra {
  body?: {
    sourceFile: string
  }
}

export class EventSourceFile extends IgnoredEvent {
  constructor (private value: string) {
    super()
  }

  isIgnored (event: Event) {
    return !!(event?.extra as Extra)?.body?.sourceFile?.startsWith(this.value)
  }
}
