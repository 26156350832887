


































import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 62
    },
    ringClass: {
      type: String,
      default: null
    },
    strokeWidth: {
      type: Number,
      default: 5
    },
    strokeWidthBg: {
      type: Number,
      default: null
    },
    dynamicStrokeColor: {
      type: Boolean,
      default: true
    },
    counterClockwise: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const stroke = computed<string>(() => {
      if (props.percentage > 50) {
        return '#2bae1a'
      } else {
        return '#ff8a00'
      }
    })

    const radius = computed<number>(() => {
      return (props.size - props.strokeWidth) / 2
    })

    const circumference = computed<number>(() => {
      return radius.value * 2 * Math.PI
    })

    const strokeDashoffset = computed<number>(() => {
      return (props.counterClockwise ? -1 : 1) * (circumference.value - props.percentage / 100 * circumference.value)
    })

    return {
      stroke,
      radius,
      circumference,
      strokeDashoffset
    }
  }
})
