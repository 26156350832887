









































































import { computed, defineComponent, onMounted, onUnmounted, ref, useRoute, watch } from '@nuxtjs/composition-api'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import InfoBoxSidebar from '~/components/Header/InfoBox/InfoBoxSidebar.vue'
import BasketBoxSidebar from '~/components/Header/BasketBox/BasketBoxSidebar.vue'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import Logo from '~/components/Header/Logo/Logo.vue'
import HeaderLayout from '~/components/Header/HeaderLayout.vue'
import LogoHomepage from '~/components/Header/Logo/LogoHomepage.vue'
import LogoAppendix from '~/components/Header/Logo/LogoAppendix.vue'
import MainMenu from '~/components/Header/MainMenu/MainMenu.vue'
import SearchBox from '~/components/Header/SearchBox/SearchBox.vue'
import BasketBox from '~/components/Header/BasketBox/BasketBox.vue'
import WishlistBox from '~/components/Header/WishlistBox/WishlistBox.vue'
import CompareBox from '~/components/Header/CompareBox/CompareBox.vue'
import InfoBox from '~/components/Header/InfoBox/InfoBox.vue'
import UserBox from '~/components/Header/UserBox/UserBox.vue'
import UserBoxSidebar from '~/components/Header/UserBox/UserBoxSidebar.vue'
import WishlistBoxSidebar from '~/components/Header/WishlistBox/WishlistBoxSidebar.vue'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

enum ScrollDirection {
  UP,
  DOWN
}

export default defineComponent({
  components: { HeaderLayout, LogoAppendix, InfoBoxSidebar, WishlistBoxSidebar, BasketBoxSidebar, UserBoxSidebar, Logo, LogoHomepage, MainMenu, SearchBox, BasketBox, WishlistBox, CompareBox, InfoBox, UserBox },
  props: {},
  emits: ['show-onboarding-modal'],
  setup () {
    const authService = useAuthService()
    const activePanel = ref<ActiveHeaderPanel>(ActiveHeaderPanel.none)
    const hideHeader = ref<boolean>(false)
    const reduceHeader = ref<boolean>(false)
    const documentScroll = ref<boolean>(false)
    const menuToggleButton = ref<boolean>(false)
    const stickyFilter = ref<boolean>(false)
    const prevScrollTop = ref<number>(0)
    const scrollTop = ref<number>(0)
    const scrollCounter = ref<number>(0)
    const scrollDirection = ref<ScrollDirection>(ScrollDirection.DOWN)
    const route = useRoute()
    const isHomepage = computed(() => route.value?.path === '/' ?? false)
    const showSuggestions = ref<boolean>(false)
    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    const headerPanelsToShowOverlay: ActiveHeaderPanel[] = [
      ActiveHeaderPanel.mainMenu,
      ActiveHeaderPanel.searchBox
    ]

    const bodyClasses = computed<{ [className: string]: boolean }>(() => {
      return {
        'offcanvas--opened': activePanel.value === ActiveHeaderPanel.mainMenu,
        'header-overlay': headerPanelsToShowOverlay.includes(activePanel.value),
        'sidebar-open': activePanel.value !== ActiveHeaderPanel.none,
        'offcanvas--slide-down': menuToggleButton.value,
        'header--reduced': reduceHeader.value,
        'body-scrolled': documentScroll.value,
        'header--hidden': hideHeader.value,
        'header--filter': stickyFilter.value
      }
    })

    watch(bodyClasses, () => {
      const body = document.body

      Object.entries(bodyClasses.value).forEach((item) => {
        const [className, active] = item

        if (active) {
          body.classList.add(className)
        } else {
          body.classList.remove(className)
        }
      })
    })

    onMounted(() => {
      window.addEventListener('scroll', processScrollingEvents)

      processScrollingEvents()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', processScrollingEvents)

      Object.keys(bodyClasses.value).forEach((bodyClass) => {
        document.body.classList.remove(bodyClass)
      })
    })

    const processScrollingEvents = () => {
      const categoryStickyControls = document.getElementById('category-sticky-controls')

      prevScrollTop.value = scrollTop.value
      scrollTop.value = window.scrollY
      if (prevScrollTop.value < scrollTop.value) {
        if (scrollDirection.value === ScrollDirection.UP) {
          scrollCounter.value = window.scrollY
        }
        scrollDirection.value = ScrollDirection.DOWN
      } else {
        if (scrollDirection.value === ScrollDirection.DOWN) {
          scrollCounter.value = window.scrollY
        }
        scrollDirection.value = ScrollDirection.UP
      }
      if (scrollDirection.value === ScrollDirection.DOWN && (Math.abs(window.scrollY - scrollCounter.value) > 200)) {
        hideHeader.value = true
        reduceHeader.value = true
      }
      if (scrollDirection.value === ScrollDirection.UP && (Math.abs(window.scrollY - scrollCounter.value) > 100)) {
        hideHeader.value = false
      }
      if (categoryStickyControls && !(categoryStickyControls.getBoundingClientRect().top > 0)) {
        stickyFilter.value = true
      } else {
        stickyFilter.value = false
      }
      if (scrollTop.value === 0) {
        hideHeader.value = false
        reduceHeader.value = false
        documentScroll.value = false
        menuToggleButton.value = false
        activePanel.value = ActiveHeaderPanel.none
      } else {
        documentScroll.value = true
      }
    }

    const panelToggled = (value) => {
      if (activePanel.value === value) {
        activePanel.value = ActiveHeaderPanel.none
        menuToggleButton.value = false
      } else {
        menuToggleButton.value = value === ActiveHeaderPanel.mainMenu
        activePanel.value = value
      }
      showSuggestions.value = false
    }

    const toggleMenu = () => {
      panelToggled(ActiveHeaderPanel.mainMenu)
    }

    const openMenu = () => {
      if (activePanel.value === ActiveHeaderPanel.mainMenu) {
        return
      }
      panelToggled(ActiveHeaderPanel.mainMenu)
    }

    const closeAll = () => {
      panelToggled(ActiveHeaderPanel.none)
    }

    const mainMenuOnOrderHelperClicked = () => {
      closeAll()
      // redirect
    }

    const menuActiveLevelChanged = () => {
      menuToggleButton.value = false
    }

    const closeSearchBox = () => {
      activePanel.value = ActiveHeaderPanel.none
    }

    authService.onLoggedOut.subscribe(closeAll)

    onUnmounted(() => {
      authService.onLoggedOut.unsubscribe(closeAll)
    })

    return {
      isActiveChristmas: christmasDeliveryConfigurationStore.isActiveChristmas,
      activePanel,
      panelToggled,
      toggleMenu,
      closeAll,
      mainMenuOnOrderHelperClicked,
      hideHeader,
      reduceHeader,
      documentScroll,
      openMenu,
      menuToggleButton,
      menuActiveLevelChanged,
      isHomepage,
      closeSearchBox,
      ActiveHeaderPanel
    }
  },
  head: {}
})
