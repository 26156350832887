import { computed } from '@nuxtjs/composition-api'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import { PriceLevel } from '~/src/Model/Price/PriceLevel'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { DatartUser } from '~/src/Model/Auth/AuthenticatedUser'

export const useAuthenticatedUser = () => {
  const authService = useAuthService()
  const { loyaltyClubAllowed } = useWebConfig()

  const authenticatedUser = authService.authenticatedUser

  const authenticatedUserId = computed<number|null>(() => authenticatedUser.value?.id || null)

  const isPdpBarAvailable = computed<boolean>(() => authenticatedUser.value?.showPdpBar ?? false)

  const isUserAuthenticated = computed<boolean>(() => authenticatedUser.value !== null)

  const isB2B = computed<boolean>(() => authenticatedUser.value?.priceLevel === PriceLevel.B2B ?? false)
  const isLoyaltyMember = computed<boolean>(() => authenticatedUser.value?.isLoyaltyMember ?? false)
  const isLoyaltyBlueCardMember = computed<boolean>(() => authenticatedUser.value?.priceLevel === PriceLevel.LOYALTY_BLUE_CARD ?? false)
  const isStudentMember = computed<boolean>(() => authenticatedUser.value?.isic?.isActive ?? false)

  const isZAM = computed<boolean>(() => authenticatedUser.value?.priceLevel === PriceLevel.ZAM ?? false)
  const isVIP = computed<boolean>(() => authenticatedUser.value?.priceLevel === PriceLevel.VIP ?? false)
  const isZAMorVIP = computed<boolean>(() => isZAM.value || isVIP.value)
  const isNormal = computed<boolean>(() => authenticatedUser.value?.priceLevel === PriceLevel.NORMAL ?? false)
  const isSpecialPriceLevel = computed<boolean>(() => isZAMorVIP.value || isB2B.value)
  const isTwoFactorEnabled = computed<boolean>(() => authenticatedUser.value?.twoFA ?? false)

  const datartUser = computed<DatartUser | null>(() => authenticatedUser.value?.datartUser ?? null)

  const priceLevel = computed<PriceLevel>(() => {
    return authenticatedUser.value?.priceLevel as PriceLevel ?? PriceLevel.NORMAL
  })

  const canSeeLoyaltyPrices = computed<boolean>(() => isLoyaltyMember.value || loyaltyClubAllowed)

  return {
    authenticatedUser,
    authenticatedUserId,
    isPdpBarAvailable,
    isLoyaltyMember,
    isB2B,
    isZAM,
    isVIP,
    isZAMorVIP,
    isUserAuthenticated,
    isNormal,
    priceLevel,
    isSpecialPriceLevel,
    isTwoFactorEnabled,
    isStudentMember,
    canSeeLoyaltyPrices,
    datartUser,
    isLoyaltyBlueCardMember
  }
}
