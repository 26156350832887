import { Banner } from '~/src/Model/Banner/Banner'
import { PageThumbnail } from '~/src/Model/Page/PageThumbnail'
import { MetaOptions } from '~/src/Model/Meta/MetaOptions'

export const HOMEPAGE_ID = 1

export interface HomePageLoyaltyProducts {
  totalCount: number
}

export interface HomePage extends MetaOptions {

  topBanners: Banner[]
  promotedPages: PageThumbnail[]
  interestingPages: PageThumbnail[]
  smallBanners: Banner[]
  loyaltyProducts: HomePageLoyaltyProducts | null
}
