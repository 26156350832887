import { PhoneCodeFlags } from '~/src/Model/PhoneInput/PhoneCodes'
import { PhoneNumber } from '~/src/Model/PhoneInput/PhoneNumber'

export const usePhoneNumberHelpers = () => {
  const splitPhoneNumber = (phone: string) => {
    if (phone.startsWith('00')) {
      phone = '+' + phone.substr(2)
    }

    const phoneCode = Object.keys(PhoneCodeFlags).find(code => phone.substr(0, code.length) === code) ?? ''

    return {
      phone: phoneCode ? phone.substr(phoneCode.length) : phone,
      countryCallingCode: phoneCode
    } as PhoneNumber
  }

  const parsePhoneNumber = (countryCallingCode: string|null, phone: string|null): string => {
    return countryCallingCode && phone ? countryCallingCode + phone : ''
  }

  const stringifyPhoneNumber = (phoneNumber: PhoneNumber): string => {
    return phoneNumber.countryCallingCode + phoneNumber.phone
  }

  return {
    splitPhoneNumber,
    parsePhoneNumber,
    stringifyPhoneNumber
  }
}
