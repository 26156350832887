import { Product } from '~/src/Model/Product/Product'

export enum GiftChoiceType {
  withoutChoice = 'withoutChoice',
  choice = 'choice'
}

export interface Gift {
  product: Product
  worthValue: string
}

export interface GiftChoiceConfig {
  type: GiftChoiceType
  count: number
}

export interface ProductGiftOffer {
  id: number
  items: Gift[]
  choiceConfig: GiftChoiceConfig
}

export interface ProductGiftsCollection {
  gifts: ProductGiftOffer[]
}

export interface SelectedGiftItem {
  giftOfferId: number
  gift: Gift
}

export interface BasketSelectedGift {
  promotionId: number
  products: number[]
}

export interface BasketGift {
  id: number
  choiceConfig: GiftChoiceConfig
  product: Product
  promotionId: number
  worthValue: string
}

export interface NewSelectedBasketGift {
  choiceConfig: GiftChoiceConfig
  product: Product
  promotionId: number
  worthValue: string
}

export interface ChangeBasketProductGiftRequest {
  basketProductId: number
  choice: BasketSelectedGift[]
}

export interface PreselectedBasketGift {
  basketGiftId: number
  promotionId: number
  gift: Gift
  choiceType: GiftChoiceType
}

export interface DeleteBasketProductGiftRequest {
  basketProductId: number
  giftId: number
}
