export enum PhoneCodeFlags {
  '+421' = 'slovakia',
  '+420' = 'czech-republic',
  '+355' = 'albania',
  '+376' = 'andorra',
  '+32' = 'belgium',
  '+375' = 'belarus',
  '+387' = 'bosnia-and-herzegovina',
  '+359' = 'bulgaria',
  '+382' = 'montenegro',
  '+45' = 'denmark',
  '+372' = 'estonia',
  '+298' = 'faroe-islands',
  '+358' = 'finland',
  '+33' = 'france',
  '+350' = 'gibraltar',
  '+299' = 'greenland',
  '+385' = 'croatia',
  '+353' = 'ireland',
  '+354' = 'iceland',
  '+39' = 'italy',
  '+357' = 'cyprus',
  '+423' = 'liechtenstein',
  '+370' = 'lithuania',
  '+371' = 'latvia',
  '+352' = 'luxembourg',
  '+36' = 'hungary',
  '+389' = 'north-macedonia',
  '+356' = 'malta',
  '+373' = 'moldova',
  '+377' = 'monaco',
  '+49' = 'germany',
  '+31' = 'netherlands',
  '+47' = 'norway',
  '+48' = 'poland',
  '+351' = 'portugal',
  '+43' = 'austria',
  '+40' = 'romania',
  '+7' = 'kazakhstan',
  '+30' = 'greece',
  '+378' = 'san-marino',
  '+386' = 'slovenia',
  '+44' = 'united-kingdom',
  '+381' = 'serbia',
  '+34' = 'spain',
  '+46' = 'sweden',
  '+41' = 'switzerland',
  '+90' = 'turkey',
  '+380' = 'ukraine',
  // '+39' = 'vatican-city'
}
