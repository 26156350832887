














































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { BasketProductBundle } from '~/src/Model/Basket/BasketProductBundle'
import BasketBoxProductBundleItem from '~/components/Header/BasketBox/BasketBoxProductBundleItem.vue'
import InputNumber from '~/components/DesignSystem/Input/InputNumber.vue'

export default defineComponent({
  components: {
    BasketBoxProductBundleItem,
    InputNumber
  },
  props: {
    basketProductBundle: {
      required: true,
      type: Object as PropType<BasketProductBundle>
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete-bundle', 'change-quantity'],
  setup (props, { emit }) {
    const productBundleProductsTypes = computed<string>(() => {
      if (props.basketProductBundle === null) {
        return ''
      }

      if (props.basketProductBundle.products.find(p => p.typeName === null)) {
        return ''
      }

      return props.basketProductBundle.products.map(p => p.typeName).join(' + ')
    })

    const deleteBundle = () => {
      if (!props.disabled) {
        emit('delete-bundle')
      }
    }

    return {
      productBundleProductsTypes,
      deleteBundle
    }
  }
})
