
































import { defineComponent } from '@nuxtjs/composition-api'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import ChristmasDeliveryCalendar from '~/components/ChristmasDelivery/ChristmasDeliveryCalendar.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import { useNavigateToSystemPage } from '~/src/Infrastructure/SystemPages/NavigateToSystemPage'
import { usePageByUidFetch } from '~/src/Infrastructure/Page/PageByUidFetch'
import ContentEditor from '~/components/ContentEditor/ContentEditor.vue'

export default defineComponent({
  components: { ContentEditor, Button, ChristmasDeliveryCalendar, Modal },
  emits: ['close-modal'],
  setup () {
    const { page } = usePageByUidFetch('CHRISTMAS_DELIVERY_CALENDAR')
    const { navigateToUid } = useNavigateToSystemPage()
    const redirectToChristmasInfoPage = (callback: Function) => {
      callback()
      navigateToUid('CHRISTMAS_INFO_PAGE')
    }

    return {
      redirectToChristmasInfoPage,
      page
    }
  }
})
