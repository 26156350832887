











import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import {
  ChristmasDeliveryBadgeConfig,
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import {
  useChristmasDeliveryBadgeConfig
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryBadgeConfig'

export default defineComponent({
  props: {
    christmasDeliveryConfigurationItem: {
      required: true,
      type: Object as PropType<ChristmasDeliveryConfigurationItem>
    }
  },
  setup (props) {
    const { createAnnouncementBadgeConfig } = useChristmasDeliveryBadgeConfig()
    const badgeConfig = ref<ChristmasDeliveryBadgeConfig>(createAnnouncementBadgeConfig(props.christmasDeliveryConfigurationItem))

    return {
      badgeConfig
    }
  }
})
