import { Plugin } from '@nuxt/types'
import { useVisitedProductsStore } from '~/src/Infrastructure/VisitedProducts/VisitedProductsStore'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'

const visitedProductsCookieRestore: Plugin = ({ app, $auth, $pinia }) => {
  const visitedProductsListId: string | null = app.$cookies.get<string>('visitedProductsListId') ?? null
  const visitedProductsStore = useVisitedProductsStore($pinia)
  const authenticatedUser: AuthenticatedUser | null = $auth.user.value

  if (authenticatedUser) {
    visitedProductsStore.updateProductsListId(authenticatedUser.lastVisitedProducts)
  } else if (visitedProductsListId) {
    visitedProductsStore.updateProductsListId(visitedProductsListId)
  }
}
export default visitedProductsCookieRestore
