import { EventException } from '~/plugins/sentry/events/eventException'
import { EventMessage } from '~/plugins/sentry/events/eventMessage'
import { EventInternalErrorCode } from '~/plugins/sentry/events/eventInternalErrorCode'
import { EventInternalErrorCodeRange } from '~/plugins/sentry/events/eventInternalErrorCodeRange'
import { EventExceptionRegex } from '~/plugins/sentry/events/eventExceptionRegex'
import { EventExceptionFramesRegex } from '~/plugins/sentry/events/eventExceptionFramesRegex'
import { EventFilename } from '~/plugins/sentry/events/eventFilename'
import { EventModuleContains } from '~/plugins/sentry/events/eventModuleContains'
import { EventSourceFile } from '~/plugins/sentry/events/eventSourceFile'
import { Browser, Device, DeviceFamily, EventEnvironment, OSVersion } from '~/plugins/sentry/events/eventEnvironment'
import { EventErrorCodeFromUrlException } from '~/plugins/sentry/events/eventErrorCodeFromUrlException'

export type IgnoredEvents = (
  EventException |
  EventMessage |
  EventInternalErrorCode |
  EventInternalErrorCodeRange |
  EventExceptionRegex |
  EventExceptionFramesRegex |
  EventFilename |
  EventSourceFile |
  EventModuleContains |
  EventErrorCodeFromUrlException |
  EventEnvironment
  )[]

export const ignoredEvents: IgnoredEvents = [
  new EventException('UnhandledRejection', 'Non-Error promise rejection captured with value: '),
  new EventException('Event', 'Non-Error promise rejection captured with keys: '),
  new EventException('Error', 'Network Error'),
  new EventException('Error', 'NotAllowedError: Write permission denied.'),
  new EventException('TypeError', "Cannot read properties of null (reading 'formAvailible')"),
  new EventException('TypeError', "Cannot set properties of undefined (setting 'innerHTML')"),
  new EventException('TypeError', "Cannot read properties of undefined (reading '$scopedSlots')"),
  new EventException('TypeError', "undefined is not an object (evaluating 't.$scopedSlots')"),
  new EventException('TypeError', "undefined is not an object (evaluating 'a.N')"),
  new EventException('TypeError', "undefined is not an object (evaluating 'a.M')"),
  new EventException('TypeError', 'can\'t redefine non-configurable property "userAgent"'),
  new EventException('ReferenceError', "Can't find variable: instantSearchSDKJSBridgeClearHighlight"),
  new EventException('ReferenceError', "Can't find variable: _AutofillCallbackHandler"),
  new EventException('Error', 'Request aborted'),
  new EventException('Error', 'RuntimeError: call_indirect to a signature that does not match (evaluating \'a._ogv_video_decoder_process_frame(g,e)\')'),
  new EventException('Error', 'read ECONNRESET'),
  new EventExceptionFramesRegex('TypeError', /chrome-extension:\/\//),
  new EventExceptionFramesRegex('NetworkError', /https:\/\/cdn.luigisbox.com\/nay.js/),
  new EventSourceFile('chrome-extension://'),
  new EventMessage('ReportingObserver [intervention]: Ignored attempt to cancel a touchmove event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.'),
  new EventMessage('ReportingObserver [intervention]: Ignored attempt to cancel a touchstart event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.'),
  new EventMessage('ReportingObserver [deprecation]: Synchronous XMLHttpRequest on the main thread is deprecated because of its detrimental effects to the end user\'s experience. For more help, check https://xhr.spec.whatwg.org/.'),
  new EventMessage('ReportingObserver [deprecation]: Synchronous `XMLHttpRequest` on the main thread is deprecated because of its detrimental effects to the end user\'s experience. For more help, check https://xhr.spec.whatwg.org/.'),
  new EventMessage('ReportingObserver [intervention]: Ignored attempt to cancel a touchend event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.'),
  new EventMessage('ReportingObserver [deprecation]: Deprecation messages are stored in the devtools-frontend repo at front_end/models/issues_manager/DeprecationIssue.ts.'),
  new EventMessage('ReportingObserver [deprecation]: The `Expect-CT` header is deprecated and will be removed. Chrome requires Certificate Transparency for all publicly trusted certificates issued after April 30, 2018.'),
  new EventMessage('ReportingObserver [deprecation]: DOM Mutation Events, including `DOMSubtreeModified`, `DOMNodeInserted`, `DOMNodeRemoved`, `DOMNodeRemovedFromDocument`, `DOMNodeInsertedIntoDocument`, and `DOMCharacterDataModified` are deprecated (https://w3c.github....'),
  new EventInternalErrorCode(8516), // nedostatek kreditů k uplatnění
  new EventInternalErrorCode(2000), // nedostupná odpublikovaná stránka
  new EventInternalErrorCode(6008), // nekompatibilní platební metoda s doručovací metodou
  new EventInternalErrorCode(5), // authentication required
  new EventInternalErrorCode(8531), // dokončení objednávky na deaktivovaný účet
  new EventExceptionRegex('Error', /^Redirected when going from ".*" to ".*" via a navigation guard\.$/),
  new EventMessage('Could not load "stats".'),
  new EventException('TypeError', 'l.getByName is not a function'),
  new EventException('TypeError', 'Fr(...).getByName is not a function'),
  new EventException('TypeError', 'Sr(...).getByName is not a function'),
  new EventException('TypeError', 'l.remove is not a function'),
  new EventException('Error', 'window.ga is missing the getAll function'),
  new EventException('URIError', 'URI malformed'),
  new EventFilename('gtm.js'),
  new EventFilename('clarity.js'),
  new EventFilename('dtm.js'),
  new EventFilename('/gtag/js'),
  new EventFilename('exponea.min.js'),
  new EventFilename('pagead/viewthroughconversion'),
  new EventFilename('vue-router.esm.js'),
  new EventExceptionRegex('TypeError', /Object\.prototype\.hasOwnProperty\.call\([a-z],"telephone"\)/),
  new EventException('SyntaxError', 'Unexpected identifier \'https\''),
  new EventExceptionRegex('TypeError', /window.cobrowseIOgetRedactedElementsBounds is not a function/),
  new EventException('TypeError', "Cannot read properties of null (reading 'querySelector')", {
    browser: Browser.chromeMobileWebView,
    device: Device.pixel,
    osVersion: OSVersion.android10
  }),
  new EventException('TypeError', "Cannot read properties of null (reading 'src')", {
    browser: Browser.chromeMobileWebView,
    device: Device.pixel,
    osVersion: OSVersion.android10
  }),
  new EventEnvironment({ deviceFamily: DeviceFamily.k }),
  new EventEnvironment({ deviceFamily: DeviceFamily.spider }),
  new EventEnvironment({ browser: /(\w*(bot|Bot))\b/ }),
  new EventErrorCodeFromUrlException('https://live.luigisbox.com/autocomplete/v2', 408)
]
