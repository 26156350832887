








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    icon: {
      required: true,
      type: String
    }
  }
})
