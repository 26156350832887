import { Context, Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { Config } from '~/src/Model/Config/Config'
import { Web } from '~/src/Model/Config/Web'
import { RoundingType } from '~/src/Model/Rounding/RoundingType'

type ConfigFactory = (runtimeConfig: NuxtRuntimeConfig) => Config

export const webs: Record<Web, ConfigFactory> = {
  [Web.Ew]: (runtimeConfig: NuxtRuntimeConfig): Config => {
    return {
      web: Web.Ew,
      webId: 3,
      isNay: false,
      defaultLocale: 'cs',
      dicFormat: /^[a-zA-Z][a-zA-Z][0-9]{8,10}$/,
      useIcDph: false,
      phoneCode: '+420',
      allowedLocales: [
        'cs',
        'en'
      ],
      noImagePath: '/img/noimg/noimg_ew.svg',
      luigisBoxTrackerId: '42362-52395',
      luigisBoxSrc: 'https://cdn.luigisbox.com/electroworld.js',
      cardNumberRanges: [
        { from: 7000000000000, to: 7999999999999 },
        { from: 9000000000000, to: 9999999999999 }
      ],
      currency: 'CZK',
      currencySymbol: 'Kč',
      googleApiKey: 'AIzaSyCMayx75rw_KefhqM2jaz4TMvP1jszhNPs',
      countryCode: 'cz',
      countryCenterCoordinates: {
        lat: 49.8464004,
        long: 15.4749003
      },
      countryBoundsCoordinates: {
        north: 51.170053600533514,
        south: 48.441237322187895,
        west: 11.945557756223371,
        east: 19.042725075382926
      },
      googleLoginClientId: runtimeConfig.googleLoginClientId,
      facebookAppId: runtimeConfig.facebookAppId,
      domain: 'www.electroworld.cz',
      presentableDomain: 'Electroworld.cz',
      cdnUrl: 'https://cdn.electroworld.cz/images',
      accessoryAlias: 'prislusenstvi',
      magazineUid: 'PAGE_EW_EXTRA_MAGAZINE',
      appleLoginClientId: 'electroworld.cz.signWithApple',
      appleLoginRedirectUri: 'https://www.electroworld.cz/',
      externalCareerPageUri: 'https://electroworld.jobs.cz/',
      christmasMainCategoryId: 186142,
      christmasMainCategoryUid: 'CHRISTMAS_EW',
      showB2BGdprAgreement: false,
      seoPdpUrl: 'https://app.electroworld.cz/pdp/core-mod/seo-alias/list',
      pickupPointMapDefaultZip: '61200',
      googleMapId: '4c2e54a431928c00',
      googleMapStoreBackground: '#ffffff',
      googleAutocompleteMinCharsLimit: 2,
      instalments: {
        enableInstalmentsWithoutIncrease: true,
        productDetailQuattroWithoutIncrease: false,
        productDetailHomeCreditWithoutIncrease: true,
        instalmentsWithoutIncreaseLimitMin: 1000,
        instalmentsWithoutIncreaseLimitMax: 100000,
        instalmentsCount: 10,
        instalmentsRounding: RoundingType.MATH,
        roundingDigits: 0,
        basketInsertPayMethodWithoutBaremWithoutIncrease: true,
        quattroShowOnlyNoIncreaseBaremInModal: false,
        instalmentsWithoutIncreaseEnabledFrom: '2024-05-09 00:00:00',
        instalmentsWithoutIncreaseEnabledTo: '2024-05-15 23:59:59',
        productTypesWithoutIncrease: [],
        acceptProductTypesFrom: '2024-6-03 00:00:00'
      },
      consentsCount: 5,
      enableGoodAngel: false,
      isActiveChristmas: false,
      homepageMinimumLoyaltyProductsToShow: 2,
      enableLuigisBoxRecommender: false,
      loyaltyClubAllowed: false,
      loyaltyPointsUsageAllowed: false,
      karpatyStopSelling: true
    }
  },
  [Web.Nay]: (runtimeConfig: NuxtRuntimeConfig): Config => {
    return {
      web: Web.Nay,
      webId: 1,
      isNay: true,
      phoneCode: '+421',
      dicFormat: /^[0-9]{10}$/,
      useIcDph: true,
      defaultLocale: 'sk',
      allowedLocales: [
        'sk',
        'en'
      ],
      noImagePath: '/img/noimg/noimg_nay.svg',
      luigisBoxTrackerId: '14457-16493',
      luigisBoxSrc: 'https://cdn.luigisbox.com/nay.js',
      cardNumberRanges: [
        { from: 6188867800000000, to: 8888889999999999 },
        { from: 5276000000000000, to: 5276999999999999 },
        { from: 5577000000000000, to: 5577999999999999 },
        { from: 1700000000000000, to: 1799999999999999 }
      ],
      currency: 'EUR',
      currencySymbol: '€',
      googleApiKey: 'AIzaSyDcRDlQiK_k9cWxW7lPTXnH6sh4_TPDJLI',
      countryCode: 'sk',
      countryCenterCoordinates: {
        lat: 48.7047188,
        long: 19.7340035
      },
      countryBoundsCoordinates: {
        north: 49.75225903156046,
        south: 47.603580441541595,
        west: 16.72151434301675,
        east: 22.69328138920159
      },
      googleLoginClientId: runtimeConfig.googleLoginClientId,
      facebookAppId: runtimeConfig.facebookAppId,
      domain: 'www.nay.sk',
      presentableDomain: 'Nay.sk',
      cdnUrl: 'https://cdn.nay.sk/images',
      accessoryAlias: 'prislusenstvo',
      magazineUid: 'PAGE_NAY_EXTRA_MAGAZINE',
      appleLoginClientId: 'sk.nay.signWithApple',
      appleLoginRedirectUri: 'https://www.nay.sk/',
      externalCareerPageUri: null,
      christmasMainCategoryId: 236743,
      christmasMainCategoryUid: 'CHRISTMAS_NAY',
      showB2BGdprAgreement: true,
      seoPdpUrl: 'https://app.nay.sk/pdp/core-mod/seo-alias/list',
      pickupPointMapDefaultZip: '81103',
      googleMapId: '4c2e54a431928c00',
      googleMapStoreBackground: '#ffffff',
      googleAutocompleteMinCharsLimit: 3,
      instalments: {
        enableInstalmentsWithoutIncrease: true,
        instalmentsWithoutIncreaseLimitMin: 100,
        instalmentsWithoutIncreaseLimitMax: 10000,
        productDetailQuattroWithoutIncrease: true,
        productDetailHomeCreditWithoutIncrease: true,
        instalmentsCount: 10,
        instalmentsRounding: RoundingType.UP,
        roundingDigits: 2,
        basketInsertPayMethodWithoutBaremWithoutIncrease: true,
        quattroShowOnlyNoIncreaseBaremInModal: false,
        instalmentsWithoutIncreaseEnabledFrom: '2024-08-01 00:00:00',
        instalmentsWithoutIncreaseEnabledTo: '2024-08-18 23:59:59',
        productTypesWithoutIncrease: [],
        acceptProductTypesFrom: null
      },
      consentsCount: 4,
      enableGoodAngel: true,
      isActiveChristmas: false,
      homepageMinimumLoyaltyProductsToShow: 6,
      enableLuigisBoxRecommender: true,
      loyaltyClubAllowed: true,
      loyaltyPointsUsageAllowed: true,
      karpatyStopSelling: false
    }
  }
}

const webConfig: Plugin = (context: Context, inject: Inject) => {
  inject('webConfig', webs[context.$config.web as Web || Web.Nay](context.$config))
}

declare module 'vue/types/vue' {
  interface Vue {
    $webConfig: Config
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $webConfig: Config
  }

  interface Context {
    $webConfig: Config
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $webConfig: Config
  }
}

export default webConfig
