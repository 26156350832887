
















































import { defineComponent, ref, onMounted, watch, useRoute, onUnmounted } from '@nuxtjs/composition-api'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import SuggestResponse from '~/src/Model/LuigisBox/Suggest/SuggestResponse'
import SuggestHit from '~/src/Model/LuigisBox/Suggest/SuggestHit'
import LuigisBoxClient from '~/utils/LuigisBox/LuigisBoxClient'
import AutoSuggest from '~/components/Header/SearchBox/AutoSuggest.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'

interface ServerResponse {
  data: SuggestResponse
}

export default defineComponent({
  components: {
    AutoSuggest,
    Button,
    Form
  },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'change-panel', 'input'],
  setup (props, { emit }) {
    const query = ref<string|null>(null)
    const suggestions = ref<SuggestHit[]>([])
    const showSuggestions = ref<boolean>(false)
    const { app } = useApp()
    const $webConfig = useWebConfig()
    const route = useRoute()
    const systemPagesStore = useSystemPagesStore()
    const pathToSearchResults = systemPagesStore.pathByUid('LUIGIS_BOX_SEARCH')
    const input = ref<Vue|null>()
    const isInputFocused = ref<boolean>(false)

    onMounted(() => {
      const searchQuery = route.value.query.q

      if (searchQuery !== undefined) {
        query.value = (searchQuery as string)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('click', handleClick)
    })

    const isSearchBoxPanelVisible = props.activePanel === ActiveHeaderPanel.searchBox

    const handleClick = (e: any) => {
      if (!showSuggestions.value) {
        return
      }

      showSuggestions.value = e.target.id === input.value?.$el.id
    }

    const getData = async (): Promise<void> => {
      if (!query.value?.length) {
        suggestions.value = []
        return
      }

      showSuggestions.value = true

      await LuigisBoxClient.get<SuggestResponse>(
        '/autocomplete/v2',
        {
          params: {
            tracker_id: $webConfig.luigisBoxTrackerId,
            type: 'public_product:6,brand:6,category:6,article:6,common_page:6',
            q: query.value
          }
        }
      ).then((response: ServerResponse): ServerResponse => {
        suggestions.value = response.data.hits
        return response
      })
    }

    const navigationInProgress = ref<boolean>(false)

    const processSearch = async (): Promise<void> => {
      showSuggestions.value = false

      if (input.value?.$el) {
        (input.value.$el as HTMLInputElement).blur()
      }

      if (navigationInProgress.value) {
        return
      }

      if (route.value.query.q === query.value) {
        return
      }

      navigationInProgress.value = true
      await app.router?.push({ path: pathToSearchResults.value, query: { q: query.value } })
      navigationInProgress.value = false
    }

    watch(() => route.value, () => {
      query.value = null
    })

    const emitClose = (): void => {
      emit('close')
    }

    const toggleSearchBox = () => {
      emit('change-panel', ActiveHeaderPanel.searchBox)
    }

    watch(showSuggestions, () => {
      if (showSuggestions.value) {
        document.body.style.overflow = 'hidden'
        window.addEventListener('click', handleClick)
      } else {
        document.body.style.overflow = ''
        window.removeEventListener('click', handleClick)
      }
    })

    const eraseQuery = () => {
      query.value = null
      // @ts-ignore
      input.value.$el.focus()
    }

    const suggestionScrolled = () => {
      if (isInputFocused.value) {
        // @ts-ignore
        input.value.$el.blur()
      }
    }

    return {
      query,
      input,
      suggestions,
      showSuggestions,
      isSearchBoxPanelVisible,
      processSearch,
      emitClose,
      getData,
      toggleSearchBox,
      eraseQuery,
      suggestionScrolled,
      isInputFocused
    }
  }
})
