




















import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'

export default defineComponent({
  components: { FormInput },
  inheritAttrs: false,
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      type: String,
      default: null
    },
    button: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'eager'
    },
    vid: {
      type: String,
      default: null
    },
    immediate: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    inputWrapClass: {
      type: String,
      default: null
    }
  },
  emits: ['input'],
  setup (props) {
    const formatter = useDateFormatter()
    const required = ref<boolean>(props.rules.includes('required'))

    const minMaxRules = computed<string>(() => {
      let rules = '|'

      if (props.min) {
        rules += `minDate:${formatter.dateInputFormat(new Date(props.min))}|`
      }

      if (props.max) {
        rules += `maxDate:${formatter.dateInputFormat(new Date(props.max))}|`
      }

      return rules
    })

    const inputRules = computed<string>(() => {
      return (props.rules + minMaxRules.value).replace(/^\|+|\|+$/g, '')
    })

    return {
      required,
      minMaxRules,
      inputRules
    }
  }
})
