import { extend } from 'vee-validate'
import {
  required,
  confirmed,
  min,
  max,
  double,
  size,
  image,
  // eslint-disable-next-line camelcase
  required_if
} from 'vee-validate/dist/rules.umd'
import { Plugin } from '@nuxt/types'
import { IVueI18n } from 'vue-i18n'
import { createRuleFactory } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { NoTabsRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/NoTabs/NoTabsValidation'
import { PhoneRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Phone/PhoneValidation'
import { StartsWithRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/StartsWith/StartsWithValidation'
import {
  StartsNotWithRule
} from '~/src/Infrastructure/Form/Validation/ValidationRules/StartsNotWith/StartsNotWithValidation'
import { InvalidRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Invalid/InvalidValidation'
import { RequiredOneRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/RequiredOne/RequiredOneValidation'
import { DecimalRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Decimal/DecimalValidation'
import { AlphaNumSpaceRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/AlphaNumSpace/AlphaNumSpaceValidation'
import { IcoRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Ico/IcoValidation'
import { IcDphRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/IcDph/IcDphValidation'
import { ZipRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Zip/ZipValidation'
import { CityRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/City/CityValidation'
import { AddressRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Address/AddressValidation'
import { MaxValueRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MaxValue/MaxValueValidation'
import { MaxFileSizeRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MaxFileSize/MaxFileSizeValidation'
import { MinValueRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MinValue/MinValueValidation'
import { NumericRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Numeric/NumericValidation'
import { MaxDateRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MaxDate/MaxDateValidation'
import { MinDateRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MinDate/MinDateValidation'
import { ZipCountryRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ZipCountry/ZipCountryValidation'
import { EmailRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Email/EmailValidation'
import { SlugRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Slug/SlugValidation'
import {
  NumberOrEmailRule
} from '~/src/Infrastructure/Form/Validation/ValidationRules/NumberOrEmail/NumberOrEmailValidation'
import { DicRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/Dic/DicValidation'
import {
  NoTrailingWhiteSpaceRule
} from '~/src/Infrastructure/Form/Validation/ValidationRules/NoTrailingWhitespace/NoTrailingWhitespaceValidation'
import { MinFilesCountRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MinFilesCount/MinFilesCountValidation'
import { MaxFilesCountRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/MaxFilesCount/MaxFilesCountValidation'
import {
  NumericSpaceRule
} from '~/src/Infrastructure/Form/Validation/ValidationRules/NumericSpace/NumericSpaceValidation'

const plugin: Plugin = ({ app }) => {
  const i18n = app.i18n as IVueI18n
  const ruleFactory = createRuleFactory(i18n)

  extend('required', {
    ...required,
    message: (field: string) => i18n.t('validations.required', { field }).toString()
  })

  extend('confirmed', {
    ...confirmed,
    message: i18n.t('validations.confirmed').toString()
  })

  extend('image', {
    ...image,
    message: i18n.t('validations.image').toString()
  })

  extend('min', {
    ...min,
    message: (field: string) => i18n.t('validations.min', { field }).toString()
  })

  extend('max', {
    ...max,
    message: (field: string) => i18n.t('validations.max', { field }).toString()
  })

  extend('double', {
    ...double,
    message: (field: string) => i18n.t('validations.double', { field }).toString()
  })

  extend('size', {
    ...size,
    params: ['size'],
    message: (_: string, { size }: Record<string, any>): string => {
      return i18n.t('validations.size', { size }).toString()
    }
  })

  extend('required_if', {
    // eslint-disable-next-line camelcase
    ...required_if,
    message: (field: string) => i18n.t('validations.required_if', { field }).toString()
  })

  extend('dic', new DicRule(app.$webConfig, i18n).create())
  extend('zipCountry', new ZipCountryRule(app.$axios, i18n).create())
  extend('minDate', new MinDateRule(app.$formatter, i18n).create())
  extend('maxDate', new MaxDateRule(app.$formatter, i18n).create())
  extend('slug', ruleFactory(SlugRule))
  extend('email', ruleFactory(EmailRule))
  extend('no_trailing_whitespace', ruleFactory(NoTrailingWhiteSpaceRule))
  extend('numeric', ruleFactory(NumericRule))
  extend('numeric_space', ruleFactory(NumericSpaceRule))
  extend('min_value', ruleFactory(MinValueRule))
  extend('max_value', ruleFactory(MaxValueRule))
  extend('phone', ruleFactory(PhoneRule))
  extend('address', ruleFactory(AddressRule))
  extend('zip', ruleFactory(ZipRule))
  extend('city', ruleFactory(CityRule))
  extend('icDph', ruleFactory(IcDphRule))
  extend('ico', ruleFactory(IcoRule))
  extend('alpha_num_space', ruleFactory(AlphaNumSpaceRule))
  extend('decimal', ruleFactory(DecimalRule))
  extend('required_one', ruleFactory(RequiredOneRule))
  extend('no_tabs', ruleFactory(NoTabsRule))
  extend('starts_with', ruleFactory(StartsWithRule))
  extend('starts_not_with', ruleFactory(StartsNotWithRule))
  extend('invalid', ruleFactory(InvalidRule))
  extend('name', ruleFactory(AlphaNumSpaceRule))
  extend('max_file_size', ruleFactory(MaxFileSizeRule))
  extend('min_files_count', ruleFactory(MinFilesCountRule))
  extend('max_files_count', ruleFactory(MaxFilesCountRule))
  extend('numberOrEmail', ruleFactory(NumberOrEmailRule))
}

export default plugin
