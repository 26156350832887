






import { defineComponent } from '@nuxtjs/composition-api'
import RegistrationForm from '~/components/Auth/RegistrationForm.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    RegistrationForm,
    PageSectionContent
  },
  emits: ['registered']
})
