

























import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api'
import cloneDeep from 'lodash/cloneDeep'

export default defineComponent({
  props: {
    totalCount: {
      required: true,
      type: Number
    },
    perPage: {
      required: true,
      type: Number
    },
    queryName: {
      type: String,
      default: 'page'
    }
  },
  emits: ['change', 'input'],
  setup (props) {
    const route = useRoute()
    const pageCount = computed<number>(() => {
      return Math.ceil(props.totalCount / props.perPage)
    })

    const linkGen = (pageNum: number) => {
      const query = cloneDeep(route.value.query)

      if (pageNum === 1) {
        delete query.page

        return { query }
      }

      query.page = pageNum.toString()

      return { query }
    }

    const pages = computed<string[]>(() => {
      const pages: string[] = []

      for (let i = 1; i <= pageCount.value; i++) {
        pages.push(i.toString())
      }

      return pages
    })

    return {
      pages,
      linkGen
    }
  }
})
