






import { defineComponent } from '@nuxtjs/composition-api'
import LogoImage from '~/components/Header/Logo/LogoImage.vue'

export default defineComponent({
  components: {
    LogoImage
  },
  props: {
    logoPosition: {
      type: String,
      default: 'position-absolute left-0'
    }
  }
})
