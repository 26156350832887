import Router from 'vue-router'
import { Dictionary, RawLocation, Route, RouteConfigSingleView } from 'vue-router/types/router'
import axios, { AxiosError } from 'axios'
import * as SentryTypes from '@sentry/minimal'
import { componentMap } from '~/utils/router/componentMap'
import scrollBehavior from '~/utils/router/scrollBehavior'
import ProductAccessory from '~/pages/product-accessory/default.vue'
import ErrorComponentFactory from '~/src/Infrastructure/Router/AliasRouter/ErrorComponentFactory'
import { RouterErrorComponentFactory } from '~/utils/router/RouterErrorComponentFactory'
import { webs } from '~/plugins/web-config'
import { Web } from '~/src/Model/Config/Web'
import { Config } from '~/src/Model/Config/Config'
const Homepage = () => import('~/pages/index.vue')

interface RouteData {
  mainId: number
  isSeoAlias: boolean
  originAlias: string|null
}

export function createAliasRouter (ssrContext: any, createDefaultRouter: Function, routerOptions: any, runtimeConfig: any) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  const webConfig: Config = webs[runtimeConfig.web as Web || Web.Nay](runtimeConfig)

  const router: any = new Router({
    mode: 'history',
    routes: [
      ...options.routes, // Defaultní routy vytvořené nuxtem,
      {
        path: `/:alias/${webConfig.accessoryAlias}`,
        component: ProductAccessory
      },
      {
        path: '/',
        component: async () => (await Homepage()).default
      }
    ],
    scrollBehavior
  })

  const defaultMatch = (router as any).matcher.match
  const defaultPush = router.push
  const routesData = new Map<string, RouteData>()

  router.push = async function (location: RawLocation, onComplete?: Function, onAbort?: Function) {
    const $sentry = this.app.$sentry as typeof SentryTypes

    if (!onComplete && !onAbort && typeof Promise !== 'undefined') {
      try {
        return await defaultPush.call(this, location)
      } catch (error) {
        if (Router.isNavigationFailure(error, Router.NavigationFailureType.duplicated)) {
          return
        }

        if (Router.isNavigationFailure(error, Router.NavigationFailureType.cancelled)) {
          return
        }

        $sentry.captureException(new Error((error as AxiosError).message))

        throw error
      }
    }

    defaultPush.call(this, location, onComplete, onAbort)
  }

  // Přetížíme si match funkci matcheru
  router.matcher.match = function match (
    raw: RawLocation,
    currentRoute: Route,
    redirectedFrom: Location
  ): Route {
    const match = defaultMatch(raw, currentRoute, redirectedFrom) // Zavoláme si původní match

    if (match.matched.length === 0) { // Když nic nenajdem, tak to znamená, že neexistuje žádná daná systémová stránka a může se jednat o náš alias z API
      const path = getPathFromRawLocation(raw)
      const allowedPathCharactersRegex = /^(([a-z0-9-_.;])|\/(?!\/))+$/i

      if (!allowedPathCharactersRegex.test(path)) {
        return match
      }

      const asyncRoute = createAsyncRoute(raw)

      router.addRoute(asyncRoute)

      return defaultMatch(raw, currentRoute, redirectedFrom)
    }

    return match
  }

  function createAsyncRoute (raw: RawLocation): RouteConfigSingleView {
    const path = getPathFromRawLocation(raw)
    const routerErrorComponentFactory = new RouterErrorComponentFactory()

    let baseUrl: string | undefined
    let appVersion: string | undefined

    if (process.client) {
      // @ts-ignore
      baseUrl = window.__NUXT__.config.axios.browserBaseURL || window.__NUXT__.config.axios.baseURL
      // @ts-ignore
      appVersion = window.__NUXT__.config.appVersion ?? ''
    } else {
      baseUrl = ssrContext?.nuxt.config.axios.baseURL
      appVersion = ssrContext?.nuxt.config.appVersion ?? ''
    }

    const $axios = axios.create({
      baseURL: baseUrl ?? 'http://localhost:8080',
      headers: {
        'X-Client-Version': appVersion
      }
    })
    const alias = getPathAlias(path)

    return {
      name: alias,
      path: `/${alias}/:all*`,
      component: async () => {
        let axiosResponse

        try {
          axiosResponse = await $axios.get(`/router/${alias}`)
        } catch (e) {
          const queryParams = typeof raw === 'object' ? raw.query as Dictionary<string> : {}

          return routerErrorComponentFactory.create(e as AxiosError, path, queryParams)
        }

        const responseData = axiosResponse.data
        const vueComponentFactory = componentMap[responseData.module][responseData.template] ?? null

        if (!vueComponentFactory) {
          // eslint-disable-next-line no-console
          console.error(`Can't find template key "${responseData.module} - ${responseData.template}" in componentMap`)

          return ErrorComponentFactory(404, 'Page not found')
        }

        const vueComponent = await vueComponentFactory()

        routesData.set(path, {
          mainId: responseData.mainId,
          isSeoAlias: responseData.isSeoAlias,
          originAlias: responseData.originAlias
        })
        vueComponent.default.props = {
          mainId: {
            default: responseData.mainId
          },
          isSeoAlias: {
            default: responseData.isSeoAlias
          },
          originAlias: {
            default: responseData.originAlias
          }
        }

        return vueComponent.default
      },
      props: () => (
        {
          mainId: routesData.get(path)?.mainId,
          isSeoAlias: routesData.get(path)?.isSeoAlias,
          originAlias: routesData.get(path)?.originAlias
        }
      )
    }
  }

  function getPathFromRawLocation (raw: string | RawLocation) {
    return typeof raw === 'string' ? raw : raw.path ?? ''
  }

  function getPathAlias (path: string) {
    let alias = path.substring(1, path.length)

    if (alias.split('/').length > 0) {
      alias = alias.split('/')[0]
    }
    return alias
  }

  return router
}
