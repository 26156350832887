import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'

export class MinValueRule extends ValidationRule {
  params = ['min']
  message = (field: string, { min }: Record<string, any>): string => {
    return this.i18n.t('validations.min_value', { field, min }).toString()
  }

  validate = (value: number | string, { min }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: false
      }
    }

    const parsedValue = bigify(value)
    const valid = parsedValue.gte(min)

    return {
      valid
    }
  }
}
