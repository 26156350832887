export enum ContentEditorBlockType {
  richText = 'richText',
  storeBox = 'storeBox',
  productBox = 'productBox',
  productBoxList = 'productBoxList',
  feeCalculator = 'feeCalculator',
  accordeon = 'accordeon',
  karpatyAccordeon = 'karpatyAccordeon',
}

export interface RichText {
  html: string
}

export interface StoreBox {
  storeId: number
}

export enum ProductBoxType {
  SMALL = 'small',
  FULL = 'full'
}
export interface ProductBox {
  type: ProductBoxType
  basketButton: boolean
  productId: string
}

export interface ProductBoxList {
  basketButton: boolean
  productIds: string[]
}

export interface FeeCalculator {
  productType: string
}

export interface AccordeonItem {
  title: string
  body: string
}

export interface Accordeon {
  items: AccordeonItem[]
}

export interface KarpatyAccordeonItem {
  title: string
  imageId: string
  perex: string
  content: string
}

export interface KarpatyAccordeon {
  items: KarpatyAccordeonItem[]
}

export interface ContentEditorBlock {
  type: ContentEditorBlockType
  value: RichText | StoreBox | ProductBox | ProductBoxList | FeeCalculator | Accordeon | KarpatyAccordeon
}

export interface ContentEditorBlocks {
  blocks: ContentEditorBlock[]
}
