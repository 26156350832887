










import { defineComponent } from '@nuxtjs/composition-api'
import SystemLink from '~/components/SystemPage/SystemLink.vue'

export default defineComponent({
  components: { SystemLink },
  setup () {
  }
})
