




import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import {
  PickupPointRatioMap,
  PickupPointTemplateMap,
  PickupPointTypeMap
} from '~/src/Model/Delivery/PickupPointIcon'
import { DeliveryType } from '~/src/Model/Delivery/DeliveryMethods'
import Logo from '~/components/Store/Components/Icon/Logo.vue'
import Icon from '~/components/Store/Components/Icon/Icon.vue'

export default defineComponent({
  components: { Logo, Icon },
  props: {
    iconType: {
      type: String as PropType<DeliveryType>,
      default: DeliveryType.store
    }
  },
  setup (props) {
    const template = computed<string | null>(() => PickupPointTemplateMap[props.iconType] ?? PickupPointTemplateMap[DeliveryType.store])
    const variant = computed<string | null>(() => PickupPointTypeMap[props.iconType] ?? PickupPointTypeMap[DeliveryType.store])
    const aspectRatio = computed<number | null>(() => PickupPointRatioMap[props.iconType] ?? PickupPointRatioMap[DeliveryType.store])

    return {
      template,
      variant,
      aspectRatio
    }
  }
})
