













import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { StaticCrossroadNode } from '~/src/Model/ServicesCrossroad/ServicesCrossroad'

export default defineComponent({
  props: {
    node: {
      required: true,
      type: Object as PropType<StaticCrossroadNode>
    }
  }
})
