




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Product } from '~/src/Model/Product/Product'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>
    }
  }
})
