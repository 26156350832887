
























import { defineComponent, ref, PropType } from '@nuxtjs/composition-api'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import { AppleLoginMergeRequest, AppleLoginRequest } from '~/src/Model/Auth/Apple/Apple'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    BaseValidationObserver,
    LoadingAnim,
    FlashMessageCenter,
    FormInput,
    Button,
    Form,
    Alert
  },
  props: {
    appleCredentials: {
      type: Object as PropType<AppleLoginRequest>,
      required: true
    }
  },
  emits: ['merged'],
  setup (props, { emit }) {
    const { unificate } = useVueInstanceUid()
    const { appleLoginMerge } = useCustomerAuthRepository()
    const password = ref<string>('')

    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const { exec: submit, pending: pendingLogin, onSuccess: onLoggedIn } = useApiCall(
      () => {
        const mergeRequest: AppleLoginMergeRequest = {
          authorization: props.appleCredentials.authorization,
          user: props.appleCredentials.user,
          password: password.value
        }
        return appleLoginMerge(mergeRequest)
      },
      flashMessageCenter
    )

    onLoggedIn.subscribe((mergeResponse) => {
      emit('merged', mergeResponse)
    })

    return {
      password,
      pendingLogin,
      flashMessages,
      submit,
      unificate
    }
  }
})
