export enum AvailabilityTypes {
  IS_OLD = 0,
  IN_STOCK = 1,
  NOT_IN_STOCK = 3,
  AVAILABILITY_SOLD = 8,
  EXHIBIT_ON_ESHOP = 2,
  AVAILABILITY_NOT_AVAILABLE = 6,
  CURRENTLY_NOT_AVAILABLE = 7,
  AVAILABILITY_PREORDER = 5,
  UNAVAILABLE_FOR_SALE_ACTION = 4
}
