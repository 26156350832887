


























import { computed, defineComponent } from '@nuxtjs/composition-api'
import { ButtonVariant } from '~/src/Model/Button/ButtonVariant'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null
    },
    isButton: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    uid: {
      type: String,
      default: null
    },
    split: {
      type: Boolean,
      default: false
    },
    noFlip: {
      type: Boolean,
      default: false
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    dropup: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    splitClass: {
      type: [String, Array, Object],
      default: null
    },
    toggleClass: {
      type: [String, Array],
      default: null
    },
    menuClass: {
      type: [String, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataTest: {
      type: String,
      default: null
    }
  },
  emits: ['show', 'click'],
  setup (props) {
    const variant = computed<string | null>(() => {
      if (props.success) {
        return ButtonVariant.success
      }
      if (props.primary) {
        return ButtonVariant.primary
      }

      return null
    })

    const linkTo = computed<string | null>(() => {
      if (props.to) {
        return props.to
      }
      if (props.uid) {
        const systemPagesStore = useSystemPagesStore()
        return systemPagesStore.pathByUid(props.uid).value
      }

      return null
    })

    return {
      variant,
      linkTo
    }
  }
})
