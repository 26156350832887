import { doubleRequestAnimationFrame } from '~/src/Infrastructure/WebAPI/DoubleRequestAnimationFrame'

export const useScrollTo = () => {
  const scrollToElement = (elementSelector: string) => {
    const element = document.querySelector(elementSelector)

    if (!(element instanceof HTMLElement)) {
      return
    }

    element.scrollIntoView()
  }
  const scrollToHtmlElement = (element: Element) => {
    element.scrollIntoView()
  }

  const scrollToElementAfterDomRepaint = (element: Element) => {
    doubleRequestAnimationFrame(() => {
      scrollToHtmlElement(element)
    })
  }

  const scrollToTop = () => {
    doubleRequestAnimationFrame(() => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    })
  }

  return {
    scrollToElement,
    scrollToHtmlElement,
    scrollToElementAfterDomRepaint,
    scrollToTop
  }
}
