var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",class:[
    'skeleton skeleton--text',
    {'w-100 d-inline-block' : _vm.maxWidth}
  ],style:(_vm.maxWidth ? 'max-width: '+ _vm.maxWidth + 'em;' : null)},_vm._l((_vm.rowCount),function(row,index){return _c('span',{key:index,class:[
      _vm.itemClass,
      'skeleton__item',
      {'skeleton__item--custom-w w-100' : _vm.maxWidth}
    ]})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }