



























import { defineComponent } from '@nuxtjs/composition-api'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'

export default defineComponent({
  components: { Container, Row, Column },
  setup () {
    const systemPagesStore = useSystemPagesStore()
    const path = systemPagesStore.pathByUid('contact')

    return {
      path
    }
  }
})
