













import { defineComponent, onMounted, onUnmounted, ref, useRoute, watch } from '@nuxtjs/composition-api'
import PdpBarControl from '~/components/PdpBar/PdpBarControl.vue'
import PdpBarContent from '~/components/PdpBar/PdpBarContent.vue'
import { usePdpBarStore } from '~/src/Infrastructure/PdpBar/PdpBarStore'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export default defineComponent({
  components: { PdpBarContent, PdpBarControl },
  setup () {
    const { isPdpBarAvailable } = useAuthenticatedUser()
    const showPdpBar = ref<boolean>(false)

    const closePdpBar = function () {
      showPdpBar.value = false
    }

    onMounted(() => {
      document.body.addEventListener('click', closePdpBar)
    })

    onUnmounted(() => {
      document.body.removeEventListener('click', closePdpBar)
    })

    const route = useRoute()

    const pdpBarStore = usePdpBarStore()
    const pdpBar = pdpBarStore.pdpBar

    watch(
      () => route.value.name,
      () => {
        if (isPdpBarAvailable.value && pdpBarStore.pdpBarContext.value) {
          pdpBarStore.resetContext()
        }
      }
    )

    const clickPdpBar = () => {
      if (!showPdpBar.value) {
        pdpBarStore.loadPdpBar(route.value.name || '')
      }
      showPdpBar.value = !showPdpBar.value
    }

    return {
      showPdpBar,
      clickPdpBar,
      isPdpBarAvailable,
      pdpBar
    }
  }
})
