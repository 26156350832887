

































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import { useStoresMapRepository } from '~/src/Infrastructure/StoresMap/StoresMapRepository'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import MapList from '~/components/StoresMap/MapList.vue'
import MapMark from '~/components/StoresMap/MapMark.vue'
import { MapRegion, StoresMap } from '~/src/Model/StoresMap/StoresMap'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: {
    Container,
    MapList,
    MapMark,
    Button
  },
  setup () {
    const storesMap = ref<StoresMap | null>(null)
    const { loadStoresMap } = useStoresMapRepository()
    const rootElement = ref<HTMLElement | null>()

    const loadData = async () => {
      storesMap.value = await loadStoresMap()
    }

    const $webConfig = useWebConfig()

    const isNay = computed<boolean>(() => $webConfig.web === Web.Nay)
    const pathPrefix = computed<string>(() => isNay.value ? 'nay' : 'ew')

    useVisibilityLazyLoad(
      rootElement,
      loadData,
      {
        rootMargin: '400px'
      }
    )

    const totalCount = computed<number>({
      get: () => {
        if (storesMap.value === null) {
          return 0
        }

        return storesMap.value.storesCount
      },
      set: () => {}
    })

    const regions = computed<MapRegion[]>(() => {
      if (storesMap.value === null) {
        return []
      }

      return storesMap.value.regions
    })

    return {
      totalCount,
      storesMap,
      regions,
      rootElement,
      pathPrefix
    }
  }
})
