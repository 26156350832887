













import { defineComponent, watch, ref } from '@nuxtjs/composition-api'
import { Duration } from 'date-fns'

export default defineComponent({
  props: {
    duration: {
      required: true,
      type: Object as () => Duration
    },
    property: {
      required: true,
      type: String
    },
    trackerIndex: {
      required: true,
      type: Number
    }
  },

  setup (props: {duration: Duration, property: string, trackerIndex: number}) {
    const current = ref<number | string>(0)
    const previous = ref<number | string>(0)

    watch(() => props.duration, () => {
      let value = (props.duration as any)[props.property]

      if (value < 0) {
        value = '00'
      } else if (value < 10) {
        value = '0' + value
      }

      const elements = document.getElementsByClassName('countdown__item')

      if (value !== current.value) {
        elements[props.trackerIndex]?.classList.add('countdown__item--flip')
        previous.value = current.value
        current.value = value

        setTimeout(function () {
          elements[props.trackerIndex]?.classList.remove('countdown__item--flip')
        }, 300)
      }
    })

    return {
      current,
      previous
    }
  }
})
