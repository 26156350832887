import { ProductAccessories, ProductAccessoriesRequest } from '~/src/Model/ProductAccessories/ProductAccessories'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductAccessoriesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadProductAccessories:
      (id: number, parameters: ProductAccessoriesRequest): Promise<ProductAccessories> =>
        $axios.$get<ProductAccessories>(`/products/${id}/accessories`, { params: parameters })
  }
}
