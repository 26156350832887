import { Route } from 'vue-router/types/router'

export interface LUXOptions {
  id: string
  debugMode: boolean
  enabled: boolean
  logFirstHit: boolean
  sampleRate: number
  translateBool: boolean
  autoStartOnNav: boolean
}

export const LUXDefaultOptions = () => {
  return {
    id: '',
    debugMode: false,
    enabled: true,
    logFirstHit: true,
    sampleRate: 10,
    translateBool: true,
    autoStartOnNav: true
  }
}

declare global {
  interface Window {
    LUX: any
  }
}

export class LUX {
  private ignoreLUX: boolean = false
  private firstHit: boolean | null = null
  private label: string = '' // undocumented - we actually need to default/update the label ourselves for SPAs

  constructor (
    private options: LUXOptions
  ) {
  }

  private get checkLUXIsPresent (): boolean {
    if (process.server || !window || !window.LUX || this.ignoreLUX) {
      return false
    }

    return true
  }

  init () {
    if (!this.checkLUXIsPresent || (this.firstHit && this.options.logFirstHit)) {
      return
    }

    window.LUX.init()
    this.label = ''
  }

  pageLoaded () {
    if (!this.checkLUXIsPresent || (this.firstHit && this.options.logFirstHit)) {
      return
    }

    setTimeout(() => {
      window.LUX.label = this.label || document.title
      window.LUX.send()
    }, 20)
  }

  setLabel (newLabel: string) {
    if (!this.checkLUXIsPresent || !newLabel) {
      return
    }
    this.label = newLabel
  }

  addData (name: string, value: string) {
    if (!this.checkLUXIsPresent || !name || typeof value === 'undefined') {
      return
    }

    value = value.toString()

    if (this.options.translateBool) {
      if (value === 'true') {
        value = 'Yes'
      } else if (value === 'false') {
        value = 'No'
      }
    }

    window.LUX.addData(name, value)
  }

  // must be called before send()
  mark (name: string) {
    if (!this.checkLUXIsPresent || !name) {
      return
    }
    window.LUX.mark(name)
  }

  markLoadTime () {
    if (!this.checkLUXIsPresent) {
      return
    }

    window.LUX.markLoadTime()
  }

  // must be called before send()
  measure (name: string, startMark: string, endMark: string) {
    if (!this.checkLUXIsPresent || !name || !startMark || !endMark) {
      return
    }
    window.LUX.measure(name, startMark, endMark)
  }

  // exposed in ctx so it can be overridden
  routerBeforeEach (to: Route, from: Route) {
    // Clean up and stop ignoring.  Leave this before the 'to' below just in case the user pushes to another route that's ignored too.
    if (from.params.ignoreLUX) {
      delete from.params.ignoreLUX
      this.ignoreLUX = false
    }

    // Sometimes users are just updating the url based on the current state (and not via query strings).
    // Allow a param that they can use to ignore all calls until the next nav.
    // This allows users to keep all of their existing triggers/calls and not have to worry about manually disabling them under these conditions.
    if (to.params.ignoreLUX) {
      this.ignoreLUX = true
    }

    // trigger the start timer for the user if they are going somewhere different.  No, this will not trigger for query string changes.
    if (this.options.autoStartOnNav && !this.isFirstHit && to.path !== from.path && !this.ignoreLUX) {
      this.init()
    }
  }

  get isFirstHit (): boolean | null {
    return this.firstHit
  }

  set isFirstHit (value: boolean | null) {
    this.firstHit = value
  }
}
