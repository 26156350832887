












import { defineComponent, computed } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import BrandsLogoItem from '~/components/Header/MainMenu/Brands/BrandsLogoItem.vue'
import { menuCategoryBrands } from '~/src/Infrastructure/MenuCategoryBrands/MenuCategoryBrands'
import { BrandItem } from '~/src/Model/MenuCategoryBrands/MenuCategoryBrand'

export default defineComponent({
  components: { BrandsLogoItem },
  props: {
    nodeId: {
      required: true,
      type: Number
    }
  },
  setup (props) {
    const $webConfig = useWebConfig()

    const brands = computed<BrandItem[]>(() => {
      const category = menuCategoryBrands.find(brand => brand.category === props.nodeId)

      if (!category) {
        return []
      }

      if ($webConfig.isNay) {
        return category.nay
      }

      return category.ew
    })

    return {
      brands
    }
  }
})
