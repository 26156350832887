
















































































































import { computed, defineComponent, onMounted, ref, watch } from '@nuxtjs/composition-api'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import WishListsPanel from '~/components/WishLists/WishListsPanel.vue'
import UserBoxSidebar from '~/components/Header/UserBox/UserBoxSidebar.vue'
import { useProductComparisonStore } from '~/src/Infrastructure/ProductComparison/ProductComparisonStore'
import { useWishListsStore } from '~/src/Infrastructure/WishLists/WishListsStore'
import { SelectedWishlist, WishList } from '~/src/Model/WishLists/WishLists'
import { useWishListItemsFetch } from '~/src/Infrastructure/WishLists/WishListItemsFetch'
import { useBasketInsertProducts } from '~/src/Infrastructure/Basket/BasketInsert'
import WishListBoxMessageLink from '~/components/Header/WishlistBox/WishListBoxMessageLink.vue'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Dropdown from '~/components/DesignSystem/Dropdown/Dropdown.vue'
import DropdownItem from '~/components/DesignSystem/Dropdown/DropdownItem.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

enum FlashMessage {
  basket = 1,
  remove,
  compare
}

export default defineComponent({
  components: {
    Sidebar,
    WishListsPanel,
    UserBoxSidebar,
    WishListBoxMessageLink,
    Row,
    Column,
    Button,
    Dropdown,
    DropdownItem,
    Alert,
    PageSectionContent
  },
  emits: ['hidden'],
  setup (_props, { emit }) {
    const { authenticatedUser } = useAuthenticatedUser()
    const productComparisonStore = useProductComparisonStore()
    const { basketInsertProducts, basketInsertProductsPending } = useBasketInsertProducts()
    const wishListsStore = useWishListsStore()
    const wishLists = wishListsStore.wishLists
    const displayedFlashMessage = ref<FlashMessage | null>(null)
    const showUserBoxSidebar = ref<boolean>(false)
    const showWishlistSidebar = ref<boolean>(false)

    const wishListsOptions = computed<SelectedWishlist[]>(() => {
      if (!wishLists.value) {
        return []
      }

      return sortBy(wishLists.value, wl => !wl.lastEdited).map((w) => {
        return {
          text: `${w.name} (${w.items.length})`,
          value: w.hash
        }
      })
    })

    const selectedWishListObject = computed<WishList | null>(() => {
      return wishLists.value?.find(w => w.hash === selectedWishList.value?.value) ?? null
    })

    const wishListProductsIds = computed<number[]>(() => wishListDetail.value?.items.map(item => item.productBox.product.id) ?? [])

    const selectedWishList = ref<SelectedWishlist | null>(wishListsOptions.value?.[0] ?? null)

    const hasSelectedWishlistProducts = computed(() => selectedWishListObject.value && selectedWishListObject.value.itemsCount > 0)

    onMounted(() => {
      showUserBoxSidebar.value = !authenticatedUser.value
      showWishlistSidebar.value = !showUserBoxSidebar.value
    })

    watch(wishListsOptions, () => {
      selectedWishList.value = wishListsOptions.value?.[0] ?? null
    })

    watch(selectedWishListObject, (newSelectedWishListObject, oldSelectedWishListObject) => {
      if (isEqual(newSelectedWishListObject, oldSelectedWishListObject)) {
        return
      }

      if (selectedWishListObject.value) {
        reloadItems(selectedWishListObject.value.hash)
      }
    })

    const { wishList: wishListDetail, reloadItems, fetchPending: wishListDetailPending } = useWishListItemsFetch(selectedWishListObject.value?.hash)

    const setSelectedWishList = (newlySelectedWishlist: SelectedWishlist) => {
      selectedWishList.value = newlySelectedWishlist
      displayedFlashMessage.value = null
    }

    const removeWishListItem = async (hash: string, wishListItemId: number) => {
      await wishListsStore.removeProduct(hash, wishListItemId)
      displayedFlashMessage.value = null
    }

    const addAllProductsToComparison = () => {
      productComparisonStore.addProducts(wishListProductsIds.value)
      displayedFlashMessage.value = FlashMessage.compare
    }

    const addAllProductsToBasket = () => {
      basketInsertProducts(wishListProductsIds.value)
      displayedFlashMessage.value = FlashMessage.basket
    }

    const removeAllProducts = async () => {
      if (!selectedWishListObject.value) {
        return
      }

      const itemsIds = wishListDetail.value?.items.map(item => item.id) ?? []
      if (itemsIds.length !== 0) {
        await wishListsStore.removeItems({
          hash: selectedWishListObject.value.hash,
          itemsIds
        })
        displayedFlashMessage.value = FlashMessage.remove
      }
    }

    const closeUserSidebar = () => {
      showUserBoxSidebar.value = false
      if (!authenticatedUser.value) {
        emit('hidden')
      }
    }

    const wishlistSidebarHidden = () => {
      emit('hidden')
    }

    const userLogged = () => {
      showUserBoxSidebar.value = false
      showWishlistSidebar.value = true
    }

    return {
      showWishlistSidebar,
      closeUserSidebar,
      authenticatedUser,
      ActiveHeaderPanel,
      wishlistSidebarHidden,
      addAllProductsToComparison,
      wishLists,
      setSelectedWishList,
      removeWishListItem,
      selectedWishList,
      selectedWishListObject,
      wishListDetailPending,
      wishListsOptions,
      addAllProductsToBasket,
      basketInsertProductsPending,
      removeAllProducts,
      wishListDetail,
      displayedFlashMessage,
      FlashMessage,
      showUserBoxSidebar,
      userLogged,
      hasSelectedWishlistProducts
    }
  }
})
