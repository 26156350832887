





































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { BreadCrumb } from '~/src/Model/BreadCrumb/BreadCrumb'
import Popover from '~/components/DesignSystem/Popover/Popover.vue'
import BreadCrumbBarItem from '~/components/BreadCrumb/BreadCrumbBarItem.vue'

export default defineComponent({
  components: { BreadCrumbBarItem, Popover },
  props: {
    popoverBreadCrumbs: {
      type: Array as PropType<BreadCrumb[]>,
      required: true
    }
  },
  setup () {
    const showPopover = ref<boolean>(false)

    return {
      showPopover
    }
  }
})
