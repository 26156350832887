import { Plugin } from '@nuxt/types'
import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { PageType, PageTypeEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/PageType/PageTypeEvent'

const pageTypePlugin: Plugin = ({ app, route }) => {
  const dataLayer = new DataLayer(app.$gtm)

  const pageTypeEvent = new PageTypeEvent(dataLayer)

  const pageType: PageType = route.meta?.reduce((pageType: PageType, meta: any) => meta.pageType || pageType, PageType.Other)

  pageTypeEvent.fire(pageType)
}

export default pageTypePlugin
