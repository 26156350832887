



















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { ButtonVariant } from '~/src/Model/Button/ButtonVariant'

export default defineComponent({
  inheritAttrs: false,
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modalClass: {
      type: [String, Array, Object],
      required: false,
      default: ''
    },
    dialogClass: {
      type: String,
      required: false,
      default: ''
    },
    noHeaderContent: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    },
    bodyClass: {
      type: [String, Array, Object],
      required: false,
      default: ''
    },
    footerClass: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    static: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false,
      required: false
    },
    hideHeader: {
      type: Boolean,
      default: false,
      required: false
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    cancelVariant: {
      type: String as PropType<ButtonVariant>,
      default: ButtonVariant.secondary
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    confirmVariant: {
      type: String as PropType<ButtonVariant>,
      default: ButtonVariant.primary
    },
    showBackwardsButton: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    noStacking: {
      type: Boolean,
      default: false
    },
    hideBackwardsButtonText: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'close-modal', 'confirm', 'cancel', 'shown', 'go-back'],
  setup () {
    const modal = ref<any>()

    const hide = () => {
      modal.value?.hide()
    }

    return {
      modal,
      hide
    }
  }
})
