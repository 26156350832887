





































import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api'
import { intervalToDuration, Duration } from 'date-fns'
import MinimalCountdownTracker from '~/components/Countdown/MinimalCountdownTracker.vue'
import CountdownTracker from '~/components/Countdown/CountdownTracker.vue'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'

enum Classes {
  inactive = 'inactive',
  active = 'active'
}

const DAY_IN_HOURS = 24

export default defineComponent({
  components: {
    MinimalCountdownTracker,
    CountdownTracker,
    Badge
  },
  props: {
    startDate: {
      required: true,
      type: String
    },
    endDate: {
      required: true,
      type: String
    },
    isBadge: {
      type: Boolean,
      default: false
    },
    isMinimal: {
      type: Boolean,
      default: false
    },
    badgeVariant: {
      type: String,
      default: 'raspberry'
    },
    badgeClass: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'p'
    },
    stayAtZero: {
      type: Boolean,
      default: false
    }
  },
  emits: ['countdown-finished'],
  setup (props, { emit }) {
    const { i18n } = useTranslator()
    const dateFormatter = useDateFormatter()
    const startDate = computed<Date>(() => {
      return dateFormatter.getValue(props.startDate)
    })
    const endDate = computed<Date>(() => {
      return dateFormatter.getValue(props.endDate)
    })

    const currentTime = ref<Date>(new Date())
    const refreshCurrentTime = () => {
      if (props.stayAtZero && isCountdownOver.value) {
        return
      }
      currentTime.value = new Date()
    }
    setInterval(refreshCurrentTime, 1000)

    const hasExpired = computed<boolean>(() => {
      return endDate.value < currentTime.value
    })

    const countdownClass = computed<Classes>(() => {
      return duration.value.hours && duration.value.hours < DAY_IN_HOURS ? Classes.inactive : Classes.active
    })

    const isActive = computed<boolean>(() => {
      return startDate.value < currentTime.value && currentTime.value < endDate.value
    })

    const countdownMessage = computed<string>(() => {
      if (hasExpired.value) {
        return i18n.t('countdown.expired').toString()
      }
      return isActive.value ? i18n.t('countdown.remaining').toString() : i18n.t('countdown.starts_from').toString()
    })

    const duration = computed<Duration>(() => {
      const currentTimeValue = currentTime.value as Date
      return isActive.value || hasExpired.value ? intervalToDuration({ start: currentTimeValue, end: endDate.value }) : intervalToDuration({ start: currentTimeValue, end: startDate.value })
    })

    const trackers = computed<string[]>(() => {
      if (!duration.value) {
        return []
      }

      if (duration.value.years) {
        return ['years', 'months']
      } else if (duration.value.months) {
        return ['months', 'days']
      } else if (duration.value.days) {
        return ['days', 'hours']
      } else if (duration.value.hours) {
        return ['hours', 'minutes']
      }

      return ['minutes', 'seconds']
    })

    const isCountdownOver = computed<boolean>(() => {
      if (!trackers.value.includes('minutes') && !trackers.value.includes('seconds')) {
        return false
      }

      if (duration.value.seconds === undefined) {
        return false
      }

      return duration.value.minutes === 0 && duration.value.seconds < 1
    })

    watch(isCountdownOver, () => {
      if (isCountdownOver.value) {
        emit('countdown-finished')
      }
    })

    return {
      countdownMessage,
      countdownClass,
      duration,
      trackers,
      isActive,
      hasExpired,
      isCountdownOver
    }
  }
})
