import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export enum Browser {
  chromeMobileWebView = 'Chrome Mobile WebView'
}

export enum OSVersion {
  android10 = 'Android 10'
}

export enum Device {
  pixel = 'Pixel'
}

export enum DeviceFamily {
  spider = 'Spider',
  k = 'K'
}

export interface Environment {
  browser?: Browser | RegExp
  osVersion?: OSVersion
  device?: Device
  deviceFamily?: DeviceFamily
}

export class EventEnvironment extends IgnoredEvent {
  constructor (private value: Environment) {
    super()
  }

  isIgnored (event: Event) {
    if (this.value.browser instanceof RegExp && event.contexts?.browser?.name && !this.value.browser.test(event.contexts?.browser?.name as string)) {
      return false
    }

    if (this.value.browser && this.value.browser !== event.contexts?.browser?.name) {
      return false
    }

    if (this.value.osVersion && this.value.osVersion !== `${event.contexts?.os?.name} ${event.contexts?.os?.version}`) {
      return false
    }

    if (this.value.deviceFamily && this.value.deviceFamily !== event.contexts?.device?.family) {
      return false
    }

    return !(this.value.device && this.value.device !== event.contexts?.device?.name)
  }
}
