import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export class FormSentEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (formName: string): void {
    this.dataLayer.push({
      event: 'form_submit',
      form_name: formName
    })
  }
}

export const useFormSentEvent = () => {
  const dataLayer = useDataLayer()

  return new FormSentEvent(dataLayer)
}
