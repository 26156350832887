
































































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import BasketBoxProductGift from '~/components/Header/BasketBox/BasketBoxProductGift.vue'
import { BasketProduct } from '~/src/Model/Basket/Product'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import BasketBoxProductService from '~/components/Header/BasketBox/BasketBoxProductService.vue'
import InputNumber from '~/components/DesignSystem/Input/InputNumber.vue'

export default defineComponent({
  components: {
    BasketBoxProductService,
    BasketBoxProductGift,
    ImageWithFallback,
    InputNumber
  },
  props: {
    basketProduct: {
      type: Object as PropType<BasketProduct>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-quantity', 'delete-product', 'delete-service'],
  setup (props, { emit }) {
    const showExtraServiceDetailModal = ref<Boolean>(false)
    const showExtraServicesListModal = ref<Boolean>(false)
    const changeQuantity = (newQuantity: number) => {
      emit('change-quantity', newQuantity)
    }

    const deleteProduct = () => {
      if (!props.disabled) {
        emit('delete-product')
      }
    }

    return {
      changeQuantity,
      showExtraServiceDetailModal,
      showExtraServicesListModal,
      deleteProduct
    }
  }
})
