
























import { defineComponent } from '@nuxtjs/composition-api'
import Tooltip from '~/components/DesignSystem/Tooltip/Tooltip.vue'

export default defineComponent({
  components: { Tooltip },
  props: {
    forId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    hint: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
})
