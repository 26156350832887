











import { defineComponent, computed } from '@nuxtjs/composition-api'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import InfoBoxLinks from '~/components/Header/InfoBox/InfoBoxLinks.vue'

export default defineComponent({
  components: { InfoBoxLinks },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['sidebar-toggled'],
  setup (props, { emit }) {
    const toggleSidebar = () => {
      emit('sidebar-toggled', ActiveHeaderPanel.infoBox)
    }

    const sidebarOpened = computed<boolean>(() => props.activePanel === ActiveHeaderPanel.infoBox)

    return {
      sidebarOpened,
      toggleSidebar
    }
  }
})
