export enum AlertVariant {
  primary = 'info',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  tip = 'tip',
  successAlternative = 'success-alternative',
  warningAlternative = 'warning-alternative',
  dangerAlternative = 'danger-alternative',
  reverseCharge = 'reverse-charge'
}

export const AlertVariantIcon: { [key in AlertVariant]: string } = {
  [AlertVariant.primary]: 'icon-info',
  [AlertVariant.info]: 'icon-info',
  [AlertVariant.success]: 'icon-check',
  [AlertVariant.warning]: 'icon-warning',
  [AlertVariant.danger]: 'icon-error',
  [AlertVariant.tip]: 'icon-star-fill',
  [AlertVariant.successAlternative]: 'icon-check',
  [AlertVariant.warningAlternative]: 'icon-warning',
  [AlertVariant.dangerAlternative]: 'icon-error',
  [AlertVariant.reverseCharge]: 'icon-wallet'
}
