export class OldFilterDetector {
  private readonly sortQuery = 'sort'
  private readonly scrapQuery = 'scrap'
  private readonly freeDeliveryQuery = 'ft'
  private readonly clearanceQuery = 'c'
  private readonly cashbackQuery = 'cashback'
  private readonly actionProductQuery = 'a'

  detect (query: { [key: string]: string | (string | null)[] }): boolean {
    if (this.sortQuery in query) {
      return true
    }

    if (this.scrapQuery in query) {
      return true
    }

    if (this.freeDeliveryQuery in query) {
      return true
    }

    if (this.clearanceQuery in query) {
      return true
    }

    if (this.cashbackQuery in query) {
      return true
    }

    if (this.actionProductQuery in query) {
      return true
    }

    const textWithKeys = Object.keys(query).join(';')
    const regex = /((p\d+)|rating|price)\[\d+\]/s

    if (regex.test(textWithKeys)) {
      return true
    }

    return false
  }
}
