export const htmlBlockToInline = (value: string) => {
  const tags = [
    'address',
    'article',
    'aside',
    'blockquote',
    'canvas',
    'dd',
    'div',
    'dl',
    'dt',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'li',
    'main',
    'nav',
    'noscript',
    'ol',
    'p',
    'pre',
    'section',
    'table',
    'tfoot',
    'ul',
    'video'
  ]
  const newTag = 'span'
  let updatedValue = value

  tags.forEach((tag) => {
    updatedValue = updatedValue.replaceAll('<' + tag, '<' + newTag).replaceAll('</' + tag, '</' + newTag)
  })

  return updatedValue
}
