












































































































import { defineComponent, ref, watch, computed, PropType } from '@nuxtjs/composition-api'
import { ImageGalleryItem, GalleryItemValue } from '~/src/Model/ProductDetail/ProductDetailGallery'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { Image } from '~/src/Model/Image/Image'
import { useSrcset } from '~/src/Infrastructure/Image/Srcset'
import NoImage from '~/components/Image/NoImage.vue'
import GalleryModal from '~/components/Gallery/GalleryModal.vue'
import { useGalleryHelpers } from '~/src/Infrastructure/Gallery/GalleryHelpers'

export default defineComponent({
  components: { ImageWithFallback, NoImage, GalleryModal },
  props: {
    galleryItems: {
      type: Array as PropType<GalleryItemValue[]>,
      required: true
    },
    productGallery: {
      type: Boolean,
      default: false
    },
    hasEnergyLabel: {
      type: Boolean,
      default: false
    },
    hasBrandLogo: {
      type: Boolean,
      default: false
    },
    highlightedImageSize: {
      type: String,
      default: 'product-w510h463'
    },
    highlightedImageSrcset: {
      type: String,
      default: 'productGallery'
    },
    highlightedImageSizes: {
      type: String,
      default: null
    },
    hideMosaic: {
      type: Boolean,
      default: false
    },
    disableModal: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { isVideo } = useGalleryHelpers()

    const maxThumbnails = computed<number>(() => {
      let maxThumbs = 6
      if (props.hasEnergyLabel) {
        maxThumbs -= 1
      }
      if (props.hasBrandLogo) {
        maxThumbs -= 1
      }
      return maxThumbs
    })

    const items = ref<GalleryItemValue[]>(props.galleryItems)
    const selectedImage = ref<Image | null>(null)
    const showGalleryModal = ref<boolean>(false)
    const selectedSlide = ref<number>(items.value.filter(i => isVideo(i.type)).length)
    const hasItems = computed<boolean>(() => items.value.length > 0)
    const isFirstImage = ref<boolean>(true)

    const srcSet = useSrcset()

    watch(() => props.galleryItems, () => {
      items.value = props.galleryItems
    })

    watch(items, () => {
      if (!items.value || !items.value.length) {
        return
      }

      selectedImage.value = isVideo(items.value[0].type) ? (items.value[1] as ImageGalleryItem).imageData : (items.value[0] as ImageGalleryItem).imageData
    })

    const highlightedImage = computed<Image | null>(() => {
      if (selectedImage.value) {
        return selectedImage.value
      }

      if (!items.value[0]) {
        return null
      }

      if (isVideo(items.value[0].type)) {
        return items.value[1] ? (items.value[1] as ImageGalleryItem).imageData : null
      }

      return (items.value[0] as ImageGalleryItem).imageData
    })

    const changeSelectedImage = (index: number): void => {
      selectedImage.value = (items.value[index] as ImageGalleryItem).imageData
      selectedSlide.value = index
      isFirstImage.value = false
    }

    const playVideo = (): void => {
      selectedSlide.value = 0
      showGalleryModal.value = true
    }

    const createImageURL = (image: Image): string => {
      return srcSet.createURL(image.id, image.extension, 'product-w510h463')
    }

    const showMore = () => {
      selectedSlide.value = maxThumbnails.value - 1
      showGalleryModal.value = true
    }

    return {
      maxThumbnails,
      items,
      hasItems,
      isVideo,
      playVideo,
      selectedImage,
      showGalleryModal,
      selectedSlide,
      changeSelectedImage,
      highlightedImage,
      createImageURL,
      showMore,
      isFirstImage
    }
  }
})
