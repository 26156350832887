

























































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { isAfter } from 'date-fns'
import { ChristmasDeliveryConfigurationItem } from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import PickupPointIcon from '~/components/PickupPoint/PickupPointIcon.vue'

const PERSONAL_STORE = 'DEL_PERSONAL_PRODEJNA'
const ECONOMY = 'VIRTUAL_ECONOMIC_DELIVERY_PARENT'

export default defineComponent({
  components: { PickupPointIcon },
  props: {
    deadline: {
      required: true,
      type: Date
    },
    today: {
      required: true,
      type: Date
    },
    christmasDeliveryConfigItem: {
      required: true,
      type: Object as PropType<ChristmasDeliveryConfigurationItem>
    },
    finalDay: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const disabled = computed<boolean>(() => isAfter(props.today, props.deadline))

    return {
      disabled,
      PERSONAL_STORE,
      ECONOMY
    }
  }
})
