import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Route } from 'vue-router/types/router'
import { useAclRepositoryWithAxios } from '~/src/Infrastructure/Acl/AclRepository'
import { AclPermissionRequest, AclPermissionResponse, AclPrivilege } from '~/src/Model/Acl/AclPermission'
import { Web } from '~/src/Model/Config/Web'

export const checkPagePermission = (route: Route, $axios: NuxtAxiosInstance, currentWeb: Web): Promise<AclPermissionResponse> | void => {
  const { evaluatePermission } = useAclRepositoryWithAxios($axios)

  const acl = route.meta?.reduce((acl: any, meta: any) => meta.acl || acl, null)
  const resource = acl?.resource ?? null
  const web: Web | null = acl?.web ?? null

  if (!resource) {
    return
  }

  if (web && currentWeb !== web) {
    return allowImmediately()
  }

  const privilege: AclPrivilege = route.meta?.acl?.privilege ?? AclPrivilege.read

  const authorizeRequest: AclPermissionRequest = {
    resource,
    privilege
  }

  return evaluatePermission(authorizeRequest)
}

const allowImmediately = (): Promise<AclPermissionResponse> => {
  return Promise.resolve<AclPermissionResponse>({
    isAllowed: true,
    message: null
  })
}
