import { NuxtCookies } from 'cookie-universal-nuxt'

export class BasketHashCookieStorage {
  constructor (
    private cookies: NuxtCookies,
    private cookieExpirationInMiliseconds = 30 * 24 * 60 * 60 * 1000
  ) {}

  public get basketHashCookie (): string | null {
    return this.cookies.get('basketHash')?.toString() ?? null
  }

  public removeBasketHashCookie (): void {
    if (this.cookies.get('basketHash')) {
      this.cookies.remove('basketHash')
    }
  }

  public setBasketHashCookie (basketHash: string): void {
    this.cookies.set('basketHash', basketHash, { expires: new Date(Date.now() + this.cookieExpirationInMiliseconds) })
  }

  public refreshBasketHashCookieExpiration (): void {
    const basketHash = this.basketHashCookie
    if (basketHash) {
      this.setBasketHashCookie(basketHash)
    }
  }
}
