import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'

export enum MigrationAccountType {
  migratedAccount = 'migrated_account',
  migratedAccountWithOrders = 'migrated_account_with_orders',
  migratedCustomerAccountWithOrders = 'migrated_customer_account_with_orders',
  nonMigratedAccount = 'non_migrated_account',
  noAccount = 'no_account',
}

export enum OnboardingStep {
  info,
  emailCheck,
  userAccountAction,
  userInfo,
  loyaltyClubSignUp,
  final
}

export interface OnboardingCheckedCustomerEvent {
  checkedCustomer: CheckCustomerResponse
  email: string
}
