import { computed } from '@nuxtjs/composition-api'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import { useProductComparisonStore as useStore } from '~/stores/productComparison'

export const useProductComparisonStore = () => {
  const store = useStore()

  const products = computed<number[]>(() => store.products)
  const history = computed<number[]>(() => store.history)
  const productBoxes = computed<ProductBox[]>(() => store.productBoxes)
  const lastComparedProduct = computed<number>(() => store.lastComparedProduct)
  const isInComparison = (productId: number) => computed<boolean>(() => store.isInComparison(productId))

  return {
    addProduct: store.addProduct,
    addProducts: store.addProducts,
    removeProduct: store.removeProduct,
    toggleProduct: store.toggleProduct,
    loadProductBoxes: store.loadProductBoxes,
    isInComparison,
    products,
    history,
    productBoxes,
    lastComparedProduct
  }
}
