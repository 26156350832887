import {
  ChristmasDeliveryBadgeColor,
  ChristmasDeliveryBadgeConfig,
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'

export const useChristmasDeliveryBadgeConfig = () => {
  const dateFormatter = useDateFormatter()
  const { i18n } = useTranslator()

  const isAfterDeadline = (configItem: ChristmasDeliveryConfigurationItem) => {
    return new Date() > dateFormatter.getValue(configItem.deadline)
  }

  const createBadgeConfig = (configItem: ChristmasDeliveryConfigurationItem): ChristmasDeliveryBadgeConfig => {
    if (isAfterDeadline(configItem)) {
      return {
        color: ChristmasDeliveryBadgeColor.RED,
        text: i18n.t('order.delivery.christmas_delivery.after_christmas') as string,
        shortText: i18n.t('order.delivery.christmas_delivery.after_christmas') as string
      }
    }

    return {
      color: ChristmasDeliveryBadgeColor.GREEN,
      text: i18n.t('order.delivery.christmas_delivery.before_christmas') as string,
      shortText: i18n.t('order.delivery.christmas_delivery.before_christmas') as string
    }
  }

  const createAnnouncementBadgeConfig = (configItem: ChristmasDeliveryConfigurationItem): ChristmasDeliveryBadgeConfig => {
    if (isAfterDeadline(configItem)) {
      return {
        color: ChristmasDeliveryBadgeColor.RED,
        text: configItem.name,
        shortText: configItem.shortName
      }
    }

    return {
      color: ChristmasDeliveryBadgeColor.GREEN,
      text: configItem.name,
      shortText: configItem.shortName
    }
  }

  return {
    createBadgeConfig,
    createAnnouncementBadgeConfig,
    isAfterDeadline
  }
}
