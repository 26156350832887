import { computed } from '@nuxtjs/composition-api'
import { PdpBar, PdpBarContext } from '~/src/Model/PdpBar/PdpBar'
import { usePdpBarStore as useStore } from '~/stores/pdpBar'

export const usePdpBarStore = () => {
  const store = useStore()

  const pdpBar = computed<PdpBar>(() => store.pdpBar)
  const pdpBarContext = computed<PdpBarContext | null>(() => store.context)

  return {
    setContextWarehouse: store.setContextWarehouse,
    setContextOrder: store.setContextOrder,
    resetContext: store.resetContext,
    loadPdpBar: store.loadPdpBar,
    pdpBar,
    pdpBarContext
  }
}
