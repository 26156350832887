export enum ObjectFit {
  'contain' = 'contain',
  'cover' = 'cover',
  'none' = 'none',
  'scaleDown' = 'scaleDown'
}

export interface ImageConfiguration {
  width: number
  height: number
  fixedRatio: boolean
  retina: boolean
  objectFit: ObjectFit
  fileType?: string
}
