



























import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import ProductSpecialState from '~/components/ProductDetail/ProductSpecialStates/ProductSpecialState.vue'
import Watchdog from '~/components/Watchdog/Watchdog.vue'
import { useWatchDogsStore } from '~/src/Infrastructure/WatchDogs/WatchDogsStore'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    Watchdog,
    ProductSpecialState,
    Button
  },
  props: {
    productId: {
      required: true,
      type: Number
    },
    showCta: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const processWatchDog = ref<boolean>(false)
    const { authenticatedUser } = useAuthenticatedUser()

    const watchDogsStore = useWatchDogsStore()
    const watchDog = watchDogsStore.productWatchDog(props.productId)

    onMounted(() => {
      if (!watchDog.value && authenticatedUser.value) {
        watchDogsStore.loadForProduct(props.productId)
      }
    })

    return {
      processWatchDog,
      watchDog
    }
  }
})
