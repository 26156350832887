





































import { defineComponent } from '@nuxtjs/composition-api'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'

export default defineComponent({
  components: { CurrentWeb }
})
