









































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { Gift, ProductGiftOffer } from '~/src/Model/ProductGift/ProductGift'
import ProductGiftItem from '~/components/ProductDetail/ProductGifts/ProductGiftItem.vue'
import FormRadio from '~/components/DesignSystem/Input/FormRadio.vue'
import CollapsableElement from '~/components/DesignSystem/Collapse/CollapsableElement.vue'

const DEFAULT_VISIBLE_LIMIT = 3

export default defineComponent({
  components: {
    ProductGiftItem,
    FormRadio,
    CollapsableElement
  },
  props: {
    productGiftOffer: {
      required: true,
      type: Object as PropType<ProductGiftOffer>
    },
    value: {
      required: true,
      type: Object as PropType<Gift>
    },
    inputName: {
      type: String,
      default: 'giftOffer'
    },
    allowModal: {
      type: Boolean,
      default: true
    },
    forceExpanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selected-gift'],
  setup (props, { emit }) {
    const selectedGiftId = computed<number>({
      get: () => props.value.product.id,
      set: (selectedGiftId: number) => {
        const selectedGift = props.productGiftOffer.items.find(item => item.product.id === selectedGiftId)
        emit('update:selected-gift', selectedGift ?? props.productGiftOffer.items[0])
      }
    })

    const defaultVisibleItems = computed<Gift[]>(() => {
      if (props.forceExpanded) {
        return props.productGiftOffer.items
      }
      return props.productGiftOffer.items.slice(0, DEFAULT_VISIBLE_LIMIT)
    })

    const defaultHiddenItems = computed<Gift[]>(() => {
      if (props.forceExpanded) {
        return []
      }
      return props.productGiftOffer.items.slice(DEFAULT_VISIBLE_LIMIT)
    })

    return {
      selectedGiftId,
      defaultVisibleItems,
      defaultHiddenItems,
      DEFAULT_VISIBLE_LIMIT
    }
  }
})
