import { AxiosResponse } from 'axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { CustomerRegistrationData, CustomerRegistrationResponse } from '~/src/Model/Customer/CustomerRegistrationData'
import { PasswordChangeRequest, PasswordChangeResponse } from '~/src/Model/PasswordChange/PasswordChange'
import { EmailChangeRequest } from '~/src/Model/EmailChange/EmailChange'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { AppleLoginMergeRequest, AppleLoginRequest } from '~/src/Model/Auth/Apple/Apple'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { RecoveryEmailRequest } from '~/src/Model/RecoveryEmail/RecoveryEmail'
import { TwoFAChallengeRequest, TwoFARequired } from '~/src/Model/Auth/TwoFA'
import { ReAuthReason, ReAuthResponse, ReAuthTokenResponse } from '~/src/Model/Auth/ReAuthReason'
import { CustomerLoginData } from '~/src/Model/Customer/CustomerLoginData'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'

export const useCustomerAuthRepository = () => {
  const { $axios } = useAxios()

  return useCustomerAuthRepositoryWithAxios($axios)
}

export const useCustomerAuthRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    login: (loginData: CustomerLoginData): Promise<AuthTokens | TwoFARequired> => {
      return $axios.$post<AuthTokens | TwoFARequired>('/auth/login', loginData)
    },
    revoke: (refreshToken: string): Promise<void> => {
      return $axios.$post<void>('/auth/revoke', { refreshToken })
    },
    refresh: (refreshToken: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/refresh', { refreshToken })
    },
    getUser: (): Promise<AuthenticatedUser> => {
      return $axios.$get<AuthenticatedUser>('/auth/user')
    },
    registerCustomer: (customerData: CustomerRegistrationData): Promise<CustomerRegistrationResponse> => {
      return $axios.$post<CustomerRegistrationResponse>('/auth/sign-up', { ...customerData })
    },
    sendRecoveryEmail: (recoveryEmailRequest: RecoveryEmailRequest): Promise<AxiosResponse> => {
      return $axios.$post('/auth/send-recovery-email', recoveryEmailRequest)
    },
    changePassword: (passwordChangeRequest: PasswordChangeRequest): Promise<PasswordChangeResponse> => {
      return $axios.$post<PasswordChangeResponse>('/auth/change-password', passwordChangeRequest)
    },
    recoverPassword: (token: string, newPassword: string): Promise<AxiosResponse> => {
      return $axios.$post('/auth/recover-password', {
        token,
        newPassword
      })
    },
    changeEmail: (emailChangeRequest: EmailChangeRequest): Promise<void> => {
      return $axios.$post<void>('/auth/change-email', emailChangeRequest)
    },
    googleLogin: (idToken: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/google-login', { idToken })
    },
    googleLoginMerge: (idToken: string, password: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/google-login-merge', { idToken, password })
    },
    facebookLogin: (idToken: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/facebook-login', { idToken })
    },
    facebookLoginMerge: (idToken: string, password: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/facebook-login-merge', { idToken, password })
    },
    appleLogin: (appleLoginRequest: AppleLoginRequest): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/apple-login', appleLoginRequest)
    },
    appleLoginMerge: (appleLoginMergeRequest: AppleLoginMergeRequest): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/apple-login-merge', appleLoginMergeRequest)
    },
    registerCustomerFromOrder: (orderToken: string, password: string): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/sign-up-from-order', {
        orderToken,
        password
      })
    },
    facebookDisconnect: (): Promise<void> => {
      return $axios.$get<void>('/auth/disconnect-facebook')
    },
    googleDisconnect: (): Promise<void> => {
      return $axios.$get<void>('/auth/disconnect-google')
    },
    challengeTwoFA: (twoFAChallengeRequest: TwoFAChallengeRequest): Promise<AuthTokens> => {
      return $axios.$post<AuthTokens>('/auth/twofa-challenge', twoFAChallengeRequest)
    },
    reAuth: (password: string, reason: ReAuthReason): Promise<ReAuthResponse> => {
      return $axios.$post<ReAuthResponse>('/auth/re-auth', { password, reason })
    },
    reAuthTwoFA: (twoFAChallengeRequest: TwoFAChallengeRequest): Promise<ReAuthTokenResponse> => {
      return $axios.$post<ReAuthTokenResponse>('/auth/re-auth-verify', twoFAChallengeRequest)
    }
  }
}
