








import { defineComponent } from '@nuxtjs/composition-api'
import { FinancialService, TechnicalService } from '~/src/Model/ProductServices/ProductServices'

interface PropTypes {
  service: FinancialService | TechnicalService
}

export default defineComponent<PropTypes>({
  name: 'ProductServiceModalContent',
  props: {
    service: {
      required: true,
      type: Object
    }
  }
})
