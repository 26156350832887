
























import { defineComponent, computed } from '@nuxtjs/composition-api'
import shuffle from 'lodash/shuffle'
import { useMatchMedia } from '~/src/Infrastructure/MatchMedia/MatchMedia'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import TopNode from '~/components/ServicesCrossroad/TopNode.vue'
import { CrossroadNode } from '~/src/Model/ServicesCrossroad/ServicesCrossroad'
import { useServicesCrossroad } from '~/src/Infrastructure/ServicesCrossroad/useServicesCrossroad'

interface SliderGroup {
  nodes: CrossroadNode[]
}

export default defineComponent({
  components: { Container, TopNode },
  setup () {
    const { services } = useServicesCrossroad()

    const { matches: isMobileActive } = useMatchMedia('(max-width: 767px)')
    const { matches: isTabletActive } = useMatchMedia('(min-width: 768px) and (max-width: 991px)')

    const serviceGroups = computed<SliderGroup[]>(() => {
      const shuffledServices = shuffle(services)
      const groups: SliderGroup[] = []
      for (let slideIndex = 0; slideIndex < slidesCount.value; slideIndex++) {
        const groupNodes: CrossroadNode[] = []

        for (let serviceInSlideIndex = 0; serviceInSlideIndex < visibleCount.value; serviceInSlideIndex++) {
          if (!shuffledServices[(slideIndex) * visibleCount.value + serviceInSlideIndex]) {
            continue
          }
          groupNodes.push(shuffledServices[(slideIndex) * visibleCount.value + serviceInSlideIndex])
        }

        if (groupNodes.length === 0) {
          continue
        }
        groups.push({ nodes: groupNodes })
      }

      return groups
    })

    const visibleCount = computed<number>(() => {
      if (isMobileActive.value) {
        return 1
      }
      if (isTabletActive.value) {
        return 2
      }
      return 3
    })

    const slidesCount = computed<number>(() => {
      return Math.ceil(services.length / visibleCount.value)
    })

    return {
      serviceGroups,
      visibleCount,
      slidesCount,
      isMobileActive,
      isTabletActive
    }
  }
})
