






























import { computed, defineComponent } from '@nuxtjs/composition-api'
import { AlertVariant, AlertVariantIcon } from '~/src/Model/Alert/AlertVariant'

export default defineComponent({
  props: {
    info: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    tip: {
      type: Boolean,
      default: false
    },
    successAlternative: {
      type: Boolean,
      default: false
    },
    warningAlternative: {
      type: Boolean,
      default: false
    },
    dangerAlternative: {
      type: Boolean,
      default: false
    },
    reverseCharge: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: null
    },
    iconCustomClass: {
      type: String,
      default: null
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    flexAlign: {
      type: String,
      default: 'align-items-start'
    },
    arrowBottom: {
      type: Boolean,
      default: false
    },
    arrowPosition: {
      type: String,
      default: null
    }
  },

  setup (props) {
    const variant = computed<AlertVariant>(() => {
      if (props.info) {
        return AlertVariant.info
      }
      if (props.success) {
        return AlertVariant.success
      }
      if (props.warning) {
        return AlertVariant.warning
      }
      if (props.danger) {
        return AlertVariant.danger
      }
      if (props.tip) {
        return AlertVariant.tip
      }
      if (props.successAlternative) {
        return AlertVariant.successAlternative
      }
      if (props.warningAlternative) {
        return AlertVariant.warningAlternative
      }
      if (props.dangerAlternative) {
        return AlertVariant.dangerAlternative
      }
      if (props.reverseCharge) {
        return AlertVariant.reverseCharge
      }

      return AlertVariant.primary
    })

    const variantIcon = computed<string>(() => props.iconClass ?? AlertVariantIcon[variant.value])

    return {
      variant,
      variantIcon
    }
  }
})
