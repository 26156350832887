

































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import {
  BasketGift,
  Gift,
  GiftChoiceType, NewSelectedBasketGift,
  PreselectedBasketGift
} from '~/src/Model/ProductGift/ProductGift'
import PreBasketGiftItem from '~/components/Basket/PreBasket/PreBasketGifts/PreBasketGiftItem.vue'
import PreBasketGiftOfferWithChoice from '~/components/Basket/PreBasket/PreBasketGifts/PreBasketGiftOfferWithChoice.vue'
import { useProductGiftsFetch } from '~/src/Infrastructure/ProductGifts/ProductGiftsFetch'

export default defineComponent({
  components: { PreBasketGiftOfferWithChoice, PreBasketGiftItem },
  props: {
    productId: {
      required: true,
      type: Number
    },
    selectedGifts: {
      required: true,
      type: Array as PropType<BasketGift[]>
    }
  },
  emits: ['update:selected-gifts'],
  setup (props, { emit }) {
    const editedPromotions = ref<number[]>([])
    const preselectedGifts = ref<PreselectedBasketGift[]>([])

    const { giftOffers, onGiftOffersLoaded } = useProductGiftsFetch(props.productId)

    onGiftOffersLoaded.subscribe(() => {
      preselectedGifts.value = resolvePreselectedGifts()
    })

    const resolvePreselectedGifts = (): PreselectedBasketGift[] => {
      const gifts: PreselectedBasketGift[] = []

      giftOffers.value.forEach((offer) => {
        const selectedGifts = props.selectedGifts.filter(selectedGift => selectedGift.promotionId === offer.id)
        selectedGifts.forEach((selectedGift) => {
          const foundItem = offer.items.find(item => item.product.id === selectedGift.product.id)
          if (foundItem) {
            gifts.push({
              basketGiftId: selectedGift.id,
              promotionId: selectedGift.promotionId,
              gift: foundItem,
              choiceType: selectedGift.choiceConfig.type
            })
          }
        })
      })

      return gifts
    }

    const getGiftOfferByPromotionId = (promotionId: number) => {
      return giftOffers.value.find(offer => offer.id === promotionId)
    }

    const isEdited = (promotionId: number) => {
      return editedPromotions.value.includes(promotionId)
    }

    const edit = (promotionId: number) => {
      editedPromotions.value.push(promotionId)
    }

    const getSelectedGift = (promotionId: number) => {
      const giftOffer = getGiftOfferByPromotionId(promotionId)
      if (giftOffer) {
        return giftOffer.items.find((item) => {
          return props.selectedGifts.find(gift => gift.product.id === item.product.id && gift.promotionId === promotionId) ?? null
        }) ?? giftOffer.items[0]
      }
    }

    const updateSelectedGifts = (promotionId: number, gift: Gift) => {
      const newSelectedGifts: NewSelectedBasketGift[] = [
        ...props.selectedGifts.filter(selectedGift => selectedGift.promotionId !== promotionId),
        {
          promotionId,
          product: gift.product,
          worthValue: gift.worthValue,
          choiceConfig: {
            type: GiftChoiceType.choice,
            count: 1
          }
        }
      ]

      emit('update:selected-gifts', newSelectedGifts)
    }

    return {
      giftOffers,
      preselectedGifts,
      editedPromotions,
      isEdited,
      edit,
      choiceTypes: GiftChoiceType,
      getGiftOfferByPromotionId,
      updateSelectedGifts,
      getSelectedGift
    }
  }
})
