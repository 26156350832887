import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { WatchDogs, WatchDog, SetWatchDogRequest, WatchDogDetailRequest } from '~/src/Model/WatchDogs/WatchDogs'
import { useAxiosWithCustomAuthorization } from '~/utils/Axios/AxiosWithCustomAuthorization'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useWatchDogsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadWatchDog: (watchDogParams: WatchDogDetailRequest, token?: string): Promise<WatchDogs> => {
      if (token) {
        $axios = useAxiosWithCustomAuthorization($axios, token)
      }

      return $axios.$get<WatchDogs>('/watch-dogs', { params: watchDogParams })
    },
    setWatchDog: (watchDog: SetWatchDogRequest, watchDogParams?: Pick<WatchDogDetailRequest, 'withDetails'>, token?: string): Promise<WatchDog> => {
      if (token) {
        $axios = useAxiosWithCustomAuthorization($axios, token)
      }

      return $axios.$post<WatchDog>('/watch-dogs', watchDog, { params: watchDogParams })
    },
    removeWatchDog: async (watchDogId: number, token?: string): Promise<void> => {
      if (token) {
        $axios = useAxiosWithCustomAuthorization($axios, token)
      }

      await $axios.$delete<WatchDog>(`/watch-dogs/${watchDogId}`)
    }
  }
}

export const useWatchDogsRepository = () => {
  const { $axios } = useAxios()

  return useWatchDogsRepositoryWithAxios($axios)
}
