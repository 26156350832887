import { ref } from '@nuxtjs/composition-api'
import { AxiosError } from 'axios'
import { GoogleCredentialResponse } from '~/src/Model/Auth/Google/Google'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'

export const useGoogleLoginPostProcess = () => {
  const authService = useAuthService()
  const { googleLogin } = useCustomerAuthRepository()

  const mergeAccount = ref<boolean>(false)
  const idToken = ref<string>('')
  const pending = ref<boolean>(false)

  const onLoggedIn = async (googleCredentialResponse: GoogleCredentialResponse) => {
    idToken.value = googleCredentialResponse.credential
    pending.value = true
    let loginResponse

    try {
      loginResponse = await googleLogin(idToken.value)
    } catch (error) {
      if ((error as AxiosError).response?.status === 412) {
        mergeAccount.value = true
      }

      return
    } finally {
      pending.value = false
    }

    await authService.setUserToken(loginResponse.accessToken, loginResponse.refreshToken)
  }

  const mergeAccountCompleted = () => {
    mergeAccount.value = false
  }

  return {
    onLoggedIn,
    mergeAccountCompleted,
    mergeAccount,
    idToken,
    pending
  }
}
