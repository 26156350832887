

















import { defineComponent } from '@nuxtjs/composition-api'
import Picture from '~/components/Picture/Picture.vue'
import Source from '~/components/Source/Source.vue'
import AccesoryImageSourceXs from '~/components/ProductAccessories/Components/AccesoryImageSourceXs.vue'

export default defineComponent({
  components: { Picture, Source, AccesoryImageSourceXs },
  props: {
    image: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  setup () {}
})
