import { Image } from '~/src/Model/Image/Image'
import { ServiceVariant } from '~/src/Model/Services/Service'

export enum ServiceType {
  insurance = 'insurance',
  warranty = 'warranty',
  immediateReplacement = 'immediateReplacement',
}

export enum ServiceVariantRequirement {
  yes = 'yes',
  no = 'no',
  optional = 'optional'
}

export interface FinancialService {
  id: number
  name: string
  annotation: string|null
  content: string|null
  icon: Image|null
  isSingleVariant: boolean
  variants: ServiceVariant[]
  type: ServiceType
  isVariantRequired: ServiceVariantRequirement
}

export interface TechnicalService {
  id: number
  name: string
  annotation: string|null
  content: string|null
  icon: Image|null
  isSingleVariant: boolean
  variants: ServiceVariant[]
  isVariantRequired: ServiceVariantRequirement
}

export interface ProductServices {
  financialServices: FinancialService[]
  technicalServices: TechnicalService[]
}

export interface SelectedFinancialService {
  service: FinancialService
  variant: ServiceVariant
}

export interface SelectedTechnicalService {
  service: TechnicalService
  variant: ServiceVariant
}

export interface SelectedPreBasketFinancialService {
  service: FinancialService
  variant: ServiceVariant | null
}

export interface SelectedPreBasketTechnicalService {
  service: TechnicalService
  variant: ServiceVariant | null
}
