import { ref, useFetch } from '@nuxtjs/composition-api'
import { ProductServices } from '~/src/Model/ProductServices/ProductServices'
import { useProductServicesRepository } from '~/src/Infrastructure/ProductServices/ProductServicesRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const useProductServicesFetch = (id: number) => {
  const productServices = ref<ProductServices | null>(null)
  const { loadProductServices } = useProductServicesRepository()

  const { exec, loading: productServicesLoading } = usePromise(async () => {
    productServices.value = await loadProductServices(id)
  })
  useFetch(exec)

  return {
    productServices,
    productServicesLoading
  }
}
