import {
  anonymizeString
} from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeDeep/AnonymizePrimitiveValue/AnonymizeTypes/AnonymizeString/AnonymizeString'
import {
  anonymizeNumber
} from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeDeep/AnonymizePrimitiveValue/AnonymizeTypes/AnonymizeNumber/AnonymizeNumber'
import {
  anonymizeBoolean
} from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeDeep/AnonymizePrimitiveValue/AnonymizeTypes/AnonymizeBoolean/AnonymizeBoolean'

export const anonymizePrimitiveValue = (value: any): string | undefined | null => {
  if (value === undefined || value === null) {
    return value
  }

  const variableType: string = typeof value
  let anonymizedValue: string

  switch (variableType) {
    case 'string':
      anonymizedValue = anonymizeString(value)
      break
    case 'number':
      anonymizedValue = anonymizeNumber(value)
      break
    case 'boolean':
      anonymizedValue = anonymizeBoolean(value)
      break
    default:
      anonymizedValue = value.toString()
      break
  }

  return `${variableType}:${anonymizedValue}`
}
