import { Plugin } from '@nuxt/types'
import { AxiosRequestConfig } from 'axios'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'

const localeMap: { [key: string]: string } = {
  cs: 'cs-CZ',
  sk: 'sk-SK',
  en: 'en-GB'
}

const axios: Plugin = (context) => {
  const $axios = context.$axios

  $axios.onRequest((config: AxiosRequestConfig) => {
    const baseURlFromCookie = context.app.$cookies.get('apiBaseUrl')

    if (baseURlFromCookie && process.env.ALLOW_BASE_URL_FROM_COOKIE === '1') {
      config.baseURL = baseURlFromCookie
    }

    return config
  })

  $axios.onRequest(() => {
    // @ts-ignore
    const locale = context.app.i18n?.locale as string
    const language: string | null = localeMap[locale] ?? null
    const basketStore = useBasketStore(context.$pinia)
    const basketHash = basketStore.basketHash.value
    const appVersion = context.$config.appVersion

    const googleAnalytics = context.app.$cookies.get('_ga')

    if (googleAnalytics) {
      $axios.setHeader('X-Pd-GoogleAnalytics', googleAnalytics)
    }

    if (language) {
      $axios.setHeader('accept-language', language)
    }

    if (basketHash) {
      $axios.setHeader('X-Basket', basketHash)
    }

    if (appVersion) {
      $axios.setHeader('X-Client-Version', appVersion)
    }
  })
}

export default axios
