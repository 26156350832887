

















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { BreadCrumb } from '~/src/Model/BreadCrumb/BreadCrumb'
import BreadCrumbBarItemWrap from '~/components/BreadCrumb/BreadCrumbBarItemWrap.vue'

export default defineComponent({
  components: { BreadCrumbBarItemWrap },
  props: {
    breadCrumb: {
      type: Object as PropType<BreadCrumb>,
      required: true
    },
    isHomepageItem: {
      type: Boolean,
      default: false
    },
    isOnlyItem: {
      type: Boolean,
      default: false
    },
    noBefore: {
      type: Boolean,
      default: false
    },
    linkClass: {
      type: String,
      default: null
    }
  }
})
