import { FilterSelectedParameter } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterQueryParameter } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { TranslatedParameter } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'

export type ParameterConvertorType = FilterElementConvertor<FilterSelectedParameter, FilterQueryParameter, TranslatedParameter>

export class ParameterConvertor implements ParameterConvertorType {
  toUrl (parameter: FilterSelectedParameter): UrlElement {
    return new UrlElement(
      parameter.parameter.alias,
      parameter.values.map(v => v.value)
    )
  }

  toUrlFromTranslated (parameter: TranslatedParameter): UrlElement {
    return new UrlElement(
      parameter.alias,
      parameter.values
    )
  }

  fromUrl (urlElement: UrlElement): FilterQueryParameter {
    if (urlElement.values.length === 0) {
      throw new Error('missing parameter values')
    }

    return {
      alias: urlElement.identifier,
      values: urlElement.values
    }
  }

  support (_urlElement: UrlElement): boolean {
    return true
  }

  shouldConvert (attribute: FilterSelectedParameter): boolean {
    return attribute.values.length > 0
  }
}
