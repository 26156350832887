import { Image } from '~/src/Model/Image/Image'

export interface NavigationNode {
  name: string
  alias: string
  pageId: number
  image: Image | null
  children: NavigationNode[]
  hasChildren: boolean
}

export interface NavigationNodes {
  nodes: NavigationNode[]
}

export enum NavigationNodeId {
  Node66265 = 66265,
  Node66266 = 66266,
  Node66268 = 66268,
  Node66269 = 66269,
  Node66270 = 66270,
  Node214729 = 214729,
}
