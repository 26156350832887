import { StoresMap } from '~/src/Model/StoresMap/StoresMap'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useStoresMapRepository = () => {
  const { $axios } = useAxios()

  return {
    loadStoresMap: (): Promise<StoresMap> => $axios.$get<StoresMap>('/stores-map')
  }
}
