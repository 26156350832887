import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { IVueI18n } from 'vue-i18n'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class ZipCountryRule extends ValidationRule {
  constructor (private $axios: NuxtAxiosInstance, protected i18n: IVueI18n) {
    super(i18n)
  }

  message = () => this.i18n.t('validations.zipCountry').toString()

  validate = async (value: string | null): Promise<ValidationRuleResult> => {
    if (value === null) {
      return {
        valid: true
      }
    }

    value = value.replace(/\s+/g, '')

    if (!value) {
      return {
        valid: true
      }
    }

    if (!/^[0-9]{5}$/.test(value)) {
      return {
        valid: false
      }
    }

    try {
      await this.$axios.$get(`/post-codes/${value}`)
    } catch (e) {
      return {
        valid: false
      }
    }

    return {
      valid: true
    }
  };
}
