


















import { defineComponent, ref } from '@nuxtjs/composition-api'
import LoyaltyClubSignUpForm from '~/components/LoyaltyClub/LoyaltyClubSignUpForm.vue'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import RegistrationColumns from '~/components/Auth/RegistrationColumns.vue'
import Toggle from '~/components/DesignSystem/Input/Toggle.vue'

export default defineComponent({
  components: {
    LoyaltyClubSignUpForm,
    RegistrationColumns,
    Toggle
  },
  props: {
    isInModal: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const showLoyaltyClubSignUp = ref<boolean>(false)
    const signUpSuccess = ref<boolean>(false)
    const authService = useAuthService()

    const signUpSucceeded = async () => {
      authService.reloadWithTokensRefresh()
      signUpSuccess.value = true
      await window.$nuxt.refresh()
    }

    return {
      showLoyaltyClubSignUp,
      signUpSuccess,
      signUpSucceeded
    }
  }
})
