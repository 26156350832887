import { computed } from '@nuxtjs/composition-api'
import { LocaleMessageObject } from 'vue-i18n'
import { Pinia } from 'pinia'
import { NavigationNode } from '~/src/Model/NavigationNode/NavigationNode'
import { Announcement } from '~/src/Model/Announcements/Announcements'
import { MainNavigation } from '~/src/Model/MainNavigation/MainNavigation'
import { useCoreDataStore as useStore } from '~/stores/core-data'

export const useCoreDataStore = (pinia?: Pinia | null) => {
  const store = useStore(pinia)

  const mainNavigationTopLevelNavigation = computed<MainNavigation | null>(() => store.topLevelMainNavigation)
  const footerMenuNodes = computed<NavigationNode[] | null>(() => store.footerMenuNodes)
  const announcements = computed<Announcement[] | null>(() => store.announcements)
  const translations = computed<LocaleMessageObject>(() => store.translations)

  return {
    mainNavigationTopLevelNavigation,
    footerMenuNodes,
    announcements,
    translations,
    loadTopLevelMainNavigation: store.loadTopLevelMainNavigation,
    loadFooterMenuNodes: store.loadFooterMenuNodes,
    loadAnnouncements: store.loadAnnouncements,
    loadTranslations: store.loadTranslations
  }
}
