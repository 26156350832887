















































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import { useCoreDataStore } from '~/src/Infrastructure/CoreData/CoreDataStore'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import CollapsableElement from '~/components/DesignSystem/Collapse/CollapsableElement.vue'

const externalLinkWorkInNay = 85516

export default defineComponent({
  components: {
    Container,
    LoadingAnim,
    Row,
    Column,
    CollapsableElement
  },
  setup () {
    const coreDataStore = useCoreDataStore()
    const navigationNodes = computed(() => coreDataStore.footerMenuNodes.value ?? [])

    const observingItem = ref<HTMLElement | null>(null)

    useVisibilityLazyLoad(observingItem, () => coreDataStore.loadFooterMenuNodes(), { rootMargin: '400px' })

    return {
      externalLinkWorkInNay,
      navigationNodes,
      observingItem
    }
  }
})
