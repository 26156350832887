import { computed } from '@nuxtjs/composition-api'
import { MigrationAccountType } from '~/src/Model/Karpaty/Onboarding'
import {
  CheckCustomerResponse,
  DatartMigrationLoginType,
  DatartMigrationUserType
} from '~/src/Model/CheckCustomer/CheckCustomer'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export const useDatartOnboardingMigrationHelpers = () => {
  const { app } = useApp()

  const migrationAccountType = (checkedCustomer: CheckCustomerResponse) => {
    if (!checkedCustomer.datartMigration) {
      return checkedCustomer.isCustomer ? MigrationAccountType.nonMigratedAccount : MigrationAccountType.noAccount
    }

    if (checkedCustomer.datartMigration.type === DatartMigrationUserType.new && checkedCustomer.datartMigration.login === DatartMigrationLoginType.datart) {
      return MigrationAccountType.migratedAccount
    }

    if (checkedCustomer.datartMigration.type === DatartMigrationUserType.migrated && checkedCustomer.datartMigration.login === DatartMigrationLoginType.nay && checkedCustomer.isCustomer) {
      return MigrationAccountType.migratedCustomerAccountWithOrders
    }

    return MigrationAccountType.migratedAccountWithOrders
  }

  const DATART_ONBOARDING_WINDOW_COOKIE_ID = 'datart-onboarding-window'

  const isOnboardingAllowed = computed<boolean>(() => app.$cookies.get(DATART_ONBOARDING_WINDOW_COOKIE_ID)?.toString() === 'true')

  return {
    migrationAccountType,
    isOnboardingAllowed
  }
}
