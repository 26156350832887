import { FilterSelectedPrice } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { PriceIntervalRange } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { TranslatedPrice } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'

export type PriceConvertorType = FilterElementConvertor<FilterSelectedPrice, PriceIntervalRange[], TranslatedPrice>

export class PriceConvertor implements PriceConvertorType {
  private readonly separator = ';'

  constructor (
    private readonly identifier: string = 'price'
  ) {
  }

  toUrl (selectedPrice: FilterSelectedPrice): UrlElement {
    return new UrlElement(
      this.identifier,
      selectedPrice.intervals.map((priceInterval) => {
        return this.createIntervalValue(priceInterval.value.from, priceInterval.value.to)
      }),
      this.createCompareFunction()
    )
  }

  toUrlFromTranslated (price: TranslatedPrice): UrlElement {
    const intervals = price.intervals
      .filter(p => p.from && p.to)
      .map(priceInterval => this.createIntervalValue(priceInterval.from as number, priceInterval.to as number))

    return new UrlElement(
      this.identifier,
      intervals,
      this.createCompareFunction()
    )
  }

  fromUrl (urlElement: UrlElement): PriceIntervalRange[] {
    if (urlElement.values.length === 0) {
      throw new Error('missing parameter values')
    }

    const priceIntervalRanges: PriceIntervalRange[] = []

    for (const rangePersistedValue of urlElement.values) {
      const intervalRange = rangePersistedValue.split(this.separator)

      if (intervalRange.length < 2) {
        continue
      }

      priceIntervalRanges.push({
        from: Number(intervalRange[0]),
        to: Number(intervalRange[1])
      })
    }

    if (priceIntervalRanges.length === 0) {
      throw new Error('Price without valid values')
    }

    return priceIntervalRanges
  }

  support (urlElement: UrlElement): boolean {
    return this.identifier === urlElement.identifier
  }

  shouldConvert (attribute: FilterSelectedPrice): boolean {
    return attribute.intervals.length > 0
  }

  private createIntervalValue (from: number, to: number): string {
    return `${from}${this.separator}${to}`
  }

  private createCompareFunction () {
    const separator = this.separator

    return (a: string, b: string) => {
      const aFrom = Number(a.split(separator)[0] ?? 0)
      const bFrom = Number(b.split(separator)[0] ?? 0)

      return aFrom - bFrom
    }
  }
}
