import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { isFromStandardCharacters } from '~/src/Infrastructure/String/StandardCharacters/IsFromStandardCharacters'

export class AlphaNumSpaceRule extends ValidationRule {
  message = (field: string) => this.i18n.t('validations.alpha_num_space', { field }).toString()

  validate = (value: string | null): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = isFromStandardCharacters(value)

    return {
      valid
    }
  };
}
