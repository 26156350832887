


















import { defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { Image } from '~/src/Model/Image/Image'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    image: {
      type: Object as () => Image | null,
      default: null
    },
    imageClass: {
      type: String,
      default: ''
    },
    imageSize: {
      type: String,
      required: true
    },
    imageSrcset: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: undefined
    },
    loading: {
      type: String,
      required: false,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    },
    fetchpriority: {
      type: String,
      default: null
    }
  }
})
