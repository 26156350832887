import { onMounted } from '@nuxtjs/composition-api'
import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export class PageEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (): void {
    this.dataLayer.push({
      ecomm_prodid: null,
      ecomm_pagetype: 'other',
      ecomm_totalvalue: null
    })
  }
}

export const usePageEvent = () => {
  const dataLayer = useDataLayer()

  return new PageEvent(dataLayer)
}

export const usePageEventOnMounted = () => {
  const pageEvent = usePageEvent()
  onMounted(() => pageEvent.fire())
}
