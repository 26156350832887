



















import { defineComponent } from '@nuxtjs/composition-api'
import { Image } from '~/src/Model/Image/Image'
import ResponsiveImage from '~/components/Image/ResponsiveImage.vue'
import NoImage from '~/components/Image/NoImage.vue'

export default defineComponent({
  components: { ResponsiveImage, NoImage },

  props: {
    image: {
      type: Object as () => Image | null,
      default: null
    },
    imageClass: {
      type: String,
      default: ''
    },
    imageSize: {
      type: [String, Array],
      required: true
    },
    imageSrcset: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: undefined
    },
    loading: {
      type: String,
      required: false,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    },
    fetchpriority: {
      type: [String, Object],
      default: null
    }
  },

  setup () {
    return {}
  }
})
