
















import { defineComponent, computed } from '@nuxtjs/composition-api'
import { useProductComparisonStore } from '~/src/Infrastructure/ProductComparison/ProductComparisonStore'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import Badge from '~/components/DesignSystem/Badge/Badge.vue'
export default defineComponent({
  components: { Badge },
  props: {
    activePanel: {
      type: String,
      required: true
    }
  },
  emits: ['sidebar-toggled'],
  setup (props, { emit }) {
    const productComparisonStore = useProductComparisonStore()
    const systemPagesStore = useSystemPagesStore()
    const comparePath = systemPagesStore.pathByUid('COMPARE')

    const compareCount = productComparisonStore.products

    const toggleSidebar = () => {
      emit('sidebar-toggled', ActiveHeaderPanel.compareBox)
    }

    const sidebarOpened = computed<boolean>({
      get: () => props.activePanel === ActiveHeaderPanel.compareBox,
      set: toggleSidebar
    })

    return {
      sidebarOpened,
      toggleSidebar,
      compareCount,
      comparePath
    }
  }
})
