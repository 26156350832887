import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export interface GtmModule {
  init: (id: string) => void
  push: (event: unknown) => void
}

export class DataLayer {
  constructor (
    private $gtmModule: GtmModule
  ) {
  }

  public push (event: unknown): void {
    this.$gtmModule.push(event)
  }
}

export const useDataLayer = (): DataLayer => {
  const { app } = useApp()

  return new DataLayer(app.$gtm)
}
