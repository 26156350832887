










import { computed, defineComponent } from '@nuxtjs/composition-api'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: { CurrentWeb },
  props: {
    tag: {
      type: String,
      default: 'span'
    }
  },
  setup () {
    const $webConfig = useWebConfig()
    const isNay = computed<boolean>(() => $webConfig.web === Web.Nay)

    return {
      isNay
    }
  }
})
