




































































import {
  defineComponent,
  useRoute,
  useFetch,
  ref,
  computed,
  watch,
  useRouter
} from '@nuxtjs/composition-api'
import { useRouterRepository } from '~/src/Infrastructure/Router/RouterRepository'
import { Alias } from '~/src/Model/Router/Alias'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import ProductAccessoryList from '~/components/ProductAccessories/ProductAccessoryList.vue'
import ProductAccessoryBox from '~/components/ProductAccessories/ProductAccessoryBox.vue'
import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import { useProductDetailsRepository } from '~/src/Infrastructure/ProductDetail/ProductDetailsRepository'
import { useSeoMetaTags } from '~/src/Infrastructure/Meta/useSeoMetaTags'
import BreadCrumbBar from '~/components/BreadCrumb/BreadCrumbBar.vue'
import { useBreadCrumbsRepository } from '~/src/Infrastructure/BreadCrumb/BreadCrumbsRepository'
import { BreadCrumb } from '~/src/Model/BreadCrumb/BreadCrumb'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { usePageEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Page/Event/PageEvent'
import { useProductAccessoriesFetch } from '~/src/Infrastructure/ProductAccessories/ProductAccessoriesFetch'
import PagePagination from '~/components/DesignSystem/Pagination/PagePagination.vue'
import { ProductAccessoriesRequest } from '~/src/Model/ProductAccessories/ProductAccessories'
import { useCurrentPageNumber } from '~/src/Infrastructure/Router/CurrentPageNumber'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'
import { AccessoryType } from '~/src/Model/ProductAccessoryType/ProductAccessoryType'
import { useProductAccessoryTypesRepository } from '~/src/Infrastructure/ProductAccessoryTypes/ProductAccessoryTypesRepository'
import { ProductAccessoryTypeSelectOption } from '~/src/Model/ProductAccessoryType/ProductAccessoryTypeSelectOption'
import BaseMultiselect from '~/components/DesignSystem/Select/BaseMultiselect.vue'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import { useBasketInsertProduct } from '~/src/Infrastructure/Basket/BasketInsert'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import {
  useBreadCrumbsChangedEvent
} from '~/src/Infrastructure/Google/TagManager/DataLayer/BreadCrumbs/BreadCrumbsChangedEvent'
import { useDataLayerStore } from '~/src/Infrastructure/DataLayer/DataLayerStore'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import PageSectionHeading from '~/components/DesignSystem/PageSection/PageSectionHeading.vue'
import { idleCallback } from '~/src/Infrastructure/WebAPI/IdleCallback'

export default defineComponent({
  components: {
    Container,
    BreadCrumbBar,
    ProductAccessoryList,
    ProductAccessoryBox,
    PagePagination,
    BaseMultiselect,
    LoadingOverlay,
    Row,
    Column,
    PageSectionHeading
  },
  setup () {
    const { i18n } = useTranslator()
    const route = useRoute()
    const router = useRouter()
    const rootElement = ref<Element>()
    const { scrollToHtmlElement } = useScrollTo()
    const { basketInsertProduct, basketInsertProductPending } = useBasketInsertProduct()
    const alias = ref<Alias | null>(null)
    const productDetail = ref<ProductDetail | null>(null)
    const breadCrumbs = ref<BreadCrumb[]>([])
    const pageNumber = useCurrentPageNumber()
    const accessoryTypes = ref<AccessoryType[]>([])
    const selectedTypeId = ref<number | null>(null)
    const accessoriesTotalCount = computed<number>(() => accessoryTypes.value.reduce((count, accessoryType) => count + accessoryType.count, 0))
    const totalCountLabel = computed<string>(() => accessoriesTotalCount.value < 100 ? accessoriesTotalCount.value.toString() : '99+')
    const basketStore = useBasketStore()
    const pageEvent = usePageEvent()
    const breadCrumbsDataLayerChangedEvent = useBreadCrumbsChangedEvent()
    const dataLayerStore = useDataLayerStore()

    const valuesForSelect = computed<ProductAccessoryTypeSelectOption[]>(() => {
      const options: ProductAccessoryTypeSelectOption[] = []
      options.push({
        text: i18n.t('nayeshop_product_accessories.modal.select_prompt') as string,
        id: null
      })
      accessoryTypes.value.forEach((accessoryType: AccessoryType) => {
        options.push({
          text: accessoryType.name + ` (${accessoryType.count})`,
          id: accessoryType.id
        })
      })

      return options
    })

    const selectedType = computed<ProductAccessoryTypeSelectOption | null>({
      get: () => valuesForSelect.value.filter(i => i.id === selectedTypeId.value)[0] ?? null,
      set: (option: ProductAccessoryTypeSelectOption | null) => { selectedTypeId.value = option?.id ?? null }
    })

    const productAccessoriesResquest: ProductAccessoriesRequest = {
      limit: 12,
      page: pageNumber.value,
      type: selectedType.value ? selectedType.value.id : null
    }

    const { getAll: loadBreadCrumbs } = useBreadCrumbsRepository()
    const { resolveAlias } = useRouterRepository()
    const { loadProductDetail } = useProductDetailsRepository()
    const { loadProductAccessoryTypes } = useProductAccessoryTypesRepository()
    const { accessories, totalCount, fetchAccessoriesForProduct, loadMore, loadPage, pending } = useProductAccessoriesFetch(alias.value?.mainId ?? null, productAccessoriesResquest)

    useFetch(async () => {
      alias.value = await resolveAlias(route.value.params.alias)
      fetchAccessoriesForProduct(alias.value.mainId)
      accessoryTypes.value = (await loadProductAccessoryTypes(alias.value.mainId)).types
      productDetail.value = await loadProductDetail(alias.value.mainId)
      breadCrumbs.value = (await loadBreadCrumbs(alias.value.mainId)).breadCrumbs

      basketStore.loadProductsIfNotEmpty()
    })

    const heading = computed<string>(() => {
      if (productDetail.value) {
        return i18n.t('nayeshop_product_accessories.accessories_for', { product: productDetail.value.name }) as string
      }

      return i18n.t('nayeshop_product_accessories.heading') as string
    })

    const myValue = ref<number>(0)
    watch(selectedTypeId, () => {
      if (pageNumber.value !== 1) {
        router.push({ path: router.currentRoute.path })
      }
      loadPage(1, selectedTypeId.value ?? null)
    })

    watch(breadCrumbs, () => {
      if (breadCrumbs.value === null || breadCrumbs.value.length === 0) {
        return
      }
      idleCallback(() => {
        breadCrumbsDataLayerChangedEvent.fire(breadCrumbs.value)
        pageEvent.fire()
        dataLayerStore.fireVirtualPageViewEvent()
      })
    }, { immediate: true })

    const onPageChange = (page: number) => {
      loadPage(page, selectedTypeId.value ?? null)
      if (rootElement.value) {
        scrollToHtmlElement(rootElement.value)
      }
    }

    const addToBasket = async (productId: number) => {
      await basketInsertProduct(productId)
    }

    useSeoMetaTags(productDetail)

    return {
      accessories,
      totalCount,
      breadCrumbs,
      heading,
      loadMore,
      pageLimit: productAccessoriesResquest.limit,
      rootElement,
      onPageChange,
      pending,
      valuesForSelect,
      selectedType,
      selectedTypeId,
      accessoryTypes,
      totalCountLabel,
      myValue,
      addToBasket,
      basketInsertProductPending
    }
  },
  head: {}
})
