import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Announcements } from '~/src/Model/Announcements/Announcements'
import { JsonCacheStorage } from '~/src/Infrastructure/Cache/Server/JsonCacheStorage'
import { ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'

export const useAnnouncementsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadAnnouncements: (): Promise<Announcements> => $axios.$get<Announcements>('/announcements')
  }
}

type AnnouncementRepositoryType = ReturnType<typeof useAnnouncementsRepositoryWithAxios>

export const useCachedAnnouncementsRepository = (
  $axios: NuxtAxiosInstance,
  cacheStorage: ServerCacheStorage
): AnnouncementRepositoryType => {
  const jsonCacheStorage = new JsonCacheStorage<Announcements>(cacheStorage)
  const announcementsRepository = useAnnouncementsRepositoryWithAxios($axios)

  return {
    loadAnnouncements: async () => {
      return await jsonCacheStorage.load(
        'announcements',
        () => announcementsRepository.loadAnnouncements(),
        { expireSeconds: 120 }
      )
    }
  }
}
