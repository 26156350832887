import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'
import { Environment, EventEnvironment } from '~/plugins/sentry/events/eventEnvironment'

export class EventException extends IgnoredEvent {
  constructor (private type: string, private value: string, private env?: Environment) {
    super()
  }

  isIgnored (event: Event) {
    if (event?.exception?.values?.length !== 1) {
      return false
    }

    const e = event.exception.values[0]
    const isIgnoredException = (e.type === this.type && e.value?.startsWith(this.value)) ?? false

    if (!this.env) {
      return isIgnoredException
    }

    return isIgnoredException && (new EventEnvironment(this.env).isIgnored(event))
  }
}
