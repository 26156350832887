















import { defineComponent, ref } from '@nuxtjs/composition-api'
import GoogleLoginButton from '~/components/Auth/Google/GoogleLoginButton.vue'
import GoogleButton from '~/components/DesignSystem/Buttons/GoogleButton.vue'

export default defineComponent({
  components: {
    GoogleLoginButton,
    GoogleButton
  },
  emits: ['input'],
  setup () {
    const showGoogleLoginButton = ref<boolean>(false)

    return {
      showGoogleLoginButton
    }
  }
})
