import { Context } from '@nuxt/types/app'
import {
  PageUrlChangedEvent
} from '~/src/Infrastructure/Google/TagManager/DataLayer/Redirect/PageUrlChangedEvent'
import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export default function ({ app }: Context) {
  if (process.server) {
    return
  }

  const datalayer = new DataLayer(app.$gtm)
  const pageUrlChangedEvent = new PageUrlChangedEvent(datalayer)
  pageUrlChangedEvent.fire()
}
