import { GalleryItemType } from '~/src/Model/ProductDetail/ProductDetailGallery'

export const useGalleryHelpers = () => {
  const isVideo = (type: GalleryItemType): boolean => {
    return type === GalleryItemType.video
  }

  return {
    isVideo
  }
}
