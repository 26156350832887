











import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { TwoFA, TwoFAType } from '~/src/Model/Auth/TwoFA'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import TwoFAForm from '~/components/Auth/TwoFA/TwoFAForm.vue'
import { FlashMessage } from '~/src/Model/FlashMessage/FlashMessage'

export default defineComponent({
  components: {
    TwoFAForm
  },
  props: {
    twoFa: {
      required: true,
      type: Object as PropType<TwoFA>
    },
    flashMessages: {
      default: () => [],
      type: Array as PropType<FlashMessage[]>
    }
  },
  emits: ['logged', 'back-to-login', 'request-code-again'],
  setup (props, { emit }) {
    const { challengeTwoFA } = useCustomerAuthRepository()
    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const authService = useAuthService()

    const messages = computed<FlashMessage[]>(() => props.flashMessages.length ? props.flashMessages : flashMessages.value)

    const { pending: challengePending, exec: submitChallenge, onSuccess: onChallengeSuccess } = useApiCall((verificationCode: string) => challengeTwoFA({
      twoFA: {
        code: verificationCode,
        token: props.twoFa.token
      }
    }), flashMessageCenter)

    onChallengeSuccess.subscribe(async (authTokens: AuthTokens) => {
      await authService.setUserToken(authTokens.accessToken, authTokens.refreshToken)
      emit('logged', authTokens)
    })

    return {
      TwoFAType,
      messages,
      challengePending,
      submitChallenge
    }
  }
})
