



























































































































import { computed, defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'
import VueI18n from 'vue-i18n'
import SidebarRegistrationPanel from '~/components/Sidebar/SidebarRegistrationPanel.vue'
import SidebarPostRegistrationPanel from '~/components/Sidebar/SidebarPostRegistrationPanel.vue'
import LostPasswordPanel from '~/components/Sidebar/LostPasswordPanel.vue'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import UserBoxLink from '~/components/Header/UserBox/UserBoxLink.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'
import { useAcl } from '~/src/Infrastructure/Acl/AclAllowed'
import { AclPrivilege } from '~/src/Model/Acl/AclPermission'
import LoginPanelCrossroad from '~/components/Auth/Karpaty/LoginPanelCrossroad.vue'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'

export default defineComponent({
  components: {
    LoginPanelCrossroad,
    UserBoxLink,
    Sidebar,
    SidebarRegistrationPanel,
    SidebarPostRegistrationPanel,
    LostPasswordPanel,
    Button,
    PageSectionContent
  },
  props: {
    backdrop: {
      type: Boolean,
      default: false
    },
    showTopSlot: {
      type: Boolean,
      default: false
    }
  },
  emits: ['logged', 'hidden', 'show-onboarding-modal'],
  setup (_props, { emit }) {
    const { app } = useApp()
    const i18n = app.i18n as VueI18n
    const { authenticatedUser } = useAuthenticatedUser()
    const authService = useAuthService()
    const { allowed: studentClubAllowed } = useAcl('eshop.user.profil.studentClub', AclPrivilege.read)

    const userFullName = computed<string>(() => {
      const maybeFirstName = authenticatedUser.value?.firstName || ''
      const maybeLastName = authenticatedUser.value?.lastName || ''
      return `${maybeFirstName} ${maybeLastName}`
    })

    const showRegistrationPanel = ref<boolean>(false)
    const showPostRegistrationPanel = ref<boolean>(false)
    const showLostPasswordPanel = ref<boolean>(false)
    const showLoginPanel = ref<boolean>(!authenticatedUser.value)

    const submodalTitle = computed<string | null>(() => {
      if (showRegistrationPanel.value) {
        return i18n.t('auth.registration.title') as string
      }
      if (showPostRegistrationPanel.value) {
        return i18n.t('postRegistrationPanel.thankYou') as string
      }
      if (showLostPasswordPanel.value) {
        return i18n.t('auth.forgotten_password') as string
      }

      return null
    })

    const closeSubSidebar = () => {
      showPostRegistrationPanel.value = false
      showRegistrationPanel.value = false
      showLostPasswordPanel.value = false
    }

    const closeSidebar = (closeSidebarCallback: Function): void => {
      if (showRegistrationPanel.value || showLostPasswordPanel.value) {
        closeSubSidebar()
        return
      }
      closeSidebarCallback()
    }

    const logOutUser = () => {
      authService.logout()
    }

    const userLogged = (callback: Function, checkedCustomer?: CheckCustomerResponse) => {
      emit('logged')
      showLoginPanel.value = false

      if (!checkedCustomer?.datartMigration || !authenticatedUser.value?.datartUser?.showOnboardingWindow) {
        return
      }

      emit('show-onboarding-modal', checkedCustomer)
      callback()
    }

    onMounted(() => {
      document.body?.classList.add('sidebar-open')
    })

    onUnmounted(() => {
      document.body?.classList.remove('sidebar-open')
    })

    const userRegistered = () => {
      showRegistrationPanel.value = false
      showPostRegistrationPanel.value = true
    }

    return {
      authenticatedUser,
      userFullName,
      showRegistrationPanel,
      showPostRegistrationPanel,
      showLostPasswordPanel,
      logOutUser,
      submodalTitle,
      closeSubSidebar,
      closeSidebar,
      userLogged,
      userRegistered,
      studentClubAllowed,
      showLoginPanel
    }
  }
})
