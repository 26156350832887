








import { defineComponent, computed } from '@nuxtjs/composition-api'
import truncate from 'lodash/truncate'
import TopParametersWrapper from '~/components/ProductBox/Components/Parameters/TopParametersWrapper.vue'

export default defineComponent({
  components: { TopParametersWrapper },
  props: {
    parametricAnnotation: {
      type: String,
      default: null
    },
    showFullAnnotation: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const annotation = computed<string | null>(() => {
      if (props.parametricAnnotation === null) {
        return null
      }

      if (props.showFullAnnotation) {
        return `${props.parametricAnnotation}. `
      }

      return truncate(props.parametricAnnotation, {
        length: 140,
        separator: ' ',
        omission: '…'
      })
    })
    return {
      annotation
    }
  }
})
