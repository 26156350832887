import { computed, ref, useRoute } from '@nuxtjs/composition-api'
import { set } from 'date-fns'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { useDatartOnboardingMigrationHelpers } from '~/src/Infrastructure/Karpaty/DatartOnboardingMigration'

export const useDatartOnboardingModal = () => {
  const DATART_ONBOARDING_COOKIE_ID = 'datart-onboarding-close'
  const DATART_ONBOARDING_URL_HASH = '#datart-je-nay'
  const { app } = useApp()
  const route = useRoute()
  const forceModalClose = ref<boolean>(false)
  const forceModalOpen = ref<boolean>(false)

  const { isOnboardingAllowed } = useDatartOnboardingMigrationHelpers()
  const isCookieSet = computed<boolean>(() => app.$cookies.get(DATART_ONBOARDING_COOKIE_ID))

  const showDatartOnboardingModal = computed<boolean>(() =>
    (!isCookieSet.value &&
    route.value.hash === DATART_ONBOARDING_URL_HASH &&
    isOnboardingAllowed.value &&
    !forceModalClose.value) || forceModalOpen.value
  )

  const setDatartOnboardingCookie = () => {
    forceModalClose.value = true
    forceModalOpen.value = false
    app.$cookies.set(DATART_ONBOARDING_COOKIE_ID,
      'true',
      {
        expires:
          set(new Date(), {
            hours: 23,
            minutes: 59,
            seconds: 59
          })
      })
  }

  const openModal = () => {
    forceModalOpen.value = true
  }

  return {
    setDatartOnboardingCookie,
    showDatartOnboardingModal,
    isOnboardingAllowed,
    openModal
  }
}
