




















































































































































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { HitType } from '~/src/Model/LuigisBox/HitType'
import SuggestHit from '~/src/Model/LuigisBox/Suggest/SuggestHit'
import { AvailabilityTypes } from '~/src/Model/LuigisBox/AvailabilityTypes'
import Tag from '~/components/ProductDetail/Tag/Tag.vue'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import TextHighlighter from '~/components/TextHighlighter/TextHighlighter.vue'
import { IconType } from '~/src/Model/ProductAvailability/ProductAvailability'
import { ProductTagStaticType } from '~/src/Model/ProductTag/ProductTag'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    Tag,
    ImageWithFallback,
    TextHighlighter,
    Row,
    Column,
    Button
  },
  props: {
    suggestions: {
      required: true,
      type: Array as PropType<SuggestHit[]>,
      default: () => []
    },
    query: {
      type: String as PropType<string | null>,
      default: null
    }
  },
  emits: ['close', 'suggestion-scrolled'],
  setup (props, { emit }) {
    const { app } = useApp()
    const systemPagesStore = useSystemPagesStore()
    const pathToSearchResults = systemPagesStore.pathByUid('LUIGIS_BOX_SEARCH')
    const getProducts = computed(
      () => props.suggestions.filter((suggestion: SuggestHit) => suggestion.type === HitType.Product && suggestion.attributes.product_id)
    )

    const getArticles = computed(
      () => props.suggestions.filter((suggestion: SuggestHit) => suggestion.type === HitType.Article)
    )

    const getCategories = computed(
      () => props.suggestions.filter((suggestion: SuggestHit) => suggestion.type === HitType.Category)
    )

    const getBrands = computed(
      () => props.suggestions.filter((suggestion: SuggestHit) => suggestion.type === HitType.Brand)
    )

    const getPages = computed(
      () => props.suggestions.filter((suggestion: SuggestHit) => suggestion.type === HitType.Page)
    )

    const redirectToSearchResults = () => {
      emitClose()
      app.router?.push({ path: pathToSearchResults.value, query: { q: props.query } })
    }

    const getAvailabilityClass = (availability: number): string => {
      if (availability === AvailabilityTypes.IN_STOCK || availability === AvailabilityTypes.AVAILABILITY_PREORDER) {
        return 'success'
      } else if (availability === AvailabilityTypes.NOT_IN_STOCK || availability === AvailabilityTypes.EXHIBIT_ON_ESHOP || availability === AvailabilityTypes.CURRENTLY_NOT_AVAILABLE) {
        return 'warning'
      }

      return 'danger'
    }

    const getAvailabilityIcon = (availability: number): string => {
      if (availability === AvailabilityTypes.IN_STOCK || availability === AvailabilityTypes.AVAILABILITY_PREORDER || availability === AvailabilityTypes.NOT_IN_STOCK || availability === AvailabilityTypes.EXHIBIT_ON_ESHOP) {
        return IconType.check
      }

      return IconType.cross
    }

    const emitClose = () => {
      emit('close')
    }

    const navigate = (url: string) => {
      emitClose()
      app.router?.push(url)
    }

    return {
      getProducts,
      getArticles,
      getCategories,
      getBrands,
      getPages,
      redirectToSearchResults,
      getAvailabilityClass,
      emitClose,
      getAvailabilityIcon,
      navigate,
      ProductTagStaticType
    }
  }
})
