import { defineStore } from 'pinia'
import {
  AclPermissionEvaluation, AclPermissionRequest,
  AclPermissionResponse,
  AclPrivilege,
  AclPrivileges,
  AclResources
} from '~/src/Model/Acl/AclPermission'
import { useAclRepositoryWithAxios } from '~/src/Infrastructure/Acl/AclRepository'

const ACL_STORE_ID = 'acl'

export interface AclState {
  resources: AclResources
}

export const useAclStore = defineStore(ACL_STORE_ID, {
  state: (): AclState => ({
    resources: {}
  }),
  getters: {
    privilegeAllowed () {
      return (resource: string, privilege: AclPrivilege) => {
        return this.resources[resource]?.[privilege]?.isAllowed ?? false
      }
    }
  },
  actions: {
    async evaluatePermission (resource: string, privilege: AclPrivilege) {
      const { evaluatePermission } = useAclRepositoryWithAxios(this.$nuxt.$axios)

      if (resource in this.resources && privilege in this.resources[resource]) {
        return this.resources[resource][privilege]
      }

      const request: AclPermissionRequest = { resource, privilege }
      try {
        const response: AclPermissionResponse = await evaluatePermission(request)
        this.updateResources({ request, response })
      } catch {
        this.updateResources({
          request,
          response: {
            message: null,
            isAllowed: false
          }
        })
      }
    },
    updateResources (aclPermissionEvaluation: AclPermissionEvaluation) {
      const resources = { ...this.resources }
      const request = aclPermissionEvaluation.request
      const response = aclPermissionEvaluation.response

      if (request.resource in resources) {
        resources[request.resource][request.privilege] = response
      } else {
        const privilege = { [request.privilege]: response }
        resources[request.resource] = privilege as AclPrivileges
      }

      this.resources = resources
    },
    clearResources () {
      this.$reset()
    }
  }
})
