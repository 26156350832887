export type SensitiveAttributeNames = string[]

export const sensitiveAttributeNames: SensitiveAttributeNames = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'countryCallingCode',
  'address',
  'city',
  'street',
  'companyName',
  'zip',
  'country',
  'dic',
  'icDph',
  'ico'
]
