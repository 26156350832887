import Big from 'big.js'
import { normalizeDecimal } from '~/src/Infrastructure/String/NormalizeDecimal'

export const bigify = (value: number | string): Big => {
  try {
    return new Big(normalizeDecimal(value.toString()))
  } catch {
    throw (new Error(`Error: can not bigify value: ${value}`))
  }
}
