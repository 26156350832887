import { computed } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { SystemPagesMap } from '~/src/Model/SystemPages/SystemPagesMap'
import { useSystemPagesStore as useStore } from '~/stores/system-pages'

export type QueryParameters = { [key: string]: string }

export interface NavigationOptions {
  query?: QueryParameters
}

export const useSystemPagesStore = (pinia?: Pinia | null) => {
  const store = useStore(pinia)

  const pages = computed<SystemPagesMap>(() => store.pages)
  const aliasByUid = (uid: string) => computed<string>(() => store.aliasByUid(uid) ?? '/')
  const pathByUid = (uid: string, options: NavigationOptions | null = null) => computed<string>(() => store.pathByUid(uid, options))
  const uidByAlias = (alias: string) => computed<string | null>(() => store.uidByAlias(alias))

  return {
    loadPages: store.loadPages,
    pages,
    aliasByUid,
    pathByUid,
    uidByAlias
  }
}
