import { getCurrentInstance, ref } from '@nuxtjs/composition-api'

export const useVueInstanceUid = () => {
  const uid = ref<number>(getCurrentInstance()?.uid ?? Math.floor(Math.random() * 100))

  const unificate = (value: string): string => {
    return `${value}-${uid.value}`
  }

  return {
    uid,
    unificate
  }
}
