




import { defineComponent } from '@nuxtjs/composition-api'
import { createConfig } from '~/src/Infrastructure/Image/ConfigCreator'

export default defineComponent({
  props: {
    imageSize: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const config = createConfig(props.imageSize)
    const size = config.width

    return { size }
  }
})
