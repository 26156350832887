




















import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { EnergyLabel } from '~/src/Model/EnergyLabel/EnergyLabel'
import EnergyLabelDetailModal from '~/components/ProductBox/Components/EnergyLabel/EnergyLabelDetailModal.vue'

export default defineComponent({
  components: { EnergyLabelDetailModal, ImageWithFallback },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    energyLabel: {
      required: true,
      type: Object as PropType<EnergyLabel>
    },
    energyLabelLink: {
      type: String,
      default: null
    },
    enableDetailModal: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: String,
      default: 'image-w60'
    }
  },
  setup () {
    const showFullLabel = ref<boolean>(false)
    return {
      showFullLabel
    }
  }
})
