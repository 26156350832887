import VueI18n from 'vue-i18n'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export const useTranslator = () => {
  const { app } = useApp()
  const i18n = app.i18n as VueI18n

  const optionalTranslate = (key: string): string | null => {
    const translation = i18n.t(key) as string
    return translation !== key ? translation : null
  }

  return {
    i18n,
    optionalTranslate
  }
}
