import { format, Locale, parse } from 'date-fns'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'

export default class Formatter {
  constructor (
    private readonly defaultInputFormat: string,
    private readonly locale: Locale
  ) {
  }

  public shortTime (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'H:mm', { locale: this.locale })
  }

  public date (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), "d.'&nbsp;'M.'&nbsp;'yyyy", { locale: this.locale })
  }

  public validationDate (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'd.M.yyyy', { locale: this.locale })
  }

  public dateTime (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), "d.'&nbsp;'M.'&nbsp;'yyyy H:mm", { locale: this.locale })
  }

  public dayNumber (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'd', { locale: this.locale })
  }

  public dayOfWeek (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'iiii', { locale: this.locale })
  }

  public shortDayOfWeek (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'iiiiii', { locale: this.locale })
  }

  public monthName (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'LLLL', { locale: this.locale })
  }

  public monthNumber (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'L', { locale: this.locale })
  }

  public dayMonth (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), "d.'&nbsp;'M.", { locale: this.locale })
  }

  public year (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), 'yyyy', { locale: this.locale })
  }

  public articleStartDay (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    return format(this.getValue(value, fromFormat), "iiii'&nbsp;'d.'&nbsp;'M.'&nbsp;'H:mm", { locale: this.locale })
  }

  public dateInputFormat (value: string | Date, fromFormat: string = this.defaultInputFormat): string {
    if (typeof value === 'string') {
      value = parse(value, fromFormat, new Date())
    }
    return format(value, 'yyyy-MM-dd')
  }

  public static apiDateTime (value: Date): string {
    return format(value, 'yyyy-MM-dd H:mm:ss')
  }

  public getValue (value: string | Date, fromFormat: string = this.defaultInputFormat): Date {
    if (typeof value === 'string') {
      return parse(value, fromFormat, new Date())
    }

    return value
  }

  public todayOrTomorrow (value: string, fromFormat: string = this.defaultInputFormat): string | null {
    const { i18n } = useTranslator()
    const today = new Date()
    if (this.getValue(value, fromFormat).toDateString() === today.toDateString()) {
      return i18n.t('date_formatter.today') as string
    }

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (this.getValue(value, fromFormat).toDateString() === tomorrow.toDateString()) {
      return i18n.t('date_formatter.tomorrow') as string
    }

    return null
  }

  public userDayDate (value: string, fromFormat: string = this.defaultInputFormat, returnStrong = true): string {
    const todayOrTomorrow = this.todayOrTomorrow(value, fromFormat)

    if (todayOrTomorrow) {
      return returnStrong ? `<strong>${todayOrTomorrow}</strong>` : todayOrTomorrow
    }

    return returnStrong
      ? `<strong>${this.dayOfWeek(value, fromFormat)}</strong>&nbsp;${this.dayMonth(value, fromFormat)}`
      : `${this.dayOfWeek(value, fromFormat)}&nbsp;${this.dayMonth(value, fromFormat)}`
  }
}

export const useDateFormatter = (): Formatter => {
  const { app } = useApp()

  return app.$formatter
}
