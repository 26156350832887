import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { IVueI18n } from 'vue-i18n'
import { Config } from '~/src/Model/Config/Config'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { filterWhitespaces } from '~/src/Infrastructure/String/FilterWhiteSpaces'

export class DicRule extends ValidationRule {
  constructor (private $webConfig: Config, protected i18n: IVueI18n) {
    super(i18n)
  }

  message = (field: string) => this.i18n.t('validations.dic', { field }).toString()

  validate = (value: string | null): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = this.$webConfig.dicFormat.test(filterWhitespaces(value))

    return {
      valid
    }
  };
}
