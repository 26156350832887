













































import { defineComponent, computed, ref } from '@nuxtjs/composition-api'
import BaseValidationProvider from '~/components/Forms/Validation/BaseValidationProvider.vue'

export default defineComponent({
  components: {
    BaseValidationProvider
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isSwitch: {
      type: Boolean,
      default: false
    },
    hideRequired: {
      type: Boolean,
      default: false
    },
    isIndeterminate: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: [Boolean, String, Number, Object, Array],
      default: null
    },
    tag: {
      type: String,
      default: 'div'
    },
    tooltip: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: ''
    },
    checkboxClass: {
      type: String,
      default: null
    },
    spanClass: {
      type: String,
      default: null
    },
    checkedValue: {
      type: [Boolean, String, Number, Object],
      required: false,
      default: true
    },
    unCheckedValue: {
      type: [Boolean, String, Number, Object],
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    hoverBordered: {
      type: Boolean,
      default: false
    },
    dataTest: {
      type: String,
      default: null
    }
  },
  emits: { input: null, change: null },
  setup (props, { emit }) {
    const required = ref<boolean>(props.rules.includes('required'))

    const validationRules = computed(() => {
      if (required.value === false) {
        return {}
      }

      return {
        required:
            {
              allowFalse: false
            }
      }
    })

    const localValue = computed({
      get: () => {
        return props.value
      },
      set: (value: any) => {
        emit('input', value)
      }
    })

    return {
      validationRules,
      required,
      localValue
    }
  }
})
