import { TagVariant } from '~/src/Model/Tag/TagVariant'

export enum ProductTagStaticType {
  cashback = 'cashback',
  sale = 'sale',
  action = 'action',
  new = 'new',
  valuePack = 'valuePack',
  recommended = 'recommended',
  gift = 'gift',
  freeDelivery = 'freeDelivery',
  loyaltyPrice = 'loyaltyPrice',
  scrap = 'scrap',
  presale = 'presale'
}

export enum ProductTagCustomType {
  loyalty = 'custom_loyalty',
  discount = 'custom_discount',
  sale = 'custom_sale',
  freeDelivery = 'custom_freeDelivery',
  cashback = 'custom_cashback',
  scrap = 'custom_scrap',
  blackFriday = 'custom_blackFriday',
  cyberMonday = 'custom_cyberMonday',
  b2b = 'custom_b2b',
  zam = 'custom_zam',
  vip = 'custom_vip',
  instalmentsWithoutIncrease = 'instalments_without_increase',
}

export type ProductTagType = ProductTagStaticType | ProductTagCustomType

export interface ProductTag {
  title: string
  type: ProductTagType
}

export const ProductTagTypeMap: { [key in ProductTagType]: string } = {
  [ProductTagStaticType.cashback]: TagVariant.cashback,
  [ProductTagStaticType.sale]: TagVariant.sale,
  [ProductTagStaticType.action]: TagVariant.action,
  [ProductTagStaticType.new]: TagVariant.new,
  [ProductTagStaticType.valuePack]: TagVariant.valuePack,
  [ProductTagStaticType.recommended]: TagVariant.recommended,
  [ProductTagStaticType.gift]: TagVariant.gift,
  [ProductTagStaticType.loyaltyPrice]: TagVariant.loyaltyPrice,
  [ProductTagStaticType.freeDelivery]: TagVariant.freeDelivery,
  [ProductTagStaticType.scrap]: TagVariant.scrap,
  [ProductTagStaticType.presale]: TagVariant.presale,

  [ProductTagCustomType.loyalty]: TagVariant.loyalty,
  [ProductTagCustomType.discount]: TagVariant.discount,
  [ProductTagCustomType.sale]: TagVariant.sale,
  [ProductTagCustomType.freeDelivery]: TagVariant.freeDelivery,
  [ProductTagCustomType.cashback]: TagVariant.cashback,
  [ProductTagCustomType.scrap]: TagVariant.scrap,
  [ProductTagCustomType.blackFriday]: TagVariant.blackFriday,
  [ProductTagCustomType.cyberMonday]: TagVariant.cyberMonday,
  [ProductTagCustomType.b2b]: TagVariant.b2b,
  [ProductTagCustomType.zam]: TagVariant.zam,
  [ProductTagCustomType.vip]: TagVariant.vip,
  [ProductTagCustomType.instalmentsWithoutIncrease]: TagVariant.cashback
}
