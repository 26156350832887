import { Context } from '@nuxt/types'
import { RedirectMiddleware } from '~/src/Model/Redirect/RedirectMiddleware'

export default async function ({ route, redirect }: Context) {
  const redirectMiddleware: RedirectMiddleware = route.meta!.reduce((redirect: any, meta: any) => meta.redirect || redirect, null)
  const redirectFrom = redirectMiddleware.from ?? null
  const redirectTo = redirectMiddleware.to

  if (!redirectFrom || redirectFrom === route.path) {
    await redirect(301, redirectTo, route.query)
  }
}
