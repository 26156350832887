



















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { Image } from '~/src/Model/Image/Image'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    ImageWithFallback,
    Modal,
    PageSectionContent
  },
  props: {
    fullLabelImage: {
      required: true,
      type: Object as PropType<Image>
    }
  },
  emits: ['close-modal']
})
