








import { defineComponent } from '@nuxtjs/composition-api'
import Source from '~/components/Source/Source.vue'

export default defineComponent({
  components: { Source },
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  setup () {}
})
