import { nextTick, onUpdated, Ref } from '@nuxtjs/composition-api'
import { ILazyLoadInstance } from 'vanilla-lazyload'

export const useLazyLoadOnUpdated = (lazyLoad: Ref<ILazyLoadInstance | null>) => {
  onUpdated(() => {
    nextTick(() => {
      if (!lazyLoad.value) {
        return
      }
      lazyLoad.value.update(document.documentElement.querySelectorAll('.js-lazy'))
    })
  })
}
