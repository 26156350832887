import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export class EventMessage extends IgnoredEvent {
  constructor (private message: string) {
    super()
  }

  isIgnored (event: Event) {
    return event.message === this.message
  }
}
