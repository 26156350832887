import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { IVueI18n } from 'vue-i18n'
import { MaybePromise } from '~/src/Model/UtilityTypes/MaybePromise'

export abstract class ValidationRule {
  constructor (protected i18n: IVueI18n) { }

  protected params: string[] = []
  protected abstract message: (field: string, params: Record<string, any>) => string

  protected abstract validate: (value: any, params: any[] | Record<string, any>) => MaybePromise<ValidationRuleResult>

  create () {
    return {
      params: this.params?.length ? [...this.params] : undefined,
      validate: this.validate,
      message: this.message,
      computesRequired: true
    }
  }
}

type ChildValidationRuleConstructor<T extends ValidationRule> = new (i18n: IVueI18n) => T

export const createRuleFactory = (i18n: IVueI18n) => (ruleClass: ChildValidationRuleConstructor<ValidationRule>) => {
  // eslint-disable-next-line new-cap
  return new ruleClass(i18n).create()
}
