




































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ButtonVariant } from '~/src/Model/Button/ButtonVariant'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    Modal,
    Button,
    PageSectionContent
  },
  props: {
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    confirmVariant: {
      type: String as PropType<ButtonVariant>,
      default: ButtonVariant.primary
    },
    bodyClass: {
      type: String,
      default: null
    },
    footerClass: {
      type: String,
      default: null
    },
    modalSize: {
      type: String,
      default: null
    },
    hasFooterNote: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'confirm', 'cancel']
})
