

































































import { defineComponent, computed, ref, watch, PropType } from '@nuxtjs/composition-api'
import BaseMultiselect from '~/components/DesignSystem/Select/BaseMultiselect.vue'
import { PhoneCodeFlags } from '~/src/Model/PhoneInput/PhoneCodes'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { usePhoneNumberHelpers } from '~/src/Infrastructure/PhoneNumber/PhoneNumberHelpers'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import { PhoneNumber } from '~/src/Model/PhoneInput/PhoneNumber'
import { filterWhitespaces } from '~/src/Infrastructure/String/FilterWhiteSpaces'

export default defineComponent({
  components: { FormInput, BaseMultiselect },
  props: {
    value: {
      type: String as PropType<string | null>,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    phoneDisabled: {
      type: Boolean,
      default: false
    },
    codeDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    singleLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const webConfig = useWebConfig()
    const phoneCodes = ref<string[]>(Object.keys(PhoneCodeFlags))
    const { splitPhoneNumber, stringifyPhoneNumber } = usePhoneNumberHelpers()
    const phoneNumberInitialized = ref<boolean>(false)

    const phone = ref<PhoneNumber>({
      phone: '',
      countryCallingCode: webConfig.phoneCode
    })

    const phoneCode = computed<string>({
      get: () => {
        if (phoneCodes.value.includes(phone.value.countryCallingCode)) {
          return phone.value.countryCallingCode
        }

        return phoneCodes.value[0]
      },
      set: (value) => {
        phone.value.countryCallingCode = value
        emitFilteredValue()
      }
    })

    const phoneNumber = computed<string>({
      get: () => phone.value.phone,
      set: (newPhoneNumber: string) => {
        if (newPhoneNumber.startsWith('00')) {
          newPhoneNumber = '+' + newPhoneNumber.substr(2)
        }

        const containsPhoneCode = Object.keys(PhoneCodeFlags).some((code) => {
          if (newPhoneNumber.substr(0, code.length) === code) {
            phone.value.countryCallingCode = code
            phone.value.phone = newPhoneNumber.substr(code.length)
            return true
          }
          return false
        })

        if (!containsPhoneCode) {
          phone.value.phone = newPhoneNumber
        }

        emitFilteredValue()
      }
    })

    const emitFilteredValue = () => {
      emit('input', filterWhitespaces(stringifyPhoneNumber(phone.value)))
    }

    watch(() => props.value, () => {
      if (!props.value || phoneNumberInitialized.value) {
        return
      }

      phone.value = splitPhoneNumber(props.value)
      phoneNumberInitialized.value = true
    }, { immediate: true })

    const setAsFirstCode = (setAsFirst: string): void => {
      phoneCodes.value = phoneCodes.value.filter(code => code !== setAsFirst)
      phoneCodes.value.unshift(setAsFirst)
    }

    setAsFirstCode(webConfig.phoneCode)

    const phoneCodeLength = computed<string>(() => {
      if (phoneCode.value === phoneCodes.value[0] || phoneCode.value === phoneCodes.value[1]) {
        const CZECH_PHONE_LENGTH = '9'
        return `${CZECH_PHONE_LENGTH},${CZECH_PHONE_LENGTH}`
      }

      const EUROPEAN_PHONE_LENGTH_MIN = '5'
      const EUROPEAN_PHONE_LENGTH_MAX = '9'
      return `${EUROPEAN_PHONE_LENGTH_MIN},${EUROPEAN_PHONE_LENGTH_MAX}`
    })

    const isRequired = computed<boolean>(() => props.rules.includes('required'))

    return {
      phoneCodes,
      phoneNumber,
      phoneCode,
      isRequired,
      PhoneCodeFlags,
      phoneCodeLength
    }
  }
})
