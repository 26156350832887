import { useContext } from '@nuxtjs/composition-api'
import { Config } from '~/src/Model/Config/Config'

const useWebConfig = (): Config => {
  const { $webConfig } = useContext()

  return $webConfig
}

export default useWebConfig
