import { defineStore } from 'pinia'
import { useCachedSystemPagesMapRepository } from '~/src/Infrastructure/SystemPages/SystemPagesRepository'
import { SystemPagesMap } from '~/src/Model/SystemPages/SystemPagesMap'
import { NavigationOptions } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

const SYSTEM_PAGES_STORE_ID = 'systemPages'

interface SystemPagesState {
  pages: SystemPagesMap
}

export const useSystemPagesStore = defineStore(SYSTEM_PAGES_STORE_ID, {
  state: (): SystemPagesState => ({
    pages: {}
  }),
  getters: {
    aliasByUid () {
      return (uid: string) => {
        return this.pages[uid]
      }
    },
    pathByUid () {
      return (uid: string, options: NavigationOptions | null = null) => {
        const path = `/${this.aliasByUid(uid)}`

        if (options && options.query) {
          return `${path}?${(new URLSearchParams(options.query)).toString()}`
        }

        return path
      }
    },
    uidByAlias () {
      return (alias: string) => {
        return Object.keys(this.pages).find(key => this.pages[key] === alias) ?? null
      }
    }
  },
  actions: {
    async loadPages () {
      const { loadSystemPages } = useCachedSystemPagesMapRepository(this.$nuxt.$axios, this.$nuxt.$serverCacheStorage)
      const pages = await loadSystemPages()

      this.pages = pages

      return pages
    }
  }
})
