import cloneDeep from 'lodash/cloneDeep'
import {
  Reclamation, ReclamationCustomerContactInfo, ReclamationCustomerRequestData,
  ReclamationInfoRequestData, ReclamationIssueInfoStepData, ReclamationIssueRequestData, ReclamationRequestData, ReclamationStateEnum
} from '~/src/Model/Reclamation/Reclamation'
import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'

export class ReclamationAtHome implements Reclamation {
  constructor (
    private readonly _purchaseInfo: PurchaseInfo,
    private readonly state: ReclamationStateEnum = ReclamationStateEnum.issueInfo,
    private readonly _issueInfoStepFormData: ReclamationIssueInfoStepData | null = null,
    private readonly _reclamationInfoData: ReclamationInfoRequestData | null = null,
    private readonly _customerContactInfo: ReclamationCustomerContactInfo | null = null
  ) {
  }

  get isHomeService () {
    return true
  }

  get currentState (): ReclamationStateEnum {
    return this.state
  }

  get purchaseInfo (): PurchaseInfo {
    return this._purchaseInfo
  }

  get issueInfoStepFormData (): ReclamationIssueInfoStepData | null {
    return cloneDeep(this._issueInfoStepFormData)
  }

  get reclamationInfoData (): ReclamationInfoRequestData | null {
    return this._reclamationInfoData
  }

  get customerContactInfo (): ReclamationCustomerContactInfo | null {
    return this._customerContactInfo
  }

  getReclamationRequestData (): ReclamationRequestData {
    const issue: ReclamationIssueRequestData = {
      issueDescription: this.issueInfoStepFormData!.issueDescription,
      state: this.issueInfoStepFormData!.state,
      serialNumber: this.issueInfoStepFormData!.serialNumber,
      imei: this.issueInfoStepFormData!.imei,
      photos: this.issueInfoStepFormData?.photos.map(photo => photo.id) ?? [],
      attachments: this.issueInfoStepFormData?.attachments.map(attachment => attachment.id) ?? []
    }

    const customer: ReclamationCustomerRequestData = {
      customer: this.customerContactInfo!.customer,
      storeId: this.customerContactInfo?.storeInfo?.storeId ?? null
    }

    return {
      issue,
      customer,
      info: this.reclamationInfoData,
      homeService: this.isHomeService,
      purchaseInfo: {
        ...this.purchaseInfo,
        itemId: this.purchaseInfo.itemId.toString()
      }
    }
  }

  isStateActive (state: ReclamationStateEnum): boolean {
    return this.currentState === state
  }

  nextStep (): ReclamationAtHome {
    switch (this.currentState) {
      case ReclamationStateEnum.issueInfo: {
        if (!this.issueInfoStepFormData) {
          throw new Error('You must submit issue info before')
        }

        return new ReclamationAtHome(
          this._purchaseInfo,
          ReclamationStateEnum.customerInfo,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      case ReclamationStateEnum.customerInfo: {
        if (!this.customerContactInfo) {
          throw new Error('You must submit customer contact info before')
        }

        return new ReclamationAtHome(
          this._purchaseInfo,
          ReclamationStateEnum.summary,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      default: {
        return this
      }
    }
  }

  previousStep (): ReclamationAtHome {
    switch (this.currentState) {
      case ReclamationStateEnum.customerInfo: {
        return new ReclamationAtHome(
          this._purchaseInfo,
          ReclamationStateEnum.issueInfo,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      default: {
        return this
      }
    }
  }

  updateIssueInfoStepFormData (issueInfoStepFormData: ReclamationIssueInfoStepData, reclamationInfoData: ReclamationInfoRequestData): ReclamationAtHome {
    return new ReclamationAtHome(
      this._purchaseInfo,
      this.currentState,
      issueInfoStepFormData,
      reclamationInfoData,
      this.customerContactInfo
    )
  }

  updateCustomerInfoStepFormData (customerContactInfo: ReclamationCustomerContactInfo): ReclamationAtHome {
    return new ReclamationAtHome(
      this._purchaseInfo,
      this.currentState,
      this.issueInfoStepFormData,
      this.reclamationInfoData,
      customerContactInfo
    )
  }
}
