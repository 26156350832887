import { Plugin } from '@nuxt/types'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { useCheckoutStore } from '~/src/Infrastructure/Checkout/CheckoutStore'

const persistedState: Plugin = ({ $pinia, $authService }) => {
  $pinia.use(createPersistedState())
  const checkoutStore = useCheckoutStore($pinia)

  $authService.onLoggedIn.subscribe(() => checkoutStore.clearCheckoutState())
}

export default persistedState
