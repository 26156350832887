import { defineStore } from 'pinia'
import { AxiosError } from 'axios'
import Big from 'big.js'
import { Severity } from '@sentry/types/dist/severity'
import { Scope } from '@sentry/types'
import {
  BasketDeliveryMethod,
  BasketDeliveryService, BasketDonation,
  BasketInfo,
  BasketPayMethod,
  BasketVoucher
} from '~/src/Model/Basket/BasketInfo'
import {
  BasketProduct,
  BasketProductServices, BasketProductsResponse,
  NewBasketProduct,
  UpdateBasketProduct
} from '~/src/Model/Basket/Product'
import {
  BasketProductBundle,
  NewBasketProductBundle,
  NewBasketProductBundleResponse,
  UpdateBasketProductBundle
} from '~/src/Model/Basket/BasketProductBundle'
import { BasketDialog, BasketValidationRequestOptions, BasketValidationResult } from '~/src/Model/Basket/BasketValidation'
import { useBasketRepositoryWithAxios } from '~/src/Infrastructure/Basket/BasketRepository'
import { BasketHashCookieStorage } from '~/src/Infrastructure/Cookies/BasketHashCookies'
import { ReservationSlot } from '~/src/Model/ComfortDelivery/Deprecated/Reservation'
import {
  useComfortDeliveryRepositoryWithAxios as useComfortDeliveryRepositoryWithAxiosDeprecated
} from '~/src/Infrastructure/ComfortDelivery/Deprecated/ComfortDeliveryRepository'
import { SlotToReserve } from '~/src/Model/ComfortDelivery/Reservation'
import { useComfortDeliveryRepositoryWithAxios } from '~/src/Infrastructure/ComfortDelivery/ComfortDeliveryRepository'
import { useApiErrorParser } from '~/src/Infrastructure/Api/ApiErrorParser'
import { ApiResponseError } from '~/src/Model/Error/ApiResponseError'
import { InternalErrorCode } from '~/src/Model/Error/InternalErrorCode'
import { DonationToApply } from '~/src/Model/Basket/DonationToApply'
import { ProductDeliveryServiceRequest } from '~/src/Model/Delivery/DeliveryMethodServices'
import { BasketCreditsResponse } from '~/src/Model/Basket/Credits'
import {
  BasketGift,
  ChangeBasketProductGiftRequest,
  DeleteBasketProductGiftRequest
} from '~/src/Model/ProductGift/ProductGift'
import { AddValueVoucherRequest, ValueVouchers } from '~/src/Model/Basket/ValueVoucher'
import { InstallmentChoice } from '~/src/Model/InstallmentsCalculator/InstallmentChoice'
import { Product } from '~/src/Model/Product/Product'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'
import { HttpStatusCode } from '~/src/Model/Error/HttpStatusCode'
import { usePageLoadEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Event/PageLoadEvent'

interface BasketState {
  hash: string | null
  basket: BasketInfo | null
  basketProducts: BasketProduct[]
  basketProductBundles: BasketProductBundle[]
  basketValidationResult: BasketValidationResult | null
  basketValidationResultHistory: BasketValidationResult | null
}

export const useBasketStore = defineStore('basket', {
  state: (): BasketState => ({
    hash: null,
    basket: null,
    basketProducts: [],
    basketProductBundles: [],
    basketValidationResult: null,
    basketValidationResultHistory: null
  }),
  getters: {
    basketVouchers (): BasketVoucher[] | null {
      return this.basket?.vouchers ?? null
    },
    valueVouchers (): ValueVouchers | null {
      return this.basket?.valueVouchers ?? null
    },
    totalPrice (): string {
      return this.basket?.totalPrice ?? '0'
    },
    payMethod (): BasketPayMethod | null {
      return this.basket?.payMethod ?? null
    },
    payMethodPrice (): string {
      return bigify(this.payMethod?.price ?? 0).toString()
    },
    deliveryMethod (): BasketDeliveryMethod | null {
      return this.basket?.deliveryMethod ?? null
    },
    productsWithGifts (): Product[] {
      return [
        ...this.basketProducts.flatMap((basketProduct: BasketProduct) => {
          return [
            ...Array(basketProduct.quantity).fill(basketProduct.product),
            ...Array(basketProduct.quantity).fill(basketProduct.gifts.map(gift => gift.product)).flat()
          ]
        }),
        ...this.basketProductBundles.flatMap((bundle: BasketProductBundle) => bundle.products)
      ]
    },
    deliveryPriceWithSlot (): string {
      return bigify(0)
        .add(this.basket?.deliveryMethod?.price ?? 0)
        .add(this.basket?.deliveryMethod?.comfortDelivery?.selectedSlot?.price ?? 0)
        .toString()
    },
    deliveryServicesTotalPrice (): string {
      return this.basket?.deliveryMethod?.services.reduce(
        (prev: Big, service: BasketDeliveryService) => prev.plus(service.unitPrice).mul(service.quantity), bigify(0)).toString() ?? '0'
    },
    installmentChoice (): InstallmentChoice | null {
      const installment = this.basket?.installmentChoice ?? null
      if (!installment) {
        return null
      }

      if (this.totalPrice !== installment.financingAmount) {
        return null
      }

      return installment
    },
    validationResultBasketChanged (): boolean {
      return (this.basketValidationResult?.basketChanged && this.basketValidationResultHistory?.basketChanged) ?? false
    },
    isEmptyBasket (): boolean {
      return this.basketProductsCount === 0 && this.basketProductBundles.length === 0
    },
    basketProductsCount (): number {
      return this.basket?.totalQuantity ?? 0
    },
    isServiceInBasket (): boolean {
      return this.basketProducts.some((product: BasketProduct) => product.services.length)
    },
    isBasketPaid (): boolean {
      return bigify(this.totalPrice).eq(0)
    },
    selectedDonation (): BasketDonation | null {
      return this.basket?.donation ?? null
    }
  },
  actions: {
    async createBasket (): Promise<BasketInfo> {
      const { create: createBasket } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await createBasket()

      const basketHashCookie = new BasketHashCookieStorage(this.$nuxt.$cookies)

      if (!this.$nuxt.$auth.user.value) {
        basketHashCookie.setBasketHashCookie(basketInfo.hash)
      }

      this.hash = basketInfo.hash
      this.basket = basketInfo

      return basketInfo
    },
    async loadBasketInfo (): Promise<BasketInfo | null> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      return await this.safeBasketCall(async () => {
        const { loadInfo } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
        const basketInfo: BasketInfo = await loadInfo(this.hash!)

        this.basket = basketInfo

        return basketInfo
      })
    },
    deleteLocalBasket (): Promise<void> {
      const basketHashCookie = new BasketHashCookieStorage(this.$nuxt.$cookies)

      this.hash = null
      this.basket = null
      this.basketProducts = []
      basketHashCookie.removeBasketHashCookie()

      return Promise.resolve()
    },
    async setDelivery (deliveryMethodId: number, zip: string | null = null, externalSystemData: string | null = null): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { setDelivery } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await setDelivery(this.hash, { deliveryMethodId, zip, externalSystemData })

      this.basket = basketInfo
      await this.loadProducts()

      return basketInfo
    },
    async deleteDelivery (): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteDelivery } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await deleteDelivery(this.hash)

      this.basket = basketInfo

      return basketInfo
    },
    async reserveComfortDeliveryDeprecated (reservation: ReservationSlot): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { reserve } = useComfortDeliveryRepositoryWithAxiosDeprecated(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await reserve({ ...reservation, basketHash: this.hash })

      this.basket = basketInfo

      return basketInfo
    },
    async reserveComfortDelivery (slotToReserve: SlotToReserve): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { reserve } = useComfortDeliveryRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await reserve({ ...slotToReserve, basketHash: this.hash })

      this.basket = basketInfo

      return basketInfo
    },
    async clearComfortDeliverySlot (): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { clearTimeSlot } = useComfortDeliveryRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await clearTimeSlot(this.hash)

      this.basket = basketInfo

      return basketInfo
    },
    async setPayment (payMethodId: number): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { setPayment } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await setPayment(this.hash, payMethodId)

      this.basket = basketInfo

      return basketInfo
    },
    async deletePayment (): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deletePayment } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await deletePayment(this.hash)

      this.basket = basketInfo

      return basketInfo
    },
    fireEvent (): void {
      // @ts-ignore
      const pageLoadEvent = usePageLoadEvent(this.$nuxt.$gtm, this.$nuxt.$axios)
      if (this.hash) {
        pageLoadEvent.fire(this.hash)
      }
    },
    async addProduct (newProduct: NewBasketProduct): Promise<BasketProduct | null> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addProduct } = useBasketRepositoryWithAxios(this.$nuxt.$axios)

      return await this.safeBasketCall(async () => {
        const basketProduct: BasketProduct = await addProduct(this.hash!, newProduct)
        await this.loadBasketInfo()

        const existingBasketProduct = this.basketProducts.find((p: BasketProduct) => p.product.id === newProduct.productId)
        if (existingBasketProduct) {
          await this.loadProducts()
        } else {
          this.basketProducts = [...this.basketProducts, basketProduct]
        }

        this.fireEvent()

        return basketProduct
      })
    },
    async updateProduct (updateBasketProduct: UpdateBasketProduct): Promise<BasketProduct> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { updateProduct } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketProduct: BasketProduct = await updateProduct(
        this.hash,
        updateBasketProduct.basketProductId,
        updateBasketProduct.quantity
      )

      const basketProducts = this.basketProducts
      const indexOfBasketProduct = basketProducts.findIndex((p: BasketProduct) => p.id === updateBasketProduct.basketProductId)
      const newBasketProductsCollection = [...basketProducts]
      newBasketProductsCollection[indexOfBasketProduct] = basketProduct

      this.basketProducts = newBasketProductsCollection
      await this.loadBasketInfo()
      this.fireEvent()

      return basketProduct
    },
    async updateProductsByDialog (basketDialogs: BasketDialog[]): Promise<void> {
      const basketHash = this.hash
      if (!basketHash) {
        throw new Error('Hash is not set')
      }
      const { updateProduct, deleteProduct } = useBasketRepositoryWithAxios(this.$nuxt.$axios)

      for (const dialog of basketDialogs) {
        if (dialog.availableStock === 0) {
          await deleteProduct(basketHash, dialog.basketItemId)
        } else {
          await updateProduct(basketHash, dialog.basketItemId, dialog.availableStock)
        }
      }

      await this.loadProducts()
      await this.loadBasketInfo()
      this.fireEvent()
    },
    async deleteProduct (basketProductId: number): Promise<void> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }
      const { deleteProduct } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const { parseInternalErrorCode } = useApiErrorParser()

      try {
        await deleteProduct(this.hash, basketProductId)
      } catch (axiosError) {
        const error = axiosError as AxiosError<ApiResponseError>
        if (parseInternalErrorCode(error) !== InternalErrorCode.PRODUCT_NOT_FOUND) {
          throw new Error(error.message)
        }
      }

      const basketProducts: BasketProduct[] = this.basketProducts
      this.basketProducts = [...basketProducts.filter(p => p.id !== basketProductId)]
      await this.loadBasketInfo()
      this.fireEvent()
    },
    async loadProducts (): Promise<BasketProductsResponse | null> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      return await this.safeBasketCall(async () => {
        const { getProducts } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
        const basketProductsResponse = await getProducts(this.hash!)

        this.basketProducts = basketProductsResponse.products

        return basketProductsResponse
      })
    },
    async deleteProductBundle (basketProductBundleId: number): Promise<void> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteProductBundle } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      await deleteProductBundle(this.hash, basketProductBundleId)

      const basketProductBundles = this.basketProductBundles
      this.basketProductBundles = [...basketProductBundles.filter((p: BasketProductBundle) => p.id !== basketProductBundleId)]

      await this.loadBasketInfo()
      this.fireEvent()
    },
    async updateProductBundle (updateBasketProductBundle: UpdateBasketProductBundle): Promise<NewBasketProductBundleResponse> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { updateProductBundle } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketProductBundle: NewBasketProductBundleResponse = await updateProductBundle(
        this.hash,
        updateBasketProductBundle.basketBundleId,
        updateBasketProductBundle.quantity
      )

      const basketProductBundles = this.basketProductBundles
      const indexOfBasketProductBundle = basketProductBundles.findIndex((p: BasketProductBundle) => p.id === updateBasketProductBundle.basketBundleId)
      const newBasketProductBundlesCollection = [...basketProductBundles]
      newBasketProductBundlesCollection[indexOfBasketProductBundle] = basketProductBundle.productBundle

      this.basketProductBundles = newBasketProductBundlesCollection
      await this.loadBasketInfo()
      this.fireEvent()

      return basketProductBundle
    },
    async addProductBundle (newProductBundle: NewBasketProductBundle): Promise<BasketProductBundle | null> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addProductBundle } = useBasketRepositoryWithAxios(this.$nuxt.$axios)

      return await this.safeBasketCall(
        async () => {
          const basketProductBundle: BasketProductBundle = (await addProductBundle(this.hash!, newProductBundle)).productBundle

          this.basketProductBundles = [...this.basketProductBundles, basketProductBundle]
          await this.loadBasketInfo()
          this.fireEvent()
          return basketProductBundle
        }
      )
    },
    async loadProductBundles (): Promise<BasketProductBundle[]> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { getProductBundles } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketProductBundles: BasketProductBundle[] = (await getProductBundles(this.hash)).productBundles

      this.basketProductBundles = basketProductBundles
      return basketProductBundles
    },
    async updateServices (basketProductId: number, variantIds: number[]): Promise<BasketProductServices> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { updateServices } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketProductServices: BasketProductServices = await updateServices(this.hash, { basketProductId, variantIds })

      const basketProducts = this.basketProducts
      this.basketProducts = basketProducts.map((bp: BasketProduct) => {
        if (bp.id === basketProductId) {
          return {
            ...bp,
            services: basketProductServices.services
          }
        }

        return bp
      })
      await this.loadBasketInfo()

      return basketProductServices
    },
    async deleteService (basketProductId: number, basketServiceId: number): Promise<void> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteService } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      await deleteService(this.hash, basketProductId, basketServiceId)

      const basketProducts = this.basketProducts
      this.basketProducts = basketProducts.map((bp: BasketProduct) => {
        if (bp.id === basketProductId) {
          return {
            ...bp,
            services: bp.services.filter(s => s.id !== basketServiceId)
          }
        }

        return bp
      })

      await this.loadBasketInfo()
    },

    async addVoucher (code: string): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addVoucher } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await addVoucher(this.hash, code)

      this.basket = basketInfo

      return basketInfo
    },
    async deleteVoucher (id: number): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteVoucher } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await deleteVoucher(this.hash, id)

      this.basket = basketInfo

      return basketInfo
    },
    async applyDonation (donationToApply: DonationToApply): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { applyDonation } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await applyDonation(this.hash, donationToApply)

      this.basket = basketInfo

      return basketInfo
    },
    async deleteDonation (): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteDonation } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await deleteDonation(this.hash)

      this.basket = basketInfo

      return basketInfo
    },
    async addDeliveryService (serviceItemId: number): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addDeliveryService } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo = await addDeliveryService(this.hash, serviceItemId)

      this.basket = basketInfo

      return basketInfo
    },
    async deleteDeliveryService (serviceItemId: number): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteDeliveryService } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo = await deleteDeliveryService(this.hash, serviceItemId)

      this.basket = basketInfo

      return basketInfo
    },
    async addProductDeliveryService (service: ProductDeliveryServiceRequest): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addProductDeliveryService } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo = await addProductDeliveryService(this.hash, service)

      this.basket = basketInfo
      await this.loadProducts()

      return basketInfo
    },
    async applyCredits (creditsAmount: string): Promise<BasketCreditsResponse> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { applyCredits } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketCreditsResponse: BasketCreditsResponse = await applyCredits(this.hash, creditsAmount)

      this.basket = basketCreditsResponse.basket

      return basketCreditsResponse
    },
    async deleteCredits (): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteCredits } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketCreditsResponse: BasketInfo = await deleteCredits(this.hash)

      this.basket = basketCreditsResponse

      return basketCreditsResponse
    },
    async updateGifts (changeBasketProductGiftRequest: ChangeBasketProductGiftRequest): Promise<BasketGift[]> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { updateGifts } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      return await updateGifts(this.hash, changeBasketProductGiftRequest)
    },
    async deleteGift (deleteBasketProductGiftRequest: DeleteBasketProductGiftRequest): Promise<void> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteGift } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      return await deleteGift(this.hash, deleteBasketProductGiftRequest)
    },
    async validateBasket (basketValidationRequestOptions: BasketValidationRequestOptions): Promise<BasketValidationResult> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { validateBasket } = useBasketRepositoryWithAxios(this.$nuxt.$axios)

      const validationResult = await validateBasket(this.hash, basketValidationRequestOptions)

      if (validationResult.forceRedirect?.link) {
        this.basketValidationResultHistory = validationResult
      } else {
        this.basketValidationResult = validationResult
      }

      if (validationResult.basketChanged || validationResult.errors.length) {
        await this.reloadAll()
      }

      return validationResult
    },
    reloadAll (): Promise<unknown> {
      return Promise.all([
        this.loadBasketInfo(),
        this.loadProducts(),
        this.loadProductBundles()
      ])
    },
    setBasketValidationResultHistory (): void {
      this.basketValidationResultHistory = this.basketValidationResult
    },
    clearBasketValidationResultHistory (): void {
      this.basketValidationResultHistory = null
    },
    async addValueVoucher (addValueVoucherRequest: AddValueVoucherRequest): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { addValueVoucher } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await addValueVoucher(this.hash, addValueVoucherRequest)

      this.basket = basketInfo

      return basketInfo
    },
    async deleteValueVoucher (basketVoucherId: number): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { deleteValueVoucher } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const basketInfo: BasketInfo = await deleteValueVoucher(this.hash, basketVoucherId)

      this.basket = basketInfo

      return basketInfo
    },
    async setInstallmentChoice (installmentChoice: InstallmentChoice): Promise<BasketInfo> {
      if (!this.hash) {
        throw new Error('Hash is not set')
      }

      const { setInstallmentChoice } = useBasketRepositoryWithAxios(this.$nuxt.$axios)
      const installmentChoiceResponse: BasketInfo = await setInstallmentChoice(this.hash, installmentChoice)

      this.basket = installmentChoiceResponse

      return installmentChoiceResponse
    },
    clearInstallmentChoice (): void {
      if (!this.basket) {
        return
      }

      this.basket.installmentChoice = null
    },
    updateBasketHash (hash: string | null): void {
      this.hash = hash
    },
    async safeBasketCall<T> (callback: () => Promise<T>): Promise<T | null> {
      try {
        return await callback()
      } catch (error) {
        const e = error as AxiosError
        const statusCode = e.response?.status ?? 0
        const internalErrorCode = e.response?.data?.error?.internalErrorCode ?? null

        if (!internalErrorCode! || ![HttpStatusCode.UNAUTHORIZED, HttpStatusCode.NOT_FOUND].includes(statusCode)) {
          throw e
        }

        if (statusCode === HttpStatusCode.NOT_FOUND && internalErrorCode !== InternalErrorCode.BASKET_NOT_FOUND) {
          throw e
        }

        if (statusCode === HttpStatusCode.UNAUTHORIZED && internalErrorCode !== InternalErrorCode.FORBIDDEN_BASKET) {
          throw e
        }

        this.$nuxt.$sentry.withScope((scope: Scope) => {
          scope.setLevel(Severity.Warning)
          scope.setTransactionName('Failed to load basket')

          this.$nuxt.$sentry.captureException(e)
        })

        await this.deleteLocalBasket()

        return null
      }
    },
    async loadProductsIfNotEmpty (): Promise<void> {
      if (this.basketProductsCount !== 0 && this.basketProducts.length === 0) {
        await this.loadProducts()
      }
    },
    updateBasketFromHash (hash: string | null): Promise<BasketInfo | null> {
      this.updateBasketHash(hash)

      if (hash) {
        return this.loadBasketInfo()
      }

      return Promise.resolve(null)
    },
    async createBasketIfNotExists (): Promise<BasketInfo | null> {
      if (this.hash) {
        return Promise.resolve(null)
      }

      const basketHashCookieStorage = new BasketHashCookieStorage(this.$nuxt.$cookies)
      if (basketHashCookieStorage.basketHashCookie) {
        return await this.updateBasketFromHash(basketHashCookieStorage.basketHashCookie)
      }

      return await this.createBasket()
    }
  }
})
