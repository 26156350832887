import { Ref, useMeta, useRoute } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export const usePaginationMetaTags = (page: Ref<number>, lastPage: number, queryName: string) => {
  const route = useRoute()
  const webConfig = useWebConfig()

  useMeta(() => {
    const links = []

    if (page.value === 1) {
      links.push({
        rel: 'canonical',
        href: `https://${webConfig.domain}${route.value.path}`
      })
    }

    if (page.value === 2) {
      links.push({
        rel: 'prev',
        href: `${route.value.path}`
      })
    }

    if (page.value > 2) {
      links.push({
        rel: 'prev',
        href: `${route.value.path}?${queryName}=${page.value - 1}`
      })
    }

    if (page.value < lastPage) {
      links.push({
        rel: 'next',
        href: `${route.value.path}?${queryName}=${page.value + 1}`
      })
    }

    return {
      link: links
    }
  })
}
