








































































































































































import { defineComponent, ref, watch, PropType, computed, useRoute } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { useMatchMedia } from '~/src/Infrastructure/MatchMedia/MatchMedia'
import { useMainNavigationRepository } from '~/src/Infrastructure/MainNavigation/MainNavigationRepository'
import { ActiveHeaderPanel } from '~/src/Model/ActiveHeaderPanel/ActiveHeaderPanel'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import NavigationLevel2 from '~/components/Header/MainMenu/NavigationLevel2.vue'
import NavigationLevel3 from '~/components/Header/MainMenu/NavigationLevel3.vue'
import NavigationLevel4 from '~/components/Header/MainMenu/NavigationLevel4.vue'
import { NavigationNode, NavigationNodeId } from '~/src/Model/NavigationNode/NavigationNode'
import { useCoreDataStore } from '~/src/Infrastructure/CoreData/CoreDataStore'
import Banner from '~/components/Banner/Banner.vue'
import { Banner as BannerType } from '~/src/Model/Banner/Banner'
import { useBannersMenu } from '~/src/Infrastructure/Banners/BannersMenu'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import MainMenuVouchersBrands from '~/components/Header/MainMenu/VouchersBrands/MainMenuVouchersBrands.vue'

export default defineComponent({
  components: {
    Container,
    MainMenuVouchersBrands,
    Banner,
    NavigationLevel3,
    NavigationLevel2,
    NavigationLevel4
  },
  props: {
    activePanel: {
      type: String as PropType<ActiveHeaderPanel>,
      required: true
    }
  },
  emits: ['order-helper-clicked', 'menu-closed', 'menu-opened', 'active-level-changed'],
  setup (props, { emit }) {
    const webConfig = useWebConfig()
    const coreDataStore = useCoreDataStore()
    const topLevelNavigation = coreDataStore.mainNavigationTopLevelNavigation
    const isNay = computed<boolean>(() => webConfig.web === Web.Nay)

    const { loadMainNavigation } = useMainNavigationRepository()

    const activeTopLevelNode = ref<NavigationNode|null>(null)
    const activeNodeLevel1 = computed<NavigationNode|null>(() => {
      if (!activeTopLevelNode.value) {
        return null
      }
      return mainNavigation.value?.nodes.find(mainNavigationNode => mainNavigationNode.alias === activeTopLevelNode.value?.alias) ?? null
    })
    const activeNodeLevel2 = ref<NavigationNode|null>(null)
    const activeNodeLevel3 = ref<NavigationNode|null>(null)
    const emptyNode = ref<NavigationNode>({
      name: '',
      alias: '',
      image: null,
      pageId: 0,
      children: [],
      hasChildren: false
    })
    const activeLevel = ref<number>(1)
    const route = useRoute()

    const { loadMenuBanner, loadMobileTopLevelBanner } = useBannersMenu()
    const { matches: isTabletActive } = useMatchMedia('(max-width: 991.98px)')
    const topMenuBanner = ref<BannerType>()
    const categoryBannersMap = new Map<number, BannerType>()
    const currentCategoryBanner = ref<BannerType>()

    const loadCurrentCategoryBanner = async (pageId: number) => {
      let categoryBanner: BannerType | undefined = categoryBannersMap.get(pageId)
      if (!categoryBanner) { // pokud jsme ještě neposílali request na banner pro tuto kategorii
        categoryBanner = await loadMenuBanner(pageId)
        categoryBannersMap.set(pageId, categoryBanner)
      }
      currentCategoryBanner.value = categoryBanner
    }

    const disabledBrands = computed<NavigationNodeId[]>(() => {
      if (isNay) {
        return [
          NavigationNodeId.Node214729
        ]
      }

      return [
        NavigationNodeId.Node214729,
        NavigationNodeId.Node66270
      ]
    })

    const {
      result: mainNavigation,
      exec: execLoadMainNavigation,
      loading: loadingMainNavigation
    } = usePromise(() => loadMainNavigation())

    const lazyLoadMenu = async () => {
      if (mainNavigation.value !== null) {
        return
      }

      if (loadingMainNavigation.value) {
        return
      }

      await execLoadMainNavigation()
    }

    watch(() => props.activePanel, async () => {
      if (props.activePanel !== ActiveHeaderPanel.mainMenu) {
        activeTopLevelNode.value = null
        activeNodeLevel2.value = null
        activeNodeLevel3.value = null
        activeLevel.value = 1
      }

      if (props.activePanel === ActiveHeaderPanel.mainMenu) {
        lazyLoadMenu()
        if (isTabletActive.value && !topMenuBanner.value) { // pokud jsme v mobilní verzi, načti banner pro topLevelNavigation
          topMenuBanner.value = await loadMobileTopLevelBanner()
        }
      }
    })

    watch(route, () => {
      menuClosed()
    })

    watch(activeLevel, () => {
      emit('active-level-changed', activeLevel.value)
    })

    const processClickLevel1 = (node: NavigationNode) => {
      if (activeLevel.value === 2 && activeTopLevelNode.value === node) {
        menuClosed()
        return
      }

      activeTopLevelNode.value = node
      loadCurrentCategoryBanner(node.pageId)

      if (activeLevel.value === 1) {
        menuOpened()
      }

      activeLevel.value = 2
    }

    const stepForward = (node: NavigationNode, fromLevel: number) => {
      if (fromLevel === 2) {
        activeNodeLevel2.value = node
        activeLevel.value = 3
      } else if (fromLevel === 3) {
        activeNodeLevel3.value = node
        activeLevel.value = 4
      }
    }

    const orderHelperClick = () => {
      emit('order-helper-clicked')
    }

    const menuOpened = () => {
      emit('menu-opened')
    }

    const menuClosed = () => {
      emit('menu-closed')
    }

    return {
      mainNavigation,
      topLevelNavigation,
      activeLevel,
      activeTopLevelNode,
      activeNodeLevel1,
      activeNodeLevel2,
      activeNodeLevel3,
      orderHelperClick,
      processClickLevel1,
      menuClosed,
      menuOpened,
      stepForward,
      emptyNode,
      lazyLoadMenu,
      topMenuBanner,
      currentCategoryBanner,
      disabledBrands,
      isNay
    }
  }
})
