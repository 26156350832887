


































import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useProductComparisonStore } from '~/src/Infrastructure/ProductComparison/ProductComparisonStore'
import Popover from '~/components/DesignSystem/Popover/Popover.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    Popover,
    Button
  },
  props: {
    productId: {
      required: true,
      type: Number
    },
    target: {
      default: null,
      type: [String, Object]
    },
    popoverClass: {
      type: String,
      default: null
    },
    popoverContainer: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const productComparisonStore = useProductComparisonStore()
    const showPopover = ref<boolean>(false)

    const isInCompare = productComparisonStore.isInComparison(props.productId)

    const toggleProduct = () => {
      showPopover.value = true

      if (!isInCompare.value) {
        productComparisonStore.addProduct(props.productId)
      }
    }

    const deleteFromComparison = () => {
      productComparisonStore.removeProduct(props.productId)
      showPopover.value = false
    }

    return {
      showPopover,
      isInCompare,
      toggleProduct,
      deleteFromComparison
    }
  }
})
