import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { SystemPagesMap } from '~/src/Model/SystemPages/SystemPagesMap'
import { ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'
import { JsonCacheStorage } from '~/src/Infrastructure/Cache/Server/JsonCacheStorage'

export const useSystemPagesMapRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadSystemPages: (): Promise<SystemPagesMap> => $axios.$get<SystemPagesMap>('/system-pages-map')
  }
}

type SystemPagesRepositoryType = ReturnType<typeof useSystemPagesMapRepositoryWithAxios>

export const useCachedSystemPagesMapRepository = (
  $axios: NuxtAxiosInstance,
  cacheStorage: ServerCacheStorage
): SystemPagesRepositoryType => {
  const jsonCacheStorage = new JsonCacheStorage<SystemPagesMap>(cacheStorage)
  const systemPagesMapRepository = useSystemPagesMapRepositoryWithAxios($axios)

  return {
    loadSystemPages: async () => {
      return await jsonCacheStorage.load(
        'system-pages-map',
        () => systemPagesMapRepository.loadSystemPages(),
        { expireSeconds: 120 }
      )
    }
  }
}
