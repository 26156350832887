import { Event } from '@sentry/types'
import { IgnoredEvents } from '~/plugins/sentry/ignoredEvents'

export const isEventIgnored = (event: Event, ignoredEvents: IgnoredEvents): boolean => {
  for (const ignoredEvent of ignoredEvents) {
    if (ignoredEvent.isIgnored(event)) {
      return true
    }
  }

  return false
}
