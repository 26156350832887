import VueI18n, { LocaleMessageObject } from 'vue-i18n'
import { defineStore } from 'pinia'
import { MainNavigation } from '~/src/Model/MainNavigation/MainNavigation'
import { NavigationNode } from '~/src/Model/NavigationNode/NavigationNode'
import { Announcement } from '~/src/Model/Announcements/Announcements'
import { useCachedMainNavigationRepository } from '~/src/Infrastructure/MainNavigation/MainNavigationRepository'
import { useNavigationNodesRepositoryWithAxios } from '~/src/Infrastructure/NavigationNodes/NavigationNodesRepository'
import { useCachedAnnouncementsRepository } from '~/src/Infrastructure/Announcements/AnnouncementsRepository'
import { useCachedTranslationsRepository } from '~/src/Infrastructure/Translations/TranslationsRepository'

const CORE_DATA_STORE_ID = 'coreData'

export interface CoreDataState {
  topLevelMainNavigation: MainNavigation | null
  footerMenuNodes: NavigationNode[] | null
  announcements: Announcement[] | null
  translations: LocaleMessageObject
}

export const useCoreDataStore = defineStore(CORE_DATA_STORE_ID, {
  state: (): CoreDataState => ({
    topLevelMainNavigation: null,
    footerMenuNodes: null,
    announcements: null,
    translations: {}
  }),
  actions: {
    async loadTopLevelMainNavigation (): Promise<MainNavigation> {
      const { loadTopLevelNavigation } = useCachedMainNavigationRepository(this.$nuxt.$axios, this.$nuxt.$serverCacheStorage)
      const topLevelNavigation = await loadTopLevelNavigation()

      this.topLevelMainNavigation = topLevelNavigation

      return Promise.resolve(topLevelNavigation)
    },
    async loadFooterMenuNodes (): Promise<NavigationNode[]> {
      const { loadNavigationNodes } = useNavigationNodesRepositoryWithAxios(this.$nuxt.$axios)
      const navigationNodesResponse = await loadNavigationNodes('footerMenu')

      this.footerMenuNodes = navigationNodesResponse.nodes

      return Promise.resolve(navigationNodesResponse.nodes)
    },
    async loadAnnouncements (): Promise<Announcement[]> {
      const { loadAnnouncements } = useCachedAnnouncementsRepository(this.$nuxt.$axios, this.$nuxt.$serverCacheStorage)
      const announcementsResponse = await loadAnnouncements()

      this.announcements = announcementsResponse.announcements

      return Promise.resolve(announcementsResponse.announcements)
    },
    async loadTranslations (): Promise<void> {
      const vue18n = this.$nuxt.app.i18n as VueI18n
      const { loadTranslations } = useCachedTranslationsRepository(this.$nuxt.$axios, this.$nuxt.$serverCacheStorage)
      const translations = await loadTranslations(vue18n.locale)

      this.translations = translations
      vue18n.setLocaleMessage(vue18n.locale, translations)
    }
  }
})
