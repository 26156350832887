import { Plugin } from '@nuxt/types'
import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { BasketDataLayerDataRepository } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Repository/BasketDataLayerDataRepository'
import { PageLoadEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Event/PageLoadEvent'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'

const clientLoadPlugin: Plugin = ({ $axios, app, $pinia }) => {
  const basketStore = useBasketStore($pinia)
  if (!basketStore.basketInfo.value) {
    return
  }

  const dataLayer = new DataLayer(app.$gtm)

  const pageLoadEvent = new PageLoadEvent(
    dataLayer,
    new BasketDataLayerDataRepository($axios)
  )

  if (basketStore.basketHash.value) {
    pageLoadEvent.fire(basketStore.basketHash.value)
  }
}

export default clientLoadPlugin
