


















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  inheritAttrs: false,
  props: {
    cols: {
      type: [String, Array],
      default: null
    },
    sm: {
      type: [String, Array],
      default: null
    },
    md: {
      type: [String, Array],
      default: null
    },
    lg: {
      type: [String, Array],
      default: null
    },
    xl: {
      type: [String, Array],
      default: null
    },
    offset: {
      type: Number,
      default: null
    },
    offsetSm: {
      type: Number,
      default: null
    },
    offsetMd: {
      type: Number,
      default: null
    },
    offsetLg: {
      type: Number,
      default: null
    },
    offsetXl: {
      type: Number,
      default: null
    },
    tag: {
      type: String,
      default: 'div'
    }
  },

  setup () {
    return {}
  }
})
