import { FilterQueryTags } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterSelectedTags } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { TranslatedTags } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'

export type TagsConvertorType = FilterElementConvertor<FilterSelectedTags, FilterQueryTags, TranslatedTags>

export class TagsConvertor implements TagsConvertorType {
  constructor (
    private readonly identifier: string = 'tags'
  ) {
  }

  toUrl (filterSelectedTags: FilterSelectedTags): UrlElement {
    return new UrlElement(
      this.identifier,
      filterSelectedTags
    )
  }

  toUrlFromTranslated (attribute: TranslatedTags): UrlElement {
    return new UrlElement(
      this.identifier,
      attribute
    )
  }

  fromUrl (element: UrlElement): FilterQueryTags {
    if (element.values.length === 0) {
      throw new Error('missing parameter values')
    }

    return element.values
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }

  shouldConvert (attribute: FilterSelectedTags): boolean {
    return attribute.length > 0
  }
}
