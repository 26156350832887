








































































































import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from '@nuxtjs/composition-api'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { BasketProduct } from '~/src/Model/Basket/Product'
import Sidebar from '~/components/Sidebar/Sidebar.vue'
import { useNavigateToSystemPage } from '~/src/Infrastructure/SystemPages/NavigateToSystemPage'
import ProductServices from '~/components/Basket/PreBasket/ProductServices/ProductServices.vue'
import {
  FinancialService,
  SelectedFinancialService,
  SelectedTechnicalService,
  TechnicalService
} from '~/src/Model/ProductServices/ProductServices'
import ProductServiceModalContent from '~/components/Basket/PreBasket/ProductServices/ProductServiceModalContent.vue'
import PreBasketGifts from '~/components/Basket/PreBasket/PreBasketGifts/PreBasketGifts.vue'
import { BasketGift, BasketSelectedGift, ChangeBasketProductGiftRequest } from '~/src/Model/ProductGift/ProductGift'
import { BasketProductBundle } from '~/src/Model/Basket/BasketProductBundle'
import PreBasketProductBundleItem from '~/components/Basket/PreBasket/PreBasketProductBundleItem.vue'
import ProductGroup from '~/components/Basket/Components/ProductGroup.vue'
import ProductBox from '~/components/Basket/Components/ProductBox.vue'
import ProductLink from '~/components/Basket/Components/ProductLink.vue'
import ProductImage from '~/components/Basket/Components/ProductImage.vue'
import ProductName from '~/components/Basket/Components/ProductName.vue'
import ProductType from '~/components/Basket/Components/ProductType.vue'
import ProductPrice from '~/components/Basket/Components/ProductPrice.vue'
import PreBasketSidebarFooter from '~/components/Basket/PreBasket/PreBasketSidebarFooter.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: {
    PreBasketSidebarFooter,
    PreBasketProductBundleItem,
    ProductServiceModalContent,
    ProductServices,
    PreBasketGifts,
    Sidebar,
    ProductGroup,
    ProductBox,
    ProductLink,
    ProductImage,
    ProductName,
    ProductType,
    ProductPrice
  },
  props: {
    basketProduct: {
      type: Object as PropType<BasketProduct | null>,
      default: null
    },
    basketProductBundle: {
      type: Object as PropType<BasketProductBundle | null>,
      default: null
    },
    selectedFinancialServices: {
      required: true,
      type: Array as PropType<SelectedFinancialService[]>
    },
    selectedTechnicalServices: {
      required: true,
      type: Array as PropType<SelectedTechnicalService[]>
    }
  },
  emits: ['hidden', 'services-initialized'],
  setup (props, { emit }) {
    const { navigateToUid } = useNavigateToSystemPage()
    const basketStore = useBasketStore()
    const { karpatyStopSelling } = useWebConfig()
    const selectedGifts = ref<BasketGift[]>(props.basketProduct?.gifts ?? [])

    const productServiceDetailActive = ref<boolean>(false)
    const submodalTitle = ref<string|null>(null)
    const serviceDetail = ref<TechnicalService|FinancialService|null>(null)

    const servicesSaved = ref<boolean>(false)
    const localSelectedFinancialServices = ref<SelectedFinancialService[]>(props.selectedFinancialServices)
    const localSelectedTechnicalServices = ref<SelectedTechnicalService[]>(props.selectedTechnicalServices)

    const selectedServiceIds = computed<number[]>(() => {
      return [
        ...localSelectedFinancialServices.value.map((s: SelectedFinancialService) => s.variant.id),
        ...localSelectedTechnicalServices.value.map((s: SelectedTechnicalService) => s.variant.id)
      ]
    })

    const basketInitialized = ref<boolean>(false)

    onMounted(async () => {
      await basketStore.reloadAll()
      basketInitialized.value = true

      emit('services-initialized')
      document.body?.classList.add('sidebar-open')
    })

    onUnmounted(() => {
      document.body?.classList.remove('sidebar-open')
    })

    watch(props, () => {
      if (props.basketProduct) {
        document.body.classList.add('sidebar-open')
      } else {
        document.body.classList.remove('sidebar-open')
      }
    })

    const saveServices = async () => {
      if (!props.basketProduct) {
        return
      }

      await basketStore.updateServices(props.basketProduct.id, selectedServiceIds.value)
      servicesSaved.value = true
    }

    watch(selectedServiceIds, saveServices)

    const closeSubSidebar = () => {
      productServiceDetailActive.value = false
      serviceDetail.value = null
      submodalTitle.value = null
    }

    const openProductServiceDetail = (service: TechnicalService | FinancialService) => {
      productServiceDetailActive.value = true
      submodalTitle.value = service.name
      serviceDetail.value = service
    }

    const sidebarHidden = () => {
      if (!servicesSaved.value) {
        saveServices()
      }

      closeSubSidebar()
      emit('hidden')
    }

    const selectedProductGiftIds = computed<BasketSelectedGift[]>(() => {
      return selectedGifts.value.map((gift) => {
        return {
          promotionId: gift.promotionId,
          products: [gift.product.id]
        }
      })
    })

    const giftsUpdated = async (gifts: BasketGift[]) => {
      selectedGifts.value = gifts

      if (!props.basketProduct) {
        return
      }

      const changeBasketProductGiftRequest: ChangeBasketProductGiftRequest = {
        basketProductId: props.basketProduct.id,
        choice: selectedProductGiftIds.value
      }
      await basketStore.updateProductGifts(changeBasketProductGiftRequest)
      basketStore.loadProducts()
    }

    return {
      basketInfo: basketStore.basketInfo,
      sidebarHidden,
      navigateToUid,
      localSelectedTechnicalServices,
      localSelectedFinancialServices,
      openProductServiceDetail,
      productServiceDetailActive,
      serviceDetail,
      submodalTitle,
      closeSubSidebar,
      selectedGifts,
      giftsUpdated,
      basketInitialized,
      karpatyStopSelling
    }
  }
})
