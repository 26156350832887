










import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    displayClass: {
      type: String,
      default: 'd-contents d-md-grid'
    },
    mobileDisplayClass: {
      type: String,
      default: 'd-grid d-md-contents'
    }
  }
})
