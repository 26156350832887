






















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    customClass: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    target: {
      type: [String, Object],
      required: true
    },
    variant: {
      type: String,
      default: 'primary'
    },
    triggers: {
      type: String,
      default: 'click blur'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    container: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    },
    boundary: {
      type: Object,
      default: undefined
    },
    boundaryPadding: {
      type: Number,
      default: 10
    }
  },
  emits: ['hidden'],
  setup () {
    return {}
  }
})
