







































import { defineComponent } from '@nuxtjs/composition-api'
import { addDays } from 'date-fns'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export default defineComponent({
  components: { Container, Button },
  props: {
    cookieKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { app } = useApp()
    const cookies = app.$cookies

    const dismissed = () => {
      const expiration = addDays(new Date(), 1)
      expiration.setHours(0, 0, 0)
      cookies.set(props.cookieKey, 1, { expires: expiration })
    }

    return {
      dismissed
    }
  }
})
