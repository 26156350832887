
















import { defineComponent, PropType, watch } from '@nuxtjs/composition-api'
import TechnicalServices from '~/components/Basket/PreBasket/ProductServices/TechnicalServices/TechnicalServices.vue'
import FinancialServices from '~/components/Basket/PreBasket/ProductServices/FinancialServices/FinancialServices.vue'
import {
  SelectedFinancialService,
  SelectedTechnicalService,
  ServiceVariantRequirement
} from '~/src/Model/ProductServices/ProductServices'
import { useProductServicesFetch } from '~/src/Infrastructure/ProductServices/ProductServicesFetch'

export default defineComponent({
  components: { TechnicalServices, FinancialServices },
  props: {
    productId: {
      required: true,
      type: Number
    },
    selectedFinancialServices: {
      required: true,
      type: Array as PropType<SelectedFinancialService[]>
    },
    selectedTechnicalServices: {
      required: true,
      type: Array as PropType<SelectedTechnicalService[]>
    }
  },
  emits: ['open-detail', 'update:selected-financial-services', 'update:selected-technical-services'],
  setup (props, { emit }) {
    const { productServices } = useProductServicesFetch(props.productId)

    watch(() => productServices.value, () => {
      if (!productServices.value || props.selectedTechnicalServices.length || props.selectedFinancialServices.length) {
        return
      }

      const financialServices: SelectedFinancialService[] = productServices.value.financialServices.filter(service => service.isVariantRequired === ServiceVariantRequirement.yes && service.variants.some(variant => variant.isDefault))
        .map((service) => {
          return { service, variant: service.variants.find(variant => variant.isDefault) ?? service.variants[0] }
        })

      if (financialServices.length) {
        emit('update:selected-financial-services', financialServices)
      }

      const technicalServices: SelectedTechnicalService[] = productServices.value.technicalServices.filter(service => service.isVariantRequired === ServiceVariantRequirement.yes && service.variants.some(variant => variant.isDefault))
        .map((service) => {
          return { service, variant: service.variants.find(variant => variant.isDefault) ?? service.variants[0] }
        })

      if (technicalServices.length) {
        emit('update:selected-technical-services', technicalServices)
      }
    })

    return {
      productServices
    }
  }
})
