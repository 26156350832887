import { AxiosError } from 'axios'
import { BasketSelectedGift } from '~/src/Model/ProductGift/ProductGift'
import { BasketStore, useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { AuthService } from '~/src/Infrastructure/Auth/AuthService'
import {
  AddToBasketEvent,
  useAddToBasketEvent
} from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Event/AddToBasketEvent'
import { BasketProduct } from '~/src/Model/Basket/Product'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'

export const useBasketInsertProduct = () => {
  const basketInsert = useBasketInsert()

  const addProductObj = usePromise(basketInsert.addProduct.bind(basketInsert))

  return {
    basketInsertProduct: addProductObj.exec,
    basketInsertProductPending: addProductObj.loading
  }
}

export const useBasketInsertProducts = () => {
  const basketInsert = useBasketInsert()

  const addProductsObj = usePromise(basketInsert.addProducts.bind(basketInsert))

  return {
    basketInsertProducts: addProductsObj.exec,
    basketInsertProductsPending: addProductsObj.loading
  }
}

export const useBasketInsertProductBundle = () => {
  const basketInsert = useBasketInsert()

  const addProductBundleObj = usePromise(basketInsert.addProductBundle.bind(basketInsert))

  return {
    basketInsertProductBundle: addProductBundleObj.exec,
    basketInsertProductBundlePending: addProductBundleObj.loading
  }
}

export const useBasketInsert = (): BasketInsert => {
  return new BasketInsert(
    useBasketStore(),
    useAuthService(),
    useAddToBasketEvent()
  )
}

class BasketInsert {
  private basketStore: BasketStore
  private authService: AuthService
  private addToBasketEvent: AddToBasketEvent

  constructor (
    basketStore: BasketStore,
    authService: AuthService,
    addToBasketEvent: AddToBasketEvent
  ) {
    this.basketStore = basketStore
    this.authService = authService
    this.addToBasketEvent = addToBasketEvent
  }

  private async prepareBasket () {
    try {
      await this.basketStore.createBasketIfNotExists()
    } catch (e) {
      if ((e as AxiosError).response?.status !== 409) {
        throw e
      }

      const authenticatedUser = await this.authService.fetchUser()

      if (authenticatedUser && authenticatedUser.basketHash) {
        await this.basketStore.updateBasketHash(authenticatedUser.basketHash)
      }
    }
  }

  public async addProduct (
    productId: number,
    quantity: number = 1,
    services: number[] = [],
    gifts: BasketSelectedGift[] = []
  ) {
    await this.prepareBasket()

    const basketProduct = await this.basketStore.addProduct({
      productId,
      quantity,
      services,
      gifts
    })

    this.addToBasketEvent.fire(productId, quantity)

    return basketProduct
  }

  public async addProducts (
    productsIds: number[],
    quantity: number = 1,
    services: number[] = [],
    gifts: BasketSelectedGift[] = []
  ) {
    await this.prepareBasket()
    const basketProducts: BasketProduct[] = []

    await Promise.all(productsIds.map(async (productId) => {
      const product = await this.basketStore.addProduct({
        productId,
        quantity,
        services,
        gifts
      })

      if (!product) {
        return
      }

      basketProducts.push(product)

      this.addToBasketEvent.fire(productId, quantity)
    }))

    return basketProducts
  }

  public async addProductBundle (
    bundleId: number,
    quantity: number = 1
  ) {
    await this.prepareBasket()

    return await this.basketStore.addProductBundle({
      promotionId: bundleId,
      quantity
    })
  }
}
