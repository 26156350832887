import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ProductBoxes } from '~/src/Model/ProductBox/ProductBox'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductBoxesRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadProductBoxes: (id: number[]): Promise<ProductBoxes> => $axios.$get<ProductBoxes>(
      '/product-boxes', { params: { id } }
    )
  }
}

export const useProductBoxesRepository = () => {
  const { $axios } = useAxios()

  return useProductBoxesRepositoryWithAxios($axios)
}
