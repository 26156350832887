
























import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import Logo from '~/components/Header/Logo/Logo.vue'
import LogoAppendix from '~/components/Header/Logo/LogoAppendix.vue'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

export default defineComponent({
  components: { Container, LogoAppendix, Logo },
  setup () {
    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    return {
      isActiveChristmas: christmasDeliveryConfigurationStore.isActiveChristmas
    }
  }
})
