











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  inheritAttrs: false,
  props: {
    noGutters: {
      type: Boolean,
      default: false
    },
    alignV: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'div'
    }
  },

  setup () {
    return {}
  }
})
