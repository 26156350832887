import { CacheStorageOptions, ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'

export class EmptyCacheStorage<T> implements ServerCacheStorage<T> {
  get (_key: string) {
    return Promise.resolve(null)
  }

  async load (_key: string, generator: () => Promise<T>, _options: CacheStorageOptions) {
    return await generator()
  }

  set (_key: string, _value: T, _options: CacheStorageOptions): Promise<any> {
    return Promise.resolve()
  }

  close (): Promise<any> {
    return Promise.resolve()
  }
}
