






import { defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    image: {
      required: true,
      type: Object
    },
    tag: {
      type: String,
      default: 'span'
    }
  }
})
