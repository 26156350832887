






import { defineComponent } from '@nuxtjs/composition-api'
import { useTextHighlighter } from '~/src/Infrastructure/TextHighlighter/TextHighlighter'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    highlight: {
      type: String,
      required: true
    }
  },

  setup () {
    const { textStart, textHighlight, textEnd } = useTextHighlighter()

    return {
      textStart,
      textHighlight,
      textEnd
    }
  }
})
