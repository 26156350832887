import { FilterSelectedReview } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterQueryReview } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'
import { TranslatedReview } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'

export type ReviewConvertorType = FilterElementConvertor<FilterSelectedReview, FilterQueryReview, TranslatedReview>

export class ReviewConvertor implements ReviewConvertorType {
  private readonly separator = ';'

  constructor (
    private readonly identifier: string = 'review'
  ) {
  }

  shouldConvert (review: FilterSelectedReview): boolean {
    return review.from !== null || review.to !== null
  }

  toUrl (review: FilterSelectedReview): UrlElement {
    return new UrlElement(this.identifier, [this.createIntervalValue(review.from ?? 1, review.to ?? 5)])
  }

  toUrlFromTranslated (attribute: TranslatedReview): UrlElement {
    return new UrlElement(this.identifier, [this.createIntervalValue(attribute.from, attribute.to)])
  }

  fromUrl (element: UrlElement): FilterQueryReview {
    return {
      from: Number(element.values[0].split(';')[0]),
      to: Number(element.values[0].split(';')[0])
    }
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }

  private createIntervalValue (from: number, to: number): string {
    return `${from}${this.separator}${to}`
  }
}
