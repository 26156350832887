


















import { defineComponent, ref } from '@nuxtjs/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'
import { useConfig } from '~/src/Infrastructure/Nuxt/Config/UseConfig'

export default defineComponent({
  components: {
    VueRecaptcha
  },
  props: {
    submitted: {
      type: Boolean,
      required: true
    }
  },
  emits: ['on-success', 'on-error', 'on-expired'],
  setup (_, { emit }) {
    const invisibleRecaptcha = ref<VueRecaptcha | null>(null)
    const { $config } = useConfig()

    const recaptchaReady = () => {
      window.$nuxt.$nextTick(() => {
        if (invisibleRecaptcha.value) {
          invisibleRecaptcha.value.execute()
        }
      })
    }

    const resetRecaptcha = () => {
      if (invisibleRecaptcha.value) {
        invisibleRecaptcha.value.reset()
      }
    }

    const onSuccess = (recaptchaToken: string) => {
      resetRecaptcha()
      emit('on-success', recaptchaToken)
    }

    const onError = () => {
      resetRecaptcha()
      emit('on-error')
    }

    const onExpired = () => {
      resetRecaptcha()
      emit('on-expired')
    }

    return {
      onSuccess,
      onError,
      onExpired,
      invisibleRecaptcha,
      siteKey: $config.recaptcha.siteKey || '',
      size: $config.recaptcha.size || '',
      language: $config.recaptcha.language || '',
      recaptchaReady
    }
  }
})
