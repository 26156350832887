


















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 0
    },
    sizeSm: {
      type: Number,
      default: 0
    },
    sizeMd: {
      type: Number,
      default: 0
    },
    sizeLg: {
      type: Number,
      default: 0
    },
    positionClass: {
      type: String,
      default: 'position-relative'
    }
  }
})
