import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { BasketInfo } from '~/src/Model/Basket/BasketInfo'
import {
  BasketProduct,
  BasketProductServices,
  BasketProductsResponse,
  NewBasketProduct, ProductServicesUpdateRequestData
} from '~/src/Model/Basket/Product'
import { DonationToApply } from '~/src/Model/Basket/DonationToApply'
import {
  BasketProductBundlesResponse,
  NewBasketProductBundle, NewBasketProductBundleResponse
} from '~/src/Model/Basket/BasketProductBundle'
import { ProductDeliveryServiceRequest } from '~/src/Model/Delivery/DeliveryMethodServices'
import { BasketCreditsResponse } from '~/src/Model/Basket/Credits'
import {
  BasketGift,
  ChangeBasketProductGiftRequest,
  DeleteBasketProductGiftRequest
} from '~/src/Model/ProductGift/ProductGift'
import { SelectedDeliveryMethod } from '~/src/Model/Basket/DeliveryMethod'
import {
  AgreeWithAvailabilityRequest,
  BasketValidationRequestOptions,
  BasketValidationResult
} from '~/src/Model/Basket/BasketValidation'
import { InstallmentChoice } from '~/src/Model/InstallmentsCalculator/InstallmentChoice'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { AddValueVoucherRequest } from '~/src/Model/Basket/ValueVoucher'
import { ExpressPickupStores } from '~/src/Model/ExpressPickup/ExpressPickup'

export const useBasketRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    create: (): Promise<BasketInfo> => $axios.$post<BasketInfo>('/baskets'),
    loadInfo: (hash: string): Promise<BasketInfo> => $axios.$get<BasketInfo>(`/baskets/${hash}`),
    setDelivery: (hash: string, selectedDeliveryMethod: SelectedDeliveryMethod): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${hash}/delivery-method`, { ...selectedDeliveryMethod })
    },
    deleteDelivery: (hash: string): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${hash}/delivery-method`)
    },
    setPayment: (hash: string, payMethodId: number): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${hash}/pay-method`, { payMethodId })
    },
    deletePayment: (hash: string): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${hash}/pay-method`)
    },

    addProduct: (hash: string, newProduct: NewBasketProduct): Promise<BasketProduct> => {
      return $axios.$post<BasketProduct>(`/baskets/${hash}/products`, { ...newProduct })
    },
    getProducts: (hash: string): Promise<BasketProductsResponse> => {
      return $axios.$get<BasketProductsResponse>(`/baskets/${hash}/products`)
    },
    updateProduct: (basketHash: string, basketProductId: number, quantity: number): Promise<BasketProduct> => {
      return $axios.$post<BasketProduct>(`/baskets/${basketHash}/products/${basketProductId}`, { quantity })
    },
    deleteProduct: (basketHash: string, basketProductId: number): Promise<void> => {
      return $axios.$delete<void>(`/baskets/${basketHash}/products/${basketProductId}`)
    },
    addProductBundle: (hash: string, newProductBundle: NewBasketProductBundle): Promise<NewBasketProductBundleResponse> => {
      return $axios.$post<NewBasketProductBundleResponse>(`/baskets/${hash}/product-bundles`, newProductBundle)
    },
    getProductBundles: (hash: string): Promise<BasketProductBundlesResponse> => {
      return $axios.$get<BasketProductBundlesResponse>(`/baskets/${hash}/product-bundles`)
    },
    deleteProductBundle: (basketHash: string, basketProductBundleId: number): Promise<void> => {
      return $axios.$delete<void>(`/baskets/${basketHash}/product-bundles/${basketProductBundleId}`)
    },
    updateProductBundle: (basketHash: string, basketProductBundleId: number, quantity: number): Promise<NewBasketProductBundleResponse> => {
      return $axios.$post<NewBasketProductBundleResponse>(`/baskets/${basketHash}/product-bundles/${basketProductBundleId}`, { quantity })
    },
    addVoucher: (basketHash: string, code: string): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${basketHash}/vouchers`, { code })
    },
    deleteVoucher: (basketHash: string, id: number): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${basketHash}/vouchers/${id}`)
    },
    applyDonation: (basketHash: string, donationToApply: DonationToApply): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${basketHash}/donate`, donationToApply)
    },
    deleteDonation: (basketHash: string): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${basketHash}/donate`)
    },
    addDeliveryService: (basketHash: string, serviceItemId: number): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${basketHash}/delivery-services`, { serviceItemId })
    },
    deleteDeliveryService: (basketHash: string, serviceItemId: number): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${basketHash}/delivery-services/${serviceItemId}`)
    },
    addProductDeliveryService: (basketHash: string, service: ProductDeliveryServiceRequest): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${basketHash}/product-and-delivery-services`, { ...service })
    },
    applyCredits: (basketHash: string, creditsAmount: string): Promise<BasketCreditsResponse> => {
      return $axios.$post<BasketCreditsResponse>(`/baskets/${basketHash}/credits`, { amount: creditsAmount })
    },
    deleteCredits: (basketHash: string): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/baskets/${basketHash}/credits`)
    },
    updateGifts: (basketHash: string, changeBasketProductGiftRequest: ChangeBasketProductGiftRequest): Promise<BasketGift[]> => {
      return $axios.$post<BasketGift[]>(`/baskets/${basketHash}/products/${changeBasketProductGiftRequest.basketProductId}/gifts`, { choice: changeBasketProductGiftRequest.choice })
    },
    deleteGift: (basketHash: string, deleteBasketProductGiftRequest: DeleteBasketProductGiftRequest): Promise<void> => {
      return $axios.$delete<void>(`/baskets/${basketHash}/products/${deleteBasketProductGiftRequest.basketProductId}/gifts/${deleteBasketProductGiftRequest.giftId}`)
    },

    updateServices: (
      basketHash: string,
      productServicesUpdateRequestData: ProductServicesUpdateRequestData
    ): Promise<BasketProductServices> => {
      const serviceIds: object[] = productServicesUpdateRequestData.variantIds.map((variantId: number) => {
        return { serviceItemId: variantId }
      })

      return $axios.$put<BasketProductServices>(
        `/baskets/${basketHash}/products/${productServicesUpdateRequestData.basketProductId}/services`, serviceIds
      )
    },
    deleteService: (
      basketHash: string,
      basketProductId: number,
      basketServiceId: number
    ): Promise<void> => {
      return $axios.$delete<void>(`/baskets/${basketHash}/products/${basketProductId}/services/${basketServiceId}`)
    },
    validateBasket: (basketHash: string, requestOptions: BasketValidationRequestOptions): Promise<BasketValidationResult> => $axios.$get<BasketValidationResult>(`/baskets/${basketHash}/validate`, {
      params: {
        context: requestOptions.context,
        email: requestOptions.email ? encodeURIComponent(requestOptions.email) : null
      }
    }),
    agreeWithAvailability: (basketHash: string, agreeWithAvailabilityRequest: AgreeWithAvailabilityRequest): Promise<void> => $axios.$post<void>(`/baskets/${basketHash}/products/agree-with-availability`, agreeWithAvailabilityRequest),
    addValueVoucher: (basketHash: string, addValueVoucherRequest: AddValueVoucherRequest) => $axios.$post<BasketInfo>(`/baskets/${basketHash}/value-vouchers`, addValueVoucherRequest),
    deleteValueVoucher: (basketHash: string, basketVoucherId: number) => $axios.$delete<BasketInfo>(`/baskets/${basketHash}/value-vouchers/${basketVoucherId}`),
    setInstallmentChoice: (basketHash: string, installmentChoice: InstallmentChoice): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>(`/baskets/${basketHash}/installment-choice`, installmentChoice)
    },
    loadExpressPickupStores: (basketHash: string) => $axios.$get<ExpressPickupStores>(`/baskets/${basketHash}/express-delivery-stores`)
  }
}

export const useBasketRepository = () => {
  const { $axios } = useAxios()

  return useBasketRepositoryWithAxios($axios)
}
