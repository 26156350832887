import { FilterQueryTopCategories } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterSelectedTopCategories } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'

export type TopCategoriesConvertorType = FilterElementConvertor<FilterSelectedTopCategories, FilterQueryTopCategories, number[]>

export class TopCategoriesConvertor implements TopCategoriesConvertorType {
  constructor (
    private readonly identifier: string = 'top-categories'
  ) {
  }

  toUrl (filterSelectedTopCategories: FilterSelectedTopCategories): UrlElement {
    return new UrlElement(
      this.identifier,
      filterSelectedTopCategories.map(f => f.value.toString())
    )
  }

  toUrlFromTranslated (attribute: number[]): UrlElement {
    return new UrlElement(
      this.identifier,
      attribute.map(a => a.toString())
    )
  }

  fromUrl (element: UrlElement): FilterQueryTopCategories {
    if (element.values.length === 0) {
      throw new Error('missing parameter values')
    }

    return element.values.map(v => Number(v))
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }

  shouldConvert (attribute: FilterSelectedTopCategories): boolean {
    return attribute.length > 0
  }
}
