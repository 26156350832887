import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import { ProductDetailGallery } from '~/src/Model/ProductDetail/ProductDetailGallery'
import { ProductAvailability } from '~/src/Model/Product/ProductAvailability'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { ProductAvailabilityModal, ProductAvailabilityModalRequest } from '~/src/Model/Product/ProductAvailabilityModal'

export const useProductDetailsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadProductDetail: (id: number): Promise<ProductDetail> => $axios.$get<ProductDetail>(`/product-details/${id}`),
    loadGalleryItems: (id: number): Promise<ProductDetailGallery> => $axios.$get<ProductDetailGallery>(`/products/${id}/gallery-items`),
    loadAvailability: (id: number): Promise<ProductAvailability> => $axios.$get<ProductAvailability>(`/products/${id}/availability`),
    loadModalAvailability: (id: number, request: ProductAvailabilityModalRequest): Promise<ProductAvailabilityModal> => $axios.$post<ProductAvailabilityModal>(`/products/${id}/modal-availability`, request)
  }
}
