import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import { useVisitedProductsRepositoryWithAxios } from '~/src/Infrastructure/VisitedProducts/VisitedProductsRepository'
import Formatter from '~/src/Infrastructure/Date/DateFormatter'
import { VisitedProductsResponse } from '~/src/Model/VisitedProducts/VisitedProducts'
import { useProductBoxesRepositoryWithAxios } from '~/src/Infrastructure/ProductBoxes/ProductBoxesRepository'

const VISITED_PRODUCTS_STORE_ID = 'visitedProducts'
const HISTORY_LIMIT = 40
const VISITED_PRODUCTS_LIST_EXPIRATION_TIME = 14 * 24 * 60 * 60

interface VisitedProductsState {
  productsIds: number[]
  productsListId: string | null
  productBoxes: ProductBox[]
}

export const useVisitedProductsStore = defineStore(VISITED_PRODUCTS_STORE_ID, {
  state: (): VisitedProductsState => ({
    productsIds: [],
    productsListId: null,
    productBoxes: []
  }),
  actions: {
    setProducts (products: number[]): void {
      this.productsIds = products
    },
    setProductsListId (productsListId: string | null) {
      this.productsListId = productsListId
    },
    setProductBoxes (productBoxes: ProductBox[]): void {
      this.productBoxes = productBoxes
    },
    addProduct (productId: number): Promise<void> {
      if (this.productsListId === null) {
        this.setProductsListId(uuidv4())
      }

      if (!this.$nuxt.$auth.user.value) {
        this.$nuxt.$cookies.set('visitedProductsListId', this.productsListId, { maxAge: VISITED_PRODUCTS_LIST_EXPIRATION_TIME })
      }

      const newProductsIds = [
        productId,
        ...this.productsIds.filter((existingProductId: number) => existingProductId !== productId)
      ].slice(0, HISTORY_LIMIT - 1)

      this.setProducts(newProductsIds)

      const { addProduct } = useVisitedProductsRepositoryWithAxios(this.$nuxt.$axios)

      return addProduct({
        occurredOn: Formatter.apiDateTime(new Date()),
        productId,
        visitedProductsListId: this.productsListId as string
      })
    },
    updateProductListId (productsListId: string | null) {
      this.setProducts([])
      this.setProductsListId(productsListId)
      this.setProductBoxes([])
    },
    clear () {
      this.$reset()
    },
    async loadProductList (): Promise<VisitedProductsResponse | null> {
      const { getVisitedProducts } = useVisitedProductsRepositoryWithAxios(this.$nuxt.$axios)

      if (!this.productsListId) {
        return new Promise(() => null)
      }

      const response = await getVisitedProducts(this.productsListId)
      this.setProducts(response.products)

      return response
    },
    async loadProductBoxes (): Promise<ProductBox[]> {
      const { loadProductBoxes } = useProductBoxesRepositoryWithAxios(this.$nuxt.$axios)
      if (this.productsListId && !this.productsIds.length) {
        await this.loadProductList()
      }

      const productBoxesIds = this.productBoxes.map((p: ProductBox) => p.product.id)
      const productsWithoutProductBox = this.productsIds.filter((p: number) => !productBoxesIds.includes(p))

      if (productsWithoutProductBox.length > 0) {
        const response = await loadProductBoxes(this.productsIds)
        this.setProductBoxes(response.productBoxes)

        return response.productBoxes
      }

      return new Promise<ProductBox[]>(() => this.productBoxes)
    }
  }
})
