import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'

export const useCheckCustomerRepository = () => {
  const { $axios } = useAxios()

  return {
    checkCustomer: (email: string, showProgress: boolean = false) => $axios.$post<CheckCustomerResponse>(
      '/actions/check-customer',
      { email },
      { progress: showProgress })
  }
}
