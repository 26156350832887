


























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    togglePopover: {
      required: true,
      type: Function
    },
    isActive: {
      type: Boolean,
      required: true
    },
    linkClass: {
      type: String,
      default: null
    },
    textOnPhoneOnly: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconFs: {
      type: String,
      default: 'icon-fs-15'
    },
    activeIcon: {
      type: String,
      default: ''
    },
    isDisabled: {
      default: false,
      type: Boolean
    }
  },
  setup () {}
})
