








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'iframe'
    },
    src: {
      type: String,
      default: null
    },
    allowFullScreen: {
      type: Boolean,
      default: false
    }
  }
})
