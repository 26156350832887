import { Banner, Banners } from '~/src/Model/Banner/Banner'
import { useBannersRepository } from '~/src/Infrastructure/Banners/BannersRepository'
import { HOMEPAGE_ID } from '~/src/Model/HomePage/HomePage'

export const useBannersMenu = () => {
  const { loadBanners } = useBannersRepository()

  const loadMenuBanner = async (pageId: number): Promise<Banner> => {
    const response: Banners = await loadBanners(pageId, 'ESHOP_BANNER_MENU')
    return response.banners[0]
  }

  const loadMobileTopLevelBanner = async (): Promise<Banner> => {
    return await loadMenuBanner(HOMEPAGE_ID)
  }

  return {
    loadMenuBanner,
    loadMobileTopLevelBanner
  }
}
