import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { createFullPath } from '~/src/Infrastructure/String/CreateFullPath/CreateFullPath'
import { QueryParameters } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

type formatAliasType = (alias: string, queries?: QueryParameters) => string

const formatAlias: Plugin = (_, inject: Inject) => {
  inject('path', createFullPath)
}

declare module 'vue/types/vue' {
  interface Vue {
    $path: formatAliasType
  }
}

export default formatAlias
