































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { BreadCrumb } from '~/src/Model/BreadCrumb/BreadCrumb'
import BreadCrumbBarListWrap from '~/components/BreadCrumb/BreadCrumbBarListWrap.vue'
import BreadCrumbBarItem from '~/components/BreadCrumb/BreadCrumbBarItem.vue'
import BreadCrumbBarPopover from '~/components/BreadCrumb/BreadCrumbBarPopover.vue'

export default defineComponent({
  components: { BreadCrumbBarPopover, BreadCrumbBarItem, BreadCrumbBarListWrap },
  props: {
    visibleBreadCrumbs: {
      type: Array as PropType<BreadCrumb[]>,
      required: true
    },
    popoverBreadCrumbs: {
      type: Array as PropType<BreadCrumb[]>,
      required: true
    }
  }
})
