import { ref, useFetch } from '@nuxtjs/composition-api'
import { SimpleEventDispatcher } from 'ste-simple-events'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { useCustomerRepository } from '~/src/Infrastructure/Customers/CustomerRepository'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'

export const useCustomerInfoFetch = (customerId?: number | null) => {
  const customerInformation = ref<CustomerInformation | null>(null)
  const { loadCustomerInfo } = useCustomerRepository()
  const onCustomerInfoLoadCancelled = new SimpleEventDispatcher<void>()

  const { exec, loading, onSuccess } = usePromise((customerId: number) => loadCustomerInfo(customerId))

  onSuccess.subscribe((customerInfoResponse) => {
    customerInformation.value = customerInfoResponse
  })

  const fetchCustomer = async () => {
    if (!customerId) {
      onCustomerInfoLoadCancelled.dispatch()
      return
    }

    await exec(customerId)
  }

  useFetch(fetchCustomer)

  return {
    customerInformation,
    onCustomerInfoLoadCancelledEvent: onCustomerInfoLoadCancelled.asEvent(),
    loading,
    onCustomerInfoLoadedEvent: onSuccess,
    loadCustomerData: exec,
    fetchCustomer
  }
}
