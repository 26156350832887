


















































































































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { isWithinInterval } from 'date-fns'
import { SaleInfo, SaleType } from '~/src/Model/ProductPriceBundle/ProductPriceBundle'
import { PurchaseAvailability } from '~/src/Model/PurchaseAvailability/PurchaseAvailability'
import Countdown from '~/components/Countdown/Countdown.vue'
import { BlockPersonalPickupMessage } from '~/src/Model/BlockPersonalPickupMessage/BlockPersonalPickupMessage'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import { ProductAvailability } from '~/src/Model/ProductAvailability/ProductAvailability'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

enum AvailabilityPlace {
  store = 'store',
  eshop = 'eshop',
  both = 'both',
  none = 'none'
}

export default defineComponent({
  components: {
    LoadingOverlay,
    Countdown,
    Button,
    Alert
  },
  props: {
    saleInfo: {
      required: true,
      type: Object as PropType<SaleInfo>
    },
    purchaseAvailability: {
      required: true,
      type: Object as PropType<PurchaseAvailability>
    },
    productAvailability: {
      required: true,
      type: Object as PropType<ProductAvailability>
    },
    blockPersonalPickup: {
      type: Object as PropType<BlockPersonalPickupMessage | null>,
      default: null
    }
  },
  setup (props) {
    const { optionalTranslate } = useTranslator()
    const $webConfig = useWebConfig()
    const dateFormatter = useDateFormatter()
    const hideAvailabilityMessage = ref<boolean>(false)
    const { isSpecialPriceLevel } = useAuthenticatedUser()

    const isNay = computed<boolean>(() => $webConfig.isNay)

    const availableAt = computed<AvailabilityPlace>(() => {
      if (props.purchaseAvailability.reservation.isAllowed && props.purchaseAvailability.order.isAllowed) {
        return AvailabilityPlace.both
      }

      if (props.purchaseAvailability.reservation.isAllowed && !props.purchaseAvailability.order.isAllowed) {
        return AvailabilityPlace.store
      }

      if (!props.purchaseAvailability.reservation.isAllowed && props.purchaseAvailability.order.isAllowed) {
        return AvailabilityPlace.eshop
      }

      return AvailabilityPlace.none
    })

    const isPersonalPickupBlocked = computed<boolean>(() => {
      if (props.blockPersonalPickup === null) {
        return false
      }

      return isWithinInterval(new Date(), {
        start: dateFormatter.getValue(props.blockPersonalPickup.validity.from),
        end: dateFormatter.getValue(props.blockPersonalPickup.validity.to)
      })
    })

    const isSoldOut = computed<boolean>(() => {
      return availableAt.value === AvailabilityPlace.none && props.saleInfo.validity.started && props.productAvailability.eshop.iconType !== 'check'
    })

    const { exec: refreshPage, loading: refreshPending } = usePromise(() => window.$nuxt.refresh())

    const saleType = computed<SaleType>(() => props.saleInfo.article.saleType)

    const saleTypeLang = computed<string>(() => saleType.value === SaleType.cyberMonday ? 'cyber_monday' : 'black_friday')

    const bgClass = computed<string>(() => saleType.value === SaleType.cyberMonday ? 'bg-cyber-monday' : 'bg-black-friday')

    const lockdownLang = computed<string | null>(() => optionalTranslate(`product_detail.special_states.${saleTypeLang.value}.lockdown_info`))

    return {
      isNay,
      refreshPage,
      refreshPending,
      AvailabilityPlace,
      availableAt,
      isPersonalPickupBlocked,
      hideAvailabilityMessage,
      saleType,
      SaleType,
      saleTypeLang,
      bgClass,
      isSoldOut,
      lockdownLang,
      isSpecialPriceLevel
    }
  }
})
