











































































































































import { defineComponent } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'

export default defineComponent({
  components: { TextSkeleton },
  props: {
    days: {
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    }
  },
  setup () {
    const $webConfig = useWebConfig()
    const deliveryArray = [1, 2, 3, 4, 5, 6, 7, 8]

    if ($webConfig.web === Web.Ew) {
      deliveryArray.push(9)
      deliveryArray.push(10)
    }

    return {
      deliveryArray
    }
  }
})
