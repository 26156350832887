


































import { defineComponent, ref } from '@nuxtjs/composition-api'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    BaseValidationObserver,
    FormInput,
    Button,
    PageSectionContent
  },
  emits: ['confirm-watchdog', 'show-auth'],

  setup (_props, { emit }) {
    const email = ref<string>('')

    const confirmWatchDog = () => {
      emit('confirm-watchdog', email.value)
    }

    return {
      email,
      confirmWatchDog
    }
  }
})
