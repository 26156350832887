import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41a0a4d3 = () => interopDefault(import('../pages/design-system/index.vue' /* webpackChunkName: "pages/design-system/index" */))
const _ea7df494 = () => interopDefault(import('../pages/design-system/500.vue' /* webpackChunkName: "pages/design-system/500" */))
const _06b0426a = () => interopDefault(import('../pages/design-system/acl.vue' /* webpackChunkName: "pages/design-system/acl" */))
const _1a97b05d = () => interopDefault(import('../pages/design-system/alert.vue' /* webpackChunkName: "pages/design-system/alert" */))
const _39cf547c = () => interopDefault(import('../pages/design-system/buttons.vue' /* webpackChunkName: "pages/design-system/buttons" */))
const _0e98f4a6 = () => interopDefault(import('../pages/design-system/christmas-delivery-calendar.vue' /* webpackChunkName: "pages/design-system/christmas-delivery-calendar" */))
const _f7899e88 = () => interopDefault(import('../pages/design-system/collapse.vue' /* webpackChunkName: "pages/design-system/collapse" */))
const _201949e0 = () => interopDefault(import('../pages/design-system/content-editor.vue' /* webpackChunkName: "pages/design-system/content-editor" */))
const _10e831cb = () => interopDefault(import('../pages/design-system/copy-button.vue' /* webpackChunkName: "pages/design-system/copy-button" */))
const _ce5ca39c = () => interopDefault(import('../pages/design-system/countdown.vue' /* webpackChunkName: "pages/design-system/countdown" */))
const _38a000e0 = () => interopDefault(import('../pages/design-system/custom-checkbox.vue' /* webpackChunkName: "pages/design-system/custom-checkbox" */))
const _facf01c0 = () => interopDefault(import('../pages/design-system/dropdown.vue' /* webpackChunkName: "pages/design-system/dropdown" */))
const _17051105 = () => interopDefault(import('../pages/design-system/fonts.vue' /* webpackChunkName: "pages/design-system/fonts" */))
const _550b1260 = () => interopDefault(import('../pages/design-system/form-input.vue' /* webpackChunkName: "pages/design-system/form-input" */))
const _35e7b281 = () => interopDefault(import('../pages/design-system/form-radio.vue' /* webpackChunkName: "pages/design-system/form-radio" */))
const _2719d5e6 = () => interopDefault(import('../pages/design-system/form-select.vue' /* webpackChunkName: "pages/design-system/form-select" */))
const _16253ca4 = () => interopDefault(import('../pages/design-system/form-textarea.vue' /* webpackChunkName: "pages/design-system/form-textarea" */))
const _1ed23755 = () => interopDefault(import('../pages/design-system/grid.vue' /* webpackChunkName: "pages/design-system/grid" */))
const _1fed6b1b = () => interopDefault(import('../pages/design-system/icons.vue' /* webpackChunkName: "pages/design-system/icons" */))
const _4b87283c = () => interopDefault(import('../pages/design-system/loader.vue' /* webpackChunkName: "pages/design-system/loader" */))
const _02aff70c = () => interopDefault(import('../pages/design-system/microsite.vue' /* webpackChunkName: "pages/design-system/microsite" */))
const _828b119c = () => interopDefault(import('../pages/design-system/others.vue' /* webpackChunkName: "pages/design-system/others" */))
const _6fd0f3ae = () => interopDefault(import('../pages/design-system/product-box.vue' /* webpackChunkName: "pages/design-system/product-box" */))
const _452d186c = () => interopDefault(import('../pages/design-system/rating.vue' /* webpackChunkName: "pages/design-system/rating" */))
const _09884c62 = () => interopDefault(import('../pages/design-system/table.vue' /* webpackChunkName: "pages/design-system/table" */))
const _524df8e6 = () => interopDefault(import('../pages/design-system/tabs.vue' /* webpackChunkName: "pages/design-system/tabs" */))
const _7ce15544 = () => interopDefault(import('../pages/design-system/tooltip.vue' /* webpackChunkName: "pages/design-system/tooltip" */))
const _665ec287 = () => interopDefault(import('../pages/design-system/modal/confirmDialog.vue' /* webpackChunkName: "pages/design-system/modal/confirmDialog" */))
const _0eee6c88 = () => interopDefault(import('../pages/design-system/parts/table-bordered.vue' /* webpackChunkName: "pages/design-system/parts/table-bordered" */))
const _1446c614 = () => interopDefault(import('../pages/design-system/parts/table-default.vue' /* webpackChunkName: "pages/design-system/parts/table-default" */))
const _4f7b1db8 = () => interopDefault(import('../pages/design-system/parts/table-scrollable.vue' /* webpackChunkName: "pages/design-system/parts/table-scrollable" */))
const _3ae6ee8a = () => interopDefault(import('../pages/design-system/parts/table-striped.vue' /* webpackChunkName: "pages/design-system/parts/table-striped" */))
const _7cc42fe0 = () => interopDefault(import('../pages/design-system/parts/table-wysiwyg.vue' /* webpackChunkName: "pages/design-system/parts/table-wysiwyg" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/design-system",
    component: _41a0a4d3,
    pathToRegexpOptions: {"strict":true},
    name: "design-system"
  }, {
    path: "/design-system/500",
    component: _ea7df494,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-500"
  }, {
    path: "/design-system/acl",
    component: _06b0426a,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-acl"
  }, {
    path: "/design-system/alert",
    component: _1a97b05d,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-alert"
  }, {
    path: "/design-system/buttons",
    component: _39cf547c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-buttons"
  }, {
    path: "/design-system/christmas-delivery-calendar",
    component: _0e98f4a6,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-christmas-delivery-calendar"
  }, {
    path: "/design-system/collapse",
    component: _f7899e88,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-collapse"
  }, {
    path: "/design-system/content-editor",
    component: _201949e0,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-content-editor"
  }, {
    path: "/design-system/copy-button",
    component: _10e831cb,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-copy-button"
  }, {
    path: "/design-system/countdown",
    component: _ce5ca39c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-countdown"
  }, {
    path: "/design-system/custom-checkbox",
    component: _38a000e0,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-custom-checkbox"
  }, {
    path: "/design-system/dropdown",
    component: _facf01c0,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-dropdown"
  }, {
    path: "/design-system/fonts",
    component: _17051105,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-fonts"
  }, {
    path: "/design-system/form-input",
    component: _550b1260,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-form-input"
  }, {
    path: "/design-system/form-radio",
    component: _35e7b281,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-form-radio"
  }, {
    path: "/design-system/form-select",
    component: _2719d5e6,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-form-select"
  }, {
    path: "/design-system/form-textarea",
    component: _16253ca4,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-form-textarea"
  }, {
    path: "/design-system/grid",
    component: _1ed23755,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-grid"
  }, {
    path: "/design-system/icons",
    component: _1fed6b1b,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-icons"
  }, {
    path: "/design-system/loader",
    component: _4b87283c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-loader"
  }, {
    path: "/design-system/microsite",
    component: _02aff70c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-microsite"
  }, {
    path: "/design-system/others",
    component: _828b119c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-others"
  }, {
    path: "/design-system/product-box",
    component: _6fd0f3ae,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-product-box"
  }, {
    path: "/design-system/rating",
    component: _452d186c,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-rating"
  }, {
    path: "/design-system/table",
    component: _09884c62,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-table"
  }, {
    path: "/design-system/tabs",
    component: _524df8e6,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-tabs"
  }, {
    path: "/design-system/tooltip",
    component: _7ce15544,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-tooltip"
  }, {
    path: "/design-system/modal/confirmDialog",
    component: _665ec287,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-modal-confirmDialog"
  }, {
    path: "/design-system/parts/table-bordered",
    component: _0eee6c88,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-parts-table-bordered"
  }, {
    path: "/design-system/parts/table-default",
    component: _1446c614,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-parts-table-default"
  }, {
    path: "/design-system/parts/table-scrollable",
    component: _4f7b1db8,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-parts-table-scrollable"
  }, {
    path: "/design-system/parts/table-striped",
    component: _3ae6ee8a,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-parts-table-striped"
  }, {
    path: "/design-system/parts/table-wysiwyg",
    component: _7cc42fe0,
    pathToRegexpOptions: {"strict":true},
    name: "design-system-parts-table-wysiwyg"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
