import { defineStore } from 'pinia'
import {
  Reclamation,
  ReclamationCustomerContactInfo,
  ReclamationInfoRequestData,
  ReclamationIssueInfoStepData,
  ReclamationStateEnum
} from '~/src/Model/Reclamation/Reclamation'
import { PurchasedProduct, PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { ReclamationAtHome } from '~/src/Model/Reclamation/ReclamationAtHome'
import { ReclamationAtStore } from '~/src/Model/Reclamation/ReclamationAtStore'
import { usePurchasedProductsRepositoryWithAxios } from '~/src/Infrastructure/PurchasedProducts/PurchasedProductsRepository'

const RECLAMATION_STORE_ID = 'reclamation'

export interface ReclamationState {
  reclamationNumber: string | null
  currentState: ReclamationStateEnum | null
  isHomeService: boolean
  purchaseInfo: PurchaseInfo | null
  purchasedProductInfo: PurchasedProduct | null
  customerContactInfo: ReclamationCustomerContactInfo | null
  issueInfoStepFormData: ReclamationIssueInfoStepData | null
  reclamationInfoData: ReclamationInfoRequestData | null
}

export const useReclamationStore = defineStore(RECLAMATION_STORE_ID, {
  state: (): ReclamationState => ({
    reclamationNumber: null,
    currentState: null,
    isHomeService: false,
    purchaseInfo: null,
    purchasedProductInfo: null,
    customerContactInfo: null,
    issueInfoStepFormData: null,
    reclamationInfoData: null
  }),
  persist: {
    key: RECLAMATION_STORE_ID,
    paths: [
      'currentState',
      'isHomeService',
      'purchaseInfo',
      'purchasedProductInfo',
      'customerContactInfo',
      'issueInfoStepFormData',
      'reclamationInfoData'
    ]
  },
  getters: {
    reclamation (): Reclamation | null {
      if (this.currentState === null) {
        return null
      }

      if (this.purchaseInfo === null) {
        return null
      }

      if (this.isHomeService && this.currentState) {
        return new ReclamationAtHome(
          this.purchaseInfo,
          this.currentState,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }

      return new ReclamationAtStore(
        this.purchaseInfo,
        this.currentState,
        this.issueInfoStepFormData,
        this.reclamationInfoData,
        this.customerContactInfo
      )
    }
  },
  actions: {
    setReclamation (reclamation: Reclamation | null): void {
      if (reclamation) {
        this.currentState = reclamation.currentState
        this.isHomeService = reclamation.isHomeService
        this.purchaseInfo = reclamation.purchaseInfo
        this.customerContactInfo = reclamation.customerContactInfo
        this.issueInfoStepFormData = reclamation.issueInfoStepFormData
        this.reclamationInfoData = reclamation.reclamationInfoData

        return
      }

      this.currentState = null
      this.isHomeService = false
    },
    createReclamationAtHome (purchaseInfo: PurchaseInfo): Promise<ReclamationAtHome> {
      const reclamationAtHome = new ReclamationAtHome(purchaseInfo)
      this.setReclamation(reclamationAtHome)
      this.purchasedProductInfo = null

      return Promise.resolve(reclamationAtHome)
    },
    createReclamationAtStore (purchaseInfo: PurchaseInfo): Promise<ReclamationAtStore> {
      const reclamationAtStore = new ReclamationAtStore(purchaseInfo)
      this.setReclamation(reclamationAtStore)
      this.purchasedProductInfo = null

      return Promise.resolve(reclamationAtStore)
    },
    update (reclamation: Reclamation): Promise<Reclamation> {
      this.setReclamation(reclamation)

      return Promise.resolve(reclamation)
    },
    setReclamationNumber (number: string): void {
      this.reclamationNumber = number
    },
    loadPurchasedProductInfoIfNotExist (): Promise<PurchasedProduct> {
      if (this.purchasedProductInfo) {
        return Promise.resolve(this.purchasedProductInfo)
      }

      return this.loadPurchasedProductInfo()
    },
    async loadPurchasedProductInfo  (): Promise<PurchasedProduct> {
      const reclamation: Reclamation | null = this.reclamation

      if (!reclamation) {
        throw new Error('Reclamation must be set before')
      }

      const { searchPurchasedProducts } = usePurchasedProductsRepositoryWithAxios(this.$nuxt.$axios)
      const purchasedProductsList = await searchPurchasedProducts(
        {
          number: reclamation.purchaseInfo.number,
          purchaseDate: reclamation.purchaseInfo.purchaseDate,
          itemId: reclamation.purchaseInfo.itemId,
          purchaseType: reclamation.purchaseInfo.type
        }
      )

      if (purchasedProductsList.purchasedProducts.length === 0) {
        throw new Error('Can`t find purchased product by given purchase info')
      }

      this.purchasedProductInfo = purchasedProductsList.purchasedProducts[0]

      return Promise.resolve(purchasedProductsList.purchasedProducts[0])
    }
  }
})
