import { AxiosError } from 'axios'
import { Dictionary } from 'vue-router/types/router'
import RedirectComponentFactory from '~/src/Infrastructure/Router/AliasRouter/RedirectComponentFactory'
import ErrorComponentFactory from '~/src/Infrastructure/Router/AliasRouter/ErrorComponentFactory'

export class RouterErrorComponentFactory {
  public create (axiosError: AxiosError, previousPath: string, queryParams: Dictionary<string>) {
    const responseStatusCode = axiosError.response?.status ?? null
    const redirectStatusCodes = [301, 302]
    const redirectLocationAlias = axiosError.response?.data?.location

    if (!responseStatusCode) {
      return this.createDefaultErrorComponent()
    }

    if (!redirectStatusCodes.includes(responseStatusCode)) {
      return this.createDefaultErrorComponent()
    }

    if (redirectLocationAlias === undefined) {
      return this.createDefaultErrorComponent()
    }

    if (redirectLocationAlias === '') {
      return RedirectComponentFactory(responseStatusCode, '/', queryParams)
    }

    return RedirectComponentFactory(
      responseStatusCode,
      this.createNewPath(previousPath, axiosError.response?.data?.location ?? ''),
      queryParams
    )
  }

  private createDefaultErrorComponent () {
    return ErrorComponentFactory(404, 'Page not found')
  }

  private createNewPath (previousPath: string, newPath: string): string {
    if (previousPath === newPath) {
      return previousPath
    }

    if (!newPath) {
      return previousPath
    }

    const modifiedPath = previousPath.split('/')
    modifiedPath.splice(1, 1, newPath)

    return modifiedPath.join('/')
  }
}
