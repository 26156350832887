import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { IVueI18n } from 'vue-i18n'

type localeNumberFormatterType = (value: number | string) => string

const localeNumberFormatterPlugin: Plugin = ({ app }, inject: Inject) => {
  const i18n = app.i18n as IVueI18n
  const localeNumberFormatter: localeNumberFormatterType = (value: number | string) => {
    return i18n.n(Number(value))
  }

  inject('number', localeNumberFormatter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $number: localeNumberFormatterType
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $number: localeNumberFormatterType
  }

  interface Context {
    $number: localeNumberFormatterType
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $number: localeNumberFormatterType
  }
}

export default localeNumberFormatterPlugin
