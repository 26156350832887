


















import { defineComponent } from '@nuxtjs/composition-api'

interface PropTypes {
  disabled: boolean
  icon: string
  tag: string | null
}

export default defineComponent<PropTypes>({
  props: {
    tag: {
      type: String,
      default: 'h2'
    },
    background: {
      type: String,
      default: 'bg-white'
    },
    borderColor: {
      type: String,
      default: 'border-input'
    },
    displayClass: {
      type: [String, Array],
      default: 'd-flex'
    },
    paddingClass: {
      type: String,
      default: 'bs-p-3 bs-px-md-4'
    },
    fontSizeClass: {
      type: String,
      default: 'fs-base'
    },
    icon: {
      type: String,
      default: null
    },
    iconColor: {
      type: [String, Array],
      default: 'text-light'
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    extendedTitle: {
      type: Boolean,
      default: false
    }
  },

  setup () {
  }
})
