import { computed } from '@nuxtjs/composition-api'
import VueI18n from 'vue-i18n'
import { StaticCrossroadNode } from '~/src/Model/ServicesCrossroad/ServicesCrossroad'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export const useServicesCrossroad = () => {
  const $webConfig = useWebConfig()
  const { app } = useApp()
  const i18n = app.i18n as VueI18n
  const isNay = computed<boolean>(() => $webConfig.web === Web.Nay)

  const nayServices: StaticCrossroadNode[] = [
    {
      id: 1,
      title: i18n.t('services_crossroad.nay.service_1.title') as string,
      desc: i18n.t('services_crossroad.nay.service_1.desc') as string,
      alias: 'vsetko-o-nay'
    },
    {
      id: 2,
      title: i18n.t('services_crossroad.nay.service_2.title') as string,
      desc: i18n.t('services_crossroad.nay.service_2.desc') as string,
      alias: 'vsetko-o-nay'
    },
    {
      id: 3,
      title: i18n.t('services_crossroad.nay.service_3.title') as string,
      desc: i18n.t('services_crossroad.nay.service_3.desc') as string,
      alias: 'nek'
    },
    {
      id: 4,
      title: i18n.t('services_crossroad.nay.service_4.title') as string,
      desc: i18n.t('services_crossroad.nay.service_4.desc') as string,
      alias: 'reklamacia-bez-blocku'
    },
    {
      id: 5,
      title: i18n.t('services_crossroad.nay.service_5.title') as string,
      desc: i18n.t('services_crossroad.nay.service_5.desc') as string,
      alias: 'reklamacie-zaruka-zarucne-listy'
    },
    {
      id: 6,
      title: i18n.t('services_crossroad.nay.service_6.title') as string,
      desc: i18n.t('services_crossroad.nay.service_6.desc') as string,
      alias: 'nakup-na-splatky'
    },
    {
      id: 7,
      title: i18n.t('services_crossroad.nay.service_7.title') as string,
      desc: i18n.t('services_crossroad.nay.service_7.desc') as string,
      alias: 'nay-doprava'
    },
    {
      id: 8,
      title: i18n.t('services_crossroad.nay.service_8.title') as string,
      desc: i18n.t('services_crossroad.nay.service_8.desc') as string,
      alias: 'nay-doprava'
    },
    {
      id: 9,
      title: i18n.t('services_crossroad.nay.service_9.title') as string,
      desc: i18n.t('services_crossroad.nay.service_9.desc') as string,
      alias: 'nay-b2b'
    },
    {
      id: 10,
      title: i18n.t('services_crossroad.nay.service_10.title') as string,
      desc: i18n.t('services_crossroad.nay.service_10.desc') as string,
      alias: 'nevhodny-tovar-vymena-ci-vratenie'
    },
    {
      id: 11,
      title: i18n.t('services_crossroad.nay.service_11.title') as string,
      desc: i18n.t('services_crossroad.nay.service_11.desc') as string,
      alias: 'osobne-vyzdvihnutie-e-shopovych-objednavok-v-predajni'
    },
    {
      id: 12,
      title: i18n.t('services_crossroad.nay.service_12.title') as string,
      desc: i18n.t('services_crossroad.nay.service_12.desc') as string,
      alias: 'predajne'
    },
    {
      id: 13,
      title: i18n.t('services_crossroad.nay.service_13.title') as string,
      desc: i18n.t('services_crossroad.nay.service_13.desc') as string,
      alias: 'osobne-vyzdvihnutie-e-shopovych-objednavok-v-predajni'
    },
    {
      id: 14,
      title: i18n.t('services_crossroad.nay.service_14.title') as string,
      desc: i18n.t('services_crossroad.nay.service_14.desc') as string,
      alias: 'predajne'
    },
    {
      id: 15,
      title: i18n.t('services_crossroad.nay.service_15.title') as string,
      desc: i18n.t('services_crossroad.nay.service_15.desc') as string,
      alias: 'nay-smart-app'
    },
    {
      id: 16,
      title: i18n.t('services_crossroad.nay.service_16.title') as string,
      desc: i18n.t('services_crossroad.nay.service_16.desc') as string,
      alias: 'nay-smart-app'
    },
    {
      id: 17,
      title: i18n.t('services_crossroad.nay.service_17.title') as string,
      desc: i18n.t('services_crossroad.nay.service_17.desc') as string,
      alias: 'doprava-domov-zadarmo'
    },
    {
      id: 18,
      title: i18n.t('services_crossroad.nay.service_18.title') as string,
      desc: i18n.t('services_crossroad.nay.service_18.desc') as string,
      alias: 'kalibracia-televizora'
    },
    {
      id: 19,
      title: i18n.t('services_crossroad.nay.service_19.title') as string,
      desc: i18n.t('services_crossroad.nay.service_19.desc') as string,
      alias: 'nay-doprava'
    },
    {
      id: 20,
      title: i18n.t('services_crossroad.nay.service_20.title') as string,
      desc: i18n.t('services_crossroad.nay.service_20.desc') as string,
      alias: 'okamzita-vymena-zarucnej-reklamacie'
    },
    {
      id: 21,
      title: i18n.t('services_crossroad.nay.service_21.title') as string,
      desc: i18n.t('services_crossroad.nay.service_21.desc') as string,
      alias: 'balikobox-slovenskej-posty'
    },
    {
      id: 22,
      title: i18n.t('services_crossroad.nay.service_22.title') as string,
      desc: i18n.t('services_crossroad.nay.service_22.desc') as string,
      alias: 'predlzenie-zaruky-kedykolvek'
    },
    {
      id: 23,
      title: i18n.t('services_crossroad.nay.service_23.title') as string,
      desc: i18n.t('services_crossroad.nay.service_23.desc') as string,
      alias: 'klimatizacie-clanok'
    }
  ]

  const ewServices: StaticCrossroadNode[] = [
    {
      id: 1,
      title: i18n.t('services_crossroad.ew.service_1.title') as string,
      desc: i18n.t('services_crossroad.ew.service_1.desc') as string,
      alias: 'prodejny'
    },
    {
      id: 2,
      title: i18n.t('services_crossroad.ew.service_2.title') as string,
      desc: i18n.t('services_crossroad.ew.service_2.desc') as string,
      alias: null
    },
    {
      id: 3,
      title: i18n.t('services_crossroad.ew.service_3.title') as string,
      desc: i18n.t('services_crossroad.ew.service_3.desc') as string,
      alias: 'okamzita-vymena-podrobne-podminky'
    },
    {
      id: 4,
      title: i18n.t('services_crossroad.ew.service_4.title') as string,
      desc: i18n.t('services_crossroad.ew.service_4.desc') as string,
      alias: 'vyzvednuti-na-prodejne'
    },
    {
      id: 5,
      title: i18n.t('services_crossroad.ew.service_5.title') as string,
      desc: i18n.t('services_crossroad.ew.service_5.desc') as string,
      alias: 'komfortni-doprava'
    },
    {
      id: 7,
      title: i18n.t('services_crossroad.ew.service_7.title') as string,
      desc: i18n.t('services_crossroad.ew.service_7.desc') as string,
      alias: 'electro-world-smart-app'
    },
    {
      id: 8,
      title: i18n.t('services_crossroad.ew.service_8.title') as string,
      desc: i18n.t('services_crossroad.ew.service_8.desc') as string,
      alias: 'prodlouzenazaruka'
    },
    {
      id: 9,
      title: i18n.t('services_crossroad.ew.service_9.title') as string,
      desc: i18n.t('services_crossroad.ew.service_9.desc') as string,
      alias: 'pojisteni-nahodneho-poskozeni-zbozi'
    },
    {
      id: 10,
      title: i18n.t('services_crossroad.ew.service_10.title') as string,
      desc: i18n.t('services_crossroad.ew.service_10.desc') as string,
      alias: 'vykup-zarizeni'
    }
  ]

  return {
    services: isNay.value ? nayServices : ewServices
  }
}
