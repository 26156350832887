import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export class EventFilename extends IgnoredEvent {
  constructor (private filename: string) {
    super()
  }

  isIgnored (event: Event) {
    return !!event.exception?.values?.some(val => val.stacktrace?.frames?.some(frame => frame.filename?.includes(this.filename)))
  }
}
