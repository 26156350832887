

















































































































import { defineComponent, ref, computed, watch, PropType, onMounted } from '@nuxtjs/composition-api'
import { InputType } from '~/src/Model/InputType/InputType'
import FormLabel from '~/components/DesignSystem/Input/FormLabel.vue'
import BaseValidationProvider from '~/components/Forms/Validation/BaseValidationProvider.vue'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import Spinner from '~/components/Loading/Spinner.vue'
import { ValidationMode } from '~/src/Model/Validation/Validation'

const INPUT_LIMIT = 49
const INPUT_LIMIT_WARNING = 40

export default defineComponent({
  components: { BaseValidationProvider, Spinner, FormLabel },
  inheritAttrs: false,
  props: {
    dataTest: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    button: {
      type: Boolean,
      default: false
    },
    hidePositiveState: {
      type: Boolean,
      default: false
    },
    hideNegativeState: {
      type: Boolean,
      default: false
    },
    buttonInside: {
      type: Boolean,
      default: false
    },
    hint: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    inputWrapClass: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: null
    },
    inputIcon: {
      type: String,
      default: null
    },
    mode: {
      type: String as PropType<ValidationMode>,
      default: 'eager'
    },
    vid: {
      type: String,
      default: null
    },
    immediate: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number] as PropType<string | number | null>,
      default: ''
    },
    maxlength: {
      type: Number,
      required: false,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    eraseButton: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 0
    },
    autocomplete: {
      type: String,
      default: ''
    },
    forceFocus: {
      type: Boolean,
      default: false
    },
    showSpinner: {
      type: Boolean,
      default: false
    },
    feedbackClass: {
      type: String,
      default: null
    },
    readOnlyText: {
      type: Boolean,
      default: false
    },
    inputMode: {
      type: String,
      default: 'text'
    },
    tag: {
      type: String,
      default: 'p'
    },
    innerTag: {
      type: String,
      default: 'span'
    }
  },

  emits: ['input', 'blur', 'focus'],

  setup (props, { emit }) {
    const inputRef = useRequiredTemplateRef<HTMLInputElement>()
    const showPassword = ref<boolean>(false)
    const required = ref<boolean>(props.rules.includes('required'))

    const forceFocus = () => {
      if (props.forceFocus && inputRef.value) {
        inputRef.value.focus()
      }
    }

    watch(() => props.forceFocus, forceFocus, { immediate: true })

    onMounted(() => {
      forceFocus()
    })

    const isTypePassword = ref<boolean>(props.type === InputType.password)

    const valueLength = computed<number>(() => props.value?.toString().length ?? 0)

    const checkInputLimit = computed<boolean>(() => props.rules.includes('city') || props.rules.includes('address') || props.rules.includes('name'))

    const inputMaxLength = computed<number | null>(() => {
      if (checkInputLimit.value) {
        return INPUT_LIMIT
      }
      return props.maxlength
    })

    const showInputLimitWarning = computed<boolean>(() => {
      return checkInputLimit.value ? valueLength.value >= INPUT_LIMIT_WARNING : false
    })

    const inputMaxLengthReached = computed<boolean>(() => {
      if (!props.value || !inputMaxLength.value) {
        return false
      }
      return valueLength.value >= inputMaxLength.value
    })

    const innerValue = computed<string | number | null>({
      get: () => props.value,
      set: (newValue: string | number | null) => emit('input', newValue)
    })

    const inputType = computed<InputType | string>(() => {
      if (props.type === InputType.password && showPassword.value) {
        return InputType.text
      }
      if (props.type) {
        return props.type
      }
      if (props.rules.includes('numeric_space')) {
        return InputType.text
      }
      if (props.rules.includes('numeric')) {
        return InputType.number
      }
      if (props.rules.includes('between')) {
        return InputType.number
      }
      if (props.rules.includes('phone')) {
        return InputType.number
      }
      if (props.rules.includes('digits')) {
        return InputType.number
      }
      if (props.rules.includes('ico')) {
        return InputType.number
      }
      if (props.rules.includes('zip')) {
        return InputType.text
      }
      if (props.rules.includes('cardNumber')) {
        return InputType.number
      }
      return InputType.text
    })

    const isButtonInside = computed<boolean>(() => {
      return props.buttonInside || isTypePassword.value || props.eraseButton
    })

    const showState = (error: string, valid: boolean, validated: boolean): boolean | null => {
      if (!validated) {
        return null
      }

      if (error) {
        return props.hideNegativeState ? null : false
      }

      if (valid && props.value) {
        return props.hidePositiveState ? null : true
      }

      return null
    }

    return {
      innerValue,
      inputType,
      inputRef,
      required,
      inputMaxLength,
      inputMaxLengthReached,
      showPassword,
      isTypePassword,
      isButtonInside,
      showInputLimitWarning,
      valueLength,
      showState
    }
  }
})
