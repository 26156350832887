























import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

export default defineComponent({
  inheritAttrs: false,
  props: {
    to: {
      type: String,
      default: null
    },
    uid: {
      type: String,
      default: null
    },
    linkClass: {
      type: String,
      default: null
    },
    iconClass: {
      type: [String, Array],
      default: null
    },
    iconSpacing: {
      type: String,
      default: 'bs-mr-3'
    },
    iconSubClass: {
      type: [String, Array, Object],
      default: null
    }
  },
  emits: ['click'],
  setup (props) {
    const linkTo = computed<string | null>(() => {
      if (props.to) {
        return props.to
      }
      if (props.uid) {
        const systemPagesStore = useSystemPagesStore()
        return systemPagesStore.pathByUid(props.uid).value
      }

      return null
    })

    return {
      linkTo
    }
  }
})
