import { ObjectFit } from '~/src/Model/Image/ImageConfiguration'

export default {
  defaultConfig: {
    width: 1024,
    height: 1024,
    fixedRatio: false,
    retina: false,
    objectFit: ObjectFit.scaleDown
  },
  imageSizes: {
    orig: {
      width: 1024,
      height: 1024
    },
    'img-125': {
      width: 125,
      height: 125
    },
    'img-250': {
      width: 250,
      height: 250
    },

    'image-w36': {
      width: 36,
      height: 36
    },
    'image-w45': {
      width: 45,
      height: 45
    },
    'image-w60': {
      width: 60,
      height: 60
    },
    'image-w80': {
      width: 80,
      height: 80
    },
    'image-w200': {
      width: 200,
      height: 200
    },
    'image-w300': {
      width: 300,
      height: 300
    },
    'image-w500': {
      width: 500,
      height: 500
    },
    'image-w800': {
      width: 800,
      height: 800
    },
    'image-w1280': {
      width: 1280,
      height: 1280
    },
    'image-h30': {
      width: 999,
      height: 30
    },

    'paymethodIcon-w100h24': {
      width: 100,
      height: 24
    },

    'leaflet-w142h200': {
      width: 142,
      height: 200,
      fileType: 'jpg'
    },

    'article-w300': {
      width: 300,
      height: 116,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'article-w360': {
      width: 360,
      height: 140,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'article-w545': {
      width: 545,
      height: 212,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'article-w600': {
      width: 600,
      height: 233,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'article-w720': {
      width: 720,
      height: 280,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'article-w1090': {
      width: 1090,
      height: 424,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },

    'articleHero-w345': {
      width: 345,
      height: 229,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w454': {
      width: 454,
      height: 301,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w628': {
      width: 628,
      height: 416,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w860': {
      width: 860,
      height: 570,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w910': {
      width: 910,
      height: 603,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w1256': {
      width: 1256,
      height: 832,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'articleHero-w1720': {
      width: 1720,
      height: 1140,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },

    'fullWidthHeroMobile-w375h148': {
      width: 375,
      height: 148,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'fullWidthHeroMobile-w375h280': {
      width: 375,
      height: 280,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHeroMobile-w750h295': {
      width: 750,
      height: 295,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'fullWidthHeroMobile-w750h560': {
      width: 750,
      height: 560,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHeroMobile-w1125h443': {
      width: 1125,
      height: 443,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'fullWidthHeroMobile-w1125h840': {
      width: 1125,
      height: 840,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHeroMobile-w1500h591': {
      width: 1500,
      height: 591,
      objectFit: ObjectFit.cover,
      fileType: 'jpg'
    },
    'fullWidthHeroMobile-w1500h1119': {
      width: 1500,
      height: 1119,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w768': {
      width: 768,
      height: 216,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w932': {
      width: 932,
      height: 262,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w1280': {
      width: 1280,
      height: 360,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w1536': {
      width: 1536,
      height: 432,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w1864': {
      width: 1864,
      height: 524,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },
    'fullWidthHero-w2560': {
      width: 2560,
      height: 720,
      objectFit: ObjectFit.cover,
      fileType: 'jpg',
      quality: 90
    },

    'pageCrossroad-w120': {
      width: 120,
      height: 120,
      fileType: 'jpg'
    },
    'pageCrossroad-w200': {
      width: 200,
      height: 200,
      fileType: 'jpg'
    },

    'bnr-w210h210': {
      width: 210,
      height: 210,
      fileType: 'jpg'
    },
    'bnr-w295h295': {
      width: 295,
      height: 295,
      fileType: 'jpg'
    },
    'bnr-w380h380': {
      width: 380,
      height: 380,
      fileType: 'jpg'
    },
    'bnr-w305h480': {
      width: 305,
      height: 480,
      fileType: 'jpg'
    },
    'bnr-w575h430': {
      width: 575,
      height: 430,
      fileType: 'jpg'
    },
    'bnr-w767h200': {
      width: 767,
      height: 200,
      fileType: 'jpg'
    },
    'bnr-w1280h150': {
      width: 1280,
      height: 150,
      fileType: 'jpg'
    },
    'bnr-w1280h360': {
      width: 1280,
      height: 360,
      fileType: 'jpg'
    },

    'pageDetailColumn-w400': {
      width: 400,
      height: 400,
      fileType: 'jpg',
      quality: 90
    },
    'pageDetailColumn-w600': {
      width: 600,
      height: 600,
      fileType: 'jpg',
      quality: 90
    },
    'pageDetailColumn-w800': {
      width: 800,
      height: 800,
      fileType: 'jpg',
      quality: 90
    },
    'pageDetailColumn-w1200': {
      width: 1200,
      height: 1200,
      fileType: 'jpg',
      quality: 90
    },
    'pageDetailColumn-w1600': {
      width: 1600,
      height: 1600,
      fileType: 'jpg',
      quality: 90
    },

    'wysiwygArticle-w375': {
      width: 375,
      height: 375,
      fileType: 'jpg'
    },
    'wysiwygArticle-w768': {
      width: 768,
      height: 768,
      fileType: 'jpg'
    },
    'wysiwygArticle-w905': {
      width: 905,
      height: 905,
      fileType: 'jpg'
    },
    'wysiwygArticle-w1536': {
      width: 1536,
      height: 1536,
      fileType: 'jpg'
    },
    'wysiwygArticle-w1810': {
      width: 1810,
      height: 1810,
      fileType: 'jpg'
    },

    'storeGallery-w150': {
      width: 150,
      height: 100,
      fileType: 'jpg'
    },
    'storeGallery-w200': {
      width: 200,
      height: 133,
      fileType: 'jpg'
    },
    'storeGallery-w375': {
      width: 375,
      height: 250,
      fileType: 'jpg'
    },
    'storeGallery-w610': {
      width: 610,
      height: 407,
      fileType: 'jpg'
    },
    'storeGallery-w750': {
      width: 750,
      height: 500,
      fileType: 'jpg'
    },
    'storeGallery-w940': {
      width: 940,
      height: 627,
      fileType: 'jpg'
    },
    'storeGallery-w1220': {
      width: 1220,
      height: 814,
      fileType: 'jpg'
    },

    'product-w30h30': {
      width: 30,
      height: 30,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w40h40': {
      width: 40,
      height: 40,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w50h50': {
      width: 50,
      height: 50,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w60h60': {
      width: 60,
      height: 60,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w80h80': {
      width: 80,
      height: 80,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w100h100': {
      width: 100,
      height: 100,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w120h120': {
      width: 120,
      height: 120,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w163h163': {
      width: 163,
      height: 163,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w170h170': {
      width: 170,
      height: 170,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w340h340': {
      width: 340,
      height: 340,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w500h500': {
      width: 500,
      height: 500,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w1100h1100': {
      width: 1100,
      height: 1100,
      fileType: 'jpg',
      fixedRatio: true
    },

    'product-w70h64': {
      width: 70,
      height: 64,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w105h95': {
      width: 105,
      height: 95,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w144h130': {
      width: 144,
      height: 130,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w210h184': {
      width: 210,
      height: 184,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w228h200': {
      width: 228,
      height: 200,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w255h232': {
      width: 255,
      height: 232,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w290h255': {
      width: 290,
      height: 255,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w304h267': {
      width: 304,
      height: 267,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w319h290': {
      width: 319,
      height: 290,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w359h315': {
      width: 359,
      height: 315,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w385h350': {
      width: 385,
      height: 350,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w510h463': {
      width: 510,
      height: 463,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w765h695': {
      width: 765,
      height: 695,
      fileType: 'jpg',
      fixedRatio: true
    },
    'product-w1020h926': {
      width: 1020,
      height: 926,
      fileType: 'jpg',
      fixedRatio: true
    },

    'productGalleryModal-w300': {
      width: 300,
      height: 300,
      fileType: 'jpg'
    },
    'productGalleryModal-w500': {
      width: 500,
      height: 500,
      fileType: 'jpg'
    },
    'productGalleryModal-w700': {
      width: 700,
      height: 700,
      fileType: 'jpg'
    },
    'productGalleryModal-w900': {
      width: 900,
      height: 900,
      fileType: 'jpg'
    },
    'productGalleryModal-w1100': {
      width: 1100,
      height: 1100,
      fileType: 'jpg'
    },
    'productGalleryModal-w1300': {
      width: 1300,
      height: 1300,
      fileType: 'jpg'
    },
    'productGalleryModal-w1500': {
      width: 1500,
      height: 1500,
      fileType: 'jpg'
    },
    'productGalleryModal-w1700': {
      width: 1700,
      height: 1700,
      fileType: 'jpg'
    },
    'productGalleryModal-w1950': {
      width: 1950,
      height: 1950,
      fileType: 'jpg'
    },
    'productGalleryModal-w2200': {
      width: 2200,
      height: 2200,
      fileType: 'jpg'
    },
    'productGalleryModal-w2340': {
      width: 2340,
      height: 2340,
      fileType: 'jpg'
    },
    'christmas-w50h50': {
      width: 50,
      height: 50,
      fixedRatio: true
    }
  }
}
