import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_sentryserver_7e937ede from 'nuxt_plugin_sentryserver_7e937ede' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_18da0d56 from 'nuxt_plugin_sentryclient_18da0d56' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_bootstrapvue_988ac56e from 'nuxt_plugin_bootstrapvue_988ac56e' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_plugin_70a86428 from 'nuxt_plugin_plugin_70a86428' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_gtm_7197be70 from 'nuxt_plugin_gtm_7197be70' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_axios_68910dcc from 'nuxt_plugin_axios_68910dcc' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_6ddfa4c2 from 'nuxt_plugin_cookieuniversalnuxt_6ddfa4c2' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginserver_9797059c from 'nuxt_plugin_pluginserver_9797059c' // Source: ./plugin.server.js (mode: 'server')
import nuxt_plugin_distplugin463cd310_6dc63498 from 'nuxt_plugin_distplugin463cd310_6dc63498' // Source: ./dist.plugin.463cd310.mjs (mode: 'all')
import nuxt_plugin_router_5f8435d5 from 'nuxt_plugin_router_5f8435d5' // Source: ./router.js (mode: 'all')
import nuxt_plugin_webconfig_576a3bd1 from 'nuxt_plugin_webconfig_576a3bd1' // Source: ../plugins/web-config (mode: 'all')
import nuxt_plugin_sentrycontext_223d7c3b from 'nuxt_plugin_sentrycontext_223d7c3b' // Source: ../plugins/sentry/sentry-context (mode: 'all')
import nuxt_plugin_initialDataLayer_df95574e from 'nuxt_plugin_initialDataLayer_df95574e' // Source: ../plugins/initialDataLayer (mode: 'all')
import nuxt_plugin_axiosprofiler_20b19c56 from 'nuxt_plugin_axiosprofiler_20b19c56' // Source: ../plugins/axios-profiler (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_dateformatter_b057d33e from 'nuxt_plugin_dateformatter_b057d33e' // Source: ../plugins/date-formatter (mode: 'all')
import nuxt_plugin_servercachestorage_33b1269c from 'nuxt_plugin_servercachestorage_33b1269c' // Source: ../plugins/server-cache-storage (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_currency_15633587 from 'nuxt_plugin_currency_15633587' // Source: ../plugins/currency (mode: 'all')
import nuxt_plugin_formatAlias_16624f63 from 'nuxt_plugin_formatAlias_16624f63' // Source: ../plugins/formatAlias (mode: 'all')
import nuxt_plugin_localenumberformatter_55c583ba from 'nuxt_plugin_localenumberformatter_55c583ba' // Source: ../plugins/locale-number-formatter (mode: 'all')
import nuxt_plugin_exponea_500e4917 from 'nuxt_plugin_exponea_500e4917' // Source: ../plugins/exponea.client (mode: 'client')
import nuxt_plugin_luigisBox_877e1116 from 'nuxt_plugin_luigisBox_877e1116' // Source: ../plugins/luigisBox.client (mode: 'client')
import nuxt_plugin_stripTagsFilter_3a8b401a from 'nuxt_plugin_stripTagsFilter_3a8b401a' // Source: ../plugins/stripTagsFilter (mode: 'all')
import nuxt_plugin_htmlBlockToInlineFilter_5d0acd98 from 'nuxt_plugin_htmlBlockToInlineFilter_5d0acd98' // Source: ../plugins/htmlBlockToInlineFilter (mode: 'all')
import nuxt_plugin_speedCurveLux_c42c9b5e from 'nuxt_plugin_speedCurveLux_c42c9b5e' // Source: ../plugins/speedCurveLux (mode: 'all')
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ../plugins/auth (mode: 'all')
import nuxt_plugin_authservice_01a40bf4 from 'nuxt_plugin_authservice_01a40bf4' // Source: ../plugins/auth-service (mode: 'all')
import nuxt_plugin_visitedproductscookierestore_fbd72856 from 'nuxt_plugin_visitedproductscookierestore_fbd72856' // Source: ../plugins/visited-products-cookie-restore (mode: 'all')
import nuxt_plugin_init_101a46aa from 'nuxt_plugin_init_101a46aa' // Source: ../plugins/init.server (mode: 'server')
import nuxt_plugin_tabfocus_11f0304f from 'nuxt_plugin_tabfocus_11f0304f' // Source: ../plugins/tab-focus.client (mode: 'client')
import nuxt_plugin_clientload_45ad04ac from 'nuxt_plugin_clientload_45ad04ac' // Source: ../plugins/dataLayer/client-load (mode: 'client')
import nuxt_plugin_pagetype_a4f8e78c from 'nuxt_plugin_pagetype_a4f8e78c' // Source: ../plugins/dataLayer/page-type (mode: 'client')
import nuxt_plugin_persistedstate_a1ce1650 from 'nuxt_plugin_persistedstate_a1ce1650' // Source: ../plugins/persisted-state.client (mode: 'client')
import nuxt_plugin_meta_a8739804 from 'nuxt_plugin_meta_a8739804' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#0099ef"}],"link":[{"rel":"Shortcut icon","type":"image\u002Fx-icon","href":"\u002Fnay\u002Ffavicon.ico"},{"rel":"apple-touch-icon","href":"\u002Fnay\u002Fapple-touch-icon.png"}],"script":[{"src":"\u002Fie\u002Fie.js?web=nay","defer":true,"nomodule":true},{"innerHTML":"0","type":"text\u002Fjavascript","pbody":true},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.defer=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-TJWF2L4')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"__dangerouslyDisableSanitizers":["script"],"htmlAttrs":{"lang":"sk"},"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-TJWF2L4&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_7e937ede === 'function') {
    await nuxt_plugin_sentryserver_7e937ede(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_18da0d56 === 'function') {
    await nuxt_plugin_sentryclient_18da0d56(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_988ac56e === 'function') {
    await nuxt_plugin_bootstrapvue_988ac56e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_70a86428 === 'function') {
    await nuxt_plugin_plugin_70a86428(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_7197be70 === 'function') {
    await nuxt_plugin_gtm_7197be70(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_68910dcc === 'function') {
    await nuxt_plugin_axios_68910dcc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_6ddfa4c2 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_6ddfa4c2(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_9797059c === 'function') {
    await nuxt_plugin_pluginserver_9797059c(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin463cd310_6dc63498 === 'function') {
    await nuxt_plugin_distplugin463cd310_6dc63498(app.context, inject)
  }

  if (typeof nuxt_plugin_router_5f8435d5 === 'function') {
    await nuxt_plugin_router_5f8435d5(app.context, inject)
  }

  if (typeof nuxt_plugin_webconfig_576a3bd1 === 'function') {
    await nuxt_plugin_webconfig_576a3bd1(app.context, inject)
  }

  if (typeof nuxt_plugin_sentrycontext_223d7c3b === 'function') {
    await nuxt_plugin_sentrycontext_223d7c3b(app.context, inject)
  }

  if (typeof nuxt_plugin_initialDataLayer_df95574e === 'function') {
    await nuxt_plugin_initialDataLayer_df95574e(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosprofiler_20b19c56 === 'function') {
    await nuxt_plugin_axiosprofiler_20b19c56(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_dateformatter_b057d33e === 'function') {
    await nuxt_plugin_dateformatter_b057d33e(app.context, inject)
  }

  if (typeof nuxt_plugin_servercachestorage_33b1269c === 'function') {
    await nuxt_plugin_servercachestorage_33b1269c(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_15633587 === 'function') {
    await nuxt_plugin_currency_15633587(app.context, inject)
  }

  if (typeof nuxt_plugin_formatAlias_16624f63 === 'function') {
    await nuxt_plugin_formatAlias_16624f63(app.context, inject)
  }

  if (typeof nuxt_plugin_localenumberformatter_55c583ba === 'function') {
    await nuxt_plugin_localenumberformatter_55c583ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_exponea_500e4917 === 'function') {
    await nuxt_plugin_exponea_500e4917(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_luigisBox_877e1116 === 'function') {
    await nuxt_plugin_luigisBox_877e1116(app.context, inject)
  }

  if (typeof nuxt_plugin_stripTagsFilter_3a8b401a === 'function') {
    await nuxt_plugin_stripTagsFilter_3a8b401a(app.context, inject)
  }

  if (typeof nuxt_plugin_htmlBlockToInlineFilter_5d0acd98 === 'function') {
    await nuxt_plugin_htmlBlockToInlineFilter_5d0acd98(app.context, inject)
  }

  if (typeof nuxt_plugin_speedCurveLux_c42c9b5e === 'function') {
    await nuxt_plugin_speedCurveLux_c42c9b5e(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_6a7e4e1e === 'function') {
    await nuxt_plugin_auth_6a7e4e1e(app.context, inject)
  }

  if (typeof nuxt_plugin_authservice_01a40bf4 === 'function') {
    await nuxt_plugin_authservice_01a40bf4(app.context, inject)
  }

  if (typeof nuxt_plugin_visitedproductscookierestore_fbd72856 === 'function') {
    await nuxt_plugin_visitedproductscookierestore_fbd72856(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_init_101a46aa === 'function') {
    await nuxt_plugin_init_101a46aa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tabfocus_11f0304f === 'function') {
    await nuxt_plugin_tabfocus_11f0304f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientload_45ad04ac === 'function') {
    await nuxt_plugin_clientload_45ad04ac(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pagetype_a4f8e78c === 'function') {
    await nuxt_plugin_pagetype_a4f8e78c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_persistedstate_a1ce1650 === 'function') {
    await nuxt_plugin_persistedstate_a1ce1650(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_a8739804 === 'function') {
    await nuxt_plugin_meta_a8739804(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
