import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { useNavigateToSystemPage } from '~/src/Infrastructure/SystemPages/NavigateToSystemPage'

const UID_CHECKOUT_EXPRESS_PICKUP = 'CHECKOUT_EXPRESS_PICKUP'
const EXPRESS_PICKUP_PAY_METHOD_ID = 1425

export const useExpressPickupOrderCreate = () => {
  const basketStore = useBasketStore()
  const { navigateToUid } = useNavigateToSystemPage()

  const { exec: createExpressPickupOrder, loading: creatingExpressPickupOrder } = usePromise(async (selectedStore: number) => {
    await basketStore.setDelivery(selectedStore)
    await basketStore.setPayment(EXPRESS_PICKUP_PAY_METHOD_ID)
    await navigateToUid(UID_CHECKOUT_EXPRESS_PICKUP)
  })

  return {
    createExpressPickupOrder,
    creatingExpressPickupOrder
  }
}
