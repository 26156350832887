import { defineComponent } from '@nuxtjs/composition-api'
import { CreateElement, VNode } from 'vue'
import useError from '~/src/Infrastructure/Nuxt/Config/UseError'

export default (statusCode: number, message: string) => {
  return defineComponent({
    setup () {
      const error = useError()
      error({ statusCode, message })

      return {}
    },
    render (createElement: CreateElement): VNode {
      return createElement('div')
    }
  })
}
