




import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  emits: ['input'],
  setup (_props, { emit }) {
    const root = ref<HTMLElement>()
    const webConfig = useWebConfig()

    onMounted(() => {
      // @ts-ignore
      if (window.google?.accounts?.id) {
        renderButton()
        return
      }

      loadGoogleLoginLibrary()
    })

    const loadGoogleLoginLibrary = (): void => {
      const googleScript = document.createElement('script')
      googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
      document.head.appendChild(googleScript)

      // @ts-ignore
      window.onGoogleLibraryLoad = renderButton
    }

    const renderButton = (): void => {
      // @ts-ignore
      window.google.accounts.id.initialize({
        client_id: webConfig.googleLoginClientId,
        callback: handleLogin,
        ux_mode: 'popup'
      })

      // @ts-ignore
      window.google.accounts.id.renderButton(root.value, {
        theme: 'outline',
        size: 'large'
      })

      // @ts-ignore
      window.google.accounts.id.prompt()
    }

    const handleLogin = (response: any) => {
      emit('input', response)
    }

    return {
      root
    }
  }
})
