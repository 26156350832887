




















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { useMatchMedia } from '~/src/Infrastructure/MatchMedia/MatchMedia'
import { BreadCrumb, BreadCrumbRichSnippet } from '~/src/Model/BreadCrumb/BreadCrumb'
import BreadCrumbBarList from '~/components/BreadCrumb/BreadCrumbBarList.vue'
import { useRichSnippet } from '~/src/Infrastructure/RichSnippet/useRichSnippet'
import {
  useBreadCrumbRichSnippetFactory
} from '~/src/Infrastructure/BreadCrumb/BreadCrumbRichSnippetFactory'

export default defineComponent({
  components: { BreadCrumbBarList },
  props: {
    breadCrumbs: {
      required: true,
      type: Array as PropType<BreadCrumb[]>
    },
    onDarkBg: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { matches: isMobileActive } = useMatchMedia('(max-width: 575.98px)')
    const { matches: isTabletActive } = useMatchMedia('(min-width: 576px) and (max-width: 991.98px)')

    const { breadCrumbRichSnippetFactory } = useBreadCrumbRichSnippetFactory()

    const richSnippet = computed<BreadCrumbRichSnippet>(() => {
      return breadCrumbRichSnippetFactory(props.breadCrumbs)
    })

    useRichSnippet(richSnippet)

    const visibleItems = computed<number>(() => {
      if (isMobileActive.value) {
        return 1
      }
      if (isTabletActive.value) {
        return 2
      }

      return 3
    })

    const visibleBreadCrumbs = computed<BreadCrumb[]>(() => {
      return props.breadCrumbs?.length > (visibleItems.value + 1)
        ? Array.prototype.concat(props.breadCrumbs[0], props.breadCrumbs.slice(props.breadCrumbs.length - visibleItems.value))
        : props.breadCrumbs
    })

    const popoverBreadCrumbs = computed<BreadCrumb[]>(() => {
      return props.breadCrumbs?.length > (visibleItems.value + 1)
        ? props.breadCrumbs.slice(1, props.breadCrumbs.length - visibleItems.value)
        : []
    })

    return {
      visibleBreadCrumbs,
      popoverBreadCrumbs
    }
  },
  head: {}
})
