














import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { PanelParameter } from '~/src/Model/PdpBar/PdpBar'
import PdpBarCopyIcon from '~/components/PdpBar/PdpBarCopyIcon.vue'

export default defineComponent({
  components: { PdpBarCopyIcon },
  props: {
    pdpBarParameters: {
      type: Array as PropType<PanelParameter[]>,
      required: true
    }
  }
})
