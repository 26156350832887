




import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { ProductTag, ProductTagTypeMap } from '~/src/Model/ProductTag/ProductTag'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'li'
    },
    productTag: {
      required: true,
      type: Object as PropType<ProductTag>
    },
    size: {
      type: String,
      default: undefined
    }
  },
  setup (props) {
    const variant = computed<string>(() => ProductTagTypeMap[props.productTag.type])

    return {
      variant
    }
  }
})
