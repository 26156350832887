




































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { BasketProductService } from '~/src/Model/Basket/Product'
import { ServiceVariantRequirement } from '~/src/Model/ProductServices/ProductServices'
import Tooltip from '~/components/DesignSystem/Tooltip/Tooltip.vue'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'

export default defineComponent({
  components: { Tooltip },
  props: {
    service: {
      required: true,
      type: Object as PropType<BasketProductService>
    }
  },
  emits: ['delete-service'],
  setup (props) {
    const { i18n } = useTranslator()
    const enableServiceRemoval = computed<boolean>(() =>
      props.service.service.financial?.isVariantRequired === ServiceVariantRequirement.no ||
      props.service.service.technical?.isVariantRequired === ServiceVariantRequirement.no
    )

    const tooltipText = computed<string>(() => {
      if (props.service.service.technical?.isVariantRequired === ServiceVariantRequirement.optional ||
        props.service.service.financial?.isVariantRequired === ServiceVariantRequirement.optional) {
        return i18n.t('product_services.optional_choice').toString()
      }

      return i18n.t('product_services.required_choice').toString()
    })

    return {
      enableServiceRemoval,
      tooltipText
    }
  }
})
