

























import { defineComponent } from '@nuxtjs/composition-api'
import Row from '~/components/DesignSystem/Grid/Row.vue'
import Column from '~/components/DesignSystem/Grid/Column.vue'

export default defineComponent({
  components: { Row, Column },
  props: {
    noAdvantages: {
      type: Boolean,
      default: false
    },
    onlyForm: {
      type: Boolean,
      default: false
    },
    isInModal: {
      type: Boolean,
      default: false
    }
  }
})
