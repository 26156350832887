






























import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Dropdown from '~/components/DesignSystem/Dropdown/Dropdown.vue'
import DropdownItem from '~/components/DesignSystem/Dropdown/DropdownItem.vue'

const localeFlagMap: { [key: string]: string } = {
  sk: '/img/flags/sk.svg',
  cs: '/img/flags/cs.svg',
  en: '/img/flags/en.svg'
}

export default defineComponent({
  name: 'LocaleSwitch',
  components: {
    Dropdown,
    DropdownItem
  },
  setup () {
    const $webConfig = useWebConfig()
    const { app } = useApp()

    const currentLocale = app.i18n.locale as string
    const currentFlag = localeFlagMap[currentLocale]

    const availableLocales = computed<string[]>(() => {
      return $webConfig.allowedLocales.filter(l => l !== currentLocale)
    })

    const setLocale = (locale: string): void => {
      app.$cookies.set('locale', locale)
      window.location.reload()
    }

    return {
      currentLocale,
      currentFlag,
      availableLocales,
      localeFlagMap,
      setLocale
    }
  }
})
