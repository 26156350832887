














import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import HeaderInfolink from '~/components/Header/HeaderInfolink.vue'
import PdpBar from '~/components/PdpBar/PdpBar.vue'
import { useVirtualPageViewTriggerWatcher } from '~/src/Infrastructure/DataLayer/VirtualPageViewTriggerWatcher'
import { useWindowInnerHeightOnMounted } from '~/src/Infrastructure/Layout/WindowInnerHeightOnMounted'
import { useScrollbarWidthOnMounted } from '~/src/Infrastructure/Layout/ScrollbarWidthOnMounted'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

const LAYOUT_NAME = 'reclamation'

export default defineComponent({
  components: { HeaderInfolink, PdpBar },
  setup () {
    useVirtualPageViewTriggerWatcher(LAYOUT_NAME)
    useWindowInnerHeightOnMounted()
    useScrollbarWidthOnMounted()

    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    onMounted(async () => {
      await christmasDeliveryConfigurationStore.loadChristmasDeliveryConfigurations()
    })
  }
})
