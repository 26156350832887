























































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import AccessoryImagePage from '~/components/ProductAccessories/Components/AccessoryImagePage.vue'
import AccessoryImageSidebar from '~/components/ProductAccessories/Components/AccessoryImageSidebar.vue'
import MinimalPriceBundle from '~/components/ProductBox/Components/Price/MinimalPriceBundle.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import Column from '~/components/DesignSystem/Grid/Column.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  name: 'ProductAccessoryBox',
  components: {
    MinimalPriceBundle,
    AccessoryImagePage,
    AccessoryImageSidebar,
    Column,
    Button
  },
  props: {
    productBox: {
      type: Object as PropType<ProductBox>,
      required: true
    },
    smColumns: {
      type: String,
      default: '6'
    },
    mdColumns: {
      type: String,
      default: undefined
    },
    lgColumns: {
      type: String,
      default: undefined
    },
    xlColumns: {
      type: String,
      default: undefined
    },
    inSidebar: {
      type: Boolean,
      default: false
    },
    inModal: {
      type: Boolean,
      default: false
    },
    addToBasketPending: {
      type: Boolean,
      default: false
    }
  },
  emits: ['product-added'],
  setup (props, { emit }) {
    const { isLoyaltyMember } = useAuthenticatedUser()

    const basketStore = useBasketStore()
    const isProductInBasket = computed<boolean>(() => basketStore.isProductInBasket(props.productBox.product.id))

    const addToBasket = () => {
      emit('product-added', props.productBox.product.id)
    }

    return {
      isProductInBasket,
      addToBasket,
      isLoyaltyMember
    }
  }
})
