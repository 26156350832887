





















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Banner } from '~/src/Model/Banner/Banner'
import Picture from '~/components/Picture/Picture.vue'
import Source from '~/components/Source/Source.vue'

export default defineComponent({
  components: { Source, Picture },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    banner: {
      required: true,
      type: Object as PropType<Banner>
    },
    imageSizeMobile: {
      type: String,
      default: 'image-w800'
    },
    imageSizeDesktop: {
      type: String,
      default: 'image-w1280'
    },
    mediaMobile: {
      type: String,
      default: null
    },
    breakpoint: {
      type: Number,
      default: 576
    },
    loading: {
      type: String,
      required: false,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    },
    fetchpriority: {
      type: String,
      default: null
    }
  }
})
