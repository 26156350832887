import { ref, useFetch } from '@nuxtjs/composition-api'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { ProductBox } from '~/src/Model/ProductBox/ProductBox'
import { useProductAccessoriesRepository } from '~/src/Infrastructure/ProductAccessories/ProductAccessoriesRepository'
import { ProductAccessoriesRequest } from '~/src/Model/ProductAccessories/ProductAccessories'

export const useProductAccessoriesFetch = (id: number | null, request: ProductAccessoriesRequest) => {
  const { loadProductAccessories } = useProductAccessoriesRepository()

  const accessories = ref<ProductBox[]>([])
  const totalCount = ref<number>(0)
  const productAccessoriesRequest = ref<ProductAccessoriesRequest>(request)
  const clearFetch = ref<boolean>(true)
  const productId = ref<number | null>(id)

  const { loading: pending, exec } = usePromise(async () => {
    if (!productId.value) {
      return
    }
    const response = await loadProductAccessories(productId.value, productAccessoriesRequest.value)

    if (clearFetch.value) {
      accessories.value = response.accessories
    } else {
      accessories.value.push(...response.accessories)
    }

    totalCount.value = response.totalCount
  })

  useFetch(async () => {
    await exec()
  })

  const clearReFetch = async () => {
    clearFetch.value = true
    await exec()
  }

  const loadMore = () => {
    productAccessoriesRequest.value.page++
    clearFetch.value = false
    exec()
  }

  const loadPage = (page: number, type: number | null = null) => {
    productAccessoriesRequest.value.type = type
    productAccessoriesRequest.value.page = page
    clearFetch.value = true
    exec()
  }

  const fetchAccessoriesForProduct = (id: number, request?: ProductAccessoriesRequest) => {
    productId.value = id
    if (request) {
      productAccessoriesRequest.value = request
    }
    exec()
  }

  return {
    accessories,
    totalCount,
    loadMore,
    loadPage,
    pending,
    fetchAccessoriesForProduct,
    clearReFetch
  }
}
