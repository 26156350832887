import { defineStore } from 'pinia'
import { ContextName, PdpBar, PdpBarContext } from '~/src/Model/PdpBar/PdpBar'
import { usePdpBarRepositoryWithAxios } from '~/src/Infrastructure/PdpBar/PdpBarRepository'

const PDP_BAR_STORE_ID = 'pdpBar'

export interface PdpBarState {
  pdpBar: PdpBar
  context: PdpBarContext | null
}

export const usePdpBarStore = defineStore(PDP_BAR_STORE_ID, {
  state: (): PdpBarState => ({
    pdpBar: {
      title: '',
      navigation: [],
      parameters: []
    },
    context: null
  }),
  actions: {
    setContextWarehouse (): void {
      this.context = {
        contextName: ContextName.Warehouse
      }
    },
    setContextOrder (orderNumber: number): void {
      this.context = {
        contextName: ContextName.Order,
        orderNumber
      }
    },
    setPdpBar (pdpBar: PdpBar): void {
      this.pdpBar = pdpBar
    },
    resetContext (): void {
      this.context = null
    },
    async loadPdpBar (alias: string): Promise<PdpBar> {
      const { loadPdpBarInfo } = usePdpBarRepositoryWithAxios(this.$nuxt.$axios)
      const pdpBar = await loadPdpBarInfo({ alias, context: this.context })
      this.setPdpBar(pdpBar)
      return pdpBar
    }
  }
})
