





















































































































































import { computed, defineComponent, PropType, reactive, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import { CustomerLoginData } from '~/src/Model/Customer/CustomerLoginData'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import { useConfig } from '~/src/Infrastructure/Nuxt/Config/UseConfig'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import { TwoFARequired } from '~/src/Model/Auth/TwoFA'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'
import OnboardingLoginAlert from '~/components/Auth/Karpaty/OnboardingLoginAlert.vue'
import RegistrationForm from '~/components/Auth/RegistrationForm.vue'
import { MigrationAccountType } from '~/src/Model/Karpaty/Onboarding'
import { defaultForceLoginEmail, ForceLoginEmail } from '~/src/Model/CheckCompanyCustomer/CheckCompanyCustomer'
import { useFormSentEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Form/FormSentEvent'
import { useCheckCustomerRepository } from '~/src/Infrastructure/CheckCustomer/CheckCustomerRepository'
import { isEmailAddress } from '~/src/Infrastructure/String/IsEmailAddress/IsEmailAddress'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import LoginPanel from '~/components/Auth/LoginPanel.vue'
import { useDatartOnboardingMigrationHelpers } from '~/src/Infrastructure/Karpaty/DatartOnboardingMigration'

export default defineComponent({
  components: {
    LoginPanel,
    RegistrationForm,
    OnboardingLoginAlert,
    BaseValidationObserver,
    FlashMessageCenter,
    FormInput,
    Button,
    Form
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    checkedCustomerData: {
      type: Object as PropType<CheckCustomerResponse | null>,
      default: null
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    showRegistrationLink: {
      type: Boolean,
      default: false
    },
    forceLoginState: {
      type: Object as PropType<ForceLoginEmail>,
      default: () => defaultForceLoginEmail
    },
    dataLayerFormId: {
      type: String,
      default: null
    },
    disableValidateOnChange: {
      type: Boolean,
      default: false
    },
    showRegistrationPanel: {
      type: Boolean,
      default: false
    },
    disableSocials: {
      type: Boolean,
      default: false
    },
    disableEmailChange: {
      type: Boolean,
      default: false
    },
    widthClass: {
      type: String,
      default: null
    }
  },
  emits: ['close-sidebar', 'show-lost-password-panel', 'logged', 'registered', 'show-registration-panel', 'two-factor-verification-started', 'two-factor-verification-cancelled'],
  setup (props, { emit }) {
    const { unificate } = useVueInstanceUid()
    const { $config } = useConfig()
    const authService = useAuthService()
    const formSentEvent = useFormSentEvent()
    const { i18n } = useTranslator()
    const error = ref<string | null>(null)
    const loginData = reactive<CustomerLoginData>({
      username: props.email || $config.defaultEmail || '',
      password: ''
    })
    const observer = ref()
    const checkedEmail = ref<string>('')

    watch(() => props.forceLoginState, (newLoginEmailState) => {
      if (!newLoginEmailState.force) {
        return
      }
      loginData.username = newLoginEmailState.email
    }, { deep: true })

    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const { exec: submitLogin, pending: pendingLogin, onSuccess: onLoggedIn } = useApiCall(
      () => authService.login(loginData),
      flashMessageCenter
    )

    onLoggedIn.subscribe((response: AuthTokens | TwoFARequired) => {
      if (props.dataLayerFormId) {
        formSentEvent.fire(props.dataLayerFormId)
      }

      if ('twoFA' in response) {
        return
      }

      loginData.username = $config.defaultEmail || ''
      loginData.password = $config.defaultPassword || ''

      observer.value.reset()
    })

    const checkedCustomer = ref<CheckCustomerResponse | null>(props.checkedCustomerData)

    const { checkCustomer } = useCheckCustomerRepository()

    const { migrationAccountType } = useDatartOnboardingMigrationHelpers()
    const migrationType = computed<MigrationAccountType | null>(() => checkedCustomer.value ? migrationAccountType(checkedCustomer.value) : null)

    const showDatartPassword = computed<boolean>(() => !!migrationType.value && ([MigrationAccountType.migratedAccount, MigrationAccountType.migratedAccountWithOrders] as MigrationAccountType[]).includes(migrationType.value))
    const showOnboardingLabels = computed<boolean>(() => props.isInModal || (!!checkedCustomer.value?.isCustomer && migrationType.value !== MigrationAccountType.nonMigratedAccount))

    const setUserByTokens = async (authTokens: AuthTokens) => {
      await authService.setUserToken(authTokens.accessToken, authTokens.refreshToken)
    }

    const registered = () => {
      emit('registered')
    }
    const checkCustomerEmail = async () => {
      if (checkedEmail.value === loginData.username) {
        return
      }

      checkedEmail.value = loginData.username
      checkedCustomer.value = await checkCustomer(loginData.username)
    }

    watch(() => loginData.username, () => {
      if (props.disableValidateOnChange) {
        return
      }

      if (!isEmailAddress(loginData.username)) {
        return
      }

      checkCustomerEmail()
    })

    const emailChanged = (_event: any, isValid: boolean) => {
      if (!isValid || props.disableValidateOnChange) {
        return
      }

      checkCustomerEmail()
    }

    const passwordLabel = computed<string>(() => {
      if (!checkedCustomer.value || !showOnboardingLabels.value) {
        return i18n.t('auth.password') as string
      }

      return showDatartPassword.value
        ? i18n.t('karpaty.onboarding_login.password_datart') as string
        : i18n.t('karpaty.onboarding_login.password_nay') as string
    })

    return {
      flashMessages,
      ...toRefs(loginData),
      error,
      submitLogin,
      setUserByTokens,
      unificate,
      observer,
      pendingLogin,
      migrationType,
      showDatartPassword,
      registered,
      emailChanged,
      passwordLabel,
      checkedCustomer,
      showOnboardingLabels
    }
  }
})
