import { LoyaltyMembersSignUpRequest } from '~/src/Model/LoyaltyMembers/LoyaltyMembersSignUpRequest'
import {
  LoyaltyClubMemberInfo,
  LoyaltyMemberTransactionResponse
} from '~/src/Model/LoyaltyMembers/LoyaltyClubMemberInfo'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useLoyaltyMembersRepository = () => {
  const { $axios } = useAxios()

  return {
    signUp: (requestData: LoyaltyMembersSignUpRequest): Promise<LoyaltyClubMemberInfo> => {
      return $axios.$post<LoyaltyClubMemberInfo>('/loyalty-members/sign-up', requestData)
    },
    loadMemberInfo: (id: number): Promise<LoyaltyClubMemberInfo> => {
      return $axios.$get<LoyaltyClubMemberInfo>(`/loyalty-members/${id}`)
    },
    sendWelcomeEmail: (id: number): Promise<void> => {
      return $axios.$get<void>(`/loyalty-members/${id}/send-welcome-email`)
    },
    getTransactions (id: number): Promise<LoyaltyMemberTransactionResponse> {
      return $axios.$get<LoyaltyMemberTransactionResponse>(`/loyalty-members/${id}/transactions`)
    }
  }
}
