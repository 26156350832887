import { ref, useFetch } from '@nuxtjs/composition-api'
import { ProductDetailGallery, GalleryItemValue } from '~/src/Model/ProductDetail/ProductDetailGallery'
import { useProductDetailsRepository } from '~/src/Infrastructure/ProductDetail/ProductDetailsRepository'

export const useProductDetailGalleryFetch = (id: number) => {
  const items = ref<GalleryItemValue[]>([])
  const { loadGalleryItems } = useProductDetailsRepository()

  useFetch(async () => {
    const gallery: ProductDetailGallery = await loadGalleryItems(id)
    items.value = gallery.items.sort((a, b) => b.type.localeCompare(a.type))
  })

  return {
    items
  }
}
