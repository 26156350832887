







import { defineComponent, ref } from '@nuxtjs/composition-api'
import ProductDetailLayoutModal from '~/components/ProductDetail/ProductDetailLayout/ProductDetailLayoutModal.vue'

export default defineComponent({
  components: { ProductDetailLayoutModal },
  props: {
    alias: {
      required: true,
      type: String
    },
    productId: {
      required: true,
      type: Number
    },
    isGift: {
      default: false,
      type: Boolean
    }
  },
  setup () {
    const showModal = ref<boolean>(false)

    return {
      showModal
    }
  }
})
