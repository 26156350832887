import { render, staticRenderFns } from "./AccessoryImagePage.vue?vue&type=template&id=70fd3f77&"
import script from "./AccessoryImagePage.vue?vue&type=script&lang=ts&"
export * from "./AccessoryImagePage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports