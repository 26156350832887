import { isSameDay, isBefore, isAfter } from 'date-fns'
import { computed } from '@nuxtjs/composition-api'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import {
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'

export const useChristmasDeliveryCalendarConfig = (startDate: Date, endDate: Date, defaultToday: Date = new Date()) => {
  const dateFormatter = useDateFormatter()

  const days = computed<Date[]>(() => {
    const dates: Date[] = []
    const date = new Date(startDate.getTime())
    // eslint-disable-next-line no-unmodified-loop-condition
    while (date <= endDate) {
      dates.push(new Date(date.getTime()))
      date.setDate(date.getDate() + 1)
    }
    return dates
  })

  const today = computed<Date>(() => new Date(defaultToday.getTime()))

  const todayIndex = computed<number>(() => {
    const todayDay = days.value.find(day => isSameDay(today.value, day))
    return (todayDay ? days.value.indexOf(todayDay) ?? 0 : 0) + 1
  })

  const todayIsBeforeStartDate = computed<boolean>(() => isBefore(today.value, startDate))
  const todayIsAfterEndDate = computed<boolean>(() => isAfter(today.value, endDate))

  const getDeadlineDayIndex = (configItem: ChristmasDeliveryConfigurationItem): number => {
    const deadlineDate = dateFormatter.getValue(configItem.deadline)
    const day = days.value.find(day => isSameDay(day, deadlineDate))

    return (day ? days.value.indexOf(day) ?? 0 : 0) + 1
  }

  return {
    days,
    today,
    getDeadlineDayIndex,
    todayIndex,
    todayIsBeforeStartDate,
    todayIsAfterEndDate
  }
}
