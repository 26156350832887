import { CacheStorageOptions, ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'

export class JsonCacheStorage<T> implements ServerCacheStorage<T> {
  constructor (
    private readonly cacheStorage: ServerCacheStorage
  ) {
  }

  async get (key: string): Promise<T | null> {
    const cacheValue = await this.cacheStorage.get(key)

    if (cacheValue) {
      return JSON.parse(cacheValue)
    }

    return null
  }

  async set (key: string, value: T, options: CacheStorageOptions = {}): Promise<void> {
    return await this.cacheStorage.set(key, JSON.stringify(value), options)
  }

  async load (key: string, generator: () => Promise<T>, options: CacheStorageOptions): Promise<any> {
    const cacheValue = await this.get(key)

    if (cacheValue) {
      return cacheValue
    }

    const generatedValue = await generator()

    await this.set(key, generatedValue, options)

    return generatedValue
  }

  close (): Promise<any> {
    return Promise.resolve()
  }
}
