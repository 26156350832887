import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { htmlBlockToInline as htmlBlockToInlineFunction } from '~/src/Infrastructure/String/HtmlBlockToInline'

type htmlBlockToInlineFilterType = (value: string) => string

// eslint-disable-next-line no-empty-pattern
const htmlBlockToInlineFilter: Plugin = ({ }, inject: Inject) => {
  const htmlBlockToInlineFilter: htmlBlockToInlineFilterType = (value: string) => htmlBlockToInlineFunction(value)

  inject('htmlBlockToInline', htmlBlockToInlineFilter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $htmlBlockToInline: htmlBlockToInlineFilterType
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $htmlBlockToInline: htmlBlockToInlineFilterType
  }

  interface Context {
    $htmlBlockToInline: htmlBlockToInlineFilterType
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $htmlBlockToInline: htmlBlockToInlineFilterType
  }
}

export default htmlBlockToInlineFilter
