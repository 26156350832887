import { ProductServices } from '~/src/Model/ProductServices/ProductServices'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductServicesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadProductServices: (id: number): Promise<ProductServices> => $axios.$get<ProductServices>(`/products/${id}/services`)
  }
}
