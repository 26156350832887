import Axios from 'axios'

const axiosInstance = Axios.create({
  baseURL: 'https://live.luigisbox.com'
})

axiosInstance.interceptors.request.use((config) => {
  config.headers = config.headers || {}
  config.headers['X-LuigisBox-Api-Version'] = '2021-06-16'

  return config
}, error => Promise.reject(error))

export default axiosInstance
