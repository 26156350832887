import { FilterQueryStocks } from '~/src/Model/ProductCategory/ParameterFilter/Request'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import { FilterSelectedStocks } from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { FilterElementConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/FilterElementConvertor'

export type StockConvertorType = FilterElementConvertor<FilterSelectedStocks, FilterQueryStocks, never>

export class StockConvertor implements StockConvertorType {
  constructor (
    private readonly identifier: string = 'stocks'
  ) {
  }

  shouldConvert (stocks: FilterSelectedStocks): boolean {
    return stocks.length > 0
  }

  toUrl (filterStockValues: FilterSelectedStocks): UrlElement {
    return new UrlElement(
      this.identifier,
      filterStockValues.map((stock) => {
        return stock.value.toString()
      })
    )
  }

  toUrlFromTranslated (): UrlElement {
    throw new Error('This type of parameter is not supported in old parameter fitler')
  }

  fromUrl (element: UrlElement): FilterQueryStocks {
    if (element.values.length === 0) {
      throw new Error('missing parameter values')
    }

    return element.values
  }

  support (element: UrlElement): boolean {
    return this.identifier === element.identifier
  }
}
