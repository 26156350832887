














import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { MapRegion } from '~/src/Model/StoresMap/StoresMap'
import RegionStoreThumbnail from '~/components/StoresMap/RegionStoreThumbnail.vue'

interface PropTypes {
  region: MapRegion
  fullName: string
}

export default defineComponent<PropTypes>({
  components: { RegionStoreThumbnail },
  props: {
    region: {
      required: true,
      type: Object as PropType<MapRegion>
    },
    fullName: {
      required: true,
      type: String
    }
  }
})
