














import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'

const CHRISTMAS_PAGE = 'CHRISTMAS_INFO_PAGE'

export default defineComponent({
  setup () {
    const { $webConfig } = useContext()
    const systemPagesStore = useSystemPagesStore()
    const pathPrefix = computed<string>(() => $webConfig.web === Web.Nay ? 'nay' : 'ew')
    const alias = computed<string>(() => systemPagesStore.pathByUid(CHRISTMAS_PAGE).value)

    return {
      pathPrefix,
      alias
    }
  }
})
