import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'
import { JsonCacheStorage } from '~/src/Infrastructure/Cache/Server/JsonCacheStorage'

type Translations = { [key: string]: string | Translations }

export const useTranslationsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadTranslations: (locale: string): Promise<Translations> => {
      return $axios.$get<Translations>(`/translations/${locale}`)
    }
  }
}

type TranslationsRepositoryType = ReturnType<typeof useTranslationsRepositoryWithAxios>

export const useCachedTranslationsRepository = (
  $axios: NuxtAxiosInstance,
  cacheStorage: ServerCacheStorage
): TranslationsRepositoryType => {
  const jsonCacheStorage = new JsonCacheStorage<Translations>(cacheStorage)
  const TranslationsRepository = useTranslationsRepositoryWithAxios($axios)

  return {
    loadTranslations: async (locale: string) => {
      return await jsonCacheStorage.load(
        'translations',
        () => TranslationsRepository.loadTranslations(locale),
        { expireSeconds: 120 }
      )
    }
  }
}
