import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class SlugRule extends ValidationRule {
  message = (field: string) => this.i18n.t('validations.slug', { field }).toString()

  validate = (value: string | null): ValidationRuleResult => {
    if (!value) {
      return {
        valid: false
      }
    }

    const valid = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g.test(value)

    return {
      valid
    }
  };
}
