import { useRouter } from '@nuxtjs/composition-api'
import isEqual from 'lodash/isEqual'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { RedirectConfig, RedirectConfigType } from '~/src/Model/RedirectConfig/RedirectConfig'

export type QueryParameters = { [key: string]: string }

export interface NavigationOptions {
  query?: QueryParameters
}

export const useNavigateToSystemPage = () => {
  const systemPagesStore = useSystemPagesStore()
  const router = useRouter()

  const navigateToUid = async (uid: string, options?: NavigationOptions): Promise<void> => {
    const aliasByUid = systemPagesStore.aliasByUid(uid)
    const newPath = `/${aliasByUid.value}`
    const currentRoute = router.currentRoute

    if (currentRoute.path === newPath && isEqual(currentRoute.query, options?.query ?? {})) {
      return Promise.resolve()
    }

    await router?.push({
      path: newPath,
      ...options
    })
  }

  const navigateByRedirectConfig = (redirectConfig: RedirectConfig) => {
    const queryParams: QueryParameters = {}
    redirectConfig.query.forEach((query) => {
      queryParams[query.name] = query.value
    })
    if (redirectConfig.type === RedirectConfigType.systemLink) {
      let uid = redirectConfig.uid
      if (['delivery', 'address'].includes(uid)) {
        uid = 'CHECKOUT_ORDER'
      }
      const aliasByUid = systemPagesStore.aliasByUid(uid)
      router?.push({
        path: `/${aliasByUid.value}`,
        query: queryParams
      })
    }
  }

  return {
    navigateToUid,
    navigateByRedirectConfig
  }
}
