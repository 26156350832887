import { ref } from '@nuxtjs/composition-api'
import { AxiosError } from 'axios'
import { FacebookAuthResponse } from '~/src/Model/Auth/Facebook/Facebook'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'

export const useFacebookLoginPostProcess = () => {
  const authService = useAuthService()
  const { facebookLogin } = useCustomerAuthRepository()

  const mergeAccount = ref<boolean>(false)
  const idToken = ref<string>('')
  const pending = ref<boolean>(false)

  const onLoggedIn = async (facebookCredentialResponse: FacebookAuthResponse) => {
    idToken.value = facebookCredentialResponse.accessToken
    pending.value = true
    let loginResponse

    try {
      loginResponse = await facebookLogin(idToken.value)
    } catch (error) {
      if ((error as AxiosError).response?.status === 412) {
        mergeAccount.value = true
      }

      // eslint-disable-next-line no-console
      console.error(error)
      return
    } finally {
      pending.value = false
    }

    await authService.setUserToken(loginResponse.accessToken, loginResponse.refreshToken)
  }

  const mergeAccountCompleted = () => {
    mergeAccount.value = false
  }

  return {
    onLoggedIn,
    mergeAccountCompleted,
    mergeAccount,
    idToken,
    pending
  }
}
