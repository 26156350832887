import { nextTick, onMounted, ref } from '@nuxtjs/composition-api'
import LazyLoad, { ILazyLoadInstance } from 'vanilla-lazyload'

export const useLazyLoadOnMounted = () => {
  const lazyLoad = ref<ILazyLoadInstance | null>(null)

  onMounted(() => {
    nextTick(() => {
      lazyLoad.value = new LazyLoad({ // eslint-disable-line @typescript-eslint/no-unused-vars
        elements_selector: '.js-lazy'
      })
    })
  })

  return {
    lazyLoad
  }
}
