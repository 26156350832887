import { defineComponent } from '@nuxtjs/composition-api'
import { CreateElement, VNode } from 'vue'
import { Dictionary } from 'vue-router/types/router'

export default (statusCode: number, path: string, queryParams: Dictionary<string>) => {
  return defineComponent({
    asyncData (ctx) {
      return ctx.redirect(statusCode, { path, replace: false, query: queryParams })
    },
    render (createElement: CreateElement): VNode {
      return createElement('div')
    }
  })
}
