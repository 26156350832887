import { useRoute, watch } from '@nuxtjs/composition-api'
import { useDataLayerStore } from '~/src/Infrastructure/DataLayer/DataLayerStore'

export const useVirtualPageViewTriggerWatcher = (layoutName: string) => {
  const route = useRoute()
  const dataLayerStore = useDataLayerStore()

  watch(route, () => dataLayerStore.enableVirtualPageViewEvent())

  if (process.client) {
    if (dataLayerStore.previousLayoutName.value && dataLayerStore.previousLayoutName.value !== layoutName) {
      dataLayerStore.enableVirtualPageViewEvent()
    }
    dataLayerStore.updatePreviousLayoutName(layoutName)
  }
}
