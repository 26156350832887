import { NuxtCookies } from 'cookie-universal-nuxt'
import { Pinia } from 'pinia'
import { computed, ComputedRef, WritableComputedRef } from '@nuxtjs/composition-api'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { useAuthStore } from '~/stores/auth'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'

const COOKIE_STRATEGY = 'local'
const AUTH_KEY = 'auth'

// 30 days in ms
const COOKIE_EXPIRATION = 30 * 24 * 60 * 60 * 1000

// 15 minutes in ms
const AUTH_TOKEN_EXPIRATION = 15 * 60 * 1000

enum AuthToken {
  REFRESH_TOKEN = '_refresh_token',
  ACCESS_TOKEN = '_token'
}

interface TokenPayload {
  token: string | null
  isValid: boolean
  expiration: number
  isExpired: boolean
}

interface SyncResult {
  shouldReload: boolean
}

export class AuthStorage {
  private _authStore: ReturnType<typeof useAuthStore>

  refreshToken: string | null = null
  accessToken: string | null = null
  isAccessTokenExpired: boolean = false
  isRefreshTokenExpired: boolean = false

  constructor (
    private readonly _cookies: NuxtCookies,
    private readonly _pinia?: Pinia | null
  ) {
    this._authStore = useAuthStore(this._pinia)
    this.syncStorage()
  }

  private _getCookieName = (tokenType: AuthToken): string =>
    `${AUTH_KEY}.${tokenType}.${COOKIE_STRATEGY}`

  private _getTokenPayload = (tokenType: AuthToken, value?: string) => {
    const tokenPayload: TokenPayload = {
      token: null,
      expiration: 0,
      isExpired: false,
      isValid: false
    }

    const token = value || this._cookies.get<string>(this._getCookieName(tokenType))
    if (!token) {
      return tokenPayload
    }

    const decodedToken = this._decodeToken(tokenType, token)
    if (!decodedToken) {
      return tokenPayload
    }

    if (decodedToken.exp) {
      tokenPayload.expiration = decodedToken.exp * 1000
    } else {
      tokenPayload.expiration = Date.now() + (tokenType === AuthToken.REFRESH_TOKEN ? COOKIE_EXPIRATION : AUTH_TOKEN_EXPIRATION)
    }

    tokenPayload.token = token
    tokenPayload.isExpired = tokenPayload.expiration < Date.now()
    tokenPayload.isValid = true

    return tokenPayload
  }

  private _decodeToken = (tokenType: AuthToken, token: any) => {
    try {
      if (!token || typeof token !== 'string') {
        return undefined
      }

      if (tokenType === AuthToken.ACCESS_TOKEN) {
        token = token.replace('Bearer ', '')
      }

      return jwtDecode<JwtPayload>(token)
    } catch {
      return undefined
    }
  }

  private _setTokenCookie = (tokenType: AuthToken, token: string) => {
    this._cookies.set(this._getCookieName(tokenType),
      token,
      { expires: new Date(Date.now() + COOKIE_EXPIRATION), secure: true })

    return token
  }

  setUser = (authTokens: AuthTokens): void => {
    this.accessToken = this._setTokenCookie(AuthToken.ACCESS_TOKEN, 'Bearer ' + authTokens.accessToken)
    const accessTokenPayload = this._getTokenPayload(AuthToken.ACCESS_TOKEN, this.accessToken)
    this.isAccessTokenExpired = accessTokenPayload.isExpired

    if (this.refreshToken !== authTokens.refreshToken) {
      this.refreshToken = this._setTokenCookie(AuthToken.REFRESH_TOKEN, authTokens.refreshToken)
      const refreshTokenPayload = this._getTokenPayload(AuthToken.REFRESH_TOKEN, this.refreshToken)
      this.isRefreshTokenExpired = refreshTokenPayload.isExpired
    }
  }

  clear = (): void => {
    this._cookies.remove(this._getCookieName(AuthToken.REFRESH_TOKEN))
    this._cookies.remove(this._getCookieName(AuthToken.ACCESS_TOKEN))
    this.refreshToken = null
    this.accessToken = null
    this.isAccessTokenExpired = false
    this.isRefreshTokenExpired = false
    this._authStore.clearResources()
  }

  loadUser = async (): Promise<AuthenticatedUser> => {
    return await this._authStore.loadUser()
  }

  syncStorage = (): SyncResult => {
    const { shouldReload: shouldReloadFromAccessToken } = this.syncAccessToken()
    const { shouldReload: shouldReloadFromRefreshToken } = this.syncRefreshToken()

    return {
      shouldReload:
        shouldReloadFromAccessToken || shouldReloadFromRefreshToken
    }
  }

  syncAccessToken = (): SyncResult => {
    const tokenPayload = this._getTokenPayload(AuthToken.ACCESS_TOKEN)

    if (this.accessToken && !tokenPayload.isValid) {
      this.clear()
      return {
        shouldReload: true
      }
    }

    this.accessToken = tokenPayload.token
    this.isAccessTokenExpired = tokenPayload.isExpired

    return {
      shouldReload: false
    }
  }

  syncRefreshToken = (): SyncResult => {
    const tokenPayload = this._getTokenPayload(AuthToken.REFRESH_TOKEN)

    if (this.refreshToken && !tokenPayload.isValid) {
      this.clear()
      return {
        shouldReload: true
      }
    }

    this.refreshToken = tokenPayload.token
    this.isRefreshTokenExpired = tokenPayload.isExpired

    return {
      shouldReload: false
    }
  }

  get user (): WritableComputedRef<AuthenticatedUser | null> {
    return computed<AuthenticatedUser | null>({
      get: () => this._authStore.user,
      set: (user) => {
        this._authStore.user = user
      }
    })
  }

  get isLoggedIn (): ComputedRef<boolean> {
    return computed(() => !!this.user.value)
  }
}
