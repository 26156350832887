



















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import { Product } from '~/src/Model/Product/Product'
import Dropdown from '~/components/DesignSystem/Dropdown/Dropdown.vue'
import DropdownItem from '~/components/DesignSystem/Dropdown/DropdownItem.vue'

export default defineComponent({
  components: { DropdownItem, Dropdown, ImageWithFallback },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true
    }
  },
  setup () {}
})
