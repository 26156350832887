










import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { AccordeonItem } from '~/src/Model/ContentEditor/ContentEditor'
import CollapsableElement from '~/components/DesignSystem/Collapse/CollapsableElement.vue'

export default defineComponent({
  components: { CollapsableElement },
  props: {
    contentEditorAccordeon: {
      required: true,
      type: Object as PropType<AccordeonItem>
    }
  }
})
