export const useUrl = () => {
  const ensureProtocol = (url: string) => {
    try {
      return new URL(url)
    } catch {
      return new URL(`https://${url}`)
    }
  }

  return {
    ensureProtocol
  }
}
