import { defineStore } from 'pinia'

const DATA_LAYER_STORE_ID = 'dataLayer'

export interface DataLayerState {
  shouldFireVirtualPageViewEvent: boolean
  previousLayoutName: string | null
}

export const useDataLayerStore = defineStore(DATA_LAYER_STORE_ID, {
  state: (): DataLayerState => ({
    shouldFireVirtualPageViewEvent: false,
    previousLayoutName: null
  }),
  actions: {
    enableVirtualPageViewEvent () {
      this.shouldFireVirtualPageViewEvent = true
    },
    disableVirtualPageViewEvent () {
      this.shouldFireVirtualPageViewEvent = true
    },
    updatePreviousLayoutName (previousLayoutName: string) {
      this.previousLayoutName = previousLayoutName
    }
  }
})
