import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'
import { ResponseError } from '~/src/Model/Error/ApiResponseError'

export class EventInternalErrorCode extends IgnoredEvent {
  constructor (private internalErrorCode: number) {
    super()
  }

  isIgnored (event: Event) {
    const apiError: ResponseError | undefined = event.contexts?.api_error as ResponseError | undefined
    if (!apiError) {
      return false
    }
    return apiError.internalErrorCode === this.internalErrorCode
  }
}
