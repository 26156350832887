import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class RequiredOneRule extends ValidationRule {
  message = () => this.i18n.t('validations.required_one').toString()

  validate = (value: any[] | null): ValidationRuleResult => {
    if (!value) {
      return {
        valid: false
      }
    }

    const valid = value.length >= 1

    return {
      valid
    }
  };
}
