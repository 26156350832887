















import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import {
  FinancialService,
  SelectedFinancialService, SelectedPreBasketFinancialService,
  ServiceType
} from '~/src/Model/ProductServices/ProductServices'
import FinancialServiceItem from '~/components/Basket/PreBasket/ProductServices/FinancialServices/FinancialServiceItem.vue'
import { ServiceVariant } from '~/src/Model/Services/Service'

export default defineComponent({
  name: 'FinancialServices',
  components: { FinancialServiceItem },
  props: {
    financialServices: {
      required: true,
      type: Array as PropType<FinancialService[]>
    },
    selectedFinancialServices: {
      required: true,
      type: Array as PropType<SelectedFinancialService[]>
    }
  },
  emits: ['selected-services', 'open-detail', 'input'],
  setup (props, { emit }) {
    const errorType = ref<ServiceType|null>(null)

    const selectedServiceVariant = (service: FinancialService) => {
      return props.selectedFinancialServices.find(s => service.id === s.service.id)?.variant ?? null
    }

    const modifyServices = (service: FinancialService, variant: ServiceVariant | null): void => {
      const newMap = new Map<ServiceType, SelectedPreBasketFinancialService>()

      props.selectedFinancialServices.forEach((item: SelectedPreBasketFinancialService) => {
        newMap.set(item.service.type, item)
      })

      const existingService = newMap.get(service.type)

      if (existingService) {
        if (variant === null) {
          newMap.delete(service.type)
        } else {
          if (existingService.service.id !== service.id) {
            errorType.value = service.type
          }
          newMap.set(service.type, { service, variant })
        }
      } else {
        newMap.set(service.type, { service, variant })
      }
      emit('input', [...newMap.values()])
    }

    return {
      modifyServices,
      selectedServiceVariant,
      errorType
    }
  }
})
