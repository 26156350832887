











































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import LoadingAnim from '~/components/Loading/LoadingAnim.vue'
import { SelectedWishlist, WishList, WishListDetail } from '~/src/Model/WishLists/WishLists'
import WishListItems from '~/components/WishLists/WishListItems.vue'
import ShareDropdown from '~/components/Share/ShareDropdown.vue'
import BaseMultiselect from '~/components/DesignSystem/Select/BaseMultiselect.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    BaseMultiselect,
    LoadingAnim,
    ShareDropdown,
    WishListItems,
    Alert,
    PageSectionContent
  },
  props: {
    wishLists: {
      type: Array as PropType<WishList[]>,
      required: true
    },
    wishListsOptions: {
      type: Array as PropType<SelectedWishlist[]>,
      required: true
    },
    selectedWishList: {
      type: Object as PropType<SelectedWishlist>,
      required: true
    },
    wishListDetail: {
      type: Object as PropType<WishListDetail | null>,
      default: null
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-item', 'select-wish-list'],
  setup (props) {
    const systemPagesStore = useSystemPagesStore()
    const linkToWishList = computed<string>(() => {
      const path = systemPagesStore.pathByUid('WISHLIST', {
        query: {
          hash: props.selectedWishList.value
        }
      }).value

      return window.location.origin + path
    })

    return {
      linkToWishList
    }
  }
})
