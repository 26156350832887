import { Banners } from '~/src/Model/Banner/Banner'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useBannersRepository = () => {
  const { $axios } = useAxios()

  return {
    loadBanners: (pageId: number, position: string): Promise<Banners> => $axios.$get<Banners>('/banners', {
      params: {
        pageId,
        position
      }
    })
  }
}
