









































import { defineComponent } from '@nuxtjs/composition-api'
import SystemLink from '~/components/SystemPage/SystemLink.vue'
import LocaleSwitch from '~/components/Header/InfoBox/LocaleSwitch.vue'

export default defineComponent({
  components: { LocaleSwitch, SystemLink },
  props: {
    isSidebar: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sidebar-toggled']
})
