import { DeliveryType } from '~/src/Model/Delivery/DeliveryMethods'

export interface PickupPointIcon {
  marker: string
  cluster: string
}

export const PickupPointIconMap: { [TKey in DeliveryType]: PickupPointIcon | null } = {
  [DeliveryType.store]: {
    marker: '/map-pin.svg',
    cluster: '/map-cluster.png'
  },
  [DeliveryType.balikomatBoxA]: null,
  [DeliveryType.balikomatBoxB]: null,
  [DeliveryType.balikomatBoxC]: null,
  [DeliveryType.deliveryToAddress]: null,
  [DeliveryType.deliveryHds]: null,
  [DeliveryType.deliveryToAddressOne]: null,
  [DeliveryType.deliveryToAddressWithRecycling]: null,
  [DeliveryType.geisCargo]: null,
  [DeliveryType.czechPost]: null,
  [DeliveryType.deliveryStandard1]: null,
  [DeliveryType.deliveryStandard2]: null,
  [DeliveryType.virtualDelivery]: null,
  [DeliveryType.deliveryUrbantzLight]: null,
  [DeliveryType.deliveryUrbantzHeavy]: null,
  [DeliveryType.geisPoint]: {
    marker: '/img/gmap/geis-pin.svg',
    cluster: '/img/gmap/geis-cluster'
  },
  [DeliveryType.geisParcel]: null,
  [DeliveryType.deliveryHdsLight]: null,
  [DeliveryType.postaBalikovna]: {
    marker: '/img/gmap/balikovna-pin.svg',
    cluster: '/img/gmap/balikovna-cluster'
  },
  [DeliveryType.postaBalikNaPostu]: {
    marker: '/img/gmap/napostu-pin.svg',
    cluster: '/img/gmap/napostu-cluster'
  },
  [DeliveryType.comfortDeliveryLight]: null,
  [DeliveryType.comfortDelivery]: null,
  [DeliveryType.onlineElectronicContent]: null,
  [DeliveryType.balikobox]: {
    marker: '/img/gmap/balikobox-pin.svg',
    cluster: '/img/gmap/balikobox-cluster'
  },
  [DeliveryType.courier2Hds]: null,
  [DeliveryType.zasilkovnaSk]: {
    marker: '/img/gmap/zasilkovna-pin.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zasilkovnaCz]: {
    marker: '/img/gmap/zasilkovna-pin.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zBoxSk]: {
    marker: '/img/gmap/zasilkovna-pin.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zBoxCz]: {
    marker: '/img/gmap/zasilkovna-pin.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.pplPickupPoints]: {
    marker: '/img/gmap/ppl-pin.svg',
    cluster: '/img/gmap/ppl-cluster'
  },
  [DeliveryType.dpdPickupPoints]: {
    marker: '/img/gmap/dpd-pin.svg',
    cluster: '/img/gmap/dpd-cluster'
  },
  [DeliveryType.postOfficeSk]: {
    marker: '/img/gmap/balikobox-pin.svg',
    cluster: '/img/gmap/balikobox-cluster'
  },
  [DeliveryType.iDoDoBigBox]: null,
  [DeliveryType.iDoDoBigBoxSameDay]: null,
  [DeliveryType.iDoDoBigBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBox]: null,
  [DeliveryType.iDoDoSmallBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBoxUrbantz]: null,
  [DeliveryType.iDoDoBigBoxUrbantz]: null,
  [DeliveryType.nayExpressHds]: null,
  [DeliveryType.nayExpressHdw]: null,
  [DeliveryType.virtualExpressDeliveryParent]: null,
  [DeliveryType.virtualEconomicDeliveryParent]: null
}

export const PickupPointIconMapNew: { [TKey in DeliveryType]: PickupPointIcon | null } = {
  [DeliveryType.store]: {
    marker: '/pickup-point/logo-new.png',
    cluster: '/map-cluster.png'
  },
  [DeliveryType.balikomatBoxA]: null,
  [DeliveryType.balikomatBoxB]: null,
  [DeliveryType.balikomatBoxC]: null,
  [DeliveryType.deliveryToAddress]: null,
  [DeliveryType.deliveryHds]: null,
  [DeliveryType.deliveryToAddressOne]: null,
  [DeliveryType.deliveryToAddressWithRecycling]: null,
  [DeliveryType.geisCargo]: null,
  [DeliveryType.czechPost]: null,
  [DeliveryType.deliveryStandard1]: null,
  [DeliveryType.deliveryStandard2]: null,
  [DeliveryType.virtualDelivery]: null,
  [DeliveryType.deliveryUrbantzLight]: null,
  [DeliveryType.deliveryUrbantzHeavy]: null,
  [DeliveryType.geisPoint]: {
    marker: '/img/gmap/geis-pin.svg',
    cluster: '/img/gmap/geis-cluster'
  },
  [DeliveryType.geisParcel]: null,
  [DeliveryType.deliveryHdsLight]: null,
  [DeliveryType.postaBalikovna]: {
    marker: '/img/gmap/balikovna-pin-new.svg',
    cluster: '/img/gmap/balikovna-cluster'
  },
  [DeliveryType.postaBalikNaPostu]: {
    marker: '/img/gmap/napostu-pin-new.svg',
    cluster: '/img/gmap/napostu-cluster'
  },
  [DeliveryType.comfortDeliveryLight]: null,
  [DeliveryType.comfortDelivery]: null,
  [DeliveryType.onlineElectronicContent]: null,
  [DeliveryType.balikobox]: {
    marker: '/img/gmap/balikobox-pin-new.svg',
    cluster: '/img/gmap/balikobox-cluster'
  },
  [DeliveryType.courier2Hds]: null,
  [DeliveryType.zasilkovnaSk]: {
    marker: '/img/gmap/zasilkovna-pin-new.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zasilkovnaCz]: {
    marker: '/img/gmap/zasilkovna-pin-new.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zBoxSk]: {
    marker: '/img/gmap/zasilkovna-pin-new.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.zBoxCz]: {
    marker: '/img/gmap/zasilkovna-pin-new.svg',
    cluster: '/img/gmap/zasilkovna-cluster'
  },
  [DeliveryType.pplPickupPoints]: {
    marker: '/img/gmap/ppl-pin-new.svg',
    cluster: '/img/gmap/ppl-cluster'
  },
  [DeliveryType.dpdPickupPoints]: {
    marker: '/img/gmap/dpd-pin-new.svg',
    cluster: '/img/gmap/dpd-cluster'
  },
  [DeliveryType.postOfficeSk]: {
    marker: '/img/gmap/balikobox-pin-new.svg',
    cluster: '/img/gmap/balikobox-cluster'
  },
  [DeliveryType.iDoDoBigBox]: null,
  [DeliveryType.iDoDoBigBoxNextDay]: null,
  [DeliveryType.iDoDoBigBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBox]: null,
  [DeliveryType.iDoDoSmallBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBoxUrbantz]: null,
  [DeliveryType.iDoDoBigBoxUrbantz]: null,
  [DeliveryType.nayExpressHds]: null,
  [DeliveryType.nayExpressHdw]: null,
  [DeliveryType.virtualExpressDeliveryParent]: null,
  [DeliveryType.virtualEconomicDeliveryParent]: null
}

export enum PickupPointIconName {
  default = 'logo',
  balikobox = 'balikobox',
  balikovna = 'balikovna',
  czechPost = 'czechPost',
  zasilkovna = 'zasilkovna',
  dpd = 'dpd',
  ppl = 'ppl',
  expressDelivery = 'express-delivery',
  economyDelivery = 'economy-delivery'
}

export const PickupPointTypeMap: { [key in DeliveryType]: string | null } = {
  [DeliveryType.store]: null,
  [DeliveryType.balikomatBoxA]: null,
  [DeliveryType.balikomatBoxB]: null,
  [DeliveryType.balikomatBoxC]: null,
  [DeliveryType.deliveryToAddress]: null,
  [DeliveryType.deliveryHds]: null,
  [DeliveryType.deliveryToAddressOne]: null,
  [DeliveryType.deliveryToAddressWithRecycling]: null,
  [DeliveryType.geisCargo]: null,
  [DeliveryType.czechPost]: null,
  [DeliveryType.deliveryStandard1]: null,
  [DeliveryType.deliveryStandard2]: null,
  [DeliveryType.virtualDelivery]: null,
  [DeliveryType.geisPoint]: null,
  [DeliveryType.geisParcel]: null,
  [DeliveryType.deliveryHdsLight]: null,
  [DeliveryType.postaBalikovna]: PickupPointIconName.balikovna,
  [DeliveryType.postaBalikNaPostu]: PickupPointIconName.czechPost,
  [DeliveryType.comfortDeliveryLight]: null,
  [DeliveryType.comfortDelivery]: null,
  [DeliveryType.onlineElectronicContent]: null,
  [DeliveryType.balikobox]: PickupPointIconName.balikobox,
  [DeliveryType.courier2Hds]: null,
  [DeliveryType.zasilkovnaSk]: PickupPointIconName.zasilkovna,
  [DeliveryType.zasilkovnaCz]: PickupPointIconName.zasilkovna,
  [DeliveryType.zBoxSk]: PickupPointIconName.zasilkovna,
  [DeliveryType.zBoxCz]: PickupPointIconName.zasilkovna,
  [DeliveryType.pplPickupPoints]: PickupPointIconName.ppl,
  [DeliveryType.dpdPickupPoints]: PickupPointIconName.dpd,
  [DeliveryType.deliveryUrbantzLight]: null,
  [DeliveryType.deliveryUrbantzHeavy]: null,
  [DeliveryType.postOfficeSk]: PickupPointIconName.balikobox,
  [DeliveryType.iDoDoBigBox]: null,
  [DeliveryType.iDoDoBigBoxSameDay]: null,
  [DeliveryType.iDoDoBigBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBox]: null,
  [DeliveryType.iDoDoSmallBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBoxUrbantz]: null,
  [DeliveryType.iDoDoBigBoxUrbantz]: null,
  [DeliveryType.nayExpressHds]: null,
  [DeliveryType.nayExpressHdw]: null,
  [DeliveryType.virtualExpressDeliveryParent]: PickupPointIconName.expressDelivery,
  [DeliveryType.virtualEconomicDeliveryParent]: PickupPointIconName.economyDelivery
}

export const PickupPointTemplateMap: { [key in DeliveryType]: string | null } = {
  [DeliveryType.store]: 'Logo',
  [DeliveryType.balikomatBoxA]: null,
  [DeliveryType.balikomatBoxB]: null,
  [DeliveryType.balikomatBoxC]: null,
  [DeliveryType.deliveryToAddress]: null,
  [DeliveryType.deliveryHds]: null,
  [DeliveryType.deliveryToAddressOne]: null,
  [DeliveryType.deliveryToAddressWithRecycling]: null,
  [DeliveryType.geisCargo]: null,
  [DeliveryType.czechPost]: null,
  [DeliveryType.deliveryStandard1]: null,
  [DeliveryType.deliveryStandard2]: null,
  [DeliveryType.virtualDelivery]: null,
  [DeliveryType.geisPoint]: null,
  [DeliveryType.geisParcel]: null,
  [DeliveryType.deliveryHdsLight]: null,
  [DeliveryType.postaBalikovna]: 'Icon',
  [DeliveryType.postaBalikNaPostu]: 'Icon',
  [DeliveryType.comfortDeliveryLight]: null,
  [DeliveryType.comfortDelivery]: null,
  [DeliveryType.onlineElectronicContent]: null,
  [DeliveryType.balikobox]: 'Icon',
  [DeliveryType.courier2Hds]: null,
  [DeliveryType.zasilkovnaSk]: 'Icon',
  [DeliveryType.zasilkovnaCz]: 'Icon',
  [DeliveryType.zBoxSk]: 'Icon',
  [DeliveryType.zBoxCz]: 'Icon',
  [DeliveryType.pplPickupPoints]: 'Icon',
  [DeliveryType.dpdPickupPoints]: 'Icon',
  [DeliveryType.deliveryUrbantzLight]: null,
  [DeliveryType.deliveryUrbantzHeavy]: null,
  [DeliveryType.postOfficeSk]: 'Icon',
  [DeliveryType.iDoDoBigBox]: null,
  [DeliveryType.iDoDoBigBoxSameDay]: null,
  [DeliveryType.iDoDoBigBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBox]: null,
  [DeliveryType.iDoDoSmallBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBoxUrbantz]: null,
  [DeliveryType.iDoDoBigBoxUrbantz]: null,
  [DeliveryType.nayExpressHds]: null,
  [DeliveryType.nayExpressHdw]: null,
  [DeliveryType.virtualExpressDeliveryParent]: 'Icon',
  [DeliveryType.virtualEconomicDeliveryParent]: 'Icon'
}

export const PickupPointRatioMap: { [key in DeliveryType]: number | null } = {
  [DeliveryType.store]: null,
  [DeliveryType.balikomatBoxA]: null,
  [DeliveryType.balikomatBoxB]: null,
  [DeliveryType.balikomatBoxC]: null,
  [DeliveryType.deliveryToAddress]: null,
  [DeliveryType.deliveryHds]: null,
  [DeliveryType.deliveryToAddressOne]: null,
  [DeliveryType.deliveryToAddressWithRecycling]: null,
  [DeliveryType.geisCargo]: null,
  [DeliveryType.czechPost]: null,
  [DeliveryType.deliveryStandard1]: null,
  [DeliveryType.deliveryStandard2]: null,
  [DeliveryType.virtualDelivery]: null,
  [DeliveryType.geisPoint]: null,
  [DeliveryType.geisParcel]: null,
  [DeliveryType.deliveryHdsLight]: null,
  [DeliveryType.postaBalikovna]: (95 / 94),
  [DeliveryType.postaBalikNaPostu]: (100 / 100),
  [DeliveryType.comfortDeliveryLight]: null,
  [DeliveryType.comfortDelivery]: null,
  [DeliveryType.onlineElectronicContent]: null,
  [DeliveryType.balikobox]: (123 / 156),
  [DeliveryType.courier2Hds]: null,
  [DeliveryType.zasilkovnaSk]: (80 / 80),
  [DeliveryType.zasilkovnaCz]: (80 / 80),
  [DeliveryType.zBoxSk]: (80 / 80),
  [DeliveryType.zBoxCz]: (80 / 80),
  [DeliveryType.pplPickupPoints]: (71 / 106),
  [DeliveryType.dpdPickupPoints]: (59 / 52),
  [DeliveryType.deliveryUrbantzLight]: null,
  [DeliveryType.deliveryUrbantzHeavy]: null,
  [DeliveryType.postOfficeSk]: (123 / 156),
  [DeliveryType.iDoDoBigBox]: null,
  [DeliveryType.iDoDoBigBoxSameDay]: null,
  [DeliveryType.iDoDoBigBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBox]: null,
  [DeliveryType.iDoDoSmallBoxNextDay]: null,
  [DeliveryType.iDoDoSmallBoxSameDay]: null,
  [DeliveryType.iDoDoSmallBoxUrbantz]: null,
  [DeliveryType.iDoDoBigBoxUrbantz]: null,
  [DeliveryType.nayExpressHds]: null,
  [DeliveryType.nayExpressHdw]: null,
  [DeliveryType.virtualExpressDeliveryParent]: (29 / 30),
  [DeliveryType.virtualEconomicDeliveryParent]: (16 / 23)
}
