export enum TagVariant {
  cashback = 'pink',
  sale = 'sale',
  action = 'pink',
  scrap = 'pink',
  new = 'green',
  valuePack = 'yellow-dark',
  recommended = 'green',
  gift = 'pink',
  freeDelivery = 'green',
  loyaltyPrice = 'loyalty',
  blackFriday = 'black-friday',
  cyberMonday = 'cyber-monday',
  presale = 'green',
  loyalty = 'loyalty',
  discount = 'orange',
  b2b = 'black-friday', // TODO: zatím nastaveno na stejný tag jako black firday, je nutné dodělat styl 'b2b'
  zam = 'black-friday', // TODO: zatím nastaveno na stejný tag jako black firday, je nutné dodělat styl 'zam'
  vip = 'black-friday' // TODO: zatím nastaveno na stejný tag jako black firday, je nutné dodělat styl 'vip'
}
