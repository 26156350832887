import { onMounted, onUnmounted } from '@nuxtjs/composition-api'

export const useWindowInnerHeightOnMounted = () => {
  const setVhProperty = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }

  onMounted(() => {
    setVhProperty()
    window.addEventListener('resize', setVhProperty)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', setVhProperty)
  })
}
