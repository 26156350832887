import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { AuthenticatedUserEvent } from '~/src/Model/Auth/AuthenticatedUser'
import { AuthService } from '~/src/Infrastructure/Auth/AuthService'
import { useVisitedProductsStore } from '~/src/Infrastructure/VisitedProducts/VisitedProductsStore'
import { DataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { UserLoggedStateChangedEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/User/UserLoggedStateChangedEvent'
import { useWishListsStore } from '~/src/Infrastructure/WishLists/WishListsStore'
import { useWatchDogsStore } from '~/src/Infrastructure/WatchDogs/WatchDogsStore'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import { createSentryUserFromAuthenticatedUser } from '~/plugins/sentry/sentry-context'
import { BasketHashCookieStorage } from '~/src/Infrastructure/Cookies/BasketHashCookies'
import { CustomerPreferredZipCodeCookieStorage } from '~/src/Infrastructure/Cookies/CustomerPreferredZipCode'
import { useAclStore } from '~/stores/acl'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { LogoutOptions } from '~/src/Model/Auth/AuthService'

const authService: Plugin = async ({ $auth, app, $sentry, $cookies, $pinia }, inject: Inject) => {
  const authService = new AuthService($auth)
  const basketStore = useBasketStore($pinia)
  const systemPagesStore = useSystemPagesStore($pinia)
  const visitedProductsStore = useVisitedProductsStore($pinia)
  const wishlistsStore = useWishListsStore($pinia)
  const watchDogsStore = useWatchDogsStore($pinia)
  const aclStore = useAclStore($pinia)

  const dataLayer = new DataLayer(app.$gtm)
  const basketHashStorage = new BasketHashCookieStorage(app.$cookies)
  const customerPreferredZipCodeCookieStorage = new CustomerPreferredZipCodeCookieStorage(app.$cookies)

  const dataLayerUserLoggedStateChangedEvent = new UserLoggedStateChangedEvent(
    dataLayer,
    authService
  )

  authService.onLoggedIn.subscribe(async (event: AuthenticatedUserEvent) => {
    $sentry.setUser(createSentryUserFromAuthenticatedUser(event.user))
    aclStore.clearResources()

    if (event.user.basketHash) {
      await basketStore.updateBasketHash(event.user.basketHash)
      basketHashStorage.removeBasketHashCookie()
      basketStore.loadBasketInfo()
    }
    dataLayerUserLoggedStateChangedEvent.fire(event.user.id)
    wishlistsStore.loadWishLists()

    visitedProductsStore.updateProductsListId(event.user.lastVisitedProducts)
    visitedProductsStore.loadProductBoxes()
    $cookies.remove('visitedProductsListId')

    if (!event.disableRefresh) {
      window.$nuxt.refresh()
    }
  })

  authService.onLoggedOut.subscribe((logoutOptions?: LogoutOptions) => {
    $sentry.setUser(null)

    basketStore.deleteLocalBasket()
    visitedProductsStore.clear()
    dataLayerUserLoggedStateChangedEvent.fire()
    wishlistsStore.clearWishLists()
    watchDogsStore.clearWatchDogs()
    aclStore.clearResources()

    customerPreferredZipCodeCookieStorage.deleteCustomerPreferredZipCodeCookie()

    if (app.context.route?.meta[0]?.logoutRedirectToLogin) {
      app.router
        ?.push(systemPagesStore.pathByUid('userLogin').value)
        .then(() => window.location.reload())

      return
    }

    if (app.context.route?.meta[0]?.logoutRedirectToHomepage) {
      app.router
        ?.push('/')
        .then(() => window.location.reload())

      return
    }

    if (app.context.route?.meta[0]?.acl) {
      app.router
        ?.push('/')
        .then(() => window.location.reload())
    }

    if (logoutOptions?.disableRefresh) {
      return
    }

    window.location.reload()
  })

  const loggedUserBasketHash = authService.authenticatedUser.value?.basketHash

  if (loggedUserBasketHash) {
    await basketStore.updateBasketHash(loggedUserBasketHash)
  }

  if (process.client) {
    dataLayerUserLoggedStateChangedEvent.fire(authService.authenticatedUser.value?.id ?? null)
  }

  const authenticatedUser = authService.authenticatedUser.value

  if (authenticatedUser) {
    $sentry.setUser(createSentryUserFromAuthenticatedUser(authenticatedUser))
  }

  inject('authService', authService)
}

declare module 'vue/types/vue' {
  interface Vue {
    $authService: AuthService
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $authService: AuthService
  }

  interface Context {
    $authService: AuthService
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $authService: AuthService
  }
}

export default authService
