import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { DataLayer, GtmModule } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { BasketDataLayerDataRepository } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Repository/BasketDataLayerDataRepository'
import { idleCallback } from '~/src/Infrastructure/WebAPI/IdleCallback'

export const usePageLoadEvent = (gtm: GtmModule, axios: NuxtAxiosInstance) => {
  return new PageLoadEvent(
    new DataLayer(gtm),
    new BasketDataLayerDataRepository(axios)
  )
}

export class PageLoadEvent {
  constructor (
    private readonly dataLayer: DataLayer,
    private readonly basketDataLayerDataRepository: BasketDataLayerDataRepository
  ) {
  }

  public fire (basketHash: string): void {
    idleCallback(async () => {
      const basketData = await this.basketDataLayerDataRepository.get(basketHash)
      this.dataLayer.push({
        cart_contents: {
          items: basketData.items.map(i => ({
            item_sap_id: i.item_sap_id
          }))
        }
      })
    })
  }
}
