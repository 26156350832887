import { CustomerTurnoverResponse } from '~/src/Model/Customer/CustomerTurnover'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { useAxiosWithCustomAuthorization } from '~/utils/Axios/AxiosWithCustomAuthorization'
import {
  CreateUserAddressRequest,
  CreateUserAddressResponse,
  UpdatedUserAddressBillingAddressResponse,
  UpdatedUserAddressDeliveryAddressResponse,
  UserAddressesCollectionResponse,
  UserBillingAddress,
  UserDeliveryAddress
} from '~/src/Model/Customer/UserAddress'
import { StudentClubRegistrationRequest } from '~/src/Model/StudentClub/StudentClub'
import { UserIsicInformation } from '~/src/Model/Auth/AuthenticatedUser'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'

export const useCustomerRepository = () => {
  const { $axios } = useAxios()

  return {
    loadCustomerInfo: (id: number): Promise<CustomerInformation> => $axios.$get<CustomerInformation>(`/customers/${id}`),
    getPurchasesTurnover: (id: number): Promise<CustomerTurnoverResponse> => {
      return $axios.$get<CustomerTurnoverResponse>(`/customers/${id}/turnover`)
    },
    changePhone: (id: number, newPhone: string, token: string): Promise<void> => {
      const customAxios = useAxiosWithCustomAuthorization($axios, token)

      return customAxios.$post<void>(`/customers/${id}/change-phone`, { data: { newPhone } })
    },
    changeEmail: (id: number, newEmail: string, token: string): Promise<void> => {
      const customAxios = useAxiosWithCustomAuthorization($axios, token)

      return customAxios.$post<void>(`/customers/${id}/change-email`, { data: { newEmail } })
    },
    changeUsername: (id: number, firstName: string, lastName: string): Promise<void> => {
      return $axios.$post<void>(`/customers/${id}/change-name`, { firstName, lastName })
    },
    createNewAddress: (userId: number, request: CreateUserAddressRequest): Promise<CreateUserAddressResponse> => {
      return $axios.$post<CreateUserAddressResponse>(`/customers/${userId}/addresses`, request)
    },
    makeDefault: (userId: number, addressId: string): Promise<UserAddressesCollectionResponse> => {
      return $axios.$post<UserAddressesCollectionResponse>(`/customers/${userId}/addresses/${addressId}/make-default`)
    },
    deleteAddress: (userId: number, addressId: string): Promise<UserAddressesCollectionResponse> => {
      return $axios.$delete<UserAddressesCollectionResponse>(`/customers/${userId}/addresses/${addressId}`)
    },
    editBillingAddress: (userId: number, addressId: string, address: UserBillingAddress): Promise<UpdatedUserAddressBillingAddressResponse> => {
      return $axios.$post<UpdatedUserAddressBillingAddressResponse>(`/customers/${userId}/addresses/${addressId}/billing`, address)
    },
    editDeliveryAddress: (userId: number, addressId: string, address: UserDeliveryAddress): Promise<UpdatedUserAddressDeliveryAddressResponse> => {
      return $axios.$post<UpdatedUserAddressDeliveryAddressResponse>(`/customers/${userId}/addresses/${addressId}/delivery`, address)
    },
    deleteIsicCard: (userId: number): Promise<void> => {
      return $axios.$delete<void>(`/customers/${userId}/isic-card`)
    },
    registerIsicCard: (userId: number, request: StudentClubRegistrationRequest): Promise<UserIsicInformation> => {
      return $axios.$post<UserIsicInformation>(`/customers/${userId}/isic-card`, request)
    },
    disableOnboardingWindow: (userId: number): Promise<void> => {
      return $axios.$post<void>(`customers/${userId}/disable-datart-onboarding-window`)
    }
  }
}
