





















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { MapStoreInfo } from '~/src/Model/StoresMap/StoresMap'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

interface PropTypes {
  store: MapStoreInfo
}

export default defineComponent<PropTypes>({
  components: { Button },
  props: {
    store: {
      required: true,
      type: Object as PropType<MapStoreInfo>
    }
  }
})
