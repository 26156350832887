













import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'

export default defineComponent({
  components: { Container }
})
