import { Context, Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import VueI18n from 'vue-i18n'
import { ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'
import { EmptyCacheStorage } from '~/src/Infrastructure/Cache/Server/EmptyCacheStorage'

const serverCacheStoragePlugin: Plugin = async (context: Context, inject: Inject) => {
  const i18n = context.app.i18n as VueI18n

  let cacheStorage: ServerCacheStorage

  if (process.server && process.env.ENABLE_REDIS_CACHE === '1') {
    const { RedisStorage } = await import('~/src/Infrastructure/Cache/Server/Redis/RedisCacheStorage')
    cacheStorage = new RedisStorage({
      keyPrefix: `${context.$webConfig.web}-${i18n.locale}:`
    })
  } else {
    cacheStorage = new EmptyCacheStorage()
  }

  inject('serverCacheStorage', cacheStorage)
}

declare module 'vue/types/vue' {
  interface Vue {
    $serverCacheStorage: ServerCacheStorage
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $serverCacheStorage: ServerCacheStorage
  }

  interface Context {
    $serverCacheStorage: ServerCacheStorage
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $serverCacheStorage: ServerCacheStorage
  }
}

export default serverCacheStoragePlugin
