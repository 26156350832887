






import { defineComponent } from '@nuxtjs/composition-api'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'

export default defineComponent({
  components: { TextSkeleton },
  inheritAttrs: true,
  props: {
    tag: {
      type: String,
      default: 'p'
    },
    rowCount: {
      type: Number,
      default: null
    }
  }
})
