import { ref, useFetch } from '@nuxtjs/composition-api'
import { useProductGiftsRepository } from '~/src/Infrastructure/ProductGifts/ProductGiftsRepository'
import { ProductGiftOffer } from '~/src/Model/ProductGift/ProductGift'
import { usePromise } from '~/src/Infrastructure/Api/Promise'

export const useProductGiftsFetch = (id: number) => {
  const giftOffers = ref<ProductGiftOffer[]>([])
  const { loadGifts } = useProductGiftsRepository()

  const { exec, onSuccess: onGiftOffersLoaded } = usePromise(async () => {
    const response = await loadGifts(id)
    giftOffers.value = response.gifts
  })

  useFetch(exec)

  return {
    giftOffers,
    onGiftOffersLoaded
  }
}
