import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NavigationNodes } from '~/src/Model/NavigationNode/NavigationNode'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useNavigationNodesRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadNavigationNodes: (uid: string): Promise<NavigationNodes> => $axios.$get<NavigationNodes>('/navigation-nodes', { params: { uid } })
  }
}

export const useNavigationNodesRepository = () => {
  const { $axios } = useAxios()

  return useNavigationNodesRepositoryWithAxios($axios)
}
