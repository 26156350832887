import { Alias } from '~/src/Model/Router/Alias'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useRouterRepository = () => {
  const { $axios } = useAxios()

  return {
    resolveAlias: (alias: string): Promise<Alias> => {
      return $axios.$get<Alias>(`/router/${alias}`)
    }
  }
}
