import { Page, PagesCollection } from '~/src/Model/Page/Page'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const usePagesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadPage: (id: number): Promise<Page> => $axios.$get<Page>(`/pages/${id}`),
    loadPagesByUid: (uid: string): Promise<PagesCollection> => $axios.$get<PagesCollection>('/pages', {
      params: {
        uid
      }
    })
  }
}
