import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'
import { Images } from '~/src/Model/Image/Image'

export const useImageRepository = () => {
  const { $axios } = useAxios()

  return {
    loadImages: (ids: number[]) => $axios.$get<Images>('/images', {
      params: {
        id: ids
      }
    })
  }
}
