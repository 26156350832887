














import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { StandardAnnouncementContent, TypedAnnouncement } from '~/src/Model/Announcements/Announcements'
import AnnouncementLayout from '~/components/Announcements/AnnouncementLayout.vue'
import ContentEditor from '~/components/ContentEditor/ContentEditor.vue'
import { ContentEditorBlockType } from '~/src/Model/ContentEditor/ContentEditor'

export default defineComponent({
  components: {
    ContentEditor,
    AnnouncementLayout
  },
  props: {
    announcement: {
      required: true,
      type: Object as PropType<TypedAnnouncement<StandardAnnouncementContent>>
    }
  },
  emits: ['dismissed'],
  setup () {
    return {
      contentEditorBlockTypes: ContentEditorBlockType
    }
  }
})
