var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"christmas-delivery__row bs-py-4 bs-py-lg-0"},[_c('td',{staticClass:"christmas-delivery__cell christmas-delivery__cell--icon border-blue-border bs-px-lg-2 bs-px-lg-3"},[_c('PickupPointIcon',{class:[
        'christmas-delivery__icon',
        {'christmas-delivery__icon--personal' : _vm.christmasDeliveryConfigItem.uid === _vm.PERSONAL_STORE},
        {'christmas-delivery__icon--economy bs-mx-auto' : _vm.christmasDeliveryConfigItem.uid === _vm.ECONOMY}
      ],attrs:{"icon-type":_vm.christmasDeliveryConfigItem.uid}})],1),_vm._v(" "),_c('th',{class:[
      'christmas-delivery__cell christmas-delivery__cell--title border-blue-border bs-px-lg-3',
      {'text-lg-nowrap typo-complex-lg-20' : _vm.christmasDeliveryConfigItem.uid === _vm.PERSONAL_STORE}
    ]},[_vm._v("\n    "+_vm._s(_vm.christmasDeliveryConfigItem.name)+"\n  ")]),_vm._v(" "),_c('td',{staticClass:"christmas-delivery__cell christmas-delivery__cell--status border-blue-border bs-px-lg-3"},[_c('span',{class:[
        'christmas-delivery__status d-flex align-items-center bs-gap-1 font-weight-bold',
        {'christmas-delivery__status--error' : _vm.disabled},
        {'christmas-delivery__status--personal bs-p-2 bg-success text-white' : !_vm.disabled && _vm.christmasDeliveryConfigItem.uid === _vm.PERSONAL_STORE},
        {'christmas-delivery__status--ok text-success' : !_vm.disabled && _vm.christmasDeliveryConfigItem.uid !== _vm.PERSONAL_STORE}
      ]},[(_vm.disabled)?[_c('i',{staticClass:"icon-error icon-fs-20",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('christmas_delivery_calendar.unavailable')))])]:(_vm.christmasDeliveryConfigItem.uid === _vm.PERSONAL_STORE)?[_c('i',{staticClass:"icon-tree-ok icon-fs-20",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('christmas_delivery_calendar.available'))+" "+_vm._s(_vm.$t('christmas_delivery_calendar.order_until'))+":\n          "),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(((_vm.$formatter.dayMonth(_vm.christmasDeliveryConfigItem.deadline)) + "&nbsp;" + (_vm.$formatter.shortTime(_vm.christmasDeliveryConfigItem.deadline))))}})])]:[_c('i',{staticClass:"icon-tree-ok icon-fs-20",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('christmas_delivery_calendar.available')))])]],2)]),_vm._v(" "),_c('td',{staticClass:"christmas-delivery__cell christmas-delivery__cell--desc border-blue-border bs-px-lg-3",domProps:{"innerHTML":_vm._s(_vm.christmasDeliveryConfigItem.note)}})])}
var staticRenderFns = []

export { render, staticRenderFns }