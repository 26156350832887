import { ref, useFetch } from '@nuxtjs/composition-api'
import { ProductDetail } from '~/src/Model/ProductDetail/ProductDetail'
import { useProductDetailsRepository } from '~/src/Infrastructure/ProductDetail/ProductDetailsRepository'

export const useProductDetailFetch = (id: number) => {
  const productDetail = ref<ProductDetail | null>(null)
  const { loadProductDetail } = useProductDetailsRepository()

  useFetch(async () => {
    productDetail.value = await loadProductDetail(id)
  })

  return {
    productDetail
  }
}
