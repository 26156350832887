import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import {
  EcommerceEventDataFactory,
  useEcommerceEventDataFactory
} from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/EcommerceEventDataFactory'
import { ProductDataLayerDataRepository } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Product/Repository/ProductDataLayerDataRepository'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export class AddToBasketEvent {
  constructor (
    private readonly dataLayer: DataLayer,
    private readonly productDataLayerDataRepository: ProductDataLayerDataRepository,
    private readonly eventFactory: EcommerceEventDataFactory
  ) {
  }

  public async fire (productId: number, quantity: number = 1): Promise<void> {
    this.dataLayer.push({ ecommerce: null })
    this.dataLayer.push(
      this.eventFactory.create(
        'add_to_cart',
        null,
        {
          ...await this.productDataLayerDataRepository.get(productId),
          quantity: quantity.toString()
        }
      )
    )
  }
}

export const useAddToBasketEvent = () => {
  const { $axios } = useAxios()
  const dataLayer = useDataLayer()
  const ecommerceEventDataFactory = useEcommerceEventDataFactory()

  return new AddToBasketEvent(
    dataLayer,
    new ProductDataLayerDataRepository($axios),
    ecommerceEventDataFactory
  )
}
