


















































































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import deburr from 'lodash/deburr'
import sortBy from 'lodash/sortBy'
import { ExpressPickupStore, ExpressPickupStores } from '~/src/Model/ExpressPickup/ExpressPickup'
import Dropdown from '~/components/DesignSystem/Dropdown/Dropdown.vue'
import DropdownItem from '~/components/DesignSystem/Dropdown/DropdownItem.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PickerDropdownLabel from '~/components/ExpressPickup/parts/PickerDropdownLabel.vue'
import PickerDropdownNote from '~/components/ExpressPickup/parts/PickerDropdownNote.vue'

export default defineComponent({
  components: {
    DropdownItem,
    Dropdown,
    Button,
    PickerDropdownLabel,
    PickerDropdownNote
  },
  props: {
    expressPickupStores: {
      default: null,
      type: Object as PropType<ExpressPickupStores | null>
    },
    showDescriptionSection: {
      default: false,
      type: Boolean
    },
    value: {
      type: Object as PropType<ExpressPickupStore | null>,
      default: null
    },
    dropup: {
      default: true,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  emits: ['input', 'store-selected'],
  setup (props, { emit }) {
    const selectedStore = computed<ExpressPickupStore|null>({
      get: () => props.value,
      set: selectedStore => emit('input', selectedStore)
    })

    const filterValue = ref<string>('')
    const filteredOptions = computed<ExpressPickupStore[]>(() => {
      if (!props.expressPickupStores) {
        return []
      }

      let stores: ExpressPickupStore[] = sortBy(props.expressPickupStores.stores, [store => deburr(store.name)])

      if (filterValue.value) {
        stores = stores.filter(store => store.name.toLowerCase().includes(filterValue.value.toLowerCase()))
      }

      return props.expressPickupStores.favouriteCustomerStore ? [...stores, props.expressPickupStores.favouriteCustomerStore] : stores
    })

    return {
      selectedStore,
      filteredOptions,
      filterValue
    }
  }
})
