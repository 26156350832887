















































































import { defineComponent, ref } from '@nuxtjs/composition-api'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useCheckCustomerRepository } from '~/src/Infrastructure/CheckCustomer/CheckCustomerRepository'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'
import { OnboardingCheckedCustomerEvent } from '~/src/Model/Karpaty/Onboarding'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: { Alert, LoadingOverlay, FlashMessageCenter, BaseValidationObserver, Modal, Button, FormInput, Form },
  props: {
    showEmailCheck: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },
  emits: ['close-modal', 'customer-checked', 'show-email-check', 'go-back'],
  setup (props, { emit }) {
    const { flashMessages, flashMessageCenter } = useLocalFlashMessageCenter()
    const { checkCustomer } = useCheckCustomerRepository()
    const authService = useAuthService()
    const loginEmail = ref<string>(props.email)

    const { exec: verifyAccount, pending, onSuccess } = useApiCall(async () => {
      if (authService.isLoggedIn.value) {
        await authService.logout({ disableRefresh: true })
      }

      return checkCustomer(loginEmail.value, true)
    }, flashMessageCenter)

    onSuccess.subscribe((checkedCustomer: CheckCustomerResponse) => {
      emit('customer-checked', {
        checkedCustomer,
        email: loginEmail.value
      } as OnboardingCheckedCustomerEvent)
    })

    return {
      loginEmail,
      verifyAccount,
      flashMessages,
      pending,
      isLoggedIn: authService.isLoggedIn
    }
  }
})
