import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { isEmailAddress } from '~/src/Infrastructure/String/IsEmailAddress/IsEmailAddress'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class NumberOrEmailRule extends ValidationRule {
  message = () => this.i18n.t('validations.number_or_email').toString()

  validate = (value: string | null): ValidationRuleResult => {
    if (!value) {
      return { valid: true }
    }

    if (isEmailAddress(value)) {
      return { valid: true }
    }

    const valid = /^\d*$/.test(value.toString())

    return {
      valid
    }
  };
}
