import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { isEmailAddress } from '~/src/Infrastructure/String/IsEmailAddress/IsEmailAddress'

export class EmailRule extends ValidationRule {
  message = (field: string) => this.i18n.t('validations.email', { field }).toString()

  validate = (value: string | null): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = isEmailAddress(value)

    return {
      valid
    }
  };
}
