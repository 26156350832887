



































import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

export default defineComponent({
  components: {
    Container,
    CurrentWeb,
    Button
  }
})
