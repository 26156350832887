import { Image } from '~/src/Model/Image/Image'
import { DeliveryMethodServices } from '~/src/Model/Delivery/DeliveryMethodServices'

export enum DeliveryType {
  store = 'DEL_PERSONAL_PRODEJNA',
  balikomatBoxA = 'BALIKOMAT_BOX_A',
  balikomatBoxB = 'BALIKOMAT_BOX_B',
  balikomatBoxC = 'BALIKOMAT_BOX_C',
  deliveryToAddress = 'DELIVERY_TO_ADDRESS',
  deliveryHds = 'DELIVERY_HDS',
  deliveryToAddressOne = 'DELIVERY_TO_ADDRESS_ONE',
  deliveryToAddressWithRecycling = 'DELIVERY_TO_ADDRESS_WITH_RECYCLING',
  geisCargo = 'GEIS_CARGO',
  czechPost = 'DEL_CPOST',
  deliveryStandard2 = 'DELIVERY_STANDARD_2',
  deliveryStandard1 = 'DELIVERY_STANDARD_1',
  virtualDelivery = 'VIRTUAL_DELIVERY',
  geisPoint = 'GEIS_POINT',
  geisParcel = 'GEIS_PARCEL',
  deliveryHdsLight = 'DELIVERY_HDS_LIGHT',
  postaBalikovna = 'POSTA_BALIKOVNA',
  postaBalikNaPostu = 'POSTA_BALIK_NA_POSTU',
  comfortDeliveryLight = 'COMFORT_DELIVERY_LIGHT',
  comfortDelivery = 'COMFORT_DELIVERY',
  onlineElectronicContent = 'ONLINE_ELECTRONIC_CONTENT',
  balikobox = 'BALIKOBOX',
  courier2Hds = 'COURIER_2_HDS',
  zasilkovnaSk = 'ZASILKOVNA_SK_PICKUP_POINTS',
  zasilkovnaCz = 'ZASILKOVNA_CZ_PICKUP_POINTS',
  zBoxSk = 'ZASILKOVNA_SK_Z_BOX',
  zBoxCz = 'ZASILKOVNA_CZ_Z_BOX',
  pplPickupPoints = 'PPL_PICKUP_POINTS',
  dpdPickupPoints = 'DPD_PICKUP_POINTS',
  deliveryUrbantzLight = 'COMFORT_DELIVERY_URBANTZ_LIGHT',
  deliveryUrbantzHeavy = 'COMFORT_DELIVERY_URBANTZ_HEAVY',
  postOfficeSk = 'POST_OFFICE_SK',
  iDoDoBigBox = 'IDODO_BIGBOX',
  iDoDoSmallBox = 'IDODO_SMALLBOX',
  iDoDoBigBoxSameDay = 'IDODO_BIGBOX_SAMEDAY',
  iDoDoBigBoxNextDay = 'IDODO_BIGBOX_NEXTDAY',
  iDoDoSmallBoxSameDay = 'IDODO_SMALLBOX_SAMEDAY',
  iDoDoSmallBoxNextDay = 'IDODO_SMALLBOX_NEXTDAY',
  iDoDoBigBoxUrbantz = 'IDODO_BIGBOX_URBANTZ',
  iDoDoSmallBoxUrbantz = 'IDODO_SMALLBOX_URBANTZ',
  nayExpressHds = 'NAY_EXPRESS_HDS',
  nayExpressHdw = 'NAY_EXPRESS_HDW',
  virtualExpressDeliveryParent = 'VIRTUAL_EXPRESS_DELIVERY_PARENT',
  virtualEconomicDeliveryParent = 'VIRTUAL_ECONOMIC_DELIVERY_PARENT'
}

export interface DeliveryMethod extends DeliveryMethodServices {
  id: number
  uid: string | null
  name: string
  type: DeliveryType
  price: string
  description: string | null
  selectOnMap: boolean
  personalPickup: boolean
  availabilityMessage: string | null
  children: DeliveryMethod[]
  image: Image | null
  validateZip: boolean
}

export interface DeliveryMethods {
  deliveryMethods: DeliveryMethod[]
}

export interface PersonalDeliveryMethod {
  id: number
  parentUid: DeliveryType | null
  isAvailableForBasket: boolean
  name: string
  shortName: string
}

export interface CourierDeliveryMethod {
  id: number
  uid: DeliveryType | null
  name: string
  shortName: string
}

export interface LowestPrice {
  amount: string
  from: boolean
}

export interface DeliveryDate {
  date: string
  from: boolean
}

export interface BaseDeliveryMethodGroup {
  lowestPrice: LowestPrice
  deliveryDate: DeliveryDate
}

export interface PersonalDeliveryMethodGroup extends BaseDeliveryMethodGroup {
  methods: PersonalDeliveryMethod[]
}

export interface CourierDeliveryMethodGroup extends BaseDeliveryMethodGroup {
  methods: CourierDeliveryMethod[]
}

export interface DeliveryMethodsGroups {
  personal: PersonalDeliveryMethodGroup | null
  courier: CourierDeliveryMethodGroup | null
}
