import { Plugin } from '@nuxt/types'
import axios, { AxiosError } from 'axios'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'
import { isEventIgnored } from '~/plugins/sentry/isIgnored'
import { ignoredEvents } from '~/plugins/sentry/ignoredEvents'
import { anonymizeSensitiveData } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeSensitiveData'
import { sensitiveAttributeNames } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/SensitiveAttributeNames'

const sentryContext: Plugin = ({ $sentry, $webConfig }) => {
  $sentry.setTag('ssr', process.server)
  $sentry.setTag('web', $webConfig.web)

  $sentry.configureScope((scope) => {
    scope.addEventProcessor((event, hint) => {
      if (!hint || !hint.originalException) {
        return event
      }

      const originalException = hint.originalException

      if (!axios.isAxiosError(originalException)) {
        return event
      }

      const axiosError = originalException as AxiosError
      const response = axiosError.response

      if (!response) {
        return event
      }

      const apiError = response.data?.error
      const internalErrorCode: any = apiError?.internalErrorCode ?? 0
      const customAxiosFingerPrints: string[] = []

      customAxiosFingerPrints.push(response.status.toString())

      if (internalErrorCode) {
        customAxiosFingerPrints.push(internalErrorCode.toString())
        event.tags = {
          ...(event.tags ?? {}),
          'internal.error.code': internalErrorCode.toString()
        }
      }

      event.fingerprint = [
        '{{ default }}',
        ...customAxiosFingerPrints
      ]

      if (apiError) {
        event.contexts = {
          ...event.contexts,
          api_error: apiError
        }
      }

      return event
    })

    scope.addEventProcessor((event) => {
      return isEventIgnored(event, ignoredEvents) ? null : event
    })

    scope.addEventProcessor((event) => {
      return anonymizeSensitiveData(event, sensitiveAttributeNames)
    })
  })
}

export const createSentryUserFromAuthenticatedUser = (user: AuthenticatedUser) => {
  return {
    ...user,
    id: user.id.toString()
  }
}

export default sentryContext
