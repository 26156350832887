import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { ProductDataLayerData } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Product/ProductDataLayerData'
import { DataLayerProduct } from '~/src/Model/Datalayer/DataLayerProduct'

export class ProductDataLayerDataRepository {
  constructor (private readonly axios: NuxtAxiosInstance) {
  }

  async get (productId: number): Promise<ProductDataLayerData> {
    const response = await this.axios.$get<DataLayerProduct>(`/datalayer/products/${productId}`)
    return {
      item_id: response.id.toString(),
      item_name: response.name,
      price: response.price,
      item_brand: response.brandName ?? '',
      item_category: response.category,
      item_category2: response.category2,
      item_sku: response.sku,
      item_sap_id: response.sapId ?? '',
      item_ean: response.ean,
      item_availability: response.availability,
      quantity: '1'
    }
  }
}
