import { computed, watch } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { OrderCustomerInfoForm } from '~/src/Model/Order/Address/CustomerInfoForm'
import { useCheckoutStore as useStore } from '~/stores/checkout'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'

export const useCheckoutStore = (pinia?: Pinia | null) => {
  const store = useStore(pinia)
  const basketStore = useBasketStore(pinia)

  watch(basketStore.isEmptyBasket, (value) => {
    if (value) {
      store.clearCheckoutState()
    }
  })

  const customerInfo = computed<OrderCustomerInfoForm | null>(() => store.customerInfo)
  const selectedDeliveryAddressId = computed<string | null>(() => store.selectedDeliveryAddressId)
  const selectedBillingAddressId = computed<string | null>(() => store.selectedBillingAddressId)

  if (customerInfo.value) {
    store.validateSchema()
  }

  return {
    setCustomerInfo: store.setCustomerInfo,
    setSelectedDeliveryAddressId: store.setDeliveryAddressId,
    setSelectedBillingAddressId: store.setBillingAddressId,
    clearCheckoutState: store.clearCheckoutState,
    customerInfo,
    selectedDeliveryAddressId,
    selectedBillingAddressId,
    validateSchema: store.validateSchema
  }
}
