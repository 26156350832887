














































import { computed, defineComponent } from '@nuxtjs/composition-api'
import { ButtonVariant } from '~/src/Model/Button/ButtonVariant'
import { useSystemPagesStore } from '~/src/Infrastructure/SystemPages/SystemPagesStore'
import Spinner from '~/components/Loading/Spinner.vue'

export default defineComponent({
  components: { Spinner },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    preventClickEvent: {
      type: Boolean,
      default: true
    },
    target: {
      type: String,
      default: '_self'
    },
    href: {
      type: String,
      default: null
    },
    hrefByUid: {
      type: String,
      default: null
    },
    uid: {
      type: String,
      default: null
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    primaryLight: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    secondaryInput: {
      type: Boolean,
      default: false
    },
    secondaryLoyalty: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    successLight: {
      type: Boolean,
      default: false
    },
    successOutline: {
      type: Boolean,
      default: false
    },
    red: {
      type: Boolean,
      default: false
    },
    redLight: {
      type: Boolean,
      default: false
    },
    datartYellow: {
      type: Boolean,
      default: false
    },
    facebook: {
      type: Boolean,
      default: false
    },
    apple: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spanClass: {
      type: String,
      default: null
    },
    buttonClass: {
      type: [String, Array],
      default: null
    },
    buttonInnerClass: {
      type: [String, Array],
      default: null
    },
    iconLeft: {
      type: String,
      default: null
    },
    iconLeftOuter: {
      type: String,
      default: null
    },
    iconRight: {
      type: String,
      default: null
    },
    iconRightOuter: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    dataTest: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    submit: {
      type: Boolean,
      default: false
    },
    tooltipHideIcon: {
      type: Boolean,
      default: false
    },
    tooltipBoundary: {
      type: String,
      default: 'window'
    },
    tooltipBoundaryPadding: {
      type: Number,
      default: null
    },
    tooltipNoninteractive: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    },
    tooltipOffset: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    tooltipTriggers: {
      type: String,
      default: 'hover focus'
    },
    loading: {
      type: Boolean,
      default: false
    },
    rel: {
      type: String,
      default: null
    }
  },

  emits: ['click'],

  setup (props, { emit }) {
    const variant = computed<string>(() => {
      if (props.primaryLight) {
        return ButtonVariant.primaryLight
      }
      if (props.success) {
        return ButtonVariant.success
      }
      if (props.successLight) {
        return ButtonVariant.successLight
      }
      if (props.successOutline) {
        return ButtonVariant.successOutline
      }
      if (props.light) {
        return ButtonVariant.light
      }
      if (props.secondary) {
        return ButtonVariant.secondary
      }
      if (props.secondaryInput) {
        return ButtonVariant.secondaryInput
      }
      if (props.secondaryLoyalty) {
        return ButtonVariant.secondaryLoyalty
      }
      if (props.red) {
        return ButtonVariant.red
      }
      if (props.redLight) {
        return ButtonVariant.redLight
      }
      if (props.datartYellow) {
        return ButtonVariant.datartYellow
      }
      if (props.facebook) {
        return ButtonVariant.facebook
      }
      if (props.apple) {
        return ButtonVariant.apple
      }
      return ButtonVariant.primary
    })

    const handleButtonClick = (e: Event) => {
      if (props.disabled) {
        e.preventDefault()
        return
      }
      if (props.preventClickEvent) {
        e.preventDefault()
      }
      emit('click', e)
    }

    const linkTo = computed<string | null>(() => {
      if (props.disabled) {
        return null
      }

      if (props.to) {
        return props.to
      }
      if (props.uid) {
        const systemPagesStore = useSystemPagesStore()
        return systemPagesStore.pathByUid(props.uid).value
      }

      return null
    })

    const hrefLink = computed<string | null>(() => {
      if (props.href) {
        return props.href
      }

      if (props.hrefByUid) {
        const systemPagesStore = useSystemPagesStore()
        return systemPagesStore.pathByUid(props.hrefByUid).value
      }

      return null
    })

    const iconRightOuterComputed = computed<string | null>(() => {
      if (props.hasTooltip) {
        if (props.tooltipHideIcon) {
          return null
        }

        let icon

        if (props.iconRightOuter !== null) {
          icon = props.iconRightOuter
        } else {
          icon = 'icon-info'
        }

        if (props.disabled && props.iconRightOuter !== '') {
          icon += ' text-primary'
        }

        return icon
      }

      return props.iconRightOuter
    })

    return {
      variant,
      handleButtonClick,
      linkTo,
      iconRightOuterComputed,
      hrefLink
    }
  }
})
