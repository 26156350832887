import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export const useInteralLinkFactory = () => {
  const $webConfig = useWebConfig()

  const isRelativeLink = (link: string) => {
    return link.startsWith('/')
  }

  const isInternalLink = (link: string) => {
    return link.includes($webConfig.domain) || isRelativeLink(link)
  }

  const getRelativePath = (link: string) => {
    if (isRelativeLink(link)) {
      return link
    }

    const url = new URL(link)

    return url.pathname
  }

  const getRelativePathWithoutQueryParams = (link: string) => {
    if (isRelativeLink(link)) {
      if (link.includes('?')) {
        return link.slice(0, link.indexOf('?'))
      }
      return link
    }

    const url = new URL(link)

    return url.pathname
  }

  return {
    isInternalLink,
    getRelativePath,
    getRelativePathWithoutQueryParams
  }
}
