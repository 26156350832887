






import { defineComponent } from '@nuxtjs/composition-api'
import { ValidationObserver } from 'vee-validate'
import { useValidationObserver } from '~/src/Infrastructure/Form/Validation/Validation'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import { ValidationObserverType } from '~/src/Model/Validation/Validation'

export default defineComponent({
  name: 'BaseValidationObserver',
  components: { ValidationObserver },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    slim: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const validationObserverRef = useRequiredTemplateRef<ValidationObserverType>()
    const { handleSubmitWithScroll, reset, validate } = useValidationObserver(validationObserverRef)

    return {
      handleSubmitWithScroll,
      reset,
      validationObserverRef,
      validate
    }
  }
})
