import { NuxtCookies } from 'cookie-universal-nuxt'
import { filterWhitespaces } from '~/src/Infrastructure/String/FilterWhiteSpaces'

const CUSTOMER_PREFERRED_ZIP_CODE_COOKIE = 'customerPreferredZipCode'

export class CustomerPreferredZipCodeCookieStorage {
  constructor (
    private cookies: NuxtCookies
  ) {}

  public get customerPreferredZipCode (): string {
    return this.cookies.get(CUSTOMER_PREFERRED_ZIP_CODE_COOKIE)?.toString() ?? ''
  }

  public setCustomerPreferredZipCode (zip: string): void {
    this.cookies.set(CUSTOMER_PREFERRED_ZIP_CODE_COOKIE, filterWhitespaces(zip))
  }

  public deleteCustomerPreferredZipCodeCookie (): void {
    this.cookies.remove(CUSTOMER_PREFERRED_ZIP_CODE_COOKIE)
  }
}
