import { onUnmounted, ref, Ref } from '@nuxtjs/composition-api'
export const isClient = typeof window !== 'undefined'

export function useMatchMedia (query: string) {
  const supported = isClient ? 'matchMedia' in window : false

  let mediaQueryList: Ref<MediaQueryList> = undefined as any
  let matches: Ref<boolean> = undefined as any
  let remove = () => {}

  if (supported) {
    mediaQueryList = ref<MediaQueryList>(matchMedia(query))
    matches = ref(mediaQueryList.value.matches)

    const process = (e: MediaQueryListEvent) => {
      matches.value = e.matches
    }

    if (mediaQueryList.value.addEventListener) {
      mediaQueryList.value.addEventListener('change', process)
    } else if (mediaQueryList.value.addListener) {
      mediaQueryList.value.addListener(process)
    }

    remove = () => {
      if (mediaQueryList.value.removeEventListener) {
        mediaQueryList.value.removeEventListener('change', process)
      } else if (mediaQueryList.value.removeListener) {
        mediaQueryList.value.removeListener(process)
      }
    }

    onUnmounted(remove)
  } else {
    mediaQueryList = ref<MediaQueryList>({} as MediaQueryList)
    matches = ref(false)
  }

  return {
    supported,
    mediaQueryList,
    matches,
    remove
  }
}
