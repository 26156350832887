





import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  inheritAttrs: false,

  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    }
  },

  emits: ['submit'],

  setup (_props, { emit }) {
    const submit = () => {
      emit('submit')
    }
    return {
      submit
    }
  }
})
