import { Ref, ref, toRaw } from '@nuxtjs/composition-api'
import { SimpleEventDispatcher } from 'ste-simple-events'
import { ApiError } from '~/src/Infrastructure/Api/ApiError'
import { useApiErrorFactory } from '~/src/Infrastructure/Api/ApiErrorFactory'

export type PromiseType<T extends Promise<any>> = T extends Promise<infer R>
    ? R
    : never;

export const usePromise = <T extends Promise<any>, TArgs extends Array<any>>(
  fn: (...args: TArgs) => T
) => {
  const loading = ref(false)
  const error = ref<any>(null)
  const result: Ref<PromiseType<T> | null> = ref<PromiseType<T> | null>(null)
  const promise = ref<T>()
  const { apiErrorFactory } = useApiErrorFactory()

  const onErrorDispatcher = new SimpleEventDispatcher<ApiError>()
  const onSuccessDispatcher = new SimpleEventDispatcher<PromiseType<T>>()
  const onCompletedDispatcher = new SimpleEventDispatcher()

  const exec = async (...args: TArgs): Promise<PromiseType<T>> => {
    loading.value = true
    error.value = null

    const currentPromise = (promise.value = fn(...args))

    try {
      const r = await currentPromise
      if (promise.value === currentPromise) {
        result.value = r
        onSuccessDispatcher.dispatch(result.value as PromiseType<T>)
      }

      return r
    } catch (er: any) {
      if (toRaw(promise.value) === toRaw(currentPromise)) {
        error.value = er
        result.value = null
      }

      const apiError = apiErrorFactory(er)
      onErrorDispatcher.dispatch(apiError)

      return currentPromise
    } finally {
      if (promise.value === currentPromise) {
        loading.value = false
      }
      onCompletedDispatcher.dispatch(null)
    }
  }

  return {
    exec,
    result,
    promise,
    loading,
    error,
    onError: onErrorDispatcher.asEvent(),
    onSuccess: onSuccessDispatcher.asEvent(),
    onCompleted: onCompletedDispatcher.asEvent()
  }
}

export const useSafePromise = <T extends Promise<any>, TArgs extends Array<any>>(
  fn: (...args: TArgs) => T
) => {
  const customUsePromise = usePromise(fn)

  return {
    ...customUsePromise,
    exec: async (...args: TArgs): Promise<PromiseType<T> | undefined> => {
      try {
        return await customUsePromise.exec(...args)
      } catch {
        return undefined
      }
    }
  }
}
