




































































import { computed, defineComponent, onMounted, PropType } from '@nuxtjs/composition-api'
import { NuxtError } from '@nuxt/types'
import { HttpStatusCode } from '~/src/Model/Error/HttpStatusCode'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import HeaderLayout from '~/components/Header/HeaderLayout.vue'
import LogoAppendix from '~/components/Header/Logo/LogoAppendix.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionHeading from '~/components/DesignSystem/PageSection/PageSectionHeading.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'
import Logo from '~/components/Header/Logo/Logo.vue'
import { useWysiwygContentLinkConvertor } from '~/src/Infrastructure/WysiwygContentLink/WysiwygContentLinksConvertor'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import { useWindowInnerHeightOnMounted } from '~/src/Infrastructure/Layout/WindowInnerHeightOnMounted'
import { useScrollbarWidthOnMounted } from '~/src/Infrastructure/Layout/ScrollbarWidthOnMounted'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

export default defineComponent({
  components: {
    Container,
    HeaderLayout,
    Logo,
    LogoAppendix,
    Button,
    PageSectionHeading,
    PageSectionContent
  },
  layout (context) {
    return !context.app.nuxt.err || context.app.nuxt.err.statusCode === HttpStatusCode.NOT_FOUND ? 'default' : 'empty'
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true
    }
  },
  setup (props) {
    const errorDescriptionElement = useRequiredTemplateRef<HTMLElement>()
    const { convertLinks } = useWysiwygContentLinkConvertor(errorDescriptionElement)

    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    onMounted(async () => {
      convertLinks()
      await christmasDeliveryConfigurationStore.loadChristmasDeliveryConfigurations()
    })

    useWindowInnerHeightOnMounted()
    useScrollbarWidthOnMounted()

    const statusCode = computed<string>(() => {
      switch (props.error.statusCode) {
        case HttpStatusCode.NOT_FOUND:
          return HttpStatusCode.NOT_FOUND.toString()
        default:
          return 'default'
      }
    })

    const isDefault = computed<boolean>(() => {
      return statusCode.value === 'default'
    })

    return {
      isActiveChristmas: christmasDeliveryConfigurationStore.isActiveChristmas,
      statusCode,
      isDefault,
      errorDescriptionElement
    }
  }
})
