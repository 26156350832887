





































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api'
import PreBasketProductsSummary from '~/components/Basket/PreBasket/PreBasketProductsSummary.vue'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import ExpressPickupStorePicker from '~/components/ExpressPickup/ExpressPickupStorePicker.vue'
import { useExpressPickupStores } from '~/src/Infrastructure/ExpressPickup/ExpressPickupStores'
import { useExpressPickupOrderCreate } from '~/src/Infrastructure/ExpressPickup/ExpressPickupOrderCreate'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import GoToDatartPromo from '~/components/Karpaty/GoToDatartPromo.vue'

const UID_CHECKOUT_BASKET = 'CHECKOUT_BASKET'

export default defineComponent({
  components: { GoToDatartPromo, ExpressPickupStorePicker, Button, LoadingOverlay, PreBasketProductsSummary },
  props: {
    isBasketInitialized: {
      type: Boolean,
      default: false
    },
    isPreBasket: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const basketStore = useBasketStore()
    const { i18n } = useTranslator()
    const { karpatyStopSelling } = useWebConfig()
    const { createExpressPickupOrder, creatingExpressPickupOrder } = useExpressPickupOrderCreate()
    const { expressPickupStores, expressPickupStoresLoading, loadStores, invalidateFetch } = useExpressPickupStores(true)
    const storesInitialized = ref<boolean>(false)

    const initStores = async () => {
      if (!props.isBasketInitialized || expressPickupStores.value || basketStore.isEmptyBasket.value) {
        return
      }

      await loadStores()
    }

    watch(() => props.isBasketInitialized, async () => {
      await initStores()
      storesInitialized.value = true
    })

    const expressPickupStoreSelected = (selectedStore: number) => {
      invalidateFetch()
      createExpressPickupOrder(selectedStore)
    }

    const loadingMessage = computed<string>(() => creatingExpressPickupOrder.value
      ? i18n.t('nayeshop_pre_basket.new.redirecting') as string
      : i18n.t('nayeshop_pre_basket.new.loading_options') as string)

    const loading = computed<boolean>(() => !storesInitialized.value || expressPickupStoresLoading.value || creatingExpressPickupOrder.value)

    return {
      UID_CHECKOUT_BASKET,
      expressPickupStoreSelected,
      loading,
      loadingMessage,
      expressPickupStores,
      isBasketEmpty: basketStore.isEmptyBasket,
      isBasketCreated: !!basketStore.basketHash,
      storesInitialized,
      karpatyStopSelling
    }
  }
})
