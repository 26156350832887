





















































































































import { computed, defineComponent, onUnmounted, PropType, reactive, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import { useFacebookLoginPostProcess } from '~/src/Infrastructure/Auth/Facebook/FacebookLoginPostProcess'
import GoogleLoginCustomButtonWrapper from '~/components/Auth/Google/GoogleLoginCustomButtonWrapper.vue'
import GoogleMergeAccount from '~/components/Auth/Google/GoogleMergeAccount.vue'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import FacebookLoginButton from '~/components/Auth/Facebook/FacebookLoginButton.vue'
import { CustomerLoginData } from '~/src/Model/Customer/CustomerLoginData'
import FacebookMergeAccount from '~/components/Auth/Facebook/FacebookMergeAccount.vue'
import { useGoogleLoginPostProcess } from '~/src/Infrastructure/Auth/Google/GoogleLoginPostProcess'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import { defaultForceLoginEmail, ForceLoginEmail } from '~/src/Model/CheckCompanyCustomer/CheckCompanyCustomer'
import AppleLoginButton from '~/components/Auth/Apple/AppleLoginButton.vue'
import { useAppleLoginPostProcess } from '~/src/Infrastructure/Auth/Apple/AppleLoginPostProcess'
import AppleMergeAccount from '~/components/Auth/Apple/AppleMergeAccount.vue'
import { useConfig } from '~/src/Infrastructure/Nuxt/Config/UseConfig'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import { useFormSentEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Form/FormSentEvent'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'
import { TwoFA, TwoFARequired } from '~/src/Model/Auth/TwoFA'
import TwoFALogin from '~/components/Auth/TwoFA/TwoFALogin.vue'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import { FacebookLoginCookieStorage } from '~/src/Infrastructure/Cookies/FacebookLoginCookies'

export default defineComponent({
  components: {
    TwoFALogin,
    AppleMergeAccount,
    AppleLoginButton,
    BaseValidationObserver,
    FlashMessageCenter,
    FacebookMergeAccount,
    GoogleLoginCustomButtonWrapper,
    GoogleMergeAccount,
    FacebookLoginButton,
    LoadingOverlay,
    FormInput,
    Button,
    Form,
    PageSectionContent
  },
  props: {
    showRegistrationLink: {
      type: Boolean,
      default: false
    },
    forceLoginState: {
      type: Object as PropType<ForceLoginEmail>,
      default: () => defaultForceLoginEmail
    },
    dataLayerFormId: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: ''
    },
    disableSocials: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-sidebar', 'show-lost-password-panel', 'logged', 'show-registration-panel', 'two-factor-verification-started', 'two-factor-verification-cancelled'],
  setup (props, { emit }) {
    const { unificate } = useVueInstanceUid()
    const { $config } = useConfig()
    const authService = useAuthService()
    const formSentEvent = useFormSentEvent()
    const error = ref<string | null>(null)
    const loginData = reactive<CustomerLoginData>({
      username: props.email || $config.defaultEmail || '',
      password: $config.defaultPassword || ''
    })
    const observer = ref()
    const twoFA = ref<TwoFA | null>(null)

    const { app } = useApp()
    const facebookLoginCookieStorage = new FacebookLoginCookieStorage(app.$cookies)

    watch(() => props.forceLoginState, (newLoginEmailState) => {
      if (!newLoginEmailState.force) {
        return
      }
      loginData.username = newLoginEmailState.email
    }, { deep: true })

    const googleLoginPostProcess = useGoogleLoginPostProcess()
    const facebookLoginPostProcess = useFacebookLoginPostProcess()
    const appleLoginPostProcess = useAppleLoginPostProcess()

    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const { exec: submitLogin, pending: pendingLogin, onSuccess: onLoggedIn } = useApiCall(
      () => authService.login(loginData),
      flashMessageCenter
    )

    onLoggedIn.subscribe(() => {
      if (props.dataLayerFormId) {
        formSentEvent.fire(props.dataLayerFormId)
      }

      if (twoFA.value) {
        return
      }

      loginData.username = $config.defaultEmail || ''
      loginData.password = $config.defaultPassword || ''

      observer.value.reset()
    })

    const onLoggedInCallback = () => {
      emit('logged')
    }

    const onTwoFaLoggedInCallback = (twoFARequired: TwoFARequired) => {
      emit('two-factor-verification-started')
      twoFA.value = twoFARequired.twoFA
    }

    authService.onLoggedIn.subscribe(onLoggedInCallback)
    authService.onTwoFALoggedIn.subscribe(onTwoFaLoggedInCallback)

    onUnmounted(() => {
      authService.onLoggedIn.unsubscribe(onLoggedInCallback)
      authService.onTwoFALoggedIn.unsubscribe(onTwoFaLoggedInCallback)
    })

    const showLoadingOverlay = computed<boolean>(() => {
      if (pendingLogin.value) {
        return true
      }

      if (googleLoginPostProcess.pending.value) {
        return true
      }

      if (facebookLoginPostProcess.pending.value) {
        return true
      }

      if (appleLoginPostProcess.pending.value) {
        return true
      }

      return false
    })

    const hideLoginSection = computed<boolean>(() => {
      if (googleLoginPostProcess.mergeAccount.value) {
        return true
      }

      if (facebookLoginPostProcess.mergeAccount.value) {
        return true
      }

      if (appleLoginPostProcess.mergeAccount.value) {
        return true
      }

      return false
    })

    const setUserByTokens = async (authTokens: AuthTokens) => {
      await authService.setUserToken(authTokens.accessToken, authTokens.refreshToken)
    }

    const accountMerged = (authTokens: AuthTokens) => {
      setUserByTokens(authTokens)
      googleLoginPostProcess.mergeAccountCompleted()
      facebookLoginPostProcess.mergeAccountCompleted()
      appleLoginPostProcess.mergeAccountCompleted()
    }

    const cancelTwoFAVerification = () => {
      twoFA.value = null
      flashMessageCenter.clear()
      emit('two-factor-verification-cancelled')
    }

    return {
      flashMessages,
      ...toRefs(loginData),
      error,
      submitLogin,
      showLoadingOverlay,
      setUserByTokens,
      facebookLoginPostProcess,
      googleLoginPostProcess,
      appleLoginPostProcess,
      hideLoginSection,
      pendingLogin,
      unificate,
      accountMerged,
      observer,
      twoFA,
      cancelTwoFAVerification,
      facebookLoginAllowed: facebookLoginCookieStorage.facebookLoginCookieAllowed
    }
  }
})
