import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class MaxFilesCountRule extends ValidationRule {
  params = ['max']
  message = (_: string, { max }: Record<string, any>): string => {
    return this.i18n.t('validations.max_files', { max }).toString()
  }

  validate = (value: Array<any>, { max }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: false
      }
    }

    return {
      valid: value.length <= max
    }
  }
}
