import { defineStore } from 'pinia'
import {
  ChristmasDeliveryConfiguration,
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import { useChristmasDeliveryConfigurationRepositoryWithAxios } from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationRepository'

const CHRISTMAS_DELIVERY_CONFIGURATION_STORE_ID = 'christmasDeliveryConfiguration'

interface ChristmasDeliveryConfigurationState {
  christmasDeliveryConfiguration: ChristmasDeliveryConfigurationItem[]
  isActiveChristmas: boolean
  dataLoaded: boolean
}

export const useChristmasDeliveryConfigurationStore = defineStore(CHRISTMAS_DELIVERY_CONFIGURATION_STORE_ID, {
  state: (): ChristmasDeliveryConfigurationState => ({
    christmasDeliveryConfiguration: [],
    isActiveChristmas: false,
    dataLoaded: false
  }),
  actions: {
    async loadConfigurations  () {
      const webConfig = this.$nuxt.$webConfig
      if (!webConfig.isActiveChristmas) {
        return
      }

      const { loadConfiguration } = useChristmasDeliveryConfigurationRepositoryWithAxios(this.$nuxt.$axios)
      const configuration = await loadConfiguration()
      this.updateConfiguration(configuration)
    },
    updateConfiguration (configuration: ChristmasDeliveryConfiguration) {
      this.christmasDeliveryConfiguration = configuration.configurations
      this.isActiveChristmas = configuration.isActiveChristmas
      this.dataLoaded = true
    }
  }
})
