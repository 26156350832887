













































import { defineComponent, ref, watch, computed } from '@nuxtjs/composition-api'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

interface PropTypes {
  value: number
  minValue: number | null
  maxValue: number | null
  disabled: boolean
}

export default defineComponent<PropTypes>({
  components: { Button },
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: true
    },
    minValue: {
      type: Number,
      default: null
    },
    maxValue: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'increase', 'decrease'],
  setup (props: PropTypes, { emit }) {
    const localValue = ref<number>(props.value as number)
    const value = computed<number>(() => props.value)

    watch(value, () => {
      localValue.value = props.value
    })

    const updateValue = () => {
      const minValue: number | null = props.minValue
      const maxValue: number | null = props.maxValue
      localValue.value = Math.round(localValue.value)

      if (minValue && minValue > localValue.value) {
        localValue.value = minValue
      }

      if (maxValue && maxValue < localValue.value) {
        localValue.value = maxValue
      }

      emit('input', localValue.value)
    }

    const disableDecreaseButton = computed<boolean>(() => {
      if (props.disabled) {
        return true
      }

      return !!(props.minValue && props.minValue === localValue.value)
    })

    const disableIncreaseButton = computed<boolean>(() => {
      if (props.disabled) {
        return true
      }

      return !!(props.maxValue && props.maxValue === localValue.value)
    })

    return {
      localValue,
      updateValue,
      disableDecreaseButton,
      disableIncreaseButton
    }
  }
})
