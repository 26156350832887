import { onMounted, ref, Ref } from '@nuxtjs/composition-api'
import { UseIntersectionObserverOptions, MaybeElement, useIntersectionObserver } from '@vueuse/core'

export const useVisibilityLazyLoad = (
  elementToObserver: Ref<MaybeElement>,
  callback: () => any,
  options?: UseIntersectionObserverOptions
) => {
  const isVisible = ref<boolean>(false)
  const { isSupported, stop } = useIntersectionObserver(elementToObserver, ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting
    if (!isIntersecting) {
      return
    }

    callback()

    if (elementToObserver.value) {
      stop()
    }
  }, options)

  onMounted(() => {
    if (!isSupported) {
      callback()
    }
  })

  return {
    isVisible
  }
}
