import { AxiosError } from 'axios'
import VueI18n from 'vue-i18n'
import { ApiResponseError } from '~/src/Model/Error/ApiResponseError'
import { ApiError } from '~/src/Infrastructure/Api/ApiError'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'

export const useApiErrorFactory = () => {
  const { app } = useApp()
  const i18n = app.i18n as VueI18n

  const apiErrorFactory = (axiosError: AxiosError<ApiResponseError>) => {
    const apiError = axiosError.response?.data?.error
    const internalErrorCode: any = apiError?.internalErrorCode ?? 0
    const message = `[${internalErrorCode}] ${apiError?.message ?? i18n.t('api_general_error')}`

    return new ApiError(
      message,
      apiError?.translatedMessage ?? i18n.t('api_general_error').toString(),
      axiosError.response?.status ? Number(axiosError.response?.status) : 500,
      internalErrorCode,
      apiError?.context ?? null,
      axiosError,
      apiError?.requestBodyValidation ?? []
    )
  }

  return {
    apiErrorFactory
  }
}
