import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'

export enum PageType {
  Product = 'product',
  Other = 'other'
}

export class PageTypeEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (pageType: PageType = PageType.Other): void {
    this.dataLayer.push({ pageType })
  }
}

export const usePageTypeEvent = () => {
  const dataLayer = useDataLayer()

  return new PageTypeEvent(dataLayer)
}
