import cloneDeep from 'lodash/cloneDeep'
import {
  Reclamation,
  ReclamationCustomerContactInfo,
  ReclamationCustomerRequestData,
  ReclamationInfoRequestData,
  ReclamationIssueInfoStepData,
  ReclamationIssueRequestData,
  ReclamationRequestData,
  ReclamationStateEnum
} from '~/src/Model/Reclamation/Reclamation'
import { PurchaseInfo } from '~/src/Model/PurchasedProduct/PurchasedProduct'

export class ReclamationAtStore implements Reclamation {
  constructor (
    private readonly _purchaseInfo: PurchaseInfo,
    private readonly _currentState: ReclamationStateEnum = ReclamationStateEnum.issueInfo,
    private readonly _issueInfoStepFormData: ReclamationIssueInfoStepData | null = null,
    private readonly _reclamationInfoData: ReclamationInfoRequestData | null = null,
    private readonly _customerContactInfo: ReclamationCustomerContactInfo | null = null
  ) {
  }

  get isHomeService () {
    return false
  }

  get currentState (): ReclamationStateEnum {
    return this._currentState
  }

  get purchaseInfo (): PurchaseInfo {
    return this._purchaseInfo
  }

  get issueInfoStepFormData (): ReclamationIssueInfoStepData | null {
    return cloneDeep(this._issueInfoStepFormData)
  }

  get reclamationInfoData (): ReclamationInfoRequestData | null {
    return this._reclamationInfoData
  }

  get customerContactInfo (): ReclamationCustomerContactInfo | null {
    return this._customerContactInfo
  }

  getReclamationRequestData (): ReclamationRequestData {
    const issue: ReclamationIssueRequestData = {
      issueDescription: this.issueInfoStepFormData!.issueDescription,
      state: this.issueInfoStepFormData!.state,
      serialNumber: this.issueInfoStepFormData!.serialNumber,
      imei: this.issueInfoStepFormData!.imei,
      photos: this.issueInfoStepFormData?.photos.map(photo => photo.id) ?? [],
      attachments: this.issueInfoStepFormData?.attachments.map(attachment => attachment.id) ?? []
    }

    const customer: ReclamationCustomerRequestData = {
      customer: this.customerContactInfo!.customer,
      storeId: this.customerContactInfo?.storeInfo?.storeId ?? null
    }

    return {
      issue,
      customer,
      info: this.reclamationInfoData,
      homeService: this.isHomeService,
      purchaseInfo: {
        ...this.purchaseInfo,
        itemId: this.purchaseInfo.itemId.toString()
      }
    }
  }

  isStateActive (state: ReclamationStateEnum): boolean {
    return this.currentState === state
  }

  nextStep (): ReclamationAtStore {
    switch (this.currentState) {
      case ReclamationStateEnum.issueInfo: {
        return new ReclamationAtStore(
          this.purchaseInfo,
          ReclamationStateEnum.reclamationData,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      case ReclamationStateEnum.reclamationData: {
        return new ReclamationAtStore(
          this.purchaseInfo,
          ReclamationStateEnum.customerInfo,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      case ReclamationStateEnum.customerInfo: {
        return new ReclamationAtStore(
          this.purchaseInfo,
          ReclamationStateEnum.summary,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      default: {
        return this
      }
    }
  }

  previousStep (): ReclamationAtStore {
    switch (this.currentState) {
      case ReclamationStateEnum.reclamationData: {
        return new ReclamationAtStore(
          this.purchaseInfo,
          ReclamationStateEnum.issueInfo,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      case ReclamationStateEnum.customerInfo: {
        return new ReclamationAtStore(
          this.purchaseInfo,
          ReclamationStateEnum.reclamationData,
          this.issueInfoStepFormData,
          this.reclamationInfoData,
          this.customerContactInfo
        )
      }
      default: {
        return this
      }
    }
  }

  updateIssueInfoStepFormData (issueInfoStepFormData: ReclamationIssueInfoStepData): ReclamationAtStore {
    return new ReclamationAtStore(
      this.purchaseInfo,
      this.currentState,
      issueInfoStepFormData,
      this.reclamationInfoData,
      this.customerContactInfo
    )
  }

  updateReclamationInfoStepData (reclamationInfoStepData: ReclamationInfoRequestData): ReclamationAtStore {
    return new ReclamationAtStore(
      this.purchaseInfo,
      this.currentState,
      this.issueInfoStepFormData,
      reclamationInfoStepData,
      this.customerContactInfo
    )
  }

  updateContactInfoStepData (customerContactInfo: ReclamationCustomerContactInfo): ReclamationAtStore {
    return new ReclamationAtStore(
      this.purchaseInfo,
      this.currentState,
      this.issueInfoStepFormData,
      this.reclamationInfoData,
      customerContactInfo
    )
  }
}
