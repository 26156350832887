import { Event } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export class EventExceptionRegex extends IgnoredEvent {
  constructor (private type: string, private value: RegExp) {
    super()
  }

  isIgnored (event: Event) {
    if (event?.exception?.values?.length === 1) {
      const e = event.exception.values[0]
      return e.value ? (e.type === this.type && this.value.test(e.value)) : false
    }
    return false
  }
}
