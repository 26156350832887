import { FilterSort, FilterSortOrder } from '~/src/Model/ProductCategory/ParameterFilter/Request'

export enum FilterSortType {
  MOST_EXPENSIVE = 'mostExpensive',
  CHEAPEST = 'cheapest',
  SOLD_COUNT = 'soldCount',
  RATING = 'rating',
  ARTICLE = 'article'
}

export const FilterSortMap: { [TKey in FilterSortType]: FilterSort } = {
  [FilterSortType.CHEAPEST]: {
    sortBy: 'prices',
    order: FilterSortOrder.ASC
  },
  [FilterSortType.MOST_EXPENSIVE]: {
    sortBy: 'prices',
    order: FilterSortOrder.DESC
  },
  [FilterSortType.SOLD_COUNT]: {
    sortBy: 'soldCount',
    order: FilterSortOrder.DESC
  },
  [FilterSortType.RATING]: {
    sortBy: 'rating',
    order: FilterSortOrder.DESC
  },
  [FilterSortType.ARTICLE]: {
    sortBy: 'article',
    order: FilterSortOrder.ASC
  }
}

export const sortTypeByFilterSort = (filterSort: FilterSort) => {
  const filterSortType: FilterSortType = DefaultFilterSortType

  for (const [sortType, filterSortInMap] of Object.entries(FilterSortMap)) {
    if (filterSort.sortBy === filterSortInMap.sortBy && filterSort.order === filterSortInMap.order) {
      return sortType as FilterSortType
    }
  }

  return filterSortType
}

export const DefaultFilterSortType = FilterSortType.SOLD_COUNT
export const DefaultFilterSort = FilterSortMap[DefaultFilterSortType]
