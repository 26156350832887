import { Event, StackFrame } from '@sentry/types'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export class EventExceptionFramesRegex extends IgnoredEvent {
  constructor (private type: string, private value: RegExp) {
    super()
  }

  isIgnored (event: Event) {
    if (event?.exception?.values?.length !== 1) {
      return false
    }
    const e = event.exception.values[0]
    return e.value ? (e.type === this.type && !!e.stacktrace?.frames?.some((f: StackFrame) => (f.filename && this.value.test(f.filename)) || (f.abs_path && this.value.test(f.abs_path)))) : false
  }
}
