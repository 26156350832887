





















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    rowCount: {
      type: Number,
      default: 1
    },
    maxWidth: {
      type: Number,
      default: null
    },
    itemClass: {
      type: String,
      default: null
    }
  }
})
