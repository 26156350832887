import { computed } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { WatchDog } from '~/src/Model/WatchDogs/WatchDogs'
import { useWatchDogsStore as useStore } from '~/stores/watch-dogs'

export const useWatchDogsStore = (pinia?: Pinia | null | undefined) => {
  const store = useStore(pinia)

  const watchDogs = computed<WatchDog[]>(() => store.watchDogs)
  const productWatchDog = (productId: number) => computed<WatchDog | null>(() => store.productWatchDog(productId))

  return {
    loadWatchdogs: store.loadWatchdogs,
    loadForProduct: store.loadForProduct,
    addWatchDog: store.addWatchDog,
    configureWatchDog: store.configureWatchDogWatchers,
    removeWatchDog: store.removeWatchDog,
    clearWatchDogs: store.clearWatchDogs,
    watchDogs,
    productWatchDog
  }
}
