import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MainNavigation } from '~/src/Model/MainNavigation/MainNavigation'
import { ServerCacheStorage } from '~/src/Infrastructure/Cache/Server/ServerCacheStorage'
import { JsonCacheStorage } from '~/src/Infrastructure/Cache/Server/JsonCacheStorage'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useMainNavigationRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    loadMainNavigation: (): Promise<MainNavigation> => $axios.$get<MainNavigation>('/main-navigation'),
    loadTopLevelNavigation: (): Promise<MainNavigation> => {
      return $axios.$get<MainNavigation>('/main-navigation', { params: { onlyFirstLevel: 1 } })
    }
  }
}

type MainNavigationRepositoryType = ReturnType<typeof useMainNavigationRepositoryWithAxios>

export const useCachedMainNavigationRepository = (
  $axios: NuxtAxiosInstance,
  cacheStorage: ServerCacheStorage
): MainNavigationRepositoryType => {
  const jsonCacheStorage = new JsonCacheStorage<MainNavigation>(cacheStorage)
  const mainNavigationRepository = useMainNavigationRepositoryWithAxios($axios)

  return {
    loadTopLevelNavigation: async () => {
      return await jsonCacheStorage.load(
        'top-level-main-navigation',
        () => mainNavigationRepository.loadTopLevelNavigation(),
        { expireSeconds: 120 }
      )
    },
    loadMainNavigation: mainNavigationRepository.loadMainNavigation
  }
}

export const useMainNavigationRepository = () => {
  const { $axios } = useAxios()

  return useMainNavigationRepositoryWithAxios($axios)
}
