import { Context, Plugin } from '@nuxt/types'
import { idleCallback } from '~/src/Infrastructure/WebAPI/IdleCallback'

const luigisBoxPlugin: Plugin = (context: Context) => {
  if (!context.$config.luigisBox.enableScript) {
    return
  }

  const trackerId = context.$webConfig.luigisBoxTrackerId
  const src = context.$webConfig.luigisBoxSrc + '?v=20231102'

  idleCallback(() => {
    const script = document.createElement('script')
    script.defer = true
    script.src = src
    script.setAttribute('data-luigisbox-tracker-id', trackerId)
    document.head.appendChild(script)
  })
}

export default luigisBoxPlugin
