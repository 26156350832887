import { Context, Plugin } from '@nuxt/types'

const initialDataLayerPlugin: Plugin = (context: Context) => {
  // @ts-ignore
  context.app.head?.script.unshift({
    hid: 'dataLayer initialize',
    innerHTML: "window.dataLayer = window.dataLayer || []; window.dataLayer.push({testVar: 'xyz'})"
  })
}

export default initialDataLayerPlugin
