import { defineStore } from 'pinia'
import { OrderCustomerInfoForm } from '~/src/Model/Order/Address/CustomerInfoForm'

const CHECKOUT_STORE_ID = 'checkout'

export interface CheckoutState {
  customerInfo: OrderCustomerInfoForm | null
  selectedDeliveryAddressId: string | null
  selectedBillingAddressId: string | null
}

export const useCheckoutStore = defineStore(CHECKOUT_STORE_ID, {
  state: (): CheckoutState => ({
    customerInfo: null,
    selectedBillingAddressId: null,
    selectedDeliveryAddressId: null
  }),
  persist: {
    key: CHECKOUT_STORE_ID
  },
  actions: {
    clearCheckoutState () {
      this.$reset()
    },
    setCustomerInfo (orderCustomerInfoForm: OrderCustomerInfoForm) {
      this.customerInfo = orderCustomerInfoForm
      this.selectedBillingAddressId = null
      this.selectedDeliveryAddressId = null
    },
    setBillingAddressId (addressId: string | null) {
      this.selectedBillingAddressId = addressId
      this.customerInfo = null
    },
    setDeliveryAddressId (addressId: string | null) {
      this.selectedDeliveryAddressId = addressId
      this.customerInfo = null
    },
    validateSchema () {
      if (!this.customerInfo) {
        return
      }

      if (![this.customerInfo.companyInfo,
        this.customerInfo.contactInfo,
        this.customerInfo.deliveryAddress,
        this.customerInfo.billingAddress
      ].every(infoPart => Object.entries(infoPart).every(([key, value]) => {
        if (key === 'email') {
          return true
        }

        return value === null || typeof value === 'string'
      }))) {
        this.clearCheckoutState()
      }
    }
  }
})
