


















































import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { useBrowserDetect } from '~/src/Infrastructure/Browser/BrowserDetect'
import { BrowserType } from '~/src/Model/Browser/BrowserType'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import FooterSocialIcon from '~/components/Footer/Components/FooterSocialIcon.vue'
import FooterSocialName from '~/components/Footer/Components/FooterSocialName.vue'

enum socialTypes {
  facebook = 'facebook',
  instagram = 'instagram',
  youtube = 'youtube',
  tiktok = 'tiktok',
}

export default defineComponent({
  components: {
    Container,
    FooterSocialIcon,
    FooterSocialName
  },
  setup () {
    const webConfig = useWebConfig()
    const isNay = computed<boolean>(() => webConfig.web === Web.Nay)
    const youtube = computed<string>(() => isNay.value ? 'https://www.youtube.com/user/nayelektrodom' : 'https://www.youtube.com/user/electroworldcz')
    const instagram = computed<string>(() => isNay.value ? 'https://www.instagram.com/nay_sk/' : 'https://instagram.com/electroworld_cz')
    const tiktok = computed<string>(() => isNay.value ? 'https://www.tiktok.com/@nay_slovensko' : 'https://www.tiktok.com/@electroworld_cz')
    const facebookFallback = computed<string>(() => isNay.value ? 'https://www.facebook.com/Elektrodom' : 'https://www.facebook.com/electroworld')
    const facebook = ref<string>(facebookFallback.value)
    const browser = useBrowserDetect()

    const socialLinkClass = 'footer-socials__link complex-link complex-link--hover-underline d-inline-block d-lg-flex flex-column flex-xl-row align-items-center justify-content-start bs-px-lg-2 bs-py-lg-1 text-center text-lg-left'

    const socials = {
      facebook: {
        id: socialTypes.facebook,
        name: 'Facebook',
        alias: '#',
        icon: 'facebook-simple'
      },
      youtube: {
        id: socialTypes.youtube,
        name: 'YouTube',
        alias: youtube.value,
        icon: 'youtube'
      },
      instagram: {
        id: socialTypes.instagram,
        name: 'Instagram',
        alias: instagram.value,
        icon: 'instagram'
      },
      tiktok: {
        id: socialTypes.tiktok,
        name: 'TikTok',
        alias: tiktok.value,
        icon: 'tiktok'
      }
    }

    onMounted(() => {
      facebook.value = getFacebookLink()
    })

    const getFacebookLink = () => {
      if (browser === BrowserType.IOS) {
        return isNay.value ? 'fb://profile/265388683512099' : 'fb://profile/311023942288608'
      } else if (browser === BrowserType.ANDROID) {
        return isNay.value ? 'fb://page/265388683512099' : 'fb://page/311023942288608'
      } else {
        return facebookFallback.value
      }
    }

    const redirectToFacebook = () => {
      if (browser === BrowserType.DESKTOP) {
        window.open(facebook.value)
      } else {
        window.open(facebook.value, '_system')
        window.open(facebookFallback.value, '_system')
      }
    }

    return {
      youtube,
      instagram,
      tiktok,
      redirectToFacebook,
      socialLinkClass,
      socials,
      socialTypes
    }
  }
})
