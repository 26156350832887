















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    standalone: {
      type: Boolean,
      default: false
    },
    standaloneMd: {
      type: Boolean,
      default: false
    },
    standaloneLg: {
      type: Boolean,
      default: false
    },
    unstyledLg: {
      type: Boolean,
      default: false
    },
    srOnly: {
      type: Boolean,
      default: false
    }
  },

  setup () {}
})
