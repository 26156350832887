import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { IVueI18n } from 'vue-i18n'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'

type currencyFormatterType = (value: number | string) => string

const webConfig: Plugin = ({ app }, inject: Inject) => {
  const i18n = app.i18n as IVueI18n

  const currencyFormatter: currencyFormatterType = (value: number | string) => {
    const priceValue = bigify(value)

    if (priceValue.eq(0)) {
      return i18n.t('freePrice').toString()
    }

    return i18n.n(Number(value), 'currency')
  }

  const currencyDecimalFormatter: currencyFormatterType = (value: number | string) => {
    return i18n.n(Number(value), 'currencyDecimal')
  }

  const currencyFormatterWithoutFallback: currencyFormatterType = (value: number | string) => {
    return i18n.n(Number(value), 'currency')
  }

  const currenyWithVatFormatter: currencyFormatterType = (value: number | string) => {
    return `${i18n.n(Number(value), 'currency')} ${i18n.t('with_vat').toString()}`
  }

  const currenyWithoutVatFormatter: currencyFormatterType = (value: number | string) => {
    return `${i18n.n(Number(value), 'currency')} ${i18n.t('without_vat').toString()}`
  }

  inject('currency', currencyFormatter)
  inject('currencyWithoutFallback', currencyFormatterWithoutFallback)
  inject('currencyDecimal', currencyDecimalFormatter)
  inject('currencyWithVat', currenyWithVatFormatter)
  inject('currencyWithoutVat', currenyWithoutVatFormatter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $currency: currencyFormatterType
    $currencyDecimal: currencyFormatterType
    $currencyWithoutFallback: currencyFormatterType
    $currencyWithVat: currencyFormatterType
    $currencyWithoutVat: currencyFormatterType
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $currency: currencyFormatterType
    $currencyDecimal: currencyFormatterType
    $currencyWithoutFallback: currencyFormatterType
    $currencyWithVat: currencyFormatterType
    $currencyWithoutVat: currencyFormatterType
  }

  interface Context {
    $currency: currencyFormatterType
    $currencyDecimal: currencyFormatterType
    $currencyWithoutFallback: currencyFormatterType
    $currencyWithVat: currencyFormatterType
    $currencyWithoutVat: currencyFormatterType
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $currency: currencyFormatterType
    $currencyDecimal: currencyFormatterType
    $currencyWithoutFallback: currencyFormatterType
    $currencyWithVat: currencyFormatterType
    $currencyWithoutVat: currencyFormatterType
  }
}

export default webConfig
