














import { defineComponent } from '@nuxtjs/composition-api'
import TextHighlighter from '~/components/TextHighlighter/TextHighlighter.vue'

export default defineComponent({
  components: { TextHighlighter },
  props: {
    alias: {
      type: String,
      default: null
    },
    quantity: {
      type: Number,
      default: null
    },
    name: {
      required: true,
      type: String
    },
    tag: {
      type: String,
      default: 'h3'
    },
    searchTerm: {
      default: '',
      type: String
    }
  },
  emits: ['route-change']
})
