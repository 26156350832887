import { computed } from '@nuxtjs/composition-api'
import { ChristmasDeliveryConfigurationItem } from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import { useChristmasDeliveryConfigurationStore as useStore } from '~/stores/christmas-delivery-configuration'

export const useChristmasDeliveryConfigurationStore = () => {
  const store = useStore()

  const christmasDeliveryConfiguration = computed<ChristmasDeliveryConfigurationItem[]>(() => store.christmasDeliveryConfiguration)
  const isActiveChristmas = computed<boolean>(() => store.isActiveChristmas)
  const dataLoaded = computed<boolean>(() => store.dataLoaded)

  return {
    christmasDeliveryConfiguration,
    isActiveChristmas,
    dataLoaded,
    loadChristmasDeliveryConfigurations: store.loadConfigurations
  }
}
