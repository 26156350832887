import { Event } from '@sentry/types'
import cloneDeep from 'lodash/cloneDeep'
import { SensitiveAttributeNames } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/SensitiveAttributeNames'
import { anonymizeDeep } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/AnonymizeDeep/AnonymizeDeep'
import { shouldBeAnonymized } from '~/src/Infrastructure/Sentry/AnonymizeSensitiveData/ShouldBeAnonymized/ShouldBeAnonymized'

export const anonymizeSensitiveData = (eventOriginal: Event, sensitiveAttributeNames: SensitiveAttributeNames): Event => {
  const event = cloneDeep(eventOriginal)

  const anonymize = shouldBeAnonymized(event)

  if (anonymize) {
    anonymizeDeep(event.contexts, sensitiveAttributeNames)
    anonymizeDeep(event.user, sensitiveAttributeNames)
  }

  return event
}
