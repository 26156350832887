export interface FilterRequestParametersSettings {
  parameters: string[]
  price: boolean
  availability: boolean
  review: boolean
  stock: boolean
  tags: boolean
  topCategories: boolean
}

export enum ParameterConditionLogic {
  AND = 'AND',
  OR = 'OR'
}

export interface FilterQueryParameter {
  alias: string
  values: string[]
}

export interface FilterQueryReview {
  from: number
  to: number
}

export interface PriceIntervalRange {
  from: number
  to: number
}

export interface FilterQueryPrice {
  level: string
  intervals: PriceIntervalRange[]
}

export interface FilterPaging {
  limit: number
  page: number
}

export enum FilterSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface FilterSort {
  sortBy: string
  order: FilterSortOrder
}

export interface FilterSortSettings {
  filterSort: FilterSort
  defaultFilterSort: FilterSort
}

export type FilterQueryStocks = string[]

export type FilterQueryAvailability = string[]

export type FilterQueryTags = string[]

export type FilterQueryTopCategories = number[]

export interface FilterQuery {
  parameters: FilterQueryParameter[]
  price: FilterQueryPrice
  availability: FilterQueryAvailability
  category: number | null
  article: number | null
  stocks: FilterQueryStocks
  review: FilterQueryReview | null
  sort: FilterSort
  paging: FilterPaging
  tags: FilterQueryTags
  storeMode: boolean
  topCategories: FilterQueryTopCategories
}

export interface SeoAliasQuery {
  alias: string
  priceLevel: string
  category: number
  paging: FilterPaging | null
  storeMode: boolean
}

export interface SimpleFilterQuery {
  parameters: FilterQueryParameter[]
  categories: number[]
  paging: FilterPaging | null
  storeMode: boolean
}

export enum ClientType {
  eshop = 'eshop',
  tool = 'tool'
}

export interface FilterRequest {
  parameterSettings: FilterRequestParametersSettings
  excludeAvailabilities: string[]
  client: ClientType
  query: FilterQuery | null
  seoAlias: SeoAliasQuery | null
}

export interface FilterProductsRequest {
  client: ClientType
  query: SimpleFilterQuery
}

export interface CreateSeoAliasRequest {
  data: string
  seoUrl: string
  metaTitle: string
  heading: string
  metaDescription: string
  annotation: string
}

export interface CreateSeoAliasResponse {
  pdpUrl: string
}

export interface CreateSeoAliasErrorContext {
  seoAlias: boolean
  seoAliasPdpAdministration: string
  url: string
}

export const defaultSeoAliasRequest = (): CreateSeoAliasRequest => {
  return {
    data: '',
    seoUrl: '',
    metaTitle: '',
    heading: '',
    metaDescription: '',
    annotation: ''
  }
}

export const defaultPaging = (): FilterPaging => {
  return {
    limit: 18,
    page: 1
  }
}

export const defaultParameterSettings = (): FilterRequestParametersSettings => {
  return {
    parameters: [],
    availability: true,
    price: true,
    tags: true,
    stock: true,
    review: false,
    topCategories: true
  }
}
