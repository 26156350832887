
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ExpressPickupStores } from '~/src/Model/ExpressPickup/ExpressPickup'

export default defineComponent({
  props: {
    showDescriptionSection: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    expressPickupStores: {
      default: null,
      type: Object as PropType<ExpressPickupStores | null>
    }
  }
})
