import { onMounted, Ref } from '@nuxtjs/composition-api'

type ResizeObserverCallback = (entries: ReadonlyArray<ResizeObserverEntry>, observer?: ResizeObserver) => void

export const useResizeObserver = (
  elementToObserve: Ref<Vue | HTMLElement | Element | undefined>,
  callback: ResizeObserverCallback
) => {
  onMounted(() => {
    const isSupported = window && 'ResizeObserver' in window
    if (isSupported && elementToObserve.value) {
      const resizeObserver = new window.ResizeObserver((entries: any) => {
        // We wrap it in requestAnimationFrame to avoid error ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          callback(entries)
        })
      })
      const element: Element = (<Vue>elementToObserve.value).$el ?? elementToObserve.value
      resizeObserver.observe(element)
    }
  })
}
