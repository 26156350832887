


























































import { defineComponent, ref, reactive, toRefs } from '@nuxtjs/composition-api'
import VueI18n from 'vue-i18n'
import { useApp } from '~/src/Infrastructure/Nuxt/App/UseApp'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import InvisibleRecaptcha from '~/components/Forms/Recaptcha/InvisibleRecaptcha.vue'
import { ShareByEmail } from '~/src/Model/Share/ShareByEmail'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { useShareRepository } from '~/src/Infrastructure/Share/ShareRepository'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import FormTextarea from '~/components/DesignSystem/Input/FormTextarea.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    BaseValidationObserver,
    InvisibleRecaptcha,
    FormInput,
    FormTextarea,
    Button,
    Alert,
    Modal,
    PageSectionContent
  },
  emits: ['close-modal'],
  setup () {
    const { shareByEmail } = useShareRepository()
    const { authenticatedUser } = useAuthenticatedUser()
    const { app } = useApp()
    const i18n = app.i18n as VueI18n

    const modal = ref<any>()

    const isSubmitted = ref<boolean>(false)
    const errorMessage = ref<string | null>(null)

    const request = reactive<ShareByEmail>({
      emailTo: '',
      fullName: authenticatedUser.value ? `${authenticatedUser.value.firstName} ${authenticatedUser.value.lastName}` : '',
      message: `${i18n.t('share.recommend')}\n${document.URL}`,
      recaptchaToken: ''
    })

    const send = () => {
      isSubmitted.value = true
    }

    const recaptchaSuccess = (token: string) => {
      errorMessage.value = null
      request.recaptchaToken = token

      shareByEmail(request).then(modal.value?.hide)
      isSubmitted.value = false
    }

    const recaptchaExpired = () => {
      errorMessage.value = i18n.t('b2b_contact.form.error_recaptcha') as string
      isSubmitted.value = false
    }

    const recaptchaError = () => {
      errorMessage.value = i18n.t('b2b_contact.form.error_recaptcha') as string
      isSubmitted.value = false
    }

    return {
      ...toRefs(request),
      modal,
      isSubmitted,
      errorMessage,
      send,
      recaptchaSuccess,
      recaptchaExpired,
      recaptchaError
    }
  }
})
