import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { VisitedProductEvent } from '~/src/Model/VisitedProducts/VisitedProductEvent'
import { VisitedProductsResponse } from '~/src/Model/VisitedProducts/VisitedProducts'

export const useVisitedProductsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    addProduct: (visitedProductEvent: VisitedProductEvent): Promise<void> => {
      return $axios.$post<void>('/events/visited-product', visitedProductEvent)
    },
    getVisitedProducts: (listId: string, page: number = 1, limit: number = 10): Promise<VisitedProductsResponse> => {
      return $axios.$get<VisitedProductsResponse>(
        '/visited-products',
        {
          params: { listId, page, limit }
        })
    }
  }
}
