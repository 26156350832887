











import { defineComponent } from '@nuxtjs/composition-api'
import { AlertVariant } from '~/src/Model/Alert/AlertVariant'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: { Alert },
  props: {
    variant: {
      type: String,
      default: 'info'
    }
  },
  setup () {
    return {
      AlertVariant
    }
  }
})
