
























































import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import { useVueInstanceUid } from '~/src/Infrastructure/Vue/VueInstanceUid'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import SuccessConfirm from '~/components/SuccessConfirm/SuccessConfirm.vue'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import InvisibleRecaptcha from '~/components/Forms/Recaptcha/InvisibleRecaptcha.vue'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { RecoveryEmailRequest } from '~/src/Model/RecoveryEmail/RecoveryEmail'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'

export default defineComponent({
  components: {
    BaseValidationObserver,
    FlashMessageCenter,
    LoadingOverlay,
    SuccessConfirm,
    InvisibleRecaptcha,
    FormInput,
    Button,
    Form,
    PageSectionContent
  },
  props: {
    inSidebar: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: null
    }
  },
  emits: ['closeSubSidebar'],
  setup () {
    const { unificate } = useVueInstanceUid()
    const customerEmail = ref<string>('')
    const emailSend = ref<boolean>(false)
    const isSubmitted = ref<boolean>(false)
    const { i18n } = useTranslator()
    const { sendRecoveryEmail } = useCustomerAuthRepository()
    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()

    const { onSuccess, exec, pending } = useApiCall((request: RecoveryEmailRequest) => sendRecoveryEmail(request), flashMessageCenter)

    onSuccess.subscribe(() => {
      emailSend.value = true
    })

    const submitForm = () => {
      isSubmitted.value = true
    }

    const recaptchaSuccess = (recaptchaToken: string) => {
      flashMessageCenter.clear()
      exec({
        email: customerEmail.value,
        recaptchaToken
      })
      isSubmitted.value = false
    }

    const recaptchaError = () => {
      flashMessageCenter.error(i18n.t('sendRecoveryEmail.recaptcha_error') as string)
      isSubmitted.value = false
    }

    const recaptchaExpired = () => {
      flashMessageCenter.error(i18n.t('sendRecoveryEmail.recaptcha_expired') as string)
      isSubmitted.value = false
    }

    return {
      flashMessages,
      customerEmail,
      submitForm,
      emailSend,
      pending,
      unificate,
      recaptchaSuccess,
      recaptchaError,
      recaptchaExpired,
      isSubmitted
    }
  }
})
