














import { computed, defineComponent, useMeta } from '@nuxtjs/composition-api'
import { useSrcset } from '~/src/Infrastructure/Image/Srcset'
import { createConfig } from '~/src/Infrastructure/Image/ConfigCreator'
import ImageDimensionsCalculator from '~/src/Infrastructure/Image/ImageDimensionCalculator'
import { ImageDimensions } from '~/src/Model/Image/ImageDimensions'

export default defineComponent({
  props: {
    imageId: {
      type: Number,
      required: true
    },
    extension: {
      type: String,
      required: true
    },
    imageSize: {
      type: String,
      required: true
    },
    origHeight: {
      type: Number,
      required: true
    },
    origWidth: {
      type: Number,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    },
    loading: {
      type: String,
      required: false,
      default: null
    },
    srcsetName: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    },
    fetchpriority: {
      type: String,
      default: null
    }
  },

  setup (props) {
    const srcSet = useSrcset()

    const src = computed<string>(() => {
      return srcSet.createURL(props.imageId, props.extension, props.imageSize)
    })

    const srcset = computed<string>(() => {
      return srcSet.generate(props.origWidth, props.origHeight, props.imageSize, props.imageId, props.extension, props.srcsetName)
    })

    const srcImageDimensions = computed<ImageDimensions>(() => {
      const config = createConfig(props.imageSize)

      return ImageDimensionsCalculator.calculateDimensions(props.origWidth, props.origHeight, config)
    })

    if (props.preload) {
      useMeta(() => {
        return {
          link: [
            {
              rel: 'preload',
              as: 'image',
              href: src.value,
              imagesrcset: srcset.value,
              imagesizes: props.sizes
            }
          ]
        }
      })
    }

    return { src, srcImageDimensions, srcset }
  },
  head: {}
})
