











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Badge',
  props: {
    tag: {
      type: String,
      default: undefined
    },
    variant: {
      type: String,
      default: 'blue'
    },
    pill: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: undefined
    }
  }
})
