export const mergeArrays = <T extends object | any, K extends keyof T>
  (
    primaryArr: T[],
    secondaryArr: T[],
    attributesToCompare?: T extends object ? K | K[] : undefined
  )
  : T[] => {
  if (typeof primaryArr[0] === 'object') {
    let objectAttributes: K | K[] = (attributesToCompare as unknown) as K | K[]

    if (!objectAttributes) {
      objectAttributes = Object.keys(primaryArr[0] as object) as K[]
    }

    const attributes = Array.isArray(objectAttributes) ? objectAttributes : [objectAttributes]

    const filteredSecondaryArr = secondaryArr.filter((secondaryElement) => {
      for (const primaryElement of primaryArr) {
        if (attributes.every(attribute => primaryElement[attribute] === secondaryElement[attribute])) {
          return false
        }
      }
      return true
    })
    const merged = [...primaryArr, ...filteredSecondaryArr]

    return merged
  } else {
    return [...new Set([...primaryArr, ...secondaryArr])]
  }
}
