






import { defineComponent, PropType } from '@nuxtjs/composition-api'
import WishListItem from '~/components/WishLists/WishListItem.vue'
import { WishListDetail } from '~/src/Model/WishLists/WishLists'

export default defineComponent({
  components: { WishListItem },
  props: {
    wishListDetail: {
      required: true,
      type: Object as PropType<WishListDetail | null>
    }
  },
  emits: ['remove-item']
})
