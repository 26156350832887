















import { defineComponent } from '@nuxtjs/composition-api'
import Container from '~/components/DesignSystem/Grid/Container.vue'
import AppList from '~/components/AppList/AppList.vue'

export default defineComponent({
  components: { Container, AppList },
  props: {
    black: {
      type: Boolean,
      default: false
    },
    hideAnnotation: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
})
