






















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { FlashMessage } from '~/src/Model/FlashMessage/FlashMessage'
import { FlashMessageType } from '~/src/Model/FlashMessage/FlashMessageType'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: { Alert },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array as PropType<FlashMessage[]>,
      required: true
    }
  },
  setup () {
    return {
      FlashMessageType
    }
  }
})
