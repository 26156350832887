import { computed } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { WishList } from '~/src/Model/WishLists/WishLists'
import { useWishListsStore as useStore } from '~/stores/wish-lists'

export const useWishListsStore = (pinia?: Pinia | null | undefined) => {
  const store = useStore(pinia)

  const wishLists = computed<WishList[]>(() => store.wishLists)
  const wishListsCount = computed<number>(() => store.wishLists.length)
  const wishListsItemsCount = computed<number>(() => store.wishListsItemsCount)

  const wishListsByProductId = (productId: number) => computed<string[]>(() => store.wishListsByProductId(productId))

  return {
    loadWishLists: store.loadWishLists,
    clearWishLists: store.clearWishLists,
    addWishList: store.addWishList,
    removeWishList: store.removeWishList,
    addProduct: store.addProduct,
    removeProduct: store.removeProduct,
    removeItems: store.removeItems,
    wishLists,
    wishListsCount,
    wishListsItemsCount,
    wishListsByProductId
  }
}
