import { Context } from '@nuxt/types/app'
import { AxiosError } from 'axios'
import { checkPagePermission } from '~/src/Infrastructure/Acl/AclCheckPagePermission'
import { AclPermissionResponse } from '~/src/Model/Acl/AclPermission'

export default async function ({ route, $axios, error, $webConfig }: Context) {
  const throwError = () => {
    error({ statusCode: 401, message: 'Permission denied' })
  }

  try {
    const response: AclPermissionResponse | void = await checkPagePermission(route, $axios, $webConfig.web)
    if (!response?.isAllowed) {
      throwError()
    }
  } catch (e) {
    if ((e as AxiosError).response?.status === 401) {
      throwError()
    }
  }
}
