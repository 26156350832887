


















































































import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watchEffect } from '@nuxtjs/composition-api'
import { GalleryItemValue } from '~/src/Model/ProductDetail/ProductDetailGallery'
import { useGalleryHelpers } from '~/src/Infrastructure/Gallery/GalleryHelpers'
import { Image } from '~/src/Model/Image/Image'
import { ImageConfiguration, ObjectFit } from '~/src/Model/Image/ImageConfiguration'
import ImageDimensionCalculator from '~/src/Infrastructure/Image/ImageDimensionCalculator'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'
import Picture from '~/components/Picture/Picture.vue'
import Source from '~/components/Source/Source.vue'
import Embed from '~/components/DesignSystem/Embed/Embed.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'

export default defineComponent({
  components: {
    Embed,
    Source,
    Picture,
    ImageWithFallback,
    Modal
  },
  props: {
    items: {
      type: Array as PropType<GalleryItemValue[]>,
      required: true
    },
    selectedSlide: {
      type: Number,
      required: true
    }
  },
  emits: ['close-modal'],
  setup (props) {
    const { isVideo } = useGalleryHelpers()

    const selected = ref<number>(props.selectedSlide)

    onMounted(() => {
      document.addEventListener('keydown', switchSlides)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', switchSlides)
    })

    const switchSlides = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          selected.value = selected.value === 0 ? props.items.length - 1 : selected.value - 1
          break
        case 'ArrowRight':
          selected.value = selected.value === props.items.length - 1 ? 0 : selected.value + 1
          break
      }
    }

    const galleryCarousel = ref<Vue|undefined>()
    const galleryCarouselInner = computed<Element|null>(() => galleryCarousel.value?.$el.querySelector('.carousel-inner') ?? null)
    const availableWidth = ref<number>(0)
    const availableHeight = ref<number>(0)

    watchEffect(() => {
      if (galleryCarouselInner.value === null) {
        return
      }

      if (window && 'ResizeObserver' in window) {
        // TODO: Nelze použít useResizeObserver, protože ten využívá onMounted, ale v onMounted ještě kvůli modálu
        // neexistuje v DOM element galerie. Proto využívám watchEffect a ne useResizeObserver.
        const resizeObserver = new window.ResizeObserver((entries) => {
          window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
              return
            }
            const target = entries[0].target as HTMLElement

            availableWidth.value = target.clientWidth
            availableHeight.value = target.clientHeight
          })
        })
        resizeObserver.observe(galleryCarouselInner.value)
      } else {
        availableWidth.value = galleryCarouselInner.value.clientWidth
        availableHeight.value = galleryCarouselInner.value.clientHeight
      }
    }, {
      flush: 'post'
    })

    const configuration = computed<ImageConfiguration>(() => {
      return {
        width: availableWidth.value,
        height: availableHeight.value,
        fixedRatio: false,
        retina: false,
        objectFit: ObjectFit.scaleDown
      }
    })

    const getSizes = (imageData: Image): string | null => {
      if (!availableWidth.value || !availableHeight.value) {
        return null
      }

      return ImageDimensionCalculator.calculateDimensions(imageData.width, imageData.height, configuration.value).width + 'px'
    }

    return {
      galleryCarousel,
      selected,
      getSizes,
      isVideo
    }
  }
})
