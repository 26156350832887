var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-alert',{class:[
    _vm.size ? ("alert-" + _vm.size) : '',
    {'position-relative alert--arrow alert--arrow-bottom' : _vm.arrowBottom},
    { 'd-flex': !_vm.hideIcon },
    {'text-white': _vm.reverseCharge},
    _vm.arrowPosition ? ("alert--arrow-position-" + _vm.arrowPosition) : '',
    _vm.flexAlign
  ],attrs:{"show":"","variant":_vm.variant,"data-test":("alert-" + _vm.variant)}},[(!_vm.hideIcon)?[_c('i',{class:[_vm.variantIcon, _vm.iconCustomClass, 'alert__icon position-relative', _vm.size === 'sm' ? 'bs-mr-2' : 'bs-mr-3'],attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('div',[_vm._t("default")],2)]:[_vm._t("default")],_vm._v(" "),_vm._t("append")],2)}
var staticRenderFns = []

export { render, staticRenderFns }