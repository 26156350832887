








import { defineComponent } from '@nuxtjs/composition-api'
import LogoImage from '~/components/Header/Logo/LogoImage.vue'

export default defineComponent({
  components: {
    LogoImage
  }
})
