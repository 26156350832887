import { computed } from '@nuxtjs/composition-api'
import { useVirtualPageViewEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/VirtualPageView/VirtualPageViewEvent'
import { PageType, usePageTypeEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/PageType/PageTypeEvent'
import { useDataLayerStore as useStore } from '~/stores/data-layer'

export const useDataLayerStore = () => {
  const store = useStore()
  const virtualPageViewEvent = useVirtualPageViewEvent()
  const pageTypeDataLayerEvent = usePageTypeEvent()

  const shouldFireVirtualPageViewEvent = computed<boolean>(() => store.shouldFireVirtualPageViewEvent)
  const previousLayoutName = computed<string | null>(() => store.previousLayoutName)

  const updatePreviousLayoutName = (layoutName: string) => {
    store.updatePreviousLayoutName(layoutName)
  }

  const fireVirtualPageViewEvent = (pageType: PageType = PageType.Other) => {
    if (shouldFireVirtualPageViewEvent.value) {
      pageTypeDataLayerEvent.fire(pageType)
      virtualPageViewEvent.fire()
      store.disableVirtualPageViewEvent()
    }
  }

  return {
    shouldFireVirtualPageViewEvent,
    previousLayoutName,
    updatePreviousLayoutName,
    fireVirtualPageViewEvent,
    enableVirtualPageViewEvent: store.enableVirtualPageViewEvent,
    disableVirtualPageViewEvent: store.disableVirtualPageViewEvent
  }
}
