import { ContentEditorBlock } from '~/src/Model/ContentEditor/ContentEditor'
import {
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import { CookieExpiration } from '~/src/Model/Cookies/CookieExpiration'

export interface AnnouncementStyle {
  backgroundColor: string | null
  textColor: string
}

export interface StandardAnnouncementContent {
  blocks: ContentEditorBlock[]
  style: AnnouncementStyle
}

export interface ChristmasAnnouncementContent extends StandardAnnouncementContent {
  deliveryConfigurations: ChristmasDeliveryConfigurationItem[]
}

export type AnnouncementContent = StandardAnnouncementContent | ChristmasAnnouncementContent

export enum LinkTarget {
  BLANK = 'blank',
  SELF = 'self',
  MODAL = 'modal'
}

export interface AnnouncementLink {
  text: string
  url: string
  style: AnnouncementStyle
  target: LinkTarget
}

export interface CloseButton {
  style: AnnouncementStyle
  visible: boolean
  cookieExpiration: CookieExpiration
}

export enum AnnouncementType {
  STANDARD = 'standard',
  SPECIAL = 'special',
  CHRISTMAS = 'christmas'
}

export interface Announcement {
  id: number
  cookieKey: string
  link: AnnouncementLink
  closeButtonStyle: AnnouncementStyle
  icon: string
  iconColor: string
  closeButton: CloseButton
  type: AnnouncementType
  content: AnnouncementContent
}

export interface TypedAnnouncement<T> extends Omit<Announcement, 'content'> {
  content: T
}

export interface Announcements {
  announcements: Announcement[]
}
