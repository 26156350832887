export type QueryParameters = Record<string, string>

export const createFullPath = (originalPath: string, queries?: QueryParameters) => {
  if (originalPath === '') {
    return '/'
  }

  let fullPath = originalPath.trim()

  if (!fullPath.startsWith('/')) {
    fullPath = '/' + fullPath
  }

  if (!queries) { return fullPath }

  const urlSearchParams = new URLSearchParams(queries)
  const queryString = urlSearchParams.toString()
  fullPath += queryString ? '?' + queryString : ''

  return fullPath
}
