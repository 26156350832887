









import { defineComponent } from '@nuxtjs/composition-api'
import ImageWithFallback from '~/components/Image/ImageWithFallback.vue'

export default defineComponent({
  components: { ImageWithFallback },
  props: {
    image: {
      default: null,
      type: Object
    },
    imageSize: {
      default: 'product-w100h100',
      type: String
    },
    name: {
      default: null,
      type: String
    }
  }
})
