import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class StartsWithRule extends ValidationRule {
  params = ['startingString']

  message = (field: string, { startingString }: Record<string, any>) => this.i18n.t('validations.starts_with', { field, startingString }).toString()

  validate = (value: string | null, { startingString }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const valid = value.startsWith(startingString)

    return {
      valid
    }
  };
}
