const middleware = {}

middleware['AclMiddleware'] = require('../middleware/AclMiddleware.ts')
middleware['AclMiddleware'] = middleware['AclMiddleware'].default || middleware['AclMiddleware']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['BlackFridayRestrictionMiddleware'] = require('../middleware/BlackFridayRestrictionMiddleware.ts')
middleware['BlackFridayRestrictionMiddleware'] = middleware['BlackFridayRestrictionMiddleware'].default || middleware['BlackFridayRestrictionMiddleware']

middleware['DataLayerUrlChangedMiddleware'] = require('../middleware/DataLayerUrlChangedMiddleware.ts')
middleware['DataLayerUrlChangedMiddleware'] = middleware['DataLayerUrlChangedMiddleware'].default || middleware['DataLayerUrlChangedMiddleware']

middleware['ReclamationMiddleware'] = require('../middleware/ReclamationMiddleware.ts')
middleware['ReclamationMiddleware'] = middleware['ReclamationMiddleware'].default || middleware['ReclamationMiddleware']

middleware['RedirectMiddleware'] = require('../middleware/RedirectMiddleware.ts')
middleware['RedirectMiddleware'] = middleware['RedirectMiddleware'].default || middleware['RedirectMiddleware']

middleware['TranslateOldFilterQueryMiddleware'] = require('../middleware/TranslateOldFilterQueryMiddleware.ts')
middleware['TranslateOldFilterQueryMiddleware'] = middleware['TranslateOldFilterQueryMiddleware'].default || middleware['TranslateOldFilterQueryMiddleware']

middleware['UnauthorizedMiddleware'] = require('../middleware/UnauthorizedMiddleware.ts')
middleware['UnauthorizedMiddleware'] = middleware['UnauthorizedMiddleware'].default || middleware['UnauthorizedMiddleware']

export default middleware
