var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",class:[
    'form-radio custom-control',
    _vm.isSwitch ? 'custom-switch' : 'custom-radio',
    {'custom-radio--indicator-center' : _vm.isCentered},
    {'custom-radio--complex' : _vm.isComplex},
    {'custom-radio--complex-sm' : _vm.isComplex && _vm.small},
    {'custom-radio--hover' : _vm.hasHover},
    {'custom-radio--border' : _vm.hasBorder},
    {'radio-vertical-progress' : _vm.isRadioProgress},
    {'radio-vertical-progress--last' : _vm.isRadioProgressLast}
  ]},[_vm._t("input",function(){return [_c('input',{staticClass:"custom-control-input",attrs:{"id":_vm.inputName,"name":_vm.name,"disabled":_vm.disabled,"type":"radio"},domProps:{"value":_vm.inputValue,"checked":_vm.checked},on:{"change":function($event){return _vm.$emit('input', _vm.optionValue)},"click":function($event){return _vm.$emit('click', _vm.optionValue)}}})]}),_vm._v(" "),(_vm.isSwitch)?_c('label',{class:[
      'custom-control-label',
      {'w-100' : _vm.full},
      {'align-items-center' : _vm.isCentered},
      _vm.labelClass
    ],attrs:{"for":_vm.inputName}},[_vm._t("default")],2):_c('div',{class:[
      _vm.wrapClass,
      'custom-control-wrap',
      {'radio-vertical-progress__step radio-vertical-progress__step--start' : _vm.isRadioProgress},
      {'radio-vertical-progress__step--end' : _vm.isRadioProgressLast}
    ]},[_c('label',{class:[
        'custom-control-label',
        {'w-100' : _vm.full},
        {'align-items-center' : _vm.isCentered},
        _vm.labelClass
      ],attrs:{"for":_vm.inputName}},[_vm._t("default")],2),_vm._v(" "),_vm._t("description-hover")],2),_vm._v(" "),_vm._t("description-no-hover")],2)}
var staticRenderFns = []

export { render, staticRenderFns }