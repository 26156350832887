import { Plugin } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { cs, sk, enUS } from 'date-fns/locale'
import { IVueI18n } from 'vue-i18n'
import Formatter from '~/src/Infrastructure/Date/DateFormatter'

const dateFormatter: Plugin = ({ app }, inject: Inject) => {
  const i18n = app.i18n as IVueI18n
  const currentLocale = i18n.locale as string
  const locale = currentLocale === 'cs' ? cs : (currentLocale === 'sk' ? sk : enUS)

  const formatter: Formatter = new Formatter('yyyy-MM-dd HH:mm:ss', locale)

  inject('formatter', formatter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $formatter: Formatter
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $formatter: Formatter
  }

  interface Context {
    $formatter: Formatter
  }
}

declare module 'vuex/types/index' {
  interface Store<S> { // eslint-disable-line @typescript-eslint/no-unused-vars,no-unused-vars
    $formatter: Formatter
  }
}

export default dateFormatter
