






import { defineComponent } from '@nuxtjs/composition-api'
import Row from '~/components/DesignSystem/Grid/Row.vue'

export default defineComponent({
  components: { Row }
})
