




















































































































import { defineComponent } from '@nuxtjs/composition-api'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export default defineComponent({
  props: {
    isDigitalProduct: {
      required: true,
      type: Boolean
    },
    isGiftVoucher: {
      required: true,
      type: Boolean
    },
    isSaleEnded: {
      type: Boolean,
      default: false
    },
    isNotSaleSeparately: {
      type: Boolean,
      default: false
    },
    isUnavailable: {
      type: Boolean,
      default: false
    },
    isBlackFridayCountdown: {
      type: Boolean,
      default: false
    },
    isExposedProduct: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const { isUserAuthenticated } = useAuthenticatedUser()

    return {
      isUserAuthenticated
    }
  }
})
