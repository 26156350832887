































import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'right'
    },
    title: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: false
    },
    reduced: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    sidebarClass: {
      type: String,
      default: null
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: null
    },
    footerClass: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    }
  },
  emits: ['hidden'],
  setup (props) {
    const localShow = ref(false)

    onMounted(() => {
      localShow.value = props.show ?? false
    })

    return {
      localShow
    }
  }
})

