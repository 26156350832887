import { DataLayer, useDataLayer } from '~/src/Infrastructure/Google/TagManager/DataLayer/DataLayer'
import { BreadCrumb } from '~/src/Model/BreadCrumb/BreadCrumb'

export class BreadCrumbsChangedEvent {
  constructor (
    private readonly dataLayer: DataLayer
  ) {
  }

  public fire (breadCrumbs: BreadCrumb[]): void {
    this.dataLayer.push({
      bread_crumbs: [...breadCrumbs].slice(1).map(b => b.title)
    })
  }
}

export const useBreadCrumbsChangedEvent = () => {
  const dataLayer = useDataLayer()

  return new BreadCrumbsChangedEvent(dataLayer)
}
