import { onMounted } from '@nuxtjs/composition-api'

export const useScrollbarWidthOnMounted = () => {
  const setScrollbarWidth = () => {
    const isSupported = window && 'ResizeObserver' in window
    if (isSupported) {
      const element = document.createElement('div')
      const varName = '--scrollbar-width'
      let width

      element.style.position = 'absolute'
      element.style.top = '0px'
      element.style.left = '0px'
      element.style.visibility = 'hidden'
      element.style.width = '200px'
      element.style.height = '150px'
      element.style.overflow = 'scroll'

      document.body.appendChild(element)

      width = element.offsetWidth - element.clientWidth
      document.documentElement.style.setProperty(varName, `${width}px`)

      const observer = new window.ResizeObserver(() => {
        width = element.offsetWidth - element.clientWidth
        document.documentElement.style.setProperty(varName, `${width}px`)
      })
      observer.observe(element)
    }
  }

  onMounted(() => {
    setScrollbarWidth()
  })
}
