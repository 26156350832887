import {
  EcommerceAdditionalInfo,
  EcommerceEvent
} from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/EcommerceEvent'
import { ProductDataLayerData } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Product/ProductDataLayerData'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export class EcommerceEventDataFactory {
  constructor (
    private readonly currencyCode: string
  ) {
  }

  create (
    name: string,
    ecommerceAdditionalInfo: EcommerceAdditionalInfo | null = null,
    ...items: ProductDataLayerData[]
  ): EcommerceEvent {
    let ecommerceData: EcommerceEvent = {
      event: name,
      ecommerce: {
        currency: this.currencyCode,
        items
      }
    }

    if (ecommerceAdditionalInfo) {
      ecommerceData = {
        ...ecommerceData,
        ...ecommerceAdditionalInfo
      }
    }

    return ecommerceData
  }
}

export const useEcommerceEventDataFactory = () => {
  const webConfig = useWebConfig()

  return new EcommerceEventDataFactory(webConfig.currency)
}
