import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AclPermissionRequest, AclPermissionResponse } from '~/src/Model/Acl/AclPermission'

export const useAclRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    evaluatePermission: (request: AclPermissionRequest, showProgress: boolean = false): Promise<AclPermissionResponse> => {
      return $axios.$post<AclPermissionResponse>('/access-control/evaluate-permission', request, { progress: showProgress })
    }
  }
}
