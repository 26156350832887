import { Event } from '@sentry/types'
import { Breadcrumb } from '@sentry/types/types/breadcrumb'
import { IgnoredEvent } from '~/plugins/sentry/events/ignoredEvent'

export class EventErrorCodeFromUrlException extends IgnoredEvent {
  constructor (private urlFragment: string, private errorCode: number) {
    super()
  }

  isIgnored (event: Event) {
    if (event?.exception?.values?.length !== 1) {
      return false
    }

    return !!event.breadcrumbs?.some((b: Breadcrumb) => b.data?.status_code === this.errorCode && b.data?.url?.includes(this.urlFragment))
  }
}
