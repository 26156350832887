import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { DeliveryDays } from '~/src/Model/ComfortDelivery/DeliveryDays'
import { ComfortDeliveryReservationRequest } from '~/src/Model/ComfortDelivery/Reservation'
import { BasketInfo } from '~/src/Model/Basket/BasketInfo'
import { OrderSlotSelectRequest } from '~/src/Model/ComfortDelivery/OrderSlotSelect'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useComfortDeliveryRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    getDeliveryDays: (basketHash: string, zip: string): Promise<DeliveryDays> => {
      return $axios.$get<DeliveryDays>(`/comfort-delivery/basket/available-timeslots/${basketHash}/${zip}`)
    },
    reserve: (request: ComfortDeliveryReservationRequest): Promise<BasketInfo> => {
      return $axios.$post<BasketInfo>('/comfort-delivery/basket/set-delivery-method', request)
    },
    getDeliveryDaysForOrder: (token: string): Promise<DeliveryDays> => {
      return $axios.$get<DeliveryDays>(`/comfort-delivery/order/available-timeslots/${token}`)
    },
    select: (request: OrderSlotSelectRequest): Promise<void> => {
      return $axios.$post<void>('/comfort-delivery/order/select-time-slot', request)
    },
    clearTimeSlot: (basketHash: string): Promise<BasketInfo> => {
      return $axios.$delete<BasketInfo>(`/comfort-delivery/basket/${basketHash}/reserved-timeslot`)
    }
  }
}

export const useComfortDeliveryRepository = () => {
  const { $axios } = useAxios()

  return useComfortDeliveryRepositoryWithAxios($axios)
}
