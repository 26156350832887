import { Inject } from '@nuxt/types/app'
import { Plugin } from '@nuxt/types'
import { ComputedRef, WritableComputedRef } from '@nuxtjs/composition-api'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'
import { AuthStorage } from '~/src/Infrastructure/Auth/AuthStorage'
import { useCustomerAuthRepositoryWithAxios } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { AuthTokens } from '~/src/Model/Auth/AuthTokens'
import { TwoFARequired } from '~/src/Model/Auth/TwoFA'
import { CustomerLoginData } from '~/src/Model/Customer/CustomerLoginData'

export interface Auth {
  user: WritableComputedRef<AuthenticatedUser | null>
  loggedIn: ComputedRef<boolean>
  fetchUser: () => Promise<AuthenticatedUser | void>
  login: (loginData: CustomerLoginData) => Promise<AuthTokens | TwoFARequired>
  logout: () => Promise<void>
  setUserToken: (token: string, refreshToken: string) => Promise<AuthenticatedUser | null>
  refreshTokens: () => Promise<void>
  reevaluateLoginState: () => Promise<boolean>
}

const auth: Plugin = async ({ $cookies, $pinia, $axios }, inject: Inject) => {
  const storage = new AuthStorage($cookies, $pinia)
  const { login: loginUser, revoke, refresh } = useCustomerAuthRepositoryWithAxios($axios)

  let refreshPromise: Promise<AuthTokens> | null = null

  const setUserByTokens = async (authTokens: AuthTokens): Promise<AuthenticatedUser | null> => {
    storage.setUser(authTokens)

    return await storage.loadUser()
  }

  const setUserToken = async (accessToken: string, refreshToken: string): Promise<AuthenticatedUser | null> => await setUserByTokens({ accessToken, refreshToken })

  const refreshTokens = (): Promise<AuthTokens | void> => {
    const refreshToken = storage.refreshToken
    if (!refreshToken) {
      return Promise.resolve()
    }

    if (storage.isRefreshTokenExpired) {
      storage.clear()
      return Promise.resolve()
    }

    // If another request started refreshing pass the created promise otherwise create refresh request
    refreshPromise = refreshPromise ?? refresh(refreshToken)

    return refreshPromise
      .then((response) => {
        // Set user tokens in cookies and SSR cache
        storage.setUser(response)
        return response
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        // Clear promise for another refreshes
        refreshPromise = null
      })
  }

  $axios.interceptors.request.use(async (req) => {
    if (req.url === '/auth/refresh') {
      return req
    }

    // Sync data with cookies. If on SSR, AuthStorage constructor is enough.
    if (process.client) {
      const { shouldReload } = storage.syncStorage()
      if (shouldReload && window) {
        window.location.reload()
      }
    }

    // Refresh token has expired. There is no way to refresh. Force reset.
    if (storage.isRefreshTokenExpired) {
      storage.clear()

      // Reload when user is logged in and window is available
      if (!!storage.accessToken && process.client && window) {
        window.location.reload()
      }

      return req
    }

    // Access token has expired
    if (storage.isAccessTokenExpired) {
      // Attempt refresh
      try {
        await refreshTokens()
      } catch {
        // Tokens couldn't be refreshed
        storage.clear()
      }
    }

    // If access token is available attach it in the header
    const token = storage.accessToken
    if (token) {
      req.headers.Authorization = token
    }

    return req
  })

  if (storage.isRefreshTokenExpired) {
    storage.clear()
  }

  if (storage.accessToken && !storage.user.value) {
    try {
      await storage.loadUser()
    } catch {
      try {
        await refreshTokens()
        await storage.loadUser()
      } catch {
        storage.clear()
      }
    }
  }

  const reevaluateLoginState = async (): Promise<boolean> => {
    const { shouldReload } = storage.syncStorage()

    if (shouldReload) {
      return true
    }

    if (!storage.user.value && storage.accessToken) {
      await storage.loadUser()
      return true
    }

    return false
  }

  const login = async (loginData: CustomerLoginData) => {
    const response = await loginUser(loginData)
    if ('refreshToken' in response) {
      await setUserByTokens(response)
    }

    return response
  }

  const logout = async () => {
    if (storage.refreshToken) {
      await revoke(storage.refreshToken)
    }

    storage.clear()
  }

  const fetchUser = async (): Promise<AuthenticatedUser | void> => {
    if (!storage.isLoggedIn.value) {
      return
    }

    return await storage.loadUser()
  }

  inject('auth', {
    user: storage.user,
    loggedIn: storage.isLoggedIn,
    fetchUser,
    login,
    logout,
    setUserToken,
    refreshTokens,
    reevaluateLoginState
  } as Auth)
}

declare module 'vue/types/vue' {
  interface Vue {
    $auth: Auth
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $auth: Auth
  }

  interface Context {
    $auth: Auth
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $auth: Auth
  }
}

export default auth
