













import { defineComponent } from '@nuxtjs/composition-api'
import Button from '~/components/DesignSystem/Buttons/Button.vue'

interface PropTypes {
  size: string | null
}

export default defineComponent<PropTypes>({
  components: { Button },
  props: {
    size: {
      type: String,
      default: null
    }
  },
  emits: ['click']
})
