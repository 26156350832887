











































































import {
  defineComponent,
  reactive,
  toRaw,
  ref,
  PropType,
  toRefs,
  computed
} from '@nuxtjs/composition-api'
import { filterWhitespaces } from '~/src/Infrastructure/String/FilterWhiteSpaces'
import { LoyaltyMembersSignUpRequest } from '~/src/Model/LoyaltyMembers/LoyaltyMembersSignUpRequest'
import { useLoyaltyMembersRepository } from '~/src/Infrastructure/LoyaltyMembers/LoyaltyMembersRepository'
import BaseValidationObserver from '~/components/Forms/Validation/BaseValidationObserver.vue'
import FormInput from '~/components/DesignSystem/Input/FormInput.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import Form from '~/components/DesignSystem/Forms/Form.vue'
import { useCustomerAuthRepository } from '~/src/Infrastructure/Auth/CustomerAuthRepository'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import { usePasswordStrengthRepository } from '~/src/Infrastructure/PasswordStrength/PasswordStrengthRepository'
import { PasswordStrength } from '~/src/Model/PasswordStrength/PasswordStrength'
import { CustomerRegistrationData, CustomerRegistrationResponse } from '~/src/Model/Customer/CustomerRegistrationData'
import { useTranslator } from '~/src/Infrastructure/Translations/Translator'
import { useLocalFlashMessageCenter } from '~/src/Infrastructure/FlashMessage/LocalFlashMessageCenter'
import useApiCall from '~/src/Infrastructure/Api/ApiCall'
import InvisibleRecaptcha from '~/components/Forms/Recaptcha/InvisibleRecaptcha.vue'
import PasswordStrengthCheck from '~/components/Auth/PasswordStrengthCheck.vue'
import SuccessConfirm from '~/components/SuccessConfirm/SuccessConfirm.vue'
import LoyaltyClubRegistrationCheckboxes from '~/components/LoyaltyClub/LoyaltyClubRegistrationCheckboxes.vue'
import FlashMessageCenter from '~/components/FlashMessage/FlashMessageCenter.vue'
import { LoyaltyClubRegistrationAgreements } from '~/src/Model/LoyaltyClub/LoyaltyClubRegistrationAgreements'
import { UserBillingAddressResponse } from '~/src/Model/Customer/UserAddress'
import { CustomerInformation } from '~/src/Model/Customer/CustomerInformation'
import LoadingOverlay from '~/components/Loading/LoadingOverlay.vue'
import LoyaltyClubSignUpFormFields from '~/components/LoyaltyClub/LoyaltyClubSignUpFormFields.vue'

const checkPasswordStrengthDelayInMiliseconds = 170

export default defineComponent({
  components: {
    LoyaltyClubSignUpFormFields,
    LoadingOverlay,
    BaseValidationObserver,
    FormInput,
    Button,
    Form,
    InvisibleRecaptcha,
    PasswordStrengthCheck,
    SuccessConfirm,
    LoyaltyClubRegistrationCheckboxes,
    FlashMessageCenter
  },
  props: {
    customerInformation: {
      type: Object as PropType<CustomerInformation | null>,
      required: false,
      default: null
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    userRegistration: {
      type: Boolean,
      default: false
    },
    loyaltyCardRefund: {
      default: null,
      type: Number
    },
    tooltipPlacementBottom: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sign-up-success', 'submit'],
  setup (props, { emit }) {
    const success = ref<boolean>(false)
    const { i18n } = useTranslator()
    const isSubmitted = ref<boolean>(false)

    const defaultBillingAddress = computed<UserBillingAddressResponse | null>(() => props.customerInformation
      ? props.customerInformation.addresses.billing.find(address => address.isDefault) ?? null
      : null)

    const customerInfo = ref<LoyaltyMembersSignUpRequest>({
      firstName: defaultBillingAddress.value?.firstName ?? (props.customerInformation?.firstName ?? ''),
      lastName: defaultBillingAddress.value?.lastName ?? (props.customerInformation?.lastName ?? ''),
      phone: props.customerInformation?.phone ?? '',
      street: defaultBillingAddress.value?.street ?? '',
      city: defaultBillingAddress.value?.city ?? '',
      zip: defaultBillingAddress.value?.zip ?? '',
      birthDate: '',
      agreedWithClubPolicy: false
    })

    const { signUp } = useLoyaltyMembersRepository()

    const { registerCustomer } = useCustomerAuthRepository()
    const authService = useAuthService()

    const { checkPassword } = usePasswordStrengthRepository()
    const passwordStrength = ref<PasswordStrength | null>(null)
    const checkPasswordStrength = async () => {
      passwordStrength.value = await checkPassword({
        password: customerData.password,
        userInputs: [customerData.username]
      })
    }

    const repeatedPassword = ref<String | null>(null)
    const customerData = reactive<CustomerRegistrationData>({
      username: '',
      password: '',
      agreedNewsletter: false,
      agreedPersonalizedNewsletter: false,
      recaptchaToken: ''
    })

    const selectionChanged = (agreements: LoyaltyClubRegistrationAgreements) => {
      customerData.agreedNewsletter = agreements.agreedNewsletter
      customerData.agreedPersonalizedNewsletter = agreements.agreedPersonalizedNewsletter
      customerInfo.value.agreedWithClubPolicy = agreements.agreedClubPolicy
    }

    const { flashMessageCenter, flashMessages } = useLocalFlashMessageCenter()
    const registerCustomerCallback = async () => {
      const response: CustomerRegistrationResponse = await registerCustomer(customerData)
      await authService.setUserToken(response.accessToken, response.refreshToken, true)
    }

    const { exec: submitRegistration, onSuccess: onRegistered } = useApiCall(registerCustomerCallback, flashMessageCenter)
    const { exec: loyaltySignUp, onSuccess: onSignedUp, onCompleted: onCompletedSignUp } = useApiCall(signUp, flashMessageCenter)

    const pending = ref<boolean>(false)

    const submit = () => {
      emit('submit')
      pending.value = true
      if (props.userRegistration) {
        isSubmitted.value = true
        return
      }

      signUpToLoyaltyClub()
    }

    const recaptchaSuccess = (recaptchaToken: string) => {
      flashMessageCenter.clear()
      customerData.recaptchaToken = recaptchaToken
      submitRegistration()
      isSubmitted.value = false
    }

    const recaptchaError = () => {
      flashMessageCenter.error(i18n.t('auth.registration.recaptcha_failed') as string)
      isSubmitted.value = false
    }

    const recaptchaExpired = () => {
      flashMessageCenter.error(i18n.t('auth.registration.recaptcha_expired') as string)
      isSubmitted.value = false
    }

    const signUpToLoyaltyClub = () => {
      customerInfo.value.zip = filterWhitespaces(customerInfo.value.zip)
      loyaltySignUp(toRaw<LoyaltyMembersSignUpRequest>(customerInfo.value))
    }

    onRegistered.subscribe(() => {
      signUpToLoyaltyClub()
    })

    onSignedUp.subscribe(() => {
      success.value = true
      emit('sign-up-success')
    })

    onCompletedSignUp.subscribe(() => {
      pending.value = false
    })

    return {
      customerInfo,
      success,
      checkPasswordStrength,
      passwordStrength,
      checkPasswordStrengthDelayInMiliseconds,
      recaptchaError,
      recaptchaExpired,
      recaptchaSuccess,
      flashMessages,
      ...toRefs(customerData),
      repeatedPassword,
      selectionChanged,
      pending,
      submit,
      isSubmitted
    }
  }
})
