
































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import Big from 'big.js'
import { AverageReview } from '~/src/Model/AverageReview/AverageReview'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'
import TextSkeleton from '~/components/DesignSystem/Skeleton/TextSkeleton.vue'

export default defineComponent({
  components: { TextSkeleton },
  props: {
    averageReview: {
      type: Object as PropType<AverageReview>,
      default: null
    },
    awardsCount: {
      required: true,
      type: Number
    },
    showAnchors: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show-reviews', 'show-awards'],
  setup (props) {
    const { scrollToElement } = useScrollTo()

    const handleClick = (): void => {
      if (props.showAnchors) {
        scrollToElement('#product-reviews')
      }
    }

    const showReviewsSummary = computed<boolean>(() => {
      if (!props.averageReview) {
        return false
      }

      return props.averageReview && props.averageReview.count > 0
    })

    const showAwardsSummary = computed<boolean>(() => {
      return props.awardsCount > 0
    })

    const reviewPercentage = computed<string>(() => {
      if (!props.averageReview?.value) {
        return '0'
      }

      return (new Big(100)).times(props.averageReview.value).div(5).toFixed(0)
    })

    return {
      showReviewsSummary,
      showAwardsSummary,
      reviewPercentage,
      handleClick
    }
  }
})
