export enum ButtonVariant {
  primary = 'primary',
  primaryLight = 'primary-light',
  secondary = 'secondary',
  secondaryInput = 'secondary-input',
  secondaryLoyalty = 'secondary-loyalty',
  light = 'light',
  success = 'success',
  successLight = 'success-light',
  successOutline = 'success-outline',
  red = 'red',
  redLight = 'red-light',
  datartYellow = 'datart-yellow',
  facebook = 'facebook',
  apple = 'apple'
}
