import { Plugin } from '@nuxt/types'
import VueI18n, { NumberFormat, NumberFormatOptions } from 'vue-i18n'
import Vue from 'vue'
import { Web } from '~/src/Model/Config/Web'
import { useCoreDataStore } from '~/src/Infrastructure/CoreData/CoreDataStore'

Vue.use(VueI18n)

const i18n: Plugin = async ({ app, $webConfig, $axios, $pinia }) => {
  let messages = {}
  const cookieLocale = app.$cookies.get('locale')
  const LOCALE = cookieLocale ?? $webConfig.defaultLocale

  if (process.client) {
    const coreDataStore = useCoreDataStore($pinia)
    if (coreDataStore.translations.value !== {}) {
      messages = { [LOCALE]: coreDataStore.translations.value }
    } else {
      const localeMessagesResponse = await $axios.get(`/translations/${LOCALE}`)
      messages = { [LOCALE]: localeMessagesResponse.data }
    }
  }

  const czechCurrency: NumberFormat = {
    currency: {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    currencyDecimal: {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }

  const slovakCurrency: NumberFormat = {
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    } as NumberFormatOptions,
    currencyDecimal: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    } as NumberFormatOptions
  }

  const englishNumberFormatWebMap: { [key in Web]: NumberFormat } = {
    [Web.Nay]: slovakCurrency,
    [Web.Ew]: czechCurrency
  }

  const pluralizationIndex = (choice: number) => {
    if (choice === 1) {
      return 0
      // rok
    }

    if (choice === 2 || choice === 3 || choice === 4) {
      return 1
      // roky
    }

    return 2
    // rokov
  }

  const options: VueI18n.I18nOptions = {
    locale: LOCALE,
    fallbackLocale: LOCALE,
    silentTranslationWarn: true,
    messages,
    numberFormats: {
      cs: czechCurrency,
      sk: slovakCurrency,
      en: englishNumberFormatWebMap[$webConfig.web]
    },
    pluralizationRules: {
      cs: pluralizationIndex,
      sk: pluralizationIndex,
      en: pluralizationIndex
    }
  }

  app.i18n = new VueI18n(options)
}

export default i18n
