






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    paddingInline: {
      type: String,
      default: 'bs-px-3 bs-px-sm-4 bs-py-4'
    }
  }
})
