import { MapRegion, RegionDefinition, RegionToRender } from '~/src/Model/StoresMap/StoresMap'

export const useStoresInRegionsToRender = (regionsData: MapRegion[], regionDefinitions: RegionDefinition[]): RegionToRender[] => {
  return regionDefinitions.map<RegionToRender|null>(
    (regionDefinition: RegionDefinition) => {
      const regionData = findRegion(regionDefinition.name, regionsData)

      if (!regionData) {
        return null
      }

      return {
        region: regionData,
        fullName: regionDefinition.fullName,
        className: regionDefinition.className
      }
    }
  ).filter((regionToRender: RegionToRender|null) => regionToRender !== null) as RegionToRender[]
}

function findRegion (name: string, regionsData: MapRegion[]): MapRegion|null {
  return regionsData.find(region => region.name === name) ?? null
}
