
























































































import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { Web } from '~/src/Model/Config/Web'
import { useStoresInRegionsToRender } from '~/src/Model/StoresMap/useStoresInRegionsToRender'
import { MapListData, MapRegion, RegionToRender } from '~/src/Model/StoresMap/StoresMap'
import RegionStoresList from '~/components/StoresMap/RegionStoresList.vue'
import MapMark from '~/components/StoresMap/MapMark.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import CollapsableElement from '~/components/DesignSystem/Collapse/CollapsableElement.vue'
import CurrentWeb from '~/components/Web/CurrentWeb.vue'

interface PropTypes {
  regions: MapRegion[]
}

export default defineComponent<PropTypes>({
  components: {
    RegionStoresList,
    MapMark,
    Button,
    CollapsableElement,
    CurrentWeb
  },
  props: {
    regions: {
      required: true,
      type: Array as PropType<MapRegion[]>
    }
  },
  setup (props: PropTypes) {
    const mapListData: MapListData = {
      nay: [
        {
          name: 'Bratislavský',
          fullName: 'Bratislavský kraj',
          className: 'bratislavsky'
        },
        {
          name: 'Trnavský',
          fullName: 'Trnavský kraj',
          className: 'trnavsky'
        },
        {
          name: 'Trenčiansky',
          fullName: 'Trenčiansky kraj',
          className: 'trencinsky'
        },
        {
          name: 'Nitranský',
          fullName: 'Nitriansky kraj',
          className: 'nitransky'
        },
        {
          name: 'Žilinský',
          fullName: 'Žilinský kraj',
          className: 'zilinsky'
        },
        {
          name: 'Banskobystrický',
          fullName: 'Banskobystrický kraj',
          className: 'banskobystricky'
        },
        {
          name: 'Košický',
          fullName: 'Košický kraj',
          className: 'kosicky'
        },
        {
          name: 'Prešovský',
          fullName: 'Prešovský kraj',
          className: 'presovsky'
        }
      ],
      ew: [
        {
          name: 'Jihomoravský',
          fullName: 'Jihomoravský kraj',
          className: 'jihomoravsky'
        },
        {
          name: 'Vysočina',
          fullName: 'Kraj Vysočina',
          className: 'vysocina'
        },
        {
          name: 'Moravskoslezský',
          fullName: 'Moravskoslezský kraj',
          className: 'moravskoslezsky'
        },
        {
          name: 'Zlínský',
          fullName: 'Zlínský kraj',
          className: 'zlinsky'
        },
        {
          name: 'Olomoucký',
          fullName: 'Olomoucký kraj',
          className: 'olomoucky'
        },
        {
          name: 'Pardubický',
          fullName: 'Pardubický kraj',
          className: 'pardubicky'
        },
        {
          name: 'Královéhradecký',
          fullName: 'Královéhradecký kraj',
          className: 'kralovehradecky'
        },
        {
          name: 'Liberecký',
          fullName: 'Liberecký kraj',
          className: 'liberecky'
        },
        {
          name: 'Středočeský',
          fullName: 'Středočeský kraj',
          className: 'stredocesky'
        },
        {
          name: 'Praha',
          fullName: 'Praha',
          className: 'praha'
        },
        {
          name: 'Ústecký',
          fullName: 'Ústecký kraj',
          className: 'ustecky'
        },
        {
          name: 'Karlovarský',
          fullName: 'Karlovarský kraj',
          className: 'karlovarsky'
        },
        {
          name: 'Plzeňský',
          fullName: 'Plzeňský kraj',
          className: 'plzensky'
        },
        {
          name: 'Jihočeský',
          fullName: 'Jihočeský kraj',
          className: 'jihocesky'
        }
      ]
    }

    const $webConfig = useWebConfig()
    const isNay = computed<boolean>(() => $webConfig.web === Web.Nay)
    const otherDomain = computed<string>(() => $webConfig.isNay ? 'https://www.electroworld.cz/prodejny' : 'https://www.nay.sk/predajne')

    const existingRegions = computed<RegionToRender[]>(() => {
      return useStoresInRegionsToRender(props.regions, isNay.value ? mapListData.nay : mapListData.ew)
    })

    const largeMapPins = ['bratislavsky']
    const isLarge = (className: string) => largeMapPins.includes(className)

    const lastToggledPin = ref<string>('')

    const togglePin = (pinName: string) => {
      lastToggledPin.value = lastToggledPin.value === pinName ? '' : pinName
    }

    return {
      existingRegions,
      isLarge,
      lastToggledPin,
      togglePin,
      isNay,
      otherDomain
    }
  }
})
