












import { defineComponent, computed } from '@nuxtjs/composition-api'
import MainMenuBrands from '~/components/Header/MainMenu/Brands/MainMenuBrands'
import GiftVouchers from '~/components/Header/MainMenu/GiftVouchers/GiftVouchers'
import { Web } from '~/src/Model/Config/Web'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: { GiftVouchers, MainMenuBrands },
  props: {
    nodeId: {
      type: Number,
      required: false,
      default: null
    },
    disabledBrands: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const webConfig = useWebConfig()
    const isNay = computed<boolean>(() => webConfig.web === Web.Nay)

    return {
      isNay
    }
  }
})
