








import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { PanelLink } from '~/src/Model/PdpBar/PdpBar'

export default defineComponent({
  props: {
    pdpBarLinks: {
      type: Array as PropType<PanelLink[]>,
      required: true
    }
  }
})
