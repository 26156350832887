export const useTextHighlighter = () => {
  const textStart = (text: string, highlight: string) => {
    const index = text.toLowerCase().indexOf(highlight.toLowerCase())
    return index === -1 ? text : text.substr(0, text.toLowerCase().indexOf(highlight.toLowerCase()))
  }

  const textHighlight = (text: string, highlight: string) => {
    const index = text.toLowerCase().indexOf(highlight.toLowerCase())
    return index === -1 ? '' : text.substr(index, highlight.length)
  }

  const textEnd = (text: string, highlight: string) => {
    const index = text.toLowerCase().indexOf(highlight.toLowerCase())
    return index === -1 ? '' : text.substr(index + highlight.length, text.length)
  }

  return {
    textStart,
    textHighlight,
    textEnd
  }
}
