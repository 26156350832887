












import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { FreeDeliveryStatus } from '~/src/Model/Basket/BasketInfo'
import SectionTitle from '~/components/Order/SectionTitle/SectionTitle.vue'
import FreeDeliveryProgress from '~/components/Order/FreeDeliveryProgress/FreeDeliveryProgress.vue'

export default defineComponent({
  components: { SectionTitle, FreeDeliveryProgress },
  props: {
    freeDeliveryStatus: {
      type: Object as PropType<FreeDeliveryStatus>,
      required: true
    }
  }
})
