import { SimpleEventDispatcher } from 'ste-simple-events'
import { ref } from '@nuxtjs/composition-api'
import { ApiError } from '~/src/Infrastructure/Api/ApiError'
import { FlashMessageCenter } from '~/src/Model/FlashMessage/FlashMessageCenter'
import { useApiErrorFactory } from '~/src/Infrastructure/Api/ApiErrorFactory'
import { PromiseType, usePromise } from '~/src/Infrastructure/Api/Promise'

const useApiCall = <T extends Promise<any>, TArgs extends Array<any>>(
  fn: (...args: TArgs) => T,
  flashMessageCenter: FlashMessageCenter
) => {
  const { apiErrorFactory } = useApiErrorFactory()
  const {
    exec,
    loading: pending,
    result
  } = usePromise<T, TArgs>(fn)

  const error = ref<ApiError | null>(null)
  const success = ref<boolean>(false)

  const onErrorDispatcher = new SimpleEventDispatcher<ApiError>()
  const onSuccessDispatcher = new SimpleEventDispatcher<PromiseType<T>>()
  const onCompletedDispatcher = new SimpleEventDispatcher()

  const localExec = async (...args: TArgs) => {
    try {
      error.value = null
      success.value = false
      flashMessageCenter.clear()

      const promiseResult = await exec(...args)

      if (result.value !== null) {
        onSuccessDispatcher.dispatch(result.value)
      }

      success.value = true

      return promiseResult
    } catch (e: any) {
      const apiError = apiErrorFactory(e)

      pending.value = false
      error.value = apiError
      flashMessageCenter.error(apiError.translatedMessage, apiError.internalErrorCode)
      onErrorDispatcher.dispatch(apiError)
    } finally {
      onCompletedDispatcher.dispatch(null)
    }
  }

  return {
    exec: localExec,
    pending,
    success,
    result,
    error,
    onError: onErrorDispatcher.asEvent(),
    onSuccess: onSuccessDispatcher.asEvent(),
    onCompleted: onCompletedDispatcher.asEvent()
  }
}

export default useApiCall
