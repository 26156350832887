





















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { PasswordStrength } from '~/src/Model/PasswordStrength/PasswordStrength'
import Tooltip from '~/components/DesignSystem/Tooltip/Tooltip.vue'

export default defineComponent({
  components: { Tooltip },
  props: {
    passwordStrength: {
      type: Object as PropType<PasswordStrength>,
      required: true
    }
  }
})
