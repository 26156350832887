






import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import FacebookButton from '~/components/DesignSystem/Buttons/FacebookButton.vue'

declare global {
  interface Window {
    FB: any
    fbAsyncInit: any
  }
}
interface PropTypes {
  size: string
}

export default defineComponent<PropTypes>({
  components: { FacebookButton },
  props: {
    size: {
      type: String,
      default: null
    }
  },
  emits: ['input'],
  setup (_props, { emit }) {
    const webConfig = useWebConfig()

    onMounted(() => {
      if (window.FB) {
        initFacebook()
        return
      }

      loadFacebookLoginLibrary()
    })

    const loadFacebookLoginLibrary = (): void => {
      if (document.getElementById('facebook-jssdk')) {
        return
      }

      const googleScript = document.createElement('script')
      googleScript.setAttribute('id', 'facebook-jssdk')
      googleScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
      document.head.appendChild(googleScript)
      window.fbAsyncInit = initFacebook
    }

    const initFacebook = (): void => {
      window.FB.init({
        appId: webConfig.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v3.3'
      })
    }

    const processLoginResponse = (response: any) => {
      if (response.status !== 'connected') {
        return false
      }
      if (!response.authResponse) {
        return false
      }

      emit('input', response.authResponse)
      return true
    }

    const login = () => {
      if (!window.FB) {
        return
      }
      window.FB.login(function (response: any) {
        processLoginResponse(response)
      }, { auth_type: 'rerequest', scope: 'public_profile,email' })
    }

    return {
      login
    }
  }
})
