























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import Big from 'big.js'
import { FreeDeliveryStatus } from '~/src/Model/Basket/BasketInfo'
import ProgressRing from '~/components/Progress/ProgressRing.vue'

export default defineComponent({
  components: { ProgressRing },
  props: {
    freeDeliveryStatus: {
      type: Object as PropType<FreeDeliveryStatus>,
      required: true
    }
  },

  setup (props) {
    const progressPercentage = computed<number>(() => {
      const requiredAmount = new Big(props.freeDeliveryStatus.requiredAmount)
      const priceLimit = new Big(props.freeDeliveryStatus.priceLimit)
      let value = 0

      if (props.freeDeliveryStatus.free) {
        value = 100
      } else {
        value = priceLimit.minus(requiredAmount).times(100).div(priceLimit).toNumber()
      }

      return value
    })

    return { progressPercentage }
  }
})
