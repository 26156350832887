
















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Gift } from '~/src/Model/ProductGift/ProductGift'
import ProductBoxSubbasket from '~/components/Basket/Components/ProductBoxSubbasket.vue'
import ProductRoundedIcon from '~/components/Basket/Components/ProductRoundedIcon.vue'
import ProductName from '~/components/Basket/Components/ProductName.vue'
import ProductPrice from '~/components/Basket/Components/ProductPrice.vue'

export default defineComponent({
  components: {
    ProductBoxSubbasket,
    ProductRoundedIcon,
    ProductName,
    ProductPrice
  },
  props: {
    gift: {
      required: true,
      type: Object as PropType<Gift>
    }
  }
})
