import { Plugin } from '@nuxt/types'
import { AxiosRequestConfig } from 'axios'
import { format } from 'date-fns'

const i18n: Plugin = ({ $axios }) => {
  if (!process.server) {
    return
  }

  if (!process.env.ENABLE_AXIOS_PROFILER) {
    return
  }

  $axios.onRequest((config: AxiosRequestConfig) => {
    const parameters = config.params ? Object.values(config.params).join('&') : null
    let message = `${format(new Date(), 'HH:mm:ss.SSSxxx')} - ${config.url}`

    if (parameters) {
      message += `?${parameters}`
    }

    // eslint-disable-next-line no-console
    console.log(message)
  })
}

export default i18n
