






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Number
    },
    property: {
      required: true,
      type: String
    }
  }
})
