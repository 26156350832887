export interface ChristmasDeliveryConfigurationItem {
  id: number
  uid: string | null
  name: string
  shortName: string
  color: string
  deadline: string
  note: string
  showInBar: boolean
  showInCalendar: boolean
  deliveryType: string
  position: number
}

export enum ChristmasDeliveryBadgeColor {
  GREEN = 'green',
  RED = 'red'
}

export interface ChristmasDeliveryConfiguration {
  configurations: ChristmasDeliveryConfigurationItem[]
  isActiveChristmas: boolean
}

export interface ChristmasDeliveryBadgeConfig {
  text: string
  shortText: string
  color: ChristmasDeliveryBadgeColor
}
