




















import { defineComponent } from '@nuxtjs/composition-api'
import { ShareNetwork } from 'vue-social-sharing'
import DropdownItem from '~/components/DesignSystem/Dropdown/DropdownItem.vue'

export default defineComponent({
  components: {
    ShareNetwork,
    DropdownItem
  },
  props: {
    network: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: null
    },
    iconSpacing: {
      type: String,
      default: 'bs-mr-3'
    }
  }
})
