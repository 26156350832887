export default {
  srcsetSizes: {
    article: {
      descriptor: 'w',
      sizes: [
        'article-w360',
        'article-w545',
        'article-w720',
        'article-w1090'
      ]
    },

    articleSmall: {
      descriptor: 'w',
      sizes: [
        'article-w300',
        'article-w360',
        'article-w600',
        'article-w720'
      ]
    },

    articleHorizontal: {
      descriptor: 'w',
      sizes: [
        'article-w300',
        'article-w360',
        'article-w545',
        'article-w600',
        'article-w1090'
      ]
    },

    articleHero: {
      descriptor: 'w',
      sizes: [
        'articleHero-w345',
        'articleHero-w454',
        'articleHero-w628',
        'articleHero-w860',
        'articleHero-w910',
        'articleHero-w1256',
        'articleHero-w1720'
      ]
    },

    fullWidthHeroMobile: {
      descriptor: 'w',
      sizes: [
        'fullWidthHeroMobile-w375h148',
        'fullWidthHeroMobile-w750h295',
        'fullWidthHeroMobile-w1125h443',
        'fullWidthHeroMobile-w1500h591'
      ]
    },

    fullWidthHeroMobileShopInShop: {
      descriptor: 'w',
      sizes: [
        'fullWidthHeroMobile-w375h280',
        'fullWidthHeroMobile-w750h560',
        'fullWidthHeroMobile-w1125h840',
        'fullWidthHeroMobile-w1500h1119'
      ]
    },

    fullWidthHeroDesktop: {
      descriptor: 'w',
      sizes: [
        'fullWidthHero-w768',
        'fullWidthHero-w932',
        'fullWidthHero-w1280',
        'fullWidthHero-w1536',
        'fullWidthHero-w1864',
        'fullWidthHero-w2560'
      ]
    },

    pageDetailColumnImageMobile: {
      descriptor: 'w',
      sizes: [
        'pageDetailColumn-w400',
        'pageDetailColumn-w600',
        'pageDetailColumn-w800',
        'pageDetailColumn-w1200'
      ]
    },

    pageDetailColumnImageDesktop: {
      descriptor: 'w',
      sizes: [
        'pageDetailColumn-w800',
        'pageDetailColumn-w1200',
        'pageDetailColumn-w1600'
      ]
    },

    wysiwygArticle: {
      descriptor: 'w',
      sizes: [
        'wysiwygArticle-w375',
        'wysiwygArticle-w768',
        'wysiwygArticle-w905',
        'wysiwygArticle-w1536',
        'wysiwygArticle-w1810'
      ]
    },

    storeGallery: {
      descriptor: 'w',
      sizes: [
        'storeGallery-w375',
        'storeGallery-w610',
        'storeGallery-w750',
        'storeGallery-w940',
        'storeGallery-w1220'
      ]
    },

    productGallery: {
      descriptor: 'w',
      sizes: [
        'product-w255h232',
        'product-w319h290',
        'product-w385h350',
        'product-w510h463',
        'product-w765h695',
        'product-w1020h926'
      ]
    },

    productGalleryModal: {
      descriptor: 'w',
      sizes: [
        'productGalleryModal-w300',
        'productGalleryModal-w500',
        'productGalleryModal-w700',
        'productGalleryModal-w900',
        'productGalleryModal-w1100',
        'productGalleryModal-w1300',
        'productGalleryModal-w1500',
        'productGalleryModal-w1700',
        'productGalleryModal-w1950',
        'productGalleryModal-w2200',
        'productGalleryModal-w2340'
      ]
    },

    productBox: {
      descriptor: 'x',
      sizes: [
        'product-w255h232',
        'product-w510h463'
      ]
    },

    productBoxMinimal: {
      descriptor: 'w',
      sizes: [
        'product-w100h100',
        'product-w163h163',
        'product-w170h170',
        'product-w340h340'
      ]
    }
  }
}
