import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'
import { bigify } from '~/src/Infrastructure/Number/Bigify/Bigify'

export class MaxFileSizeRule extends ValidationRule {
  params = ['max', 'unit']

  message = (_: string, { _max, unit }: Record<string, any>): string => {
    return this.i18n.t('validations.max_file_size', { unit }).toString()
  }

  validate = (value: Array<any>, { max }: Record<string, any>): ValidationRuleResult => {
    if (!value) {
      return {
        valid: true
      }
    }

    const fileSize = bigify(value.reduce((count, file) => count + file.size, 0))
    const valid = fileSize.lte(max)

    return {
      valid
    }
  }
}
