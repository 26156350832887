















import { defineComponent } from '@nuxtjs/composition-api'
import { Image } from '~/src/Model/Image/Image'
import { useSrcset } from '~/src/Infrastructure/Image/Srcset'

export default defineComponent({
  props: {
    image: {
      type: Object as () => Image,
      default: null
    },
    isBlank: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: String,
      default: null
    },
    imageSrcset: {
      type: String,
      default: null
    },
    media: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  setup () {
    const srcSet = useSrcset()

    const srcset = (image: Image, size: string, srcsetName: string | null) => {
      return srcSet.generate(image.width, image.height, size, image.id, image.extension, srcsetName)
    }

    return {
      srcset
    }
  }
})
