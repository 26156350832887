








































import { defineComponent, onMounted, PropType, ref } from '@nuxtjs/composition-api'
import OnboardingModal from '~/components/Karpaty/OnboardingModal.vue'
import OnboardingUserActionModal from '~/components/Karpaty/OnboardingUserActionModal.vue'
import { OnboardingCheckedCustomerEvent, OnboardingStep } from '~/src/Model/Karpaty/Onboarding'
import OnboardingUserInfoModal from '~/components/Karpaty/OnboardingUserInfoModal.vue'
import { CheckCustomerResponse } from '~/src/Model/CheckCustomer/CheckCustomer'
import OnboardingLoyaltyClubSignUpModal from '~/components/LoyaltyClub/Karpaty/OnboardingLoyaltyClubSignUpModal.vue'
import { useAuthService } from '~/src/Infrastructure/Auth/UseAuthService'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'

export default defineComponent({
  components: {
    OnboardingLoyaltyClubSignUpModal,
    OnboardingUserInfoModal,
    OnboardingModal,
    OnboardingUserActionModal
  },
  props: {
    checkedCustomerData: {
      type: Object as PropType<CheckCustomerResponse | null>,
      default: null
    }
  },
  emits: ['close-modal'],
  setup (props) {
    const authService = useAuthService()
    const { isLoyaltyMember, isSpecialPriceLevel, isVIP } = useAuthenticatedUser()

    const checkedCustomer = ref<CheckCustomerResponse | null>(props.checkedCustomerData)

    const currentStep = ref<OnboardingStep>(OnboardingStep.info)

    const customerEmail = ref<string | null>(null)
    const isNewAccount = ref<boolean>(false)
    const isFromLogin = ref<boolean>(false)

    onMounted(() => {
      if (!props.checkedCustomerData) {
        return
      }

      currentStep.value = isLoyaltyMember.value ? OnboardingStep.final : OnboardingStep.userInfo
      isFromLogin.value = true
    })

    const customerChecked = (event: OnboardingCheckedCustomerEvent) => {
      currentStep.value = OnboardingStep.userAccountAction
      checkedCustomer.value = event.checkedCustomer
      customerEmail.value = event.email
    }

    const { exec: reloadUser, loading: customerLoading } = usePromise(() => authService.reloadWithTokensRefresh())

    const successfulLoyaltyClubSignUp = () => {
      currentStep.value = OnboardingStep.final
      reloadUser()
    }

    const userRegistered = () => {
      isNewAccount.value = true
      currentStep.value = OnboardingStep.loyaltyClubSignUp
    }

    const userLogged = () => {
      currentStep.value = isSpecialPriceLevel.value && !isVIP.value ? OnboardingStep.final : OnboardingStep.userInfo
    }

    return {
      checkedCustomer,
      customerEmail,
      currentStep,
      OnboardingStep,
      customerChecked,
      successfulLoyaltyClubSignUp,
      userRegistered,
      isNewAccount,
      customerLoading,
      isFromLogin,
      userLogged
    }
  }
})
