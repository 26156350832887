import { AxiosError } from 'axios'
import { ApiResponseError } from '~/src/Model/Error/ApiResponseError'

export const useApiErrorParser = () => {
  const parseInternalErrorCode = (axiosError: AxiosError<ApiResponseError>): number => {
    return axiosError.response?.data?.error?.internalErrorCode ?? 0
  }

  return {
    parseInternalErrorCode
  }
}
