import { computed } from '@nuxtjs/composition-api'
import { Pinia } from 'pinia'
import { Reclamation } from '~/src/Model/Reclamation/Reclamation'
import { PurchasedProduct } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { useReclamationStore as useStore } from '~/stores/reclamation'

export const useReclamationStore = (pinia?: Pinia | null) => {
  const store = useStore(pinia)

  const reclamation = computed<Reclamation | null>(() => store.reclamation)
  const reclamationNumber = computed<string | null>(() => store.reclamationNumber)
  const purchasedProductInfo = computed<PurchasedProduct | null>(() => store.purchasedProductInfo)

  return {
    createReclamationAtHome: store.createReclamationAtHome,
    createReclamationAtStore: store.createReclamationAtStore,
    loadPurchasedProductInfo: store.loadPurchasedProductInfo,
    updateReclamation: store.update,
    setReclamationNumber: store.setReclamationNumber,
    loadPurchasedProductInfoIfNotExist: store.loadPurchasedProductInfoIfNotExist,
    reclamation,
    reclamationNumber,
    purchasedProductInfo
  }
}
