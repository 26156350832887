import { Ref, useRouter } from '@nuxtjs/composition-api'
import { useInteralLinkFactory } from '~/src/Infrastructure/InternalLinkFactory/InternalLinkFactory'
import { parseQueryParametersFromLink } from '~/src/Infrastructure/Url/QueryParameter'
import { useFileLink } from '~/src/Infrastructure/FileLink/FileLink'

export const useWysiwygContentLinkConvertor = (wysiwygContentElement: Ref<HTMLElement>) => {
  const router = useRouter()
  const { isInternalLink, getRelativePathWithoutQueryParams } = useInteralLinkFactory()
  const { isFileLink } = useFileLink()

  const clientRedirectEvent = (event: any, link: string) => {
    if (isInternalLink(link) && !isFileLink(link)) {
      event.preventDefault()
      router.push({
        path: getRelativePathWithoutQueryParams(link),
        query: parseQueryParametersFromLink(link)
      }).catch(() => {})
    }
  }

  const convertLinks = () => {
    const anchorElements = wysiwygContentElement.value?.querySelectorAll('a') ?? []
    anchorElements.forEach((element: HTMLElement) => element.addEventListener('click', (event) => {
      if (element.getAttribute('target') === '_blank') {
        return
      }
      const link = element.getAttribute('href')
      if (!link) {
        return
      }
      clientRedirectEvent(event, link)
    }))
  }

  return {
    convertLinks
  }
}
