import { ProductGiftsCollection } from '~/src/Model/ProductGift/ProductGift'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductGiftsRepository = () => {
  const { $axios } = useAxios()

  return {
    loadGifts: (productId: number): Promise<ProductGiftsCollection> => $axios.$get<ProductGiftsCollection>(`/products/${productId}/gifts`)
  }
}
