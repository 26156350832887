


























import { defineComponent } from '@nuxtjs/composition-api'
import OptionalLoyaltyClubSignUp from '~/components/LoyaltyClub/OptionalLoyaltyClubSignUp.vue'
import SuccessConfirm from '~/components/SuccessConfirm/SuccessConfirm.vue'
import Button from '~/components/DesignSystem/Buttons/Button.vue'
import PageSectionContent from '~/components/DesignSystem/PageSection/PageSectionContent.vue'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'

export default defineComponent({
  components: {
    OptionalLoyaltyClubSignUp,
    SuccessConfirm,
    Button,
    PageSectionContent
  },
  emits: ['close-sidebar', 'close-post-registration-panel'],
  setup () {
    const { loyaltyClubAllowed } = useWebConfig()

    return {
      loyaltyClubAllowed
    }
  }
})
