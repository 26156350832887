import {
  FilterSelected
} from '~/src/Model/ProductCategory/ParameterFilter/Selected'
import { UrlElement } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/UrlElement'
import {
  FilterQuery, FilterSortSettings
} from '~/src/Model/ProductCategory/ParameterFilter/Request'
import {
  ParameterConvertor,
  ParameterConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/ParameterConvertor'
import {
  ReviewConvertor,
  ReviewConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/ReviewConvertor'
import {
  PriceConvertor,
  PriceConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/PriceConvertor'
import {
  StockConvertor,
  StockConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/StockConvertor'
import {
  TagsConvertor,
  TagsConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/TagsConvertor'
import { TranslatedQuery } from '~/src/Model/ProductCategory/ParameterFilter/BackCompatibility/TranslatedQuery'
import {
  SortConvertor,
  SortConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/SortConvertor'
import {
  AvailabilityConvertor,
  AvailabilityConvertorType
} from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/AvailabilityConvertor'
import { TopCategoriesConvertor } from '~/src/Infrastructure/ProductCategory/ParameterFilter/Url/Convertors/TopCategoriesConvertor'
import { PriceLevel } from '~/src/Model/Price/PriceLevel'

export class FilterUrlConvertor {
  private parameterConvertor: ParameterConvertorType
  private priceConvertor: PriceConvertorType
  private stockConvertor: StockConvertorType
  private availabilityConvertor: AvailabilityConvertorType
  private tagsConvertor: TagsConvertorType
  private reviewConvertor: ReviewConvertorType
  private sortConvertor: SortConvertorType
  private topCategoriesConvertor: TopCategoriesConvertor

  constructor (
    parameterConvertor: ParameterConvertorType,
    reviewConvertor: ReviewConvertorType,
    priceConvertor: PriceConvertorType,
    stockConvertor: StockConvertorType,
    availabilityConvertor: AvailabilityConvertorType,
    tagsConvertor: TagsConvertorType,
    sortConvertor: SortConvertorType,
    topCategoriesConvertor: TopCategoriesConvertor
  ) {
    this.parameterConvertor = parameterConvertor
    this.reviewConvertor = reviewConvertor
    this.priceConvertor = priceConvertor
    this.stockConvertor = stockConvertor
    this.availabilityConvertor = availabilityConvertor
    this.tagsConvertor = tagsConvertor
    this.sortConvertor = sortConvertor
    this.topCategoriesConvertor = topCategoriesConvertor
  }

  public persist (filterSelected: FilterSelected, filterSortSettings: FilterSortSettings): string {
    const urlElements: UrlElement[] = []

    const parameters = [...filterSelected.parameters]
    parameters
      .sort((a, b) => {
        return a.parameter.alias.localeCompare(b.parameter.alias)
      })
      .forEach((filterSelectedParameter) => {
        if (this.parameterConvertor.shouldConvert(filterSelectedParameter)) {
          urlElements.push(this.parameterConvertor.toUrl(filterSelectedParameter))
        }
      })

    if (this.priceConvertor.shouldConvert(filterSelected.price)) {
      urlElements.push(this.priceConvertor.toUrl(filterSelected.price))
    }

    if (this.reviewConvertor.shouldConvert(filterSelected.review)) {
      urlElements.push(this.reviewConvertor.toUrl(filterSelected.review))
    }

    if (this.stockConvertor.shouldConvert(filterSelected.stocks)) {
      urlElements.push(this.stockConvertor.toUrl(filterSelected.stocks))
    }

    if (this.availabilityConvertor.shouldConvert(filterSelected.availability)) {
      urlElements.push(this.availabilityConvertor.toUrl(filterSelected.availability))
    }

    if (this.tagsConvertor.shouldConvert(filterSelected.tags)) {
      urlElements.push(this.tagsConvertor.toUrl(filterSelected.tags))
    }

    if (this.topCategoriesConvertor.shouldConvert(filterSelected.topCategories)) {
      urlElements.push(this.topCategoriesConvertor.toUrl(filterSelected.topCategories))
    }

    if (this.sortConvertor.shouldConvert(filterSortSettings)) {
      urlElements.push(this.sortConvertor.toUrl(filterSortSettings))
    }

    return this.createStringFromUrlElements(urlElements)
  }

  public persistFromTranslatedQuery (translatedQuery: TranslatedQuery): string {
    const urlElements: UrlElement[] = []

    const parameters = [...translatedQuery.parameters]
    parameters
      .sort((a, b) => a.alias.localeCompare(b.alias))
      .forEach((parameter) => {
        urlElements.push(this.parameterConvertor.toUrlFromTranslated(parameter))
      })

    if (translatedQuery.price) {
      urlElements.push(this.priceConvertor.toUrlFromTranslated(translatedQuery.price))
    }

    if (translatedQuery.review) {
      urlElements.push(this.reviewConvertor.toUrlFromTranslated(translatedQuery.review))
    }

    if (translatedQuery.tags.length > 0) {
      urlElements.push(this.tagsConvertor.toUrlFromTranslated(translatedQuery.tags))
    }

    if (translatedQuery.sort) {
      urlElements.push(this.sortConvertor.toUrlFromTranslated(translatedQuery.sort))
    }

    return this.createStringFromUrlElements(urlElements)
  }

  public fromString (persistedValueString: string, priceLevel: PriceLevel): Partial<FilterQuery> {
    const persistedItems = persistedValueString.split('/').map(i => UrlElement.fromString(i))
    const filterQuery: Partial<FilterQuery> = {}

    for (const urlElement of persistedItems) {
      try {
        this.updateFilterQueryByUrlElement(urlElement, filterQuery, priceLevel)
      } catch (e) {
        console.error(e) // eslint-disable-line no-console
      }
    }

    return filterQuery
  }

  private createStringFromUrlElements (urlElements: UrlElement[]): string {
    return urlElements.map(e => e.toString()).join('/')
  }

  private updateFilterQueryByUrlElement (urlElement: UrlElement, filterQuery: Partial<FilterQuery>, priceLevel: PriceLevel) {
    if (this.priceConvertor.support(urlElement)) {
      filterQuery.price = {
        level: priceLevel,
        intervals: this.priceConvertor.fromUrl(urlElement)
      }
      return
    }

    if (this.reviewConvertor.support(urlElement)) {
      filterQuery.review = this.reviewConvertor.fromUrl(urlElement)
      return
    }

    if (this.stockConvertor.support(urlElement)) {
      filterQuery.stocks = this.stockConvertor.fromUrl(urlElement)
      return
    }

    if (this.availabilityConvertor.support(urlElement)) {
      filterQuery.availability = this.availabilityConvertor.fromUrl(urlElement)
      return
    }

    if (this.tagsConvertor.support(urlElement)) {
      filterQuery.tags = this.tagsConvertor.fromUrl(urlElement)
      return
    }

    if (this.topCategoriesConvertor.support(urlElement)) {
      filterQuery.topCategories = this.topCategoriesConvertor.fromUrl(urlElement)
      return
    }

    if (filterQuery.parameters === undefined) {
      filterQuery.parameters = []
    }

    if (this.sortConvertor.support(urlElement)) {
      filterQuery.sort = this.sortConvertor.fromUrl(urlElement)
      return
    }

    filterQuery.parameters.push(this.parameterConvertor.fromUrl(urlElement))
  }
}

export const filterUrlConvertor = new FilterUrlConvertor(
  new ParameterConvertor(),
  new ReviewConvertor(),
  new PriceConvertor(),
  new StockConvertor(),
  new AvailabilityConvertor(),
  new TagsConvertor(),
  new SortConvertor(),
  new TopCategoriesConvertor()
)

export const filterUrlConvertorFactory = () => new FilterUrlConvertor(
  new ParameterConvertor(),
  new ReviewConvertor(),
  new PriceConvertor(),
  new StockConvertor(),
  new AvailabilityConvertor(),
  new TagsConvertor(),
  new SortConvertor(),
  new TopCategoriesConvertor()
)
