






import { defineComponent } from '@nuxtjs/composition-api'
import { AppleCredentialsResponse } from '~/src/Model/Auth/Apple/Apple'
import useWebConfig from '~/src/Infrastructure/Nuxt/Config/UseWebConfig'
import AppleButton from '~/components/DesignSystem/Buttons/AppleButton.vue'

export default defineComponent({
  components: { AppleButton },
  props: {
    size: {
      type: String,
      default: null
    }
  },
  emits: ['input', 'error'],
  setup (_props, { emit }) {
    const webConfig = useWebConfig()
    const loadAppleLoginLibrary = (): void => {
      if (document.getElementById('appleId-sdk')) {
        openPopup()
        return
      }
      const appleScript = document.createElement('script')
      appleScript.setAttribute('src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js')
      appleScript.setAttribute('id', 'appleId-sdk')
      appleScript.onload = () => initAppleLogin()
      document.body.appendChild(appleScript)
    }

    const initAppleLogin = (): void => {
      // @ts-ignore
      AppleID.auth.init({
        clientId: webConfig.appleLoginClientId,
        scope: 'name email',
        redirectURI: webConfig.appleLoginRedirectUri,
        state: 'CaSrčweK2KJ23uihcjaw', // neřešíme, je pouze vyplněn random stringem
        usePopup: true
      })
      openPopup()
    }

    const openPopup = async (): Promise<void> => {
      try {
        // @ts-ignore
        const data: AppleCredentialsResponse = await AppleID.auth.signIn()
        emit('input', data)
      } catch (e) {
        emit('error')
      }
    }

    return {
      loadAppleLoginLibrary
    }
  }
})
