import { ProductDataLayerData } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Product/ProductDataLayerData'
import { DataLayerBasketProduct } from '~/src/Model/Datalayer/DataLayerBasket'

export const createDataLayerBasketProduct = (basketProduct: DataLayerBasketProduct): ProductDataLayerData => {
  return {
    item_id: basketProduct.product.id.toString(),
    price: basketProduct.product.price,
    item_brand: basketProduct.product.brandName ?? '',
    item_name: basketProduct.product.name,
    item_category: basketProduct.product.category,
    item_category2: basketProduct.product.category2,
    item_sku: basketProduct.product.sku,
    item_sap_id: basketProduct.product.sapId ?? '',
    item_ean: basketProduct.product.ean,
    item_availability: basketProduct.product.availability,
    quantity: basketProduct.quantity.toString()
  }
}
