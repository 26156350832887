import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { WishLists, WishList, WishListDetail } from '~/src/Model/WishLists/WishLists'
import { WishlistShareByEmail } from '~/src/Model/Share/ShareByEmail'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useWishListsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    addWishList: (name: string): Promise<WishList> => $axios.$post<WishList>('/wish-lists', { name }),
    loadWishList: (hash: string): Promise<WishListDetail> => $axios.$get<WishListDetail>(`/wish-lists/${hash}`),
    removeWishListItem: (hash: string, id: number): Promise<void> => $axios.$delete<void>(`/wish-lists/${hash}/items/${id}`),
    loadWishLists: (productId: number | null = null): Promise<WishLists> => $axios.$get<WishLists>('/wish-lists',
      { params: { productId } }),
    addProduct: (hash: string, productId: number): Promise<void> => $axios.$post<void>(`/wish-lists/${hash}/items`, { productId }),
    removeWishList: (hash: string): Promise<void> => $axios.$delete<void>(`/wish-lists/${hash}`),
    wishlistShareByEmail: (hash: string, request: WishlistShareByEmail): Promise<void> => $axios.$post<void>(`/wish-lists/${hash}/share-by-email`, request)
  }
}

export const useWishListsRepository = () => {
  const { $axios } = useAxios()

  return useWishListsRepositoryWithAxios($axios)
}
