























































import { computed, defineAsyncComponent, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import RichText from '~/components/ContentEditor/RichText.vue'
import { ContentEditorBlock, ContentEditorBlockType, ProductBox, ProductBoxList, KarpatyAccordeon } from '~/src/Model/ContentEditor/ContentEditor'
import Accordeon from '~/components/ContentEditor/Accordeon.vue'
import { useRequiredTemplateRef } from '~/src/Infrastructure/Vue/TemplateRef'
import { useLazyLoadOnMounted } from '~/src/Infrastructure/Lazyload/LazyLoadOnMounted'
import { useLazyLoadOnUpdated } from '~/src/Infrastructure/Lazyload/LazyLoadOnUpdated'
import { useWysiwygContentLinkConvertor } from '~/src/Infrastructure/WysiwygContentLink/WysiwygContentLinksConvertor'
import { useVisibilityLazyLoad } from '~/src/Infrastructure/VisbilityLazyLoad/VisibilityLazyLoad'
import { useProductBoxesRepository } from '~/src/Infrastructure/ProductBoxes/ProductBoxesRepository'
import { usePromise } from '~/src/Infrastructure/Api/Promise'
import PreBasketSidebar from '~/components/Basket/PreBasket/PreBasketSidebar.vue'
import { BasketProduct } from '~/src/Model/Basket/Product'
import { ProductBox as ProductBoxData } from '~/src/Model/ProductBox/ProductBox'
import { useImageRepository } from '~/src/Infrastructure/Image/ImageRepository'
import { Image } from '~/src/Model/Image/Image'

export default defineComponent({
  components: {
    PreBasketSidebar,
    Accordeon,
    RichText,
    KarpatyAccordeonComponent: defineAsyncComponent(() => import('~/components/ContentEditor/KarpatyAccordeon.vue')),
    ProductBoxList: defineAsyncComponent(() => import('~/components/ContentEditor/ProductBoxList.vue')),
    ProductBox: defineAsyncComponent(() => import('~/components/ContentEditor/ProductBox.vue'))
  },
  props: {
    richTextTag: {
      type: String,
      default: 'div'
    },
    richTextClass: {
      type: String,
      default: null
    },
    contentEditorBlocks: {
      required: true,
      type: Array as PropType<ContentEditorBlock[]>
    },
    allowedBlocks: {
      type: Array as PropType<String[]>,
      default: () => []
    },
    richTextStyles: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { lazyLoad } = useLazyLoadOnMounted()
    const { loadImages } = useImageRepository()
    useLazyLoadOnUpdated(lazyLoad)
    const rootElement = useRequiredTemplateRef<HTMLElement>()
    const { convertLinks } = useWysiwygContentLinkConvertor(rootElement)
    const { loadProductBoxes } = useProductBoxesRepository()
    const basketProduct = ref<BasketProduct | null>(null)

    const filteredBlocks = computed<ContentEditorBlock[]>(() => {
      return props.allowedBlocks.length === 0 ? props.contentEditorBlocks : props.contentEditorBlocks.filter(block => props.allowedBlocks.includes(block.type))
    })

    const productBoxesIds = computed<number[]>(() => {
      return props.contentEditorBlocks
        .filter(block => [ContentEditorBlockType.productBox, ContentEditorBlockType.productBoxList].includes(block.type))
        .flatMap((block) => {
          if (block.type === ContentEditorBlockType.productBox) {
            return Number((block.value as ProductBox).productId)
          }

          return (block.value as ProductBoxList).productIds.map(id => Number(id))
        })
    })

    const imagesToFetch = computed<number[]>(() => {
      return props.contentEditorBlocks
        .filter(block => block.type === ContentEditorBlockType.karpatyAccordeon)
        .flatMap(block => (block.value as KarpatyAccordeon).items.filter(i => !!i.imageId).map(i => Number(i.imageId)))
    })

    const {
      result: productBoxes,
      exec: execLoadProductBoxes,
      loading: productBoxesLoading
    } = usePromise(() => loadProductBoxes(productBoxesIds.value))

    const {
      result: images,
      exec: execLoadImages
    } = usePromise(() => loadImages(imagesToFetch.value))

    useVisibilityLazyLoad(
      rootElement,
      convertLinks,
      {
        rootMargin: '400px'
      }
    )

    useVisibilityLazyLoad(
      rootElement,
      () => {
        if (productBoxesIds.value.length) {
          execLoadProductBoxes()
        }

        if (imagesToFetch.value.length) {
          execLoadImages()
        }
      },
      {
        rootMargin: '400px'
      }
    )

    const isProductBoxAvailable = (productId: number): boolean => {
      return productBoxes.value?.productBoxes.some(pb => pb.product.id === productId) ?? false
    }

    const getProductBox = (productId: number): ProductBoxData | null => {
      return productBoxes.value?.productBoxes.find(pb => pb.product.id === productId) ?? null
    }

    const getImage = (imageId: string): Image | null => {
      if (!imageId) {
        return null
      }

      return images.value?.images.find(i => i.id === Number(imageId)) ?? null
    }

    return {
      blockTypes: ContentEditorBlockType,
      filteredBlocks,
      rootElement,
      productBoxes,
      productBoxesLoading,
      basketProduct,
      productBoxesIds,
      isProductBoxAvailable,
      getProductBox,
      getImage
    }
  }
})
