
import { BrowserType } from '~/src/Model/Browser/BrowserType'

export const useBrowserDetect = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const userAgent = window.navigator.userAgent
  if (!/Mobi/i.test(userAgent)) {
    return BrowserType.DESKTOP
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return BrowserType.IOS
  }
  return BrowserType.ANDROID
}
