import { ValidationRuleResult } from 'vee-validate/dist/types/types'
import { ValidationRule } from '~/src/Infrastructure/Form/Validation/ValidationRules/ValidationRule'

export class InvalidRule extends ValidationRule {
  message = (field: string, { startingString }: Record<string, any>) => this.i18n.t('validations.invalid', { field, startingString }).toString()

  validate = (_value: any): ValidationRuleResult => {
    return {
      valid: false
    }
  };
}
