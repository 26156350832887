import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { PurchasedProductsList, PurchasedProductsSearchRequest, PurchasedProductsSearchResponse } from '~/src/Model/PurchasedProduct/PurchasedProduct'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const usePurchasedProductsRepository = () => {
  const { $axios } = useAxios()

  return usePurchasedProductsRepositoryWithAxios($axios)
}

export const usePurchasedProductsRepositoryWithAxios = ($axios: NuxtAxiosInstance) => {
  return {
    getPurchasedProducts: (page: number = 1, limit: number = 10): Promise<PurchasedProductsList> => {
      return $axios.$get<PurchasedProductsList>('/purchased-products', { params: { page, limit } })
    },

    searchPurchasedProducts: (request: PurchasedProductsSearchRequest): Promise<PurchasedProductsSearchResponse> => {
      return $axios.$post<PurchasedProductsSearchResponse>('/purchased-products/search', { ...request })
    }
  }
}
