
















































import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useCustomerInfoFetch } from '~/src/Infrastructure/Customers/CustomerInfoFetch'
import LoyaltyClubSignUpForm from '~/components/LoyaltyClub/LoyaltyClubSignUpForm.vue'
import Modal from '~/components/DesignSystem/Modals/Modal.vue'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import LoadingContent from '~/components/Loading/LoadingContent.vue'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'

export default defineComponent({
  components: {
    Alert,
    LoadingContent,
    LoyaltyClubSignUpForm,
    Modal
  },
  props: {
    isNewAccount: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sign-up-success', 'close-modal', 'cancel-sign-up'],
  setup (_, { emit }) {
    const { authenticatedUserId } = useAuthenticatedUser()
    const { customerInformation, loading } = useCustomerInfoFetch(authenticatedUserId.value)

    const signUpPending = ref<boolean>(false)

    const signUpSuccess = () => {
      signUpPending.value = false
      emit('sign-up-success')
    }

    const cancelSignUp = () => {
      if (signUpPending.value) {
        return
      }

      emit('sign-up-success')
    }

    return {
      customerInformation,
      loading,
      signUpPending,
      signUpSuccess,
      cancelSignUp
    }
  }
})
