import { ComputedRef, ref, useAsync, watch, computed } from '@nuxtjs/composition-api'
import { SimpleEventDispatcher } from 'ste-simple-events'
import { AclPrivilege, AclResources } from '~/src/Model/Acl/AclPermission'
import { useAuthenticatedUser } from '~/src/Infrastructure/Auth/AuthenticatedUser'
import { useAclStore } from '~/stores/acl'

export const useAcl = (resource: string, privilege: AclPrivilege = AclPrivilege.read) => {
  const { authenticatedUser } = useAuthenticatedUser()
  const aclStore = useAclStore()
  const useFetchCompleted = ref<boolean>(false)
  const onSuccess = new SimpleEventDispatcher<void>()
  const evaluated = ref<boolean>(false)
  const onError = new SimpleEventDispatcher<void>()
  const onCompleted = new SimpleEventDispatcher<void>()

  useAsync(async () => {
    if (useFetchCompleted.value) {
      return
    }
    try {
      await aclStore.evaluatePermission(resource, privilege)
      onSuccess.dispatch()
    } catch (e) {
      onError.dispatch()
    } finally {
      evaluated.value = true
      useFetchCompleted.value = true
      onCompleted.dispatch()
    }
  }, 'useAcl')

  watch(authenticatedUser, async () => {
    if (!useFetchCompleted.value) {
      return
    }
    try {
      await aclStore.evaluatePermission(resource, privilege)
      onSuccess.dispatch()
    } catch (e) {
      onError.dispatch()
    } finally {
      evaluated.value = true
      onCompleted.dispatch()
    }
  })

  const allowed: ComputedRef<boolean> = computed(() => aclStore.privilegeAllowed(resource, privilege))
  const resources: ComputedRef<AclResources> = computed(() => aclStore.resources)

  return {
    allowed,
    resources,
    evaluated,
    onSuccess: onSuccess.asEvent(),
    onError: onError.asEvent(),
    onCompleted: onCompleted.asEvent()
  }
}
