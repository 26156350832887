














import { defineComponent } from '@nuxtjs/composition-api'
import ProductSpecialState from '~/components/ProductDetail/ProductSpecialStates/ProductSpecialState.vue'
import { useScrollTo } from '~/src/Infrastructure/Scroll/ScrollTo'

export default defineComponent({
  components: { ProductSpecialState },
  props: {
    showAlternativeProductsLink: {
      required: true,
      type: Boolean
    }
  },
  setup () {
    const { scrollToElement } = useScrollTo()

    return {
      scrollToElement
    }
  }
})
