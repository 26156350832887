import { AccessoryTypes } from '~/src/Model/ProductAccessoryType/ProductAccessoryType'
import { useAxios } from '~/src/Infrastructure/Nuxt/Axios/UseAxios'

export const useProductAccessoryTypesRepository = () => {
  const { $axios } = useAxios()

  return {
    loadProductAccessoryTypes: (id: number): Promise<AccessoryTypes> => $axios.$get<AccessoryTypes>(`/products/${id}/accessory-types`)
  }
}
