





import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { RichText } from '~/src/Model/ContentEditor/ContentEditor'

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    contentEditorRichText: {
      required: true,
      type: Object as PropType<RichText>
    },
    styles: {
      type: String,
      default: null
    }
  }
})
