


























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import OnboardingLoginPanel from '~/components/Auth/Karpaty/OnboardingLoginPanel.vue'
import { useDatartOnboardingModal } from '~/src/Infrastructure/Karpaty/DatartOnboardingModal'
import { defaultForceLoginEmail, ForceLoginEmail } from '~/src/Model/CheckCompanyCustomer/CheckCompanyCustomer'
import LoginPanel from '~/components/Auth/LoginPanel.vue'

export default defineComponent({
  components: { LoginPanel, OnboardingLoginPanel },
  props: {
    showRegistrationLink: {
      type: Boolean,
      default: false
    },
    forceLoginState: {
      type: Object as PropType<ForceLoginEmail>,
      default: () => defaultForceLoginEmail
    },
    dataLayerFormId: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: ''
    },
    showRegistrationPanel: {
      type: Boolean,
      default: false
    },
    disableSocials: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-sidebar', 'show-lost-password-panel', 'logged', 'show-registration-panel', 'two-factor-verification-started', 'two-factor-verification-cancelled'],
  setup () {
    const { isOnboardingAllowed } = useDatartOnboardingModal()
    const loginPanelTag = computed<string>(() => isOnboardingAllowed.value ? 'OnboardingLoginPanel' : 'LoginPanel')

    return {
      loginPanelTag
    }
  }
})
