var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.validationRules,"tag":_vm.tag,"data-test":_vm.dataTest},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{class:[
      _vm.checkboxClass,
      {'custom-checkbox--w-full' : _vm.full},
      {'custom-checkbox--hover-bordered': _vm.hoverBordered},
      {'custom-checkbox--small': _vm.small}
    ],attrs:{"state":errors[0] && _vm.required ? false : null,"name":_vm.name,"disabled":_vm.disabled,"switch":_vm.isSwitch,"value":_vm.checkedValue,"unchecked-value":_vm.unCheckedValue,"indeterminate":_vm.isIndeterminate},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_c('span',{class:[
        _vm.spanClass,
        {'d-flex w-100' : _vm.full},
        {'typo-complex-14': _vm.small}
      ]},[_vm._t("default"),_vm._v(" "),(_vm.required && ! _vm.hideRequired)?_c('span',[_vm._v("*")]):_vm._e()],2),_vm._v(" "),(_vm.tooltip)?_c('a',{staticClass:"icon-info text-icon-info align-middle complex-link--hover-underline",attrs:{"href":"#"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('show_tooltip')))])]):_vm._e()]),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"state":errors[0] && _vm.required ? false : null}},[_vm._v("\n    "+_vm._s(errors[0])+"\n  ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }