

























import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useBasketStore } from '~/src/Infrastructure/Basket/BasketStore'
import { BasketProduct } from '~/src/Model/Basket/Product'
import BasketBoxProduct from '~/components/Header/BasketBox/BasketBoxProduct.vue'
import BasketBoxProductBundle from '~/components/Header/BasketBox/BasketBoxProductBundle.vue'
import { useRemoveFromBasketEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Event/RemoveFromBasketEvent'
import { useAddToBasketEvent } from '~/src/Infrastructure/Google/TagManager/DataLayer/Ecommerce/Basket/Event/AddToBasketEvent'

export default defineComponent({
  components: { BasketBoxProductBundle, BasketBoxProduct },
  setup () {
    const basketStore = useBasketStore()
    const pendingChange = ref<boolean>(false)

    const dataLayerRemoveFromBasketEvent = useRemoveFromBasketEvent()
    const dataLayerAddToBasketEvent = useAddToBasketEvent()

    const deleteProduct = async (basketProduct: BasketProduct) => {
      pendingChange.value = true

      await basketStore.deleteBasketProduct(basketProduct.id)
      dataLayerRemoveFromBasketEvent.fire(basketProduct.product.id, basketProduct.quantity)

      pendingChange.value = false
    }

    const changeQuantity = async (basketProduct: BasketProduct, quantity: number) => {
      pendingChange.value = true

      await basketStore.updateBasketProduct({
        basketProductId: basketProduct.id,
        quantity
      })

      if (basketProduct.quantity < quantity) {
        dataLayerAddToBasketEvent.fire(basketProduct.product.id, quantity - basketProduct.quantity)
      } else if (basketProduct.quantity > quantity) {
        dataLayerRemoveFromBasketEvent.fire(basketProduct.product.id, basketProduct.quantity - quantity)
      }

      pendingChange.value = false
    }

    const changeProductBundleQuantity = async (basketBundleId: number, quantity: number) => {
      pendingChange.value = true

      await basketStore.updateProductBundle({
        basketBundleId,
        quantity
      })

      pendingChange.value = false
    }

    const deleteBundle = async (basketBundleId: number) => {
      pendingChange.value = true

      await basketStore.deleteProductBundle(basketBundleId)

      pendingChange.value = false
    }

    const deleteProductService = async (basketProductId: number, basketProductServiceId: number) => {
      pendingChange.value = true

      await basketStore.deleteService(basketProductId, basketProductServiceId)

      pendingChange.value = false
    }

    return {
      basketProducts: basketStore.basketProducts,
      basketProductBundles: basketStore.basketProductBundles,
      deleteProduct,
      changeQuantity,
      pendingChange,
      changeProductBundleQuantity,
      deleteBundle,
      deleteProductService
    }
  }
})
