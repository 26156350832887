
















































import { computed, defineComponent } from '@nuxtjs/composition-api'
import { isAfter, parse } from 'date-fns'
import Alert from '~/components/DesignSystem/Alert/Alert.vue'
import ChristmasDeliveryCalendarSkeleton from '~/components/ChristmasDelivery/Skeleton/ChristmasDeliveryCalendarSkeleton.vue'
import ChristmasDeliveryItem from '~/components/ChristmasDelivery/ChristmasDeliveryItem.vue'
import {
  useChristmasDeliveryCalendarConfig
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryCalendarConfig'
import {
  ChristmasDeliveryConfigurationItem
} from '~/src/Model/ChristmasDeliveryConfiguration/ChristmasDeliveryConfiguration'
import { useDateFormatter } from '~/src/Infrastructure/Date/DateFormatter'
import {
  useChristmasDeliveryConfigurationStore
} from '~/src/Infrastructure/ChristmasDeliveryConfiguration/ChristmasDeliveryConfigurationStore'

export default defineComponent({
  components: {
    Alert,
    ChristmasDeliveryItem,
    ChristmasDeliveryCalendarSkeleton
  },
  props: {
    overrideToday: {
      type: Date,
      default: () => new Date()
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const $formatter = useDateFormatter()
    const startDate: Date = parse('2023-12-10 00:00:00', 'yyyy-MM-dd HH:mm:ss', new Date())
    const endDate: Date = parse('2023-12-24 23:59:59', 'yyyy-MM-dd HH:mm:ss', new Date())

    const christmasDeliveryConfigurationStore = useChristmasDeliveryConfigurationStore()

    const {
      today,
      days,
      getDeadlineDayIndex,
      todayIsBeforeStartDate,
      todayIsAfterEndDate
    } = useChristmasDeliveryCalendarConfig(startDate, endDate, props.overrideToday)

    const sortConfigurations = (configurations: ChristmasDeliveryConfigurationItem[]) => {
      const endedDeliveries: ChristmasDeliveryConfigurationItem[] = []
      const availableDeliveries: ChristmasDeliveryConfigurationItem[] = []

      configurations.filter(configItem => configItem.showInCalendar)
        .forEach((configItem) => {
          if (isAfter(today.value, $formatter.getValue(configItem.deadline))) {
            endedDeliveries.push(configItem)
            return
          }
          availableDeliveries.push(configItem)
        })

      return availableDeliveries.sort((a, b) => a.position - b.position)
        .concat(endedDeliveries.sort((a, b) => a.position - b.position))
    }

    const personalPickupConfigurations = computed<ChristmasDeliveryConfigurationItem[]>(() => sortConfigurations(christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value.filter(config => ['personal_pickup', 'pickup_places'].includes(config.deliveryType))))
    const courierDeliveryConfigurations = computed<ChristmasDeliveryConfigurationItem[]>(() => sortConfigurations(christmasDeliveryConfigurationStore.christmasDeliveryConfiguration.value.filter(config => config.deliveryType === 'courier')))

    return {
      christmasDeliveryConfiguration: christmasDeliveryConfigurationStore.christmasDeliveryConfiguration,
      dataLoaded: christmasDeliveryConfigurationStore.dataLoaded,
      personalPickupConfigurations,
      courierDeliveryConfigurations,
      days,
      today,
      getDeadlineDayIndex,
      todayIsBeforeStartDate,
      todayIsAfterEndDate
    }
  }
})
