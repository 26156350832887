import { AxiosError } from 'axios'
import { RequestBodyValidation, ScalarContext } from '~/src/Model/Error/ApiResponseError'

export class ApiError<T = ScalarContext> extends Error {
  httpCode: number
  internalErrorCode: number
  axiosError: AxiosError
  translatedMessage: string
  context: T | null
  requestBodyValidation: RequestBodyValidation[]

  constructor (message: string, translatedMessage: string, httpCode: number, internalErrorCode: number, context: T | null, axiosError: AxiosError, requestBodyValidation: RequestBodyValidation[]) {
    super(message)
    this.translatedMessage = translatedMessage
    this.httpCode = httpCode
    this.internalErrorCode = internalErrorCode
    this.axiosError = axiosError
    this.context = context
    this.requestBodyValidation = requestBodyValidation
  }
}
