import { defineStore } from 'pinia'
import { WishList, WishListItems } from '~/src/Model/WishLists/WishLists'
import { useWishListsRepositoryWithAxios } from '~/src/Infrastructure/WishLists/WishListsRepository'

const WISH_LISTS_STORE_ID = 'wishLists'

interface WishListsState {
  wishLists: WishList[]
}

export const useWishListsStore = defineStore(WISH_LISTS_STORE_ID, {
  state: (): WishListsState => ({
    wishLists: []
  }),
  getters: {
    wishListsByProductId () {
      return (productId: number) => {
        const selectedLists: string[] = []

        this.wishLists.forEach((w: WishList) => {
          if (w.items.some(i => i.productId === productId)) {
            selectedLists.push(w.hash)
          }
        })

        return selectedLists
      }
    },
    wishListsItemsCount () {
      const productIds = new Set<number>()
      if (this.wishLists === null) {
        return productIds.size
      }

      this.wishLists.forEach((wishlist: WishList) => {
        wishlist.items.forEach(item => productIds.add(item.productId))
      })

      return productIds.size
    }
  },
  actions: {
    async loadWishLists (): Promise<void> {
      const { loadWishLists } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      const response = await loadWishLists()

      this.setWishLists(response.wishLists)
    },
    clearWishLists (): void {
      this.$reset()
    },
    setWishLists (wishLists: WishList[]): void {
      this.wishLists = wishLists
    },
    async addWishList (name: string): Promise<WishList> {
      const { addWishList } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      return await addWishList(name)
    },
    async removeWishList (hash: string): Promise<void> {
      const { removeWishList } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      await removeWishList(hash)

      return await this.loadWishLists()
    },
    async addProduct (hash: string, productId: number): Promise<void> {
      const { addProduct } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      await addProduct(hash, productId)

      return await this.loadWishLists()
    },
    async removeProduct (hash: string, productId: number): Promise<void> {
      const { removeWishListItem } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      await removeWishListItem(hash, productId)

      return await this.loadWishLists()
    },
    async removeItems (wishListItems: WishListItems): Promise<void> {
      const { removeWishListItem } = useWishListsRepositoryWithAxios(this.$nuxt.$axios)
      await Promise.all(wishListItems.itemsIds.map(async itemId => await removeWishListItem(wishListItems.hash, itemId)))

      return await this.loadWishLists()
    }
  }
})
