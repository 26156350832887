import { defineStore } from 'pinia'
import { AuthenticatedUser } from '~/src/Model/Auth/AuthenticatedUser'
import { useCustomerAuthRepositoryWithAxios } from '~/src/Infrastructure/Auth/CustomerAuthRepository'

const AUTH_STORE_ID = 'auth'

interface AuthState {
  user: AuthenticatedUser | null
}

export const useAuthStore = defineStore(AUTH_STORE_ID, {
  state: (): AuthState => ({
    user: null
  }),
  actions: {
    async loadUser (): Promise<AuthenticatedUser> {
      const { getUser } = useCustomerAuthRepositoryWithAxios(this.$nuxt.$axios)
      this.user = await getUser()
      return this.user
    },
    clearResources (): void {
      this.$reset()
    }
  }
})
